import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DeleteIcon from "../images/deleteicon.png";
import LoadingIcon  from "../images/loadingicon.gif";


class ClientFormat extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      showModal2:false,
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      teammembersdata:[],
      firstname:"",
      lastname:"",
      username:"",
      email:"",
      workinglocation:"",
      password:"",
      userrole:"",
      userdegreename:"",
      userstatus:"",
      mobile:"",
      reportingto:"",
      reporting_toname:"",
      BusinessUnit:"",
      adminprivileges:"No",
      dob:"",
      doj:"",
      teamids:"",
      degreename:"",
      employeeID:"",
      AdminPrivileges:"",
      edituserid:"",
      userstatusname:"",
      dobchange:"",
      dojchange:"",
      updatemsg:"",
      firstnametwo:"",
      userdegreenametwo:"",
      degreenametwo:"",
      BussinessUnit:"",
      TLroleids:"",
      TLdegreename:"",
      TLname:"",
      Description:"",
      DataPermission:"",
      tstatus:"",
      updated_by:"",
      totalmembers:"",
      editdegreeid:"",
      original_rows: [], 
      rows: [],
       search: "",
       loadingscreen:0
      
    }
   
    this.columns = [
     
         
          {
            name: "Doc Type Name",
            selector:"doctypename",
            sortable: true
          },
         
          {
            name: "Uploaded By",
            selector:"uploadedbyname",
            sortable: true
          },
          {
            name: "Created Date",
            selector:"createddatetext",
            sortable: true
          },
           {
            name:"Download",
          cell: (e) => (
              <>
           <a href={e.docfile} download={e.doctypename} style={{margin:"3%"}} className="btn btn-primary updatebtn">Download</a>
           
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    
          {
            name:"Action",
          cell: (e) => (
              <>
            
           <img src={DeleteIcon} style={{width:"20%",marginLeft:"35%",marginTop:"7%",cursor:"pointer"}} onClick={() =>this.Deleteuser(e.id)} />
           
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];
   
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleUpdateone=this.handleUpdateone.bind(this)
  }
  Deleteuser(id){
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
        var newURL=window.location.pathname;
        var splitURL=newURL.toString().split("/");
        var data6=splitURL[6]
          const data={ userid:withoutQuotes,
            addctsubmissiondata:"deletects",
            clientid:data6,
            editctsubmissionid:id
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 this.setState({message:response.message,loadingscreen:0}) 
              //console.log(response)
           
              Swal.fire(
                response.message,
                
               
              )
             
              setTimeout(
                function() {
                   
                    var username=JSON.stringify(localStorage.getItem("UserId"))
                    const withoutQuotes = username.replaceAll('"', '');
                    var newURL=window.location.pathname;
                    var splitURL=newURL.toString().split("/");
                    var data6=splitURL[6]
                    const data={userid:withoutQuotes,addctsubmissiondata:"getctsubmissioninfo",clientid:data6}
                    console.log(data)
                    fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
                        method: "POST",
                
                        body: JSON.stringify(data), // data can be `string` or {object}!
                
                        headers: { "Content-Type": "application/json" },
                      })
                        .then((res) => res.json())
                
                        .catch((error) => console.error("Error:", error))
                
                        .then((response) => {
                        if(response.message=="No Data Found."){
                          window.location.reload()
                        }
                        else{
                          let data=response.result
                          data = data.map((currentValue, Index) => {
                            currentValue.SNO = Index + 1;
                            return currentValue;
                          });
                          this.setState({ original_rows: data, rows: data,loadingscreen:1 });
                             
                          for (var i = 0; i < this.state.original_rows.length; i++) {
                            delete this.state.original_rows[i].docfile
                          
                        }
                                  
                        }
                           
                      });
                
                    const node = loadCSS(
                      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
                      document.querySelector("#font-awesome-css")
                    );
                
                    return () => {
                      node.parentNode.removeChild(node);
                    };
                }
                .bind(this),
              2500
            );
             
                    
                  });
       
      }
    })
  }
  
  handleUpdateone(){

    if(this.state.degreename==""){
      this.setState({updatemsg:"Please Enter Degree Name."})
     
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes1 = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes1,
        addDegreedata:"editDegreeinfo",
        editdegreeid:this.state.editdegreeid,
        degreename:this.state.degreename
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getdegreeinfo", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                
                this.setState({updatemsg:response.message})
                setTimeout(
                  function() {
                   
                      var username=JSON.stringify(localStorage.getItem("UserId"))
                      const withoutQuotes = username.replaceAll('"', '');
                      console.log(withoutQuotes)
                      const data={userid:withoutQuotes,addDegreedata:"getDegreeinfo"}
                      console.log(data)
                      fetch("https://www.mrecruit.in:5200/api/getdegreeinfo", {
                          method: "POST",
                  
                          body: JSON.stringify(data), // data can be `string` or {object}!
                  
                          headers: { "Content-Type": "application/json" },
                        })
                          .then((res) => res.json())
                  
                          .catch((error) => console.error("Error:", error))
                  
                          .then((response) => {
                           
                             let data=response.result
                                data = data.map((currentValue, Index) => {
                                  currentValue.SNO = Index + 1;
                                  return currentValue;
                                });
                                this.setState({ original_rows: data, rows: data });
                                
                              });
                     
                  
                      const node = loadCSS(
                        "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
                        document.querySelector("#font-awesome-css")
                      );
                      this.setState({showModal:false})
                      return () => {
                        node.parentNode.removeChild(node);
                      };
                     
                  }
                  .bind(this),
                  1000
              );
        
                    
                  });
    }
   
      }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes1 = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes1,
      edituserid:this.state.edituserid,
      adduserdata:"edituserinfo",
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userdegreename:this.state.userdegreename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:this.state.BusinessUnit,
      adminprivileges:this.state.AdminPrivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      degreename:this.state.degreename,
      timezoneid:1,
      timezonename:"US Time",
      employeeID:this.state.employeeID
    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
    
              this.setState({updatemsg:response.message})
            setTimeout(
              function() {
               
                window.location.reload()
              }
              .bind(this),
              2500
          );
               console.log(response)
      
                  
                });
      }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userdegreename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({degreename:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editdegreeid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addDegreedata:"getsingledegreeinfo",editdegreeid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdegreeinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
          console.log(response)
          response.result.map((e)=>{
            this.setState({
             
              degreename:e.name,
              //totalmembers:e.totalmembers
              editdegreeid:e.editdegreeid
            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false,showModal2:false });
  };
openModal2(id){
  
  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotes = username.replaceAll('"', '');
    const data={ userid:withoutQuotes,adduserdata:"getteammemberinfo",editusrroleid:id}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
        console.log(response)
    if(response.message=="No Teams Found"){

    }
    else{
      this.setState({teammembersdata:response.result,showModal2: true},()=>{
        //console.log(this.state.teammembersdata)
      })
      
    }
        
      
              
            });
}
  componentDidMount() {
    const rodata={ dropdownlist_input: "getuserrole"}
    console.log(rodata)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(rodata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });

      const rdata={ dropdownlist_input: "getreportinginfo"}
      console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes1 = username.replaceAll('"', '');
       const tdata={ userid: withoutQuotes1,
        adduserdata:"getteamsinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data6=splitURL[6]
    const data={userid:withoutQuotes,addctsubmissiondata:"getctsubmissioninfo",clientid:data6}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
           
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data,loadingscreen:1 });
               
              for (var i = 0; i < this.state.original_rows.length; i++) {
                delete this.state.original_rows[i].docfile
               
            }
            //console.log(this.state.original_rows)
            });
           
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()),
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
   

    return (
      <>{this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content" style={{width:"40%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Degree Information
                  </h5>
              <hr/>
           
            </div>
            <div class="ft-modal-body">
           
            <div>
 
     <div className="row mt-3">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Degree Name</span>
 
  </div>
  <div className="col-xl-8">
 
  <input type="text" value={this.state.degreename}  onChange={this.handleChange} className="form-control  adduser" name="degreename" />
  </div>

     </div>
    
 
     <div className="row mt-3">
  <div className="col-xl-5">

  </div>

<div className="col-xl-2">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdateone}>Update</button>
</div>
  </div>
  <div className="row mt-3">
  <div className="col-xl-4">

  </div>

<div className="col-xl-7">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.updatemsg}</span>
</div>
  </div>
  
</div>
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}


      <div className={styles.pageContainer} style={{marginLeft:"1%"}}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"2em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
         
          
          <div className={styles.clientContainer_old} >
            <div className={styles.tableContainer}>
            {this.state.loadingscreen==0?<>
            <img src={LoadingIcon} style={{marginLeft:"36em",width:"5%"}}/>
          </>:<> <DataTable
               
                
               columns={this.columns}
               data={this.state.rows}
               pagination
               striped
               dense
               
             /></>}
             
        
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default ClientFormat;
