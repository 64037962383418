import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import ClockIMG from "../images/clockimg.png";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import Resizer from "react-image-file-resizer";
import UserImg from "../images/usering.png";
import Clientimage  from "../images/clientimage.png";
import Select from 'react-select'
import Header from "./Header";
const style = {
    control: (base, state) => ({
      ...base,
     border:"1px solid #A0BED8",
      borderRadius:"1px",
     marginTop:"1.5%",
     backgroundColor:"#EAF4FF",
     color:"black",
     borderRadius:"2px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: "1px solid #A0BED8",
        borderRadius:"1px",
        backgroundColor:"#EAF4FF",
        color:"black"
      }
    }),
   
  };
  
export class ViewClient extends React.Component{
    
    constructor(){
        super();
        var newURL=window.location.pathname;
        var splitURL=newURL.toString().split("/");
        var data1=splitURL[2]
        var data2=splitURL[3]
        var data3=splitURL[4]
        var data4=splitURL[5]
        var data5=splitURL[6]
       let decodedid=window.atob(data5)
       let decodedrolename=window.atob(data3)
       let roleid=window.atob(data4)
     
       if (localStorage.getItem("Username") == null) {
            

        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`


       } else {
        var username=JSON.stringify(localStorage.getItem("Username"))
        
       }

        const withoutQuotes = username.replaceAll('"', '');
        this.state={
            user_name:withoutQuotes,
            encodedid:data1,
            encodedname:data2,
            encodedemail:data3,
            userroleids:roleid,
            user_email:decodedrolename,
            encodedroleid:data4,
            display:0,
            clientid:decodedid,
            fullname:"",
            showModal2:false,
            JobTitle:"",
            CountryName:"",
            ApplicantID:"",
            EmailID:"",
            MobileNo:"",
            created_on:"",
            AppStatusname:"",
            TotalExp:"",
            SourceName:"",
            KeySkills:"",
            NoticePeriod:"",
            ExpectedCTC:"",
            DateofBirth:"",
            Relocate:"",
            HighestDegree:"",
            linkedInurl:"",
            PreviousOrg:"",
            PrevDesignation:"",
            Address:"",
            BusinessUnit:"",
            AboutCompany:"",
            POC1_conactname:"",
            POC1_email:"",
            POC1_contactno:"",
            POC2_conactname:"",
            POC2_email:"",
            POC2_contactno:"",
            POC3_conactname:"",
            POC3_email:"",
            POC3_contactno:"",
            editclientid:"",
            showModal: false,
            countrydata:[],
            clientlogo:"",
            cname:"",
            websiteurl:"",
            Countryid:"",
            Stateid:"",
            Cityid:"",
            Industryid:"",
            Countryname:"",
            Postalcode:"",
            Statename:"",
            Cityname:"",
            Industryname:"",
            categoryid:"",
            CategoryName:"",
            cstatus:"",
            ClientLeadids:"",
            ClientLeadname:"",
            ReqDocument:"",
            clientname:"",
            industrydata:[],
            catageroydata:[],
            documentdata:[
              { value: '1', label: 'CV' },
              { value: '2', label: 'Resume' },
              { value: '3', label: 'Others' },
            ],



        }
        this.handleDisplay=this.handleDisplay.bind(this)
        this.handleDisplayClose=this.handleDisplayClose.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.handleBack=this.handleBack.bind(this)
        this.handleUpdateImage=this.handleUpdateImage.bind(this)
        this.handleMaster=this.handleMaster.bind(this)
        this.handleUser=this.handleUser.bind(this)
        this.handleHome=this.handleHome.bind(this)
        this.handleJob=this.handleJob.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.hanldelClient=this.hanldelClient.bind(this) 
        this.handleBack=this.handleBack.bind(this) 
        this.handleCountry=this.handleCountry.bind(this)
        this.handleUpdate=this.handleUpdate.bind(this) 
        this.handleReport=this.handleReport.bind(this)  
        this.handleIndustry=this.handleIndustry.bind(this)  
        this.handleCity=this.handleCity.bind(this) 
        this.handleCategory=this.handleCategory.bind(this)
        this.handleRequired=this.handleRequired.bind(this)
        this.handleOnboarding=this.handleOnboarding.bind(this)
    }
    handleOnboarding(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
    }
    handleRequired(e){
      this.setState({ReqDocument:e.value})
    }
    handleCategory(e){
      this.setState({CategoryName:e.label,categoryid:e.value},()=>{
       
      })
    }
    handleCity(e){
      this.setState({Cityname:e.label,Cityid:e.value})
    }
    handleIndustry(e){
      this.setState({Industryname:e.label,Industryid:e.value})
    }
    handleReport(e){
      this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
    }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handleUpdate(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={
        userid:withoutQuotes,
        addclientdata:"editclientinfo",
        editclientid:this.state.editclientid,
        clientlogo:this.state.clientlogo,
        cname:this.state.cname,
        BusinessUnit:this.state.BusinessUnit,
        POC1_conactname:this.state.POC1_conactname,
        POC1_email:this.state.POC1_email,
        POC1_contactno:this.state.POC1_contactno,
        POC2_conactname:this.state.POC2_conactname,
        POC2_email:this.state.POC2_email,
        POC2_contactno:this.state.POC1_contactno,
        POC3_conactname:this.state.POC3_conactname,
        POC3_contactno:this.state.POC3_contactno,
        POC3_email:this.state.POC3_email,
        cphone:this.state.cphone,
        Address:this.state.Address,
        BusinessUnit:this.state.BusinessUnit,
        websiteurl:this.state.websiteurl,
        Countryid:this.state.Countryid,
        Stateid:this.state.Stateid,
        Cityid:this.state.Cityid,
        Countryname:this.state.Countryname,
        Statename:this.state.Statename,
        Cityname:this.state.Cityname,
        Postalcode:this.state.Postalcode,
        Industryid:this.state.Industryid,
        Industryname:this.state.Industryname,
        categoryid:this.state.categoryid,
        CategoryName:this.state.CategoryName,
        AboutCompany:this.state.AboutCompany,
        cstatus:this.state.cstatus,
        ClientLeadids:this.state.ClientLeadids,
        ClientLeadname:this.state.ClientLeadname,
        ReqDocument:this.state.ReqDocument
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
               this.setState({updatemsg:response.message})
               setTimeout(
                function() {
                 
                  window.location.reload()
                }
                .bind(this),
                2500
            );
                 
                 console.log(response)
        
                    
                  });
        }
    handleCountry(e){
      this.setState({Countryname:e.label,Countryid:e.value,},()=>{
        const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({statedata:options})
                  
                });
        })
    }
    closeModal = () => {
        this.setState({ showModal: false });
      };
    handleBack(){
        window.history.back()
    }
    hanldelClient(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
    handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      }
      handleJob() {
        let data = window.location.pathname.slice(0, 12);
      
        window.location.href = `${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
      }
    handleHome(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
      }
    handleUser(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    handleMaster(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    openModal = (id) => {
        this.setState({editclientid:id})
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,addclientdata:"getsingleclientinfo",editclientid:id}
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
              console.log(response)
              response.result.map((e)=>{
                this.setState({
                  clientname:e.clientname,
                  BusinessUnit:e.BusinessUnit,
                  Companyphone:e.Companyphone,
                  POC1_conactname:e.POC1_conactname,
                  POC1_email:e.POC1_email,
                  POC1_contactno:e.POC1_contactno,
                  POC2_conactname:e.POC2_conactname,
                  POC2_email:e.POC2_email,
                  POC2_contactno:e.POC2_contactno,
                  POC3_conactname:e.POC3_conactname,
                  POC3_email:e.POC3_email,
                  POC3_contactno:e.POC3_contactno,
                  Address:e.Address,
                  websiteurl:e.websiteurl,
                  Countryid:e.Countryid,
                  Stateid:e.Stateid,
                  Cityid:e.Cityid,
                  Countryname:e.Countryname,
                  Statename:e.Statename,
                  Cityname:e.Cityname,
                  Postalcode:e.Postalcode,
                  Industryid:e.Industryid,
                  Industryname:e.Industryname,
                  categoryid:e.categoryid,
                  CategoryName:e.CategoryName,
                  AboutCompany:e.AboutCompany,
                  cstatus:e.cstatus,
                  ClientLeadids:e.ClientLeadids,
                  ClientLeadname:e.ClientLeadname,
                  ReqDocument:e.ReqDocument,
                  clientstatus:e.clientstatus,
                  clientlogo:e.clientlogo,
                  cphone:e.POC2_contactno,
                
    
    
                },()=>{
    
                  const data1={ dropdownlist_input: "getstateinfo",countryid:e.Countryid}
          
          fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
              method: "POST",
      
              body: JSON.stringify(data1), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
               const data = response.result
                //console.log(json)
          
              const options = data.map(d => ({
                  "value" : d.id,
                  "label" : d.name
      
                })) 
         
              this.setState({statedata:options})
                    
                  });
                  const data={ dropdownlist_input: "getcityinfo",stateid:e.Stateid}
          
          fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
               const data = response.result
                //console.log(json)
          
              const options = data.map(d => ({
                  "value" : d.id,
                  "label" : d.name
      
                })) 
         
              this.setState({citydata:options})
                    
                  });
          
                })
              })
                    
                  });
         
        this.setState({ showModal: true });
      };
    openModal2=()=>{
        this.setState({ showModal2: true });
      }
      closeModal2 = () => {
        this.setState({ showModal2: false });
      };
    getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };
      handleImage = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              profilepic: result,
              ProfileImg:result,
              file,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
      handleImage1 = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              clientlogo: result,
             
              file,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
      handleUpdateImage(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"editsinglephotoinfo",
            profilepic:this.state.profilepic
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 this.setState({message:response.message}) 
              //console.log(response)
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
               2500
            );
             if(response.message=="Profile Pic Updated successfully"){
              const withoutQuotes = username.replaceAll('"', '');
              const data={ userid:withoutQuotes,
                adduserdata:"getsinglephotoinfo",
              
             
            }
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                    
                 //console.log(response)
                     this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                     
                            
                     })
                 
                        
                      });
             }
              
                    
             
                    
                  });
      }
    handleBack(){
        window.history.back()
    }
    componentDidMount(){
      /*Category API Call Start */
const data11={ dropdownlist_input: "getclientcategoryinfo"}
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(data11), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.categoryid,
       "label" : d.categoryname

     })) 

   this.setState({catageroydata:options})
         
       });
/* Category API Call End */
      /* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
       /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */
       /* Country API Call Start */
  const tdata={ dropdownlist_input: "getcountryinfo"
}
 console.log(tdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(tdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         console.log(response)
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.countryid,
         "label" : d.conuntryname

       })) 

     this.setState({countrydata:options})
           
         });
 /* Country API Call  End */

        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes1 = username.replaceAll('"', '');
        const pdata={ userid:withoutQuotes1,
          adduserdata:"getsinglephotoinfo",
        
       
      }
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(pdata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              
              if(response.result.profilepic=="BASE64"){
                this.setState({ProfileImg:""},()=>{
                 
                })
            
             }
             else{
                this.setState({ProfileImg:response.result.profilepic},()=>{
                 
                })
            
             }
                  
                });
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,addclientdata:"getsingleclientinfo",editclientid:this.state.clientid}
         // console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
              console.log(response)
             response.result.map((e)=>{
              this.setState({
                fullname:e.clientname,
                clientlogo:e.clientlogo,
                JobTitle:e.Industryname,
                CountryName:e.Countryname,
                Address:e.Address,
                EmailID:e.POC1_email,
                MobileNo:e.POC1_contactno,
                BusinessUnit:e.BusinessUnit,
                AboutCompany:e.AboutCompany,
                POC1_conactname:e.POC1_conactname,
                POC1_email:e.POC1_email,
                POC1_contactno:e.POC1_contactno,
                POC2_conactname:e.POC2_conactname,
                POC2_email:e.POC2_email,
                POC2_contactno:e.POC2_contactno,
                POC3_conactname:e.POC3_conactname,
                POC3_email:e.POC3_email,
                POC3_contactno:e.POC3_contactno
               


              })
             })
                    
                  });
    }
    handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
        handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
    render(){
        return(
           
            <div>
                {/**Edit Client Modal Starts */}
                {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content  bigmodalcontent">
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Client Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            
 <div>

 <div className="row">
    <div className="col-xl-1">
    
{this.state.clientlogo==null || this.state.clientlogo.length==1 || this.state.clientlogo.length==0?<>
<img src={Clientimage} 
 
 style={{width:"120%"}}  />
</>:<>  <img src={this.state.clientlogo} 
 
 style={{width:"120%",border:"2px solid #4293fe"}}  /></>}


  

   
    </div>
    <div className="col-xl-2">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage1} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-3">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Client Name</span>
    </div>
    <div className="col-xl-3">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Address</span>
    </div>
    <div className="col-xl-3">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Contact Number</span>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Business Unit</span>
    </div>
  </div>
  <div className="row">
    <div className="col-xl-3">
     <input className="form-control adduser mt-2"
     value={this.state.clientname}
      placeholder="Client Name" name="cname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
     <input name="Address"
      value={this.state.Address}
     onChange={this.handleChange} className="form-control adduser mt-2" placeholder="Address"/>
    </div>
    <div className="col-xl-3">
     <input 
     name="cphone"
     value={this.state.cphone}
     onChange={this.handleChange}
     className="form-control adduser mt-2" placeholder="Contact Number"/>
    </div>
    <div className="col-xl-3">
     
     <Select 
     placeholder={this.state.BusinessUnit}
        onChange={(e)=>this.handleBusiness(e)}
        options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                                  styles={style}/>
        </div>
  </div>

  <div className="row mt-3">
  <div className="col-xl-3">
  <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Country</span>
    <Select 
    placeholder={this.state.CountryName}
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
       </div>
  <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>State</span>
    <Select 
    placeholder={this.state.Statename}
    onChange={(e)=>this.handleState(e)}
    options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>City</span>
    <Select 
    onChange={(e)=>this.handleCity(e)}
    placeholder={this.state.Cityname}
    options={this.state.citydata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Category</span>
 <Select 
    onChange={(e)=>this.handleCategory(e)}
    placeholder={this.state.CategoryName}
    options={this.state.catageroydata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    
    
  </div>
  
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily:"Inter Bold"}}>POC-1</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Name</span>
     <input value={this.state.POC1_conactname} name="POC1_conactname" onChange={this.handleChange} placeholder="Name" 
      className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Contact Number</span>
     <input type="text" 
     value={this.state.POC1_contactno}
     name="POC1_contactno" onChange={this.handleChange} 
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Email</span>
     <input type="email" 
     value={this.state.POC1_email}
      name="POC1_email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily:"Inter Bold"}}>POC-2</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Name</span>
     <input 
      value={this.state.POC2_conactname}
     name="POC2_conactname" onChange={this.handleChange}
      placeholder="Name"  className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Contact Number</span>
     <input type="text" 
     value={this.state.POC2_contactno}
     name="POC2_contactno" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Email</span>
     <input type="email" 
     value={this.state.POC2_email}
     name="POC2_email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily:"Inter Bold"}}>POC-3</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Name</span>
     <input   value={this.state.POC3_conactname}    name="POC3_conactname" 
     onChange={this.handleChange} placeholder="Name"  className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Contact Number</span>
     <input type="text" 
    name="POC3_contactno" 
    value={this.state.POC3_contactno} 
    onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Email</span>
     <input type="email" 
       name="POC3_email" 
       value={this.state.POC3_email} 
       onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  
  <div className="row mt-2">
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Website</span>
    <input type="text" 
     value={this.state.websiteurl}
     name="websiteurl" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Industry</span>
    <Select 
    placeholder={this.state.Industryname}
    onChange={(e)=>this.handleIndustry(e)}
    options={this.state.industrydata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Status</span>
    <Select 
   
    onChange={(e)=>this.handleUserstatus(e)}
    placeholder="Active"
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Client Manager</span>
    <Select 
    placeholder={this.state.ClientLeadname}
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>

 
    <div className="row mt-2">
   
   
   <div className="col-xl-6">
   <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>Required Documents</span>
   <Select 
   onChange={(e)=>this.handleRequired(e)} placeholder="CV"
   options={this.state.documentdata}  components={{  IndicatorSeparator:() => null }}
                             styles={style}/>
   </div>
   <div className="col-xl-6">

   <span style={{fontWeight:"bold",fontFamily:"Poppins Light"}}>About Company</span>
  <textarea name="AboutCompany"
  value={this.state.AboutCompany}
  onChange={this.handleChange} className="form-control adduser" rows="4"></textarea>
   </div>
 </div>
   <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
 
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 

          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
                {/**Edit Client Modal Ends */}
                         {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{ cursor: "pointer" }}>
              Home
            </li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleJob} style={{ cursor: "pointer" }}>
              Job Posting
            </li>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li >Applicants</li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext" onClick={this.hanldelClient} style={{ cursor: "pointer" }}>
              Clients
            </li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleOnboarding} style={{cursor:"pointer"}}> Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==9 ?<>
          
          </>:<> <li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul>
        <Header/>
        </div>

        {/* Header Section Ends */}
        
           {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}  
        <div
              style={{
                backgroundColor: "#EAF4FF",
                height: "auto",
                paddingBottom: "3%",
              }}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            {/**View Client Page Starts */}
                           <div style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                        <table>
                            <tr>
                                <th style={{width:"6%",verticalAlign:"sub"}}>
                                  {this.state.clientlogo==null|| this.state.clientlogo.length==1 || this.state.clientlogo.length==0?<>
                                  <img src={UserImg} style={{width:"74%",borderRadius:"53%"}} /></>:<>
                                  <a href={this.state.websiteurl} target="_blank"><img src={this.state.clientlogo} className="clientlogo" /></a>
                                  </>}
</th>
                                <th style={{width:"90%",lineHeight:"25px"}}><h3>{this.state.fullname}</h3>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.JobTitle==null?<>N/A</>:<>{this.state.JobTitle}</>}</span><br/>
                                   <i className="fa fa-map-marker"></i> <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.Address
},{this.state.CountryName}</span><br/>
                                   <i className="fa fa-envelope-o"></i> <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.EmailID==null?<>N/A</>:<>{this.state.EmailID}</>}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-mobile" style={{fontSize:20,verticalAlign:"text-bottom"}}></i> <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.MobileNo==null?<>N/A</>:<>{this.state.MobileNo}</>}</span><br/>
                                   <span style={{fontFamily:"Inter Bold",fontWeight:"bold"}}>Business Unit - </span><span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.BusinessUnit}</span><br/>
                                   <span style={{fontFamily:"Inter Bold",fontWeight:"bold"}}>About - </span><span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.AboutCompany}</span><br/>
                                </th>
                                <th>
                                <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(this.state.clientid)} ><button className="btn btn-primary updatebtn" style={{marginTop:"-12em",width:"10em"}} >Edit Client Info</button></a>
                                    </th>&nbsp;&nbsp;
                                <th><button className="btn btn-primary updatebtn" style={{marginTop:"-12em"}} onClick={this.handleBack}>Back</button></th>
                            </tr>
                        </table>

                           </div>
                     {/**View Client Page End */}
                              {/**Documents Section Starts */}
                              <div   style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "20em",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-sm-10">
                                <h5 style={{fontFamily:"Inter",fontWeight:"bold"}}>
                                   Documents
                                </h5>
                            </div>
                            <div className="col-xl-2" style={{textAlign:"right"}}>
                            <i className="fa fa-share-alt" style={{fontSize:20}}></i>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-download" style={{fontSize:20,cursor:"pointer"}}></i>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-plus-circle" style={{fontSize:20,cursor:"pointer"}}></i>
                            </div>
                        </div>
                        <hr/>
                    </div>
                        {/**Documents Section Ends */}
                        {/**Submission Section Starts */}
                        <div    style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "20em",
                    
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-xl-11">
                                <h5 style={{fontFamily:"Inter",fontWeight:"bold"}}>
                                   POC 1
                                </h5>
                               
                            </div>
                            <div className="col-xl-1">
                           
                            </div>
                           
                        </div>
                        <hr/>
                        <table style={{marginTop:"5em"}}>
                                  <tr><td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-users" aria-hidden="true"></i>{this.state.POC1_conactname==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC1_conactname}</>}</span>
                                    </td>
                                    <td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-envelope" aria-hidden="true"></i>{this.state.POC1_email==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC1_email}</>}</span>
                                    </td>
                                    <td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-mobile" style={{fontSize:20}} aria-hidden="true"></i>{this.state.POC1_contactno==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC1_contactno}</>}</span>
                                    </td>
                                    </tr>
                                </table>
                    </div>
                    <div    style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "20em",
                    
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-xl-11">
                                <h5 style={{fontFamily:"Inter",fontWeight:"bold"}}>
                                   POC 2
                                </h5>
                            </div>
                            <div className="col-xl-1">
                           
                            </div>
                           
                        </div>
                        <hr/>
                        <table style={{marginTop:"5em"}}>
                                  <tr><td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-users" aria-hidden="true"></i>{this.state.POC2_conactname==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC2_conactname}</>}</span>
                                    </td>
                                    <td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-envelope" aria-hidden="true"></i>{this.state.POC2_email==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC2_email}</>}</span>
                                    </td>
                                    <td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-mobile" style={{fontSize:20}} aria-hidden="true"></i>{this.state.POC2_contactno==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC2_contactno}</>}</span>
                                    </td>
                                    </tr>
                                </table>
                    </div>
                    <div    style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "20em",
                    
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-xl-11">
                                <h5 style={{fontFamily:"Inter",fontWeight:"bold"}}>
                                   POC 3
                                </h5>
                            </div>
                            <div className="col-xl-1">
                           
                            </div>
                           
                        </div>
                        <hr/>
                        <table style={{marginTop:"5em"}}>
                                  <tr><td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-users" aria-hidden="true"></i>{this.state.POC3_conactname==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC3_conactname}</>}</span>
                                    </td>
                                    <td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-envelope" aria-hidden="true"></i>{this.state.POC3_email==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC3_email}</>}</span>
                                    </td>
                                    <td style={{width:"20em"}}>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}><i class="fa fa-mobile" style={{fontSize:20}} aria-hidden="true"></i>{this.state.POC3_contactno==null?<>&nbsp;&nbsp;N/A</>:<>&nbsp;&nbsp;{this.state.POC3_contactno}</>}</span>
                                    </td>
                                    </tr>
                                </table>
                    </div>
                        {/**Submission Section Ends */}

                  
                        </div>
                     

                    </div>
                   
                  
                  
                </div>
        </div>
            </div>
        )
    }
}