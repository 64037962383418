import React,{Suspense,lazy} from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import Swal from "sweetalert2";
import Select from "react-select";
import Tabs, { Tab } from "react-best-tabs";
import Resizer from "react-image-file-resizer";
import Applicantimg from "../images/applicantimg.png";
import "react-best-tabs/dist/index.css";
import Jobpostingdatatable from "./Jobpostingdatatable";
import LoadingIcon  from "../images/loadingicon.gif";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import DeletedApplicant from "./DeletedApplicants";
import Header from "./Header";
const Activeapplicant=lazy(()=>import("./Activeapplicant"))
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #A0BED8",
    borderRadius: "1px",
    marginTop: "1.5%",
    backgroundColor: "#EAF4FF",
    color: "black",
    borderRadius: "2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius: "1px",
      backgroundColor: "#EAF4FF",
      color: "black",
    },
  }),
};

export default class Applicants extends React.Component {
  constructor() {
    var username = JSON.stringify(localStorage.getItem("Username"));
    const withoutQuotes = username.replaceAll('"', "");
    var newURL = window.location.pathname;
    var splitURL = newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let decodedid = window.atob(data1);
    let decodedname = window.atob(data2);
    let decodedemail = window.atob(data3);
    let roleid = window.atob(data5);
    super();
    this.state = {
      user_name: withoutQuotes,
      display: 0,
      encodedid: data1,
      encodedname: data2,
      encodedemail: data3,
      user_email:decodedemail,
      displayform: 0,
      userroleids: roleid,
      encodedroleid: data5,
      newjob: 0,
      jobpostingscreen: 0,
      countrydata: [],
      statedata: [],
      citydata: [],
      userstausdata: [{ value: "1", label: "Active" }],
      PrefCountryID: "",
      PrefCountryName: "",
      PrefStateID: "",
      PrefStateName: "",
      PrefCityName: "",
      PrefCityID: "",
      clientdata: [],
      HiringManager: "",
      HiringManagerid: "",
      reportingtodata: [],
      SPOCMobile: "",
      SPOCName: "",
      Assingnedid: [],
      businessunit: [{ value: "1", label: "Maintec Technologies" }],
      JobTitle: "",
      ClientName: "",
      BusinessUnit: "",
      languagesdata: [],
      degreedata: [],
      ClientId: "",
      CBR_Coin: "INR",
      PR_Coin: "INR",
      PR_MinAmount: "",
      PR_MaxAmount: "",
      ClientJobID: "CJ505",
      NoofPositions: "",
      ClosedPostions: "",
      JobCreatedDate: "",
      JobClosingDate: "",
      SPOC: "",
      HiringManagerid: "",
      HiringManager: "",
      NoticePeriod: "",
      JobDescription: "",
      DegreeID: "",
      Degreename: "",
      ExperienceMin: "",
      ExperienceMax: "",
      PrimarySkills: "",
      Languages: "",
      JobStatus: "",
      SPOCMobile: "",
      SPOCName: "",
      JobCode: "",
      AssingnedID: "",
      AssingnedName: "",
      Assingnedname: [],
      ProfilePhoto: " ",
      documentscreen:0,
      personaldetailscreen:0,
      mobileerror:"",
      fullnamedata:[
        { value: '1', label: 'Mr.'},
        { value: '2', label: 'Mrs.'},
     
       
      ],
      Genderdata:[
        { value: '1', label: 'Male'},
        { value: '2', label: 'Female'},
        {value: '3', label: 'Other'},
     
       
      ],
      sourcedata:[
        { value: '1', label: 'Naukri'},
        { value: '2', label: 'Monster'},
        { value: '6', label: 'Linkedin'},
        { value: '3', label: 'Other'},
       
      ],
      secondarybtn:0,
      docdata:[
       
        { value: '2', label: 'Aadhar Card'},
        { value: '1', label: 'PAN Card'},
        { value: '3', label: 'Other'},
       
      ],
      ApplicantName:"",
      Gender:"",
      EmailID:"",
      AlternateEmail:"",
      MobileNo:"",
      AlternateMobile:"",
      DateofBirth:"",
      PreviousOrg:"",
      PrevDesignation:"",
      TotalExp:"",
      RelevantExp:"",
      HighestDegree:"",
      CurrentCTC:"",
      ExpectedCTC:"",
      VariableAmount:"",
      FixedAmount:"",
      Address:"",
      PrefPrefCountryID:"",
      PrefPrefCountryName:"",
      PrefPrefStateID:"",
      PrefPrefStateName:"",
      PrefPrefCityID:"",
      PrefPrefCityName:"",
      SourceID:"",
      SourceName:"",
      JobTitle:"",
      Availablity:"",
      NationalityID:"",
      NationalityName:"",
      GovtIDType:"",
      GovtIDNumber:"",
      IndustryID:"",
      IndustryName:"",
      linkedInurl:"",
      KeySkills:"",
      NoticePeriod:"",
      Remarks:"",
      Honorific:"",
      Relocate:"",
      nationdata:[
        { value: '1', label: 'Indian'},
 
      ],
      industrydata:[],
      searchscreen:0,
      showModal2: false,
      ProfileImg:"",
      profilepic:"",
      Noticeperiod : [
        { value: "1", label: "15 Days or less" },
        { value: "2", label: "1 Month " },
        { value: "3", label: "2 Months" },
        { value: "4", label: "3 Months" },
        { value: "5", label: "More than 3 Months" },
        { value: "6", label: "Currently Serving Notice Period" },
        { value: "7", label: "7 Days" },
        { value: "8", label: "45 Days" },
        { value: "9", label: "Any" },
        { value: "10", label: "Immediate Joiner" },
        { value: "11", label: "Maintenance" },
      ],
      documentscreen:0,
      addmore:0,
      addmoree:0,
      currentlocation:"",
      preferredlocation:"",
      mobileerror1:"",
      resume_name:"",
      uploadresumebase64:"",
      showModal3:false,
      imagename:""
    };
    this.handleDisplay = this.handleDisplay.bind(this);
    this.handleDisplayClose = this.handleDisplayClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleUser = this.handleUser.bind(this);
    this.hanldelClient = this.hanldelClient.bind(this);
    this.handleHome = this.handleHome.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleNewclose = this.handleNewclose.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleUserstatus = this.handleUserstatus.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleClient = this.handleClient.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleassigned = this.handleassigned.bind(this);
    this.handleBusiness = this.handleBusiness.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleDegree = this.handleDegree.bind(this);
    this.handleJob = this.handleJob.bind(this);
    this.handleDocument=this.handleDocument.bind(this)
    this.handleFullName=this.handleFullName.bind(this)
    this.handleGender=this.handleGender.bind(this)
    this.handleSource=this.handleSource.bind(this)
    this.handleNationality=this.handleNationality.bind(this)
    this.handleDoc=this.handleDoc.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleSearchscreen=this.handleSearchscreen.bind(this)
    this.handleSearchClose=this.handleSearchClose.bind(this)
    this.handleUpdateImage=this.handleUpdateImage.bind(this)
    this.handleNoticePeriod=this.handleNoticePeriod.bind(this)
    this.uploadDocument=this.uploadDocument.bind(this)
    this.personalDetails=this.personalDetails.bind(this)
    this.handleAddmore=this.handleAddmore.bind(this)
    this.handledeleteAddmore=this.handledeleteAddmore.bind(this)
    this.handleAddmoree=this.handleAddmoree.bind(this)
    this.handledeleteAddmoree=this.handledeleteAddmoree.bind(this)
   this.handleOnboarding=this.handleOnboarding.bind(this)
   this.handleMaster=this.handleMaster.bind(this)
   this.handleClientnew=this.handleClientnew.bind(this)
  }
  handleClientnew(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleMaster(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleOnboarding(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
  }
  handledeleteAddmore(){
    this.setState({addmore:0})
  }
  handledeleteAddmoree(){
    this.setState({addmoree:0})
  }
  handleAddmoree(){
    this.setState({addmoree:1})
  }
  handleAddmore(){
    this.setState({addmore:1})
  }
  personalDetails(){
    this.setState({documentscreen:0})
  }
  uploadDocument(){
      this.setState({documentscreen:1})
  }
  handleChangeDate = (e) => {
    let currentDate = new Date().toJSON().slice(0, 10);
    if(e.target.value==currentDate ){
      e.target.value = e.target.value.replace(currentDate, "");
   
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
  
      }); 
    }
  };
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value.length!=10){
      this.setState({mobileerror:"Contact number should be digits only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
    }
  
    
  };
  handleChangemobile1 = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value.length!=10){
      this.setState({mobileerror1:"Contact number should be digits only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror1:""
      });
    }
  
    
  };
  handleUpdateImage(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,
        adduserdata:"editsinglephotoinfo",
        profilepic:this.state.profilepic
     
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
         if(response.message=="Profile Pic Updated successfully"){
          const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             //console.log(response)
                 this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                 
                        
                 })
             
                    
                  });
         }
          
                
         
                
              });
  }
  openModal3=()=>{
    this.setState({ showModal3: true });
  }
  closeModal3 = () => {
    this.setState({ showModal3: false });
  };
  openModal2=()=>{
    this.setState({ showModal2: true });
  }
  closeModal2 = () => {
    this.setState({ showModal2: false });
  };
  handleSearchClose(){
    this.setState({searchscreen:0})
  }
  handleSearchscreen(){
    this.setState({searchscreen:1})
  }
  handleNoticePeriod(e){
this.setState({NoticePeriod:e.label})
  }
  handleIndustry(e){
    this.setState({IndustryID:e.value,IndustryName:e.label})
  }
  handleDoc(e){
    this.setState({GovtIDType:e.label})
  }
  handleNationality(e){
this.setState({NationalityID:e.value,NationalityName:e.label})
  }
  handleSource(e){
   this.setState({SourceID:e.value,SourceName:e.label}) 
  }
  handleGender(e){
this.setState({Gender:e.label})
  }
  handleFullName(e){
this.setState({Honorific:e.label})
  }
  handleDocument(){
    this.setState({documentscreen:1})
  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];
    if(file["size"]>25000){
      Swal.fire("File Size should not be more than 25kb")
      
    }
    else{
      this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          ProfilePhoto: result,
          imagename:file["name"],
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
    }
    
  };
  handleImageone = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];
    if(file["size"]>500000){
      Swal.fire("File Size should not be more than 500kb")
      window.location.reload()
    }
    else{
      this.getBase64(file)
   
      .then((result) => {
       
          file["base64"] = result;
      
          console.log("File Is", file);
          this.setState({
            uploadresumebase64: result,
            resume_name:file["name"],
            file,
          },()=>{
            //console.log(this.state.resume_name)
          });
        
        
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
    }
    
  };
  handleJob() {
    let data = window.location.pathname.slice(0,0);
  
    window.location.href = `${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
  }
  handleDegree(e) {
    this.setState({ Degreename: e.label, DegreeID: e.value });
  }
  handleLanguage(e) {
    this.setState({ Languages: e.value });
  }
  handleSave() {
    if(this.state.ApplicantName==""){
      Swal.fire(
        "Please Enter Applicant Full Name"
      )
    }
    else if(this.state.Gender==""){
      Swal.fire(
        "Please Select Gender"
      )
    }
    else if(this.state.EmailID==""){
      Swal.fire(
        "Please Enter Email Id"
      )
    }
    else if(this.state.MobileNo==""){
      Swal.fire(
        "Please Enter Mobile No"
      )
    }
    else if(this.state.IndustryID==""){
      this.setState({IndustryID:14,IndustryName:"Computer Software"})
    }
    else if(this.state.DateofBirth==""){
      Swal.fire(
        "Please Choose Date of Birth"
      )
    }
    else if(this.state.NationalityID==""){
      this.setState({NationalityID:"1",NationalityName:"Indian"})
    }
    else if(this.state.SourceID==""){
      Swal.fire(
        "Please Select Source Name"
      )
    }
    else if(this.state.currentlocation==""){
      Swal.fire(
        "Please Enter Current Location Name"
      )
    }
    else if(this.state.PreviousOrg==""){
      Swal.fire(
        "Please Enter Current/Last Organization"
      )
    }
    else if(this.state.KeySkills==""){
      Swal.fire(
        "Please Enter Key Skills"
      )
    }
    else if(this.state.preferredlocation==""){
      Swal.fire(
        "Please Enter Preferred Location"
      )
    }
    else if(this.state.NoticePeriod==""){
      Swal.fire(
        "Please Select Notice Period"
      )
    }
    else if(this.state.TotalExp==""){
      Swal.fire(
        "Please Enter Total Experience"
      )
    }
  
    else if(this.state.RelevantExp==""){
      Swal.fire(
        "Please Enter Relevant Exp"
      )
    }
    else if(this.state.CurrentCTC==""){
      Swal.fire(
        "Please Enter Current CTC"
      )
    }
    else if(this.state.GovtIDType==""){
      Swal.fire(
        "Please Enter GovtID Type"
      )
    }
    else if(this.state.Availablity==""){
      Swal.fire(
        "Please Enter Availablity"
      )
    }
    else if(this.state.uploadresumebase64==""){
      Swal.fire(
        "Please Choose Candidate Resume"
      )
    }
    else{
      this.setState({secondarybtn:1,showModal3: true })
      var username = JSON.stringify(localStorage.getItem("UserId"));
      const withoutQuotes = username.replaceAll('"', "");
      const data = {
        userid: withoutQuotes,
        addAppliantdata: "addApplicantinfo",
        ApplicantName:this.state.ApplicantName,
        ProfilePhoto:this.state.ProfilePhoto,
        Gender:this.state.Gender,
        EmailID:this.state.EmailID,
        AlternateEmail:this.state.AlternateEmail,
        MobileNo:this.state.MobileNo,
        AlternateMobile:this.state.AlternateMobile,
        DateofBirth:this.state.DateofBirth,
        PreviousOrg:this.state.PreviousOrg,
        PrevDesignation:this.state.PrevDesignation,
        TotalExp:this.state.TotalExp,
        RelevantExp:this.state.RelevantExp,
        HighestDegree:this.state.HighestDegree,
        CurrentCTC:this.state.CurrentCTC,
        ExpectedCTC:this.state.ExpectedCTC,
        VariableAmount:this.state.VariableAmount,
        FixedAmount:this.state.FixedAmount,
        Address:this.state.Address,
        PrefCountryID:this.state.PrefCountryID,
        PrefCountryName:this.state.PrefCountryName,
        PrefStateID:this.state.PrefStateID,
        PrefStateName:this.state.PrefStateName,
        PrefCityID:this.state.PrefCityID,
        PrefCityName:this.state.PrefCityName,
        SourceID:this.state.SourceID,
        SourceName:this.state.SourceName,
        JobTitle:this.state.JobTitle,
        Availablity:this.state.Availablity,
        NationalityID:this.state.NationalityID,
        NationalityName:this.state.NationalityName,
        GovtIDType:this.state.GovtIDType,
        GovtIDNumber:this.state.GovtIDNumber,
        IndustryID:this.state.IndustryID,
        IndustryName:this.state.IndustryName,
        linkedInurl:this.state.linkedInurl,
        KeySkills:this.state.KeySkills,
        NoticePeriod:this.state.NoticePeriod,
        Remarks:this.state.Remarks,
        Honorific:this.state.Honorific,
        Relocate:this.state.Relocate,
        currentlocation:this.state.currentlocation,
        preferredlocation:this.state.preferredlocation,
        uploadresumebase64:this.state.uploadresumebase64,
        resume_name:this.state.resume_name,
        imagename:this.state.imagename
  
      };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          if(response.message=="Applicant Data successfully Added."){
            this.setState({showModal3:false})
            Swal.fire(
              response.message
            );
            setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              2500
            );
          }
          else{
            Swal.fire(
              response.message
            );
           /** setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              2500
            ); */
          }
        
  
          console.log(response);
        });
    }
   
  }
  handleBusiness(e) {
    this.setState({ BusinessUnit: e.label }, () => {});
  }
  handleassigned(event, value) {
    if (value.action == "select-option") {
      event.map((e) => {
        this.setState(
          { Assingnedid: this.state.Assingnedid.concat(e.value) },
          () => {
            console.log(this.state.Assingnedid);
          }
        );
        this.setState(
          { Assingnedname: this.state.Assingnedname.concat(e.value) },
          () => {
            console.log(this.state.Assingnedname);
          }
        );
      });
    } else if (value.action == "remove-value") {
      this.setState({ Assingnedid: [] });
    } else {
      console.log("error");
    }
  }
  handleReport(e) {
    this.setState({ HiringManager: e.label, HiringManagerid: e.value });
  }
  handleCity(e) {
    this.setState({ PrefCityName: e.label, PrefCityID: e.value });
  }
  handleClient(e) {
    this.setState({ ClientName: e.label, ClientId: e.value });
  }
  handleChange = (e) => {
    var regex = /[0-9!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleChangetwo = (e) => {
  
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleUserstatus(e) {
    this.setState({ JobStatus: e.value });
  }
  handleState(e) {
    this.setState({ PrefStateName: e.label, PrefStateID: e.value }, () => {
      const data = { dropdownlist_input: "getcityinfo", stateid: e.value };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          const data = response.result;
          //console.log(json)

          const options = data.map((d) => ({
            value: d.id,
            label: d.name,
          }));

          this.setState({ citydata: options });
        });
    });
  }
  handleCountry(e) {
  
    this.setState({ PrefCountryName: e.label, PrefCountryID: e.value }, () => {
      const data = { dropdownlist_input: "getstateinfo", countryid: e.value };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          const data = response.result;
          //console.log(json)

          const options = data.map((d) => ({
            value: d.id,
            label: d.name,
          }));

          this.setState({ statedata: options });
        });
    });
  }
  handleNewclose() {
    this.setState({ newjob: 0, jobpostingscreen: 0 });
  }
  handleNew() {
    this.setState({ newjob: 1, jobpostingscreen: 1 });
  }
  handleUser() {
    let data = window.location.pathname.slice(0,0);
    window.location.href = `${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
  }

  handleLogout() {
    localStorage.clear();
    let data = window.location.pathname.slice(0,0);
    window.location.href = `${data}/`;
  }
  handleHome() {
    let data = window.location.pathname.slice(0,0);
    window.location.href = `${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`;
  }
  handleDisplay() {
    this.setState({ display: 1 });
  }
  handleDisplayClose() {
    this.setState({ display: 0 });
  }

  hanldelClient() {
    let data = window.location.pathname.slice(0,0);
    window.location.href = `${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
  }
  componentDidMount() {
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const pdata={ userid:withoutQuotes,
      adduserdata:"getsinglephotoinfo",
    
   
  }
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(pdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          
       if(response.result.profilepic=="BASE64"){
            this.setState({ProfileImg:""},()=>{
             
            })
        
         }
         else{
            this.setState({ProfileImg:response.result.profilepic},()=>{
             
            })
        
         } 
       
              
            });
    /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(response)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */
    /* Reporting To API Call Start */
    const redata = { dropdownlist_input: "getreportinginfo" };
    console.log(redata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(redata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.reportingid,
          label: d.reportingname,
        }));

        this.setState({ reportingtodata: options });
      });

    /* Reporting To  API Call End */

    /* Language API Call Start */
    const ledata = { dropdownlist_input: "getlanguagesinfoinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(ledata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ languagesdata: options });
      });

    /*Language  API Call End */

    /* Degree API Call Start */
    const degdata = { dropdownlist_input: "getdegreesinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(degdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ degreedata: options });
      });

    /*Language  API Call End */
    /* Country API Call Start */
    const tdata = { dropdownlist_input: "getcountryinfo" };
    console.log(tdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.countryid,
          label: d.conuntryname,
        }));

        this.setState({ countrydata: options });
      });
    /* Country API Call  End */

    /* Client API Call Start */
    const cdata = { dropdownlist_input: "getclientDropinfo" };
    console.log(cdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(cdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.clientid,
          label: d.clientname,
        }));

        this.setState({ clientdata: options });
      });
    /* Client API Call  End */
  }
  render() {
    return (
      <div>
        {/**Loading Icon Starts */}
        {this.state.showModal3 && (
        <div class="ft-modal" id="ft-loadingicon-modal" style={{top:"0%"}}>
          <img src={LoadingIcon} style={{marginLeft:"42em",width:"8%",marginTop:"15em"}}/>
          </div>
        )}
     
        {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" style={{marginLeft:"1%"}} />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{ cursor: "pointer" }}>
              Home
            </li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleJob} style={{ cursor: "pointer" }}>
              Job Posting
            </li>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext">Applicants</li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClientnew}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleids==9 || this.state.userroleids==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul>
          <Header/>
        </div>

        {/* Header Section Ends */}
              {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}
{this.state.searchscreen==0?<>
  {this.state.jobpostingscreen == 0 ? (
          <>
            {/* Applicants Section started */}

            <div className="row">
              <div className="col-xl-12">
                <h2 style={{ padding: "1%", fontFamily: "Poppins Light",fontWeight:"bold",marginLeft:"1%" }}>
                  Applicants
                </h2>
                <span  style={{float:"right",marginRight:"2%",marginTop:"-4em"}}> <button
                  className="btn btn-primary updatebtn"
                  onClick={this.handleNew}
                >
                  Add New Applicant
                </button></span>
              </div>
             
            </div>

            {/* Applicants Section ended */}

            {/* Applicants Data Table started */}

            <div className="row container-fluid">
              <div
                className="col-xl-12"
                style={{  marginLeft: "2%" }}
              >
                <Tabs
                  activeTab="1"
                  className=""
                  ulClassName=""
                  activityClassName="bg-success"
                  onClick={(event, tab) => console.log(event, tab)}
                >
                  <Tab title="Active Applicants" className="mr-4">
                    <div style={{marginLeft:"0%"}}>
                      <Suspense fallback={<div>Please Wait...</div>}>
                      <Activeapplicant />
                      </Suspense>
                      
                    </div>
                  </Tab>
                  <Tab title="Deleted Applicants" className="mr-4">
                    <div style={{marginLeft:"0%"}}>
                     <DeletedApplicant/>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            {/** Job Posting Data Table end */}
          </>
        ) : null}
        {/* New Applicant Start */}
        {this.state.newjob == 1 ? (
          <>
            <div
              style={{
                backgroundColor: "#EAF4FF",
                height: "auto",
                paddingBottom: "3%",
              }}
            >
              <div className="row">
                <div
                  className="col-xl-8"
                  style={{ textAlign: "right", marginTop: "2%" }}
                >
                  <h3 style={{ fontFamily: "Poppins Light",fontWeight:"bold", padding: "1%" }}>
                    Add Applicant
                  </h3>
                </div>
                <div
                  className="col-xl-4"
                  style={{ textAlign: "end", paddingRight: "4%" }}
                >
                  <button
                    className="btn btn-primary updatebtn"
                    onClick={this.handleNewclose}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xl-2"
                  style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "30em",
                    marginLeft: "3%",
                    boxShadow: "0 0 5px #a2a2a2",
                   
                  }}
                >
                  <div className="applicantmenu" onClick={this.personalDetails} style={{background:this.state.documentscreen==0?"#4293FE":"white",color:this.state.documentscreen==0?"white":"black"}}>
                    <i className="fa fa-user applicanticon"></i>&nbsp;&nbsp;
                    <span>Personal Details</span>
                  </div>
                  {/**<div className="applicantmenunotactive" style={{background:this.state.documentscreen==1?"#4293FE":null,color:this.state.documentscreen==1?"white":"black"}} onClick={this.uploadDocument}>
                    <i className="fa fa-folder applicanticonnonactive" style={{border:this.state.documentscreen==1?"3px solid white":"3px solid black"}}></i>
                    &nbsp;&nbsp;
                    <span>Upload Documents</span>
                  </div> */}
                
                
                  {/**<div className="applicantmenunotactive">
                    <i className="fa fa-folder applicanticonnonactive"></i>
                    &nbsp;&nbsp;
                    <span>Documents</span>
                  </div>
                  <div className="applicantmenunotactive">
                    <i className="fa fa-graduation-cap applicanticonnonactive"></i>
                    &nbsp;&nbsp;
                    <span>Education Details</span>
                  </div>
                  <div className="applicantmenunotactive">
                    <i className="fa fa-certificate applicanticonnonactive"></i>
                    &nbsp;&nbsp;
                    <span>Certifications</span>
                  </div>
                  <div className="applicantmenunotactive">
                    <i className="fa fa-language applicanticonnonactive"></i>
                    &nbsp;&nbsp;
                    <span>Languages</span>
                  </div>
                  <div className="applicantmenunotactive">
                    <i className="fa fa-briefcase applicanticonnonactive"></i>
                    &nbsp;&nbsp;
                    <span>Work Experience</span>
                  </div>
                  <div className="applicantmenunotactive">
                    <i className="fa fa-tasks applicanticonnonactive"></i>
                    &nbsp;&nbsp;
                    <span>Employer Details</span>
                  </div> */}
                </div>

                <div
                  className="col-xl-9"
                  style={{
                    
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                    boxShadow: "0 0 5px #a2a2a2",
                    overflowY:"scroll",
                    paddingBottom:"3%"
                  }}
                >
{this.state.documentscreen==0?<>
 {/** Personal Details Starts */}
 <br/>
 <h4 style={{ fontFamily: "Poppins Light",fontWeight:"bold" }}>Personal Details</h4>
                  <hr />
                  <div className="row">
                    <div className="col-xl-1">
                      {this.state.ProfilePhoto == " " ? (
                        <>
                          <img src={Applicantimg} style={{ width: "135%" }} />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            src={this.state.ProfilePhoto}
                            style={{
                              width: "135%",
                              border: "2px solid #4293fe",
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="col-xl-2 text-center">
                      <input
                        id="upload"
                        type="file"
                        name="ProfilePhoto"
                        onChange={this.handleImage}
                        hidden
                      />
                      <label id="actual-btn" for="upload">
                        Upload
                      </label>
                    </div>
                   
                   
                    {/**    <Select 
       onChange={(e)=>this.handleFullName(e)}
       options={this.state.fullnamedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/> */}   
                   
                   
                    <div className="col-xl-9">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Candidate Full Name</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                      <br/>
                    <input name="ApplicantName" onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" placeholder="Enter Full Name"/>    
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}} >Gender</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span> <br/>
                    <Select 
       onChange={(e)=>this.handleGender(e)}
       options={this.state.Genderdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>   
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Email ID</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input style={{height:"38px"}} name="EmailID" onChange={this.handleChangetwo} className="form-control adduser mt-1" type="email" placeholder="Email ID"/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Email ID</span>
                    <input name="AlternateEmail" onChange={this.handleChangetwo} style={{height:"38px"}} className="form-control adduser mt-1" type="email" placeholder="Alternate Email ID"/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Mobile Number</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="MobileNo" maxLength="10"  onChange={this.handleChangemobile} style={{height:"38px"}} className="form-control adduser mt-1"  placeholder="Mobile Number"/> 
                    <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror}</span>
                    </div>

                  </div>
                  <div className="row mt-2">
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Mobile Number</span>
                    <input name="AlternateMobile"  maxLength="10"  onChange={this.handleChangemobile1} style={{height:"38px"}} className="form-control adduser mt-1"  placeholder="Alternate Mobile Number"/> 
                    <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror1}</span>
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Date Of Birth</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="DateofBirth" min="1950-01-01" max="2023-01-01" onChange={this.handleChangeDate} style={{height:"38px"}} className="form-control adduser mt-1" type="date"/>  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>LinkedIn Profile URL</span>    
                    <input name="linkedInurl" onChange={this.handleChangetwo} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter LinkedIn URL"/>  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Nationality</span>   
                    <Select 
                    onChange={(e)=>this.handleNationality(e)}
                    options={this.state.nationdata}  components={{  IndicatorSeparator:() => null }}
                                              styles={style}/>    
                    </div>

                  </div>
                  <div className="row mt-2">
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Source</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <Select 
       onChange={(e)=>this.handleSource(e)}
       options={this.state.sourcedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>    
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Industry</span>   
                    <Select 
       onChange={(e)=>this.handleIndustry(e)}
       options={this.state.industrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current Location</span>     <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span><br/>
                    <input name="currentlocation" onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter Current Location"/> 
                    </div>
                    <div className="col-xl-3 mt-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Open To Reclocate</span>    <br/>
                    <input type="radio"  name="Relocate" onChange={this.handleChangetwo}  value="Yes"/>&nbsp;&nbsp;Yes&nbsp;&nbsp;
                    <input type="radio" className="mt-2" name="Relocate" onChange={this.handleChangetwo} value="No" />&nbsp;&nbsp;No
                    </div>

                  </div>
                  <div className="row mt-2">
                  <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current / Last Organization</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input className="form-control adduser mt-1" 
                    name="PreviousOrg" onChange={this.handleChange}
                    placeholder="Previous Organization" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Key Skills</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="KeySkills" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Key Skills" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Preferred Location</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="preferredlocation"  type="text" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Enter Preferred Location" style={{height:"38px"}}/>
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Notice Period</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <Select 
       onChange={(e)=>this.handleNoticePeriod(e)}
       options={this.state.Noticeperiod}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                    </div>
                         
                  

                  </div>
                  <div className="row mt-2">
                  <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Previous Designation</span>   
                    <input className="form-control adduser mt-1"
                    name="PrevDesignation" onChange={this.handleChangetwo}   
                    placeholder="Previous Designation" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Total Experience</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input  name="TotalExp" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Total Experience" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Relevant Experience</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="RelevantExp" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Relevant Experience" style={{height:"38px"}}/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Highest Degree</span>    
                    <input name="HighestDegree" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Highest Degree" style={{height:"38px"}}/>
                    </div>
                  

                  </div>
                  <div className="row mt-2">
                  <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current CTC</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="CurrentCTC" type="number" min="0" max="10000000" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Current CTC" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Expected CTC</span>    
                    <input name="ExpectedCTC" min="0" max="40000000" type="number" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Expected CTC" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Variable Amount</span>   
                    <input name="VariableAmount" min="0" max="40000000" type="number" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="VariableAmount" style={{height:"38px"}}/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Fixed Amount</span>    
                    <input name="FixedAmount"min="0" max="40000000" type="number" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Fixed Amount" style={{height:"38px"}}/>
                    </div>
                              

                  </div>
                  
                  <div className="row mt-2">
                 
                    <div className="col-xl-4">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Type</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                            <Select 
              onChange={(e)=>this.handleDoc(e)}
              options={this.state.docdata}  components={{  IndicatorSeparator:() => null }}
                                        styles={style}/>
                    </div>
                    <div className="col-xl-4">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Number</span>  
                    <input min="0" max="40000000" type="text" onChange={this.handleChangeDate}  name="GovtIDNumber"   className="form-control adduser mt-1" placeholder="GovtID Number" style={{height:"38px"}}/> 
                    </div>
                    <div className="col-xl-4">
                            <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Availablity</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input name="Availablity" onChange={this.handleChangetwo} className="form-control adduser mt-1" placeholder="Availablity" style={{height:"38px"}} />
                            </div>
                 

                  </div>
                  <div className="row mt-2">
                  <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Candidate Resume</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13,color:"red"}}>(You can Upload only PDF File)</span>
                      <br/>
                     <div className="adduser text-center" style={{padding:"0.5%"}}>
                     <input
                        id="upload"
                        type="file"
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white"}}
                        onChange={this.handleImageone}
                        accept=".pdf"
                      />
                     
                     </div>
                    </div>
                  </div>
                  
                  <div className="row mt-2">
                    <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Address</span>    
                     <textarea name="Address" onChange={this.handleChangetwo} className="adduser form-control mt-1" rows="2">

                     </textarea>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Remarks</span>    
                     <textarea name="Remarks" onChange={this.handleChangetwo} className="adduser form-control mt-1" rows="2">

                     </textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-10" >
                            
                    </div>
                            <div className="col-xl-1" style={{textAlign:"right"}}>
                              {this.state.secondarybtn==0?<>
                                <a href="#ft-loadingicon-modal"  >
                                <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={this.handleSave}>Save</button>
                                </a>
                              </>:<>
                              <button className="btn btn-secondary updatebtn" style={{marginTop:"2%"}} >Save</button>
                              
                              </>}
                           
                            </div>
                            <div className="col-xl-1" style={{textAlign:"right"}}>
                            <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={()=>window.location.reload(false)}>Reset</button>
                            </div>
                  </div>
                  {/**Personal Details Ends*/}

</>:<>
<br/>
<h4 style={{ fontFamily: "Poppins Light",fontWeight:"bold" }}>Upload Documents</h4>
                  <hr />
<div className="row">
  <div className="col-xl-4">
  
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
  <Select 
       onChange={(e)=>this.handleFullName(e)}
       options={this.state.docdata} placeholder="Adhar Card" components={{  IndicatorSeparator:() => null }}
      styles={style}/>   
  </div>
<div className="col-xl-6">
  <br/>
<input
                        id="upload"
                        type="file"
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white",width:"inherit",border: "2px solid rgb(66, 147, 254)"}}
                        
                        accept=".doc, .docx, .pdf"
                      />

</div>
<div className="col-xl-2">
<span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13,visibility:"hidden"}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14,visibility:"hidden"}}>*</span><br/>
 <button className="btn btn-primary mt-1" style={{fontSize:14}} onClick={this.handleAddmore}><i class="fa fa-plus-circle" aria-hidden="true" style={{color:"white"}}></i>&nbsp;Add More</button>

</div>
</div>
{this.state.addmore==1?<>
  <div className="row">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
  <Select 
       onChange={(e)=>this.handleFullName(e)}
       options={this.state.docdata} placeholder="PAN Card" components={{  IndicatorSeparator:() => null }}
      styles={style}/>   
  </div>
<div className="col-xl-6">
  <br/>
<input
                        id="upload"
                        type="file"
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white",width:"inherit",border: "2px solid rgb(66, 147, 254)"}}
                        
                        accept=".doc, .docx, .pdf"
                      />

</div>
<div className="col-xl-2">
<span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13,visibility:"hidden"}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14,visibility:"hidden"}}>*</span><br/>
 <button style={{fontSize:14}} className="btn btn-primary mt-1" onClick={this.handleAddmoree}><i class="fa fa-plus-circle" aria-hidden="true" style={{color:"white"}}></i>&nbsp;Add More</button>&nbsp;&nbsp;
 <button className="btn btn-danger mt-1" style={{fontSize:14}} onClick={this.handledeleteAddmore}><i class="fa fa-trash" aria-hidden="true" style={{color:"white"}}></i></button>
</div>
</div>
</>:null}
{this.state.addmoree==1?<>
  <div className="row">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
  <Select 
       onChange={(e)=>this.handleFullName(e)}
       options={this.state.docdata} placeholder="Other" components={{  IndicatorSeparator:() => null }}
      styles={style}/>   
  </div>
<div className="col-xl-6">
  <br/>
<input
                        id="upload"
                        type="file"
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white",width:"inherit",border: "2px solid rgb(66, 147, 254)"}}
                        
                        accept=".doc, .docx, .pdf"
                      />

</div>
<div className="col-xl-2">
<span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13,visibility:"hidden"}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14,visibility:"hidden"}}>*</span><br/>
 
 <button className="btn btn-danger mt-1" style={{fontSize:14}} onClick={this.handledeleteAddmoree}><i class="fa fa-trash" aria-hidden="true" style={{color:"white"}}></i></button>
</div>
</div>
</>:null}
<div className="text-center">
  <button className="btn updatebtn" style={{color:"white",marginTop:"2%"}}>Upload</button>

</div>







</>}

                 
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
</>:<>
<div style={{backgroundColor:"rgb(234, 244, 255)",height:"auto",paddingBottom:"3%"}}>
<div className="row">
  <div className="col-xl-12" style={{textAlign:"right"}}>
    <button className="btn btn-primary updatebtn" style={{marginRight:"4%",marginTop:"1%"}} onClick={this.handleSearchClose}>Back</button>

  </div>

</div>
<div className="row container-fluid" style={{marginLeft:"2%"}}>
  <div className="col-xl-8" style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "40em",
                    boxShadow: "0 0 5px #a2a2a2",
                   
                    paddingBottom:"3%"
                  }}>

          <h4 style={{fontFamily:"Poppins Bold"}}>Search Candidate</h4>
          <div className="row">
            <div className="col-xl-12">
                <span style={{fontFamily:"Poppins Bold",fontSize:13}}>Key Words</span><br/>
                <input type="text" className="form-control adduser mt-1" placeholder="Enter the Keyword" />
            </div>

          </div>

  </div>
  <div className="col-xl-3" style={{padding:"0%",margin:"1%"}}>
    <div style={{
                   
                    background: "#fff",
                    borderRadius: "10px",
                    height: "10em",
                    boxShadow: "0 0 5px #a2a2a2",
                    padding:"5%",
                    paddingBottom:"3%"
                  }}>
                      <h5>
                    <i class="fa fa-hand-o-right" aria-hidden="true"></i>  Recent Searches
                    </h5>

    </div>

    <div style={{
                  
                    background: "#fff",
                    borderRadius: "10px",
                    height: "10em",
                    boxShadow: "0 0 5px #a2a2a2",
                  padding:"5%",
                    paddingBottom:"3%",
                    marginTop:"3%"
                  }}>
                      <h5>
                    <i class="fa fa-hand-o-right" aria-hidden="true"></i>  Saved Searches
                    </h5>

    </div>           

  </div>
</div>
</div>
</>}
       
        {/*New Applicant End*/}
      </div>
    );
  }
}
