import React from "react";
import "./style.css";
import Mainteclogo  from "../images/mrecruit-19.png";
import {Link} from "react-router-dom";
export class Forgotpassword extends React.Component{
    constructor(){
        super();
        this.state={
            email:" ",
            password:"",
            showpwd:0,
            forgotpwd:0,
            emailvalidation:""
        }
       this.handleReset=this.handleReset.bind(this)
      }
      handleReset(){
        var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
        if(this.state.email==" "){
            this.setState({emailvalidation:"Please Enter Your Email ID"})
        }
        else if(this.state.email.match(pattern)){
          const data = {

            email:this.state.email,
          }
       
      
             fetch("https://www.mrecruit.in:5200/api/resetpassword", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                console.log(response)
                if(response.message=="Reset Password Link Successfully sent to your Registered Email Id."){
                  window.location.href=`./createnewpassword/${this.state.email}`
                }
                else{
                  this.setState({emailvalidation:"Incorrect Email ID"})
                }
              });
         
        }
        else{
          this.setState({emailvalidation:"Incorrect Email ID"})
        }
        
      }
      handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        emailvalidation:""
        });
        
      };
    render(){
        return(
            <>
              <div>
          {/* Background Image Starts */}
        <div className="backgroundimg">
           <img src={Mainteclogo} className="mainteclogo" style={{padding:"3%"}} />

            <div className="forgotpasswordbox">
           
            </div>
         

             {/* Email ID Field Starts*/}
          
             <div className="row emailidtop">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none inputboxxx" type="email" placeholder="Email" name="email" onChange={this.handleChange} />
                        {this.state.emailvalidation==" "?<>
                        
                        </>:<><span style={{color:"white",fontFamily:"Inter Bold"}}>{this.state.emailvalidation}</span></>}
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* Email ID Field End*/}


          
             {/* Reset Password button  Starts*/}
          
             <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5">
                      <button className="btn loginbtn" style={{fontWeight:600}} onClick={this.handleReset}>Reset Password</button>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
             {/* Reset Password button End */}
      
           {/* Back to Home Page Text Starts */}
           <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5 text-center">
                      <span>
                        <Link className="returntohomepagetxt" to="/">Return to Login Page</Link>
                        </span>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
           {/* Back to Home Page Text End */}
             {/* Mrecruit 2.0 text starts */}
             <div className="row">
                  <div className="col-sm-8"></div>
                <div className="col-sm-3 mrecruittxtbelowfp">
                  <span>mRecruit 2.0</span>
                </div>
                </div>
             {/* Mrecruit 2.0 text ends */}
             <span className="mrecruitheadingfpass">mRecruit</span>
             
             <span className="resetpasswordtext">
           
              Reset Your Password
             
              </span>
            <span className="passemailtext">Please enter your Email and we will send you link.</span>
        </div>
      </div>
            </>
        )
    }
}