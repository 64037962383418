import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

function Deleteclient(id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addclientdata: "deleteclient",editclientid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

function EditUser(e,id){
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  let data=window.location.pathname.slice(0,12);
  let encodedid=window.btoa(id)
  //window.location.href=`${data}/edituser/${encodedid}/${data1}/${data2}/${data3}`
}

function InactiveUser(id){
  Swal.fire({
    title: 'Are you sure to Inactive the User?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Inactive it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"inactiveuser",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Inactiveted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}
class Clientpending extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
     
    
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      firstname:"",
      lastname:"",
      username:"",
      email:"",
      workinglocation:"",
      password:"",
      userrole:"",
      userrolename:"",
      userstatus:"",
      mobile:"",
      reportingto:"",
      reporting_toname:"",
      BusinessUnit:"",
      adminprivileges:"No",
      dob:"",
      doj:"",
      teamids:"",
      teamname:"",
      employeeID:"",
      AdminPrivileges:"",
      edituserid:"",
      userstatusname:"",
      dobchange:"",
      dojchange:"",
      updatemsg:""
    }
   
    this.columns = [
      {
        name: "Client Name",
        selector: "clientname",
        sortable: true
      },
      {
        name: "Business Unit",
        selector: "BusinessUnit",
        sortable: true
      },
      {
        name: "Key Contact Person",
        selector: "Companyphone",
        sortable: true
      },
      {
        name: "Website",
        selector: "websiteurl",
        sortable: true
      },
      {
        name: "Status",
        selector: "clientstatus",
        sortable: true
      },
     
      {
        name: "Website",
        selector: "websiteurl",
        sortable: true
      },
      {
        name: "Created By",
        selector: "createdbyname",
        sortable: true
      },
      {
        name: "Created Date",
        selector: "created_on",
        sortable: true
      },
     
         
          {
            name:"Action",
          cell: (e) => (
              <>
          
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
  }

  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes1 = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes1,
      edituserid:this.state.edituserid,
      adduserdata:"edituserinfo",
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userrolename:this.state.userrolename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:this.state.BusinessUnit,
      adminprivileges:this.state.AdminPrivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      teamname:this.state.teamname,
      timezoneid:1,
      timezonename:"US Time",
      employeeID:this.state.employeeID
    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
    
              this.setState({updatemsg:response.message})
            setTimeout(
              function() {
               
                window.location.reload()
              }
              .bind(this),
              2500
          );
               console.log(response)
      
                  
                });
      }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({edituserid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,adduserdata:"getsingleuserinfo",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              firstname:e.firstname,
              lastname:e.lastname,
              username:e.displayname,
              email:e.email,
              workinglocation:e.branchlocation,
              employeeID:e.employeeID,
              userrolename:e.userrolename,
              userrole:e.userroleids,
              reportingto:e.reporting_to,
              reporting_toname:e.reporting_toname,
              teamids:e.teamids,
              teamname:e.teamname,
              AdminPrivileges:e.AdminPrivileges,
              dob:e.dobchange,
              doj:e.dojchange,
              dobchange:e.dob,
              dojchange:e.doj,

              userstatus:e.userstatus,
              BusinessUnit:e.BusinessUnit,
              mobile:e.mobile,
              userstatusname:e.userstatusname,

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
    const rodata={ dropdownlist_input: "getuserrole"}
    console.log(rodata)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(rodata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });

      const rdata={ dropdownlist_input: "getreportinginfo"}
      console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes1 = username.replaceAll('"', '');
       const tdata={ userid: withoutQuotes1,
        adduserdata:"getteamsinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addclientdata:"getclientinfo",statuscode:3}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data });
              
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).indexOf(this.state.search) >= 0
    );
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
   
    let data=this.props.user_id;
console.log(data)
    return (
      <>{this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content  bigmodalcontent">
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit User Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            {/**Add user for starts */}
 <div>
  <hr/>
  <div className="row">
    <div className="col-xl-12">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Candiate Name</span>
    </div>
  </div>
  <div className="row">
    <div className="col-xl-4">
     <input className="form-control adduser mt-2" 
     value={this.state.firstname}
     placeholder="First Name" name="firstname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-4">
     <input className="form-control adduser mt-2" placeholder="Middle Name"/>
    </div>
    <div className="col-xl-4">
     <input name="lastname"
     value={this.state.lastname}
     onChange={this.handleChange} className="form-control adduser mt-2" placeholder="Last Name"/>
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Display Name</span>
     <input type="text" name="username"
     value={this.state.username}
     onChange={this.handleChange} className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Password</span>
     <input type="password" 
    
     name="password" onChange={this.handleChange} 
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Contact Number</span>
     <input name="mobile" 
      value={this.state.mobile}
     onChange={this.handleChange}  className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Email ID</span>
     <input type="email" 
     value={this.state.email}
     name="email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Working Location</span>
      <input type="text" 
       value={this.state.workinglocation}
      name="workinglocation" onChange={this.handleChange}className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Date of Birth</span>
     <input type="text"
      name="dob"
      onFocus={(e) =>
        (e.currentTarget.type = "date")
      }
      onBlur={(e) =>
        (e.currentTarget.type = "date")
      }
      value={this.state.dob}
      onChange={this.handleChange} className="form-control adduser mt-2" />
    </div>
  </div>
 
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Date of Join</span>
     <input type="text"
      value={this.state.doj}
      onFocus={(e) =>
        (e.currentTarget.type = "date")
      }
      onBlur={(e) =>
        (e.currentTarget.type = "date")
      }
      name="doj" onChange={this.handleChange}
     className="form-control adduser mt-2" />
     
    
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Time Zone</span>
    <input type="text"   className="form-control adduser mt-2" />

    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Employee ID</span>
    <input type="text"
    name="employeeID"
    value={this.state.employeeID}
    className="form-control adduser mt-2" onChange={this.handleChange} />
     
    
    </div>
  </div>
  <div className="row mt-2">
    
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Admin Privilleges</span><br/>
   {this.state.on==1 || this.state.AdminPrivileges=="Yes"?<>
    <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handlecloseAdmin}
    type="checkbox" id="myToggle" checked />
    <div class="toggle__fill"></div>
</label>
   </>:<> <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handleAdmin}
    type="checkbox" id="myToggle"  />
    <div class="toggle__fill"></div>
</label></>}
   
<div style={{fontWeight:"bold",fontFamily:"Montserrat",marginLeft:"25%",marginTop:"-9%"}}>
  {this.state.on==1 || this.state.AdminPrivileges=="Yes"?<>
    <span>On</span>
  </>:<> <span>Off</span></>}
 </div>

    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>User Status</span>
    <Select 
    placeholder={this.state.userstatusname}
    onChange={(e)=>this.handleUserstatus(e)}
    options={this.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Business Unit</span>
    <Select 
    onChange={(e)=>this.handleBusiness(e)}
    placeholder={this.state.BusinessUnit}
    options={this.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
     
    
    </div>
  </div>
  <div className="row mt-2">
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Roles</span>
    <Select
    onChange={(e)=>this.handleRoles(e)}
    placeholder={this.state.userrolename}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Reporting To</span>
    <Select 
    placeholder={this.state.reporting_toname}
    onChange={(e)=>this.handleReport(e)} options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
     
    
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team</span>
    <Select
    placeholder={this.state.teamname}
    onChange={this.handleTeamdata} options={this.state.teamdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>
 
   <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
 
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
            <DataTableExtensions columns={this.columns}
                data={this.state.rows}>
              <DataTable
                defaultSortField="id"
                
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                
              />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Clientpending;
