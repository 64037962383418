import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route,Switch,withRouter} from "react-router-dom";
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import { Register } from './components/Register';
import { Forgotpassword } from './components/Forgotpassword';
import { Createnewpassword } from './components/Createnewpassword';
import Jobposting from './components/Jobposting';
import Otppage from './components/Otppage';
import UserManagement from './components/UserManagement';
import Client from './components/Clients';
import EditUser from './components/EditUser';
import Applicants from './components/Applicants';
import { ViewJob } from './components/ViewJob';
import MasterSettings from './components/MasterSettings';
import ClientSubmissionformat from './components/ClientSubmissionformat';
import { Viewapplicant } from './components/Viewapplicant';
import JobSearch from './components/JobSearch';
import { ViewClient } from './components/ViewClient';
import { ViewUser } from './components/ViewUser';
import Onboardings from './components/Onboardings';

function App() {
  return(
    <Router>
    <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard/:id1/:id2/:id3/:id4" component={Dashboard} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgotpassword" component={Forgotpassword} />
          <Route exact path="/createnewpassword/:id" component={Createnewpassword} />
          <Route exact path="/jobposting/:id1/:id2/:id3/:id4" component={Jobposting} />
          <Route exact path="/otppage/:id1/:id2/:id3/:id4" component={Otppage} />
          <Route exact path="/edituser/:id1/:id2/:id3/:id4" component={EditUser} />
          <Route exact path="/usermanagement/:id1/:id2/:id3/:id4" component={UserManagement} />
          <Route exact path="/clientmanagement/:id1/:id2/:id3/:id4" component={Client} />
          <Route exact path="/mastersettings/:id1/:id2/:id3/:id4" component={MasterSettings} />
          <Route exact path="/applicants/:id1/:id2/:id3/:id4" component={Applicants} />
          <Route exact path="/viewjob/:id1/:id2/:id3/:id4/:id5" component={ViewJob} />
          <Route exact path="/clientsubmissionformat/:id1/:id2/:id3/:id4" component={ClientSubmissionformat} />
          <Route exact path="/viewapplicant/:id1/:id2/:id3/:id4/:id5" component={Viewapplicant} />
          <Route exact path="/jobsearch/:id1/:id2/:id3/:id4/:id5/:id6" component={JobSearch} />
          <Route exact path="/viewclient/:id1/:id2/:id3/:id4/:id5" component={ViewClient} />
          <Route exact path="/viewuser/:id1/:id2/:id3/:id4/:id5" component={ViewUser} />
          <Route exact path="/onboardings/:id1/:id2/:id3/:id4" component={Onboardings} />
        </Switch>
    </div>
    </Router>
  )
 

}

export default App;