import React from "react";
export class SubmissionsTable extends React.Component{
    constructor(){
        super();
        this.state={
            Pipeline:"",
            Submission:"",
            SubmissionDate:"",
            ClientSubmission:"",
            ClientSubmissionDate:"",
            Interview:"",
            InterviewDate:"",
            Confirmation:"",
            ConfirmationDate:"",
            Placement:"",
            PlacementDate:"",
            NotJoined:""
        }
}
componentDidMount(){
    /**Submissions Status Table API Starts */
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    var applicant_id=JSON.stringify(localStorage.getItem("Applicant_ID"))
    const applicantid = applicant_id.replaceAll('"', '');
    var job_id=JSON.stringify(localStorage.getItem("Job_ID"))
    const jobid = job_id.replaceAll('"', '');
    const repdata={userid:withoutQuotes,
          addjobpostingdata:"getjobsubmissionplus",
          editapplicantid:applicantid,
          editjobid:jobid
    }
      console.log(repdata)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
    
          body: JSON.stringify(repdata), // data can be `string` or {object}!
    
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
    
          .catch((error) => console.error("Error:", error))
    
          .then((response) => {
            console.log(response)
         if(response.status==1){
            response.result.map((e)=>{
                this.setState({
                    Pipeline:e.Pipeline,
                    Submission:e.Submission,
                    SubmissionDate:e.SubmissionDate,
                    ClientSubmission:e.ClientSubmission,
                    ClientSubmissionDate:e.ClientSubmissionDate,
                    Interview:e.Interview,
                    InterviewDate:e.InterviewDate,
                    Confirmation:e.Confirmation,
                    ConfirmationDate:e.ConfirmationDate,
                    Placement:e.Placement,
                    PlacementDate:e.PlacementDate,
                    NotJoined:e.NotJoined
                })
            })
         }
         else{
            console.log(response)
         }
          });   
    /**Submissions Status Table API Ends */
}
    render(){
        return(
            <>
            <div className="submissiontble">
       
       
      
            <table class="table" style={{textAlign:"center"}}>
                <thead style={{fontSize:13}}>
                <tr>
                <th>Pipeline</th>
                <th>Submission</th>
                <th>Client Submission</th>
                <th>Interview</th>
                <th>Confirmation</th>
                <th>Onboarded</th>
                <th>Not Joined</th>
            </tr>
                </thead>
            <tbody style={{fontSize:24}}>
            <tr>
                <td colSpan="7">
                    <div className="progress" style={{height:"0.50em"}}>
                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"10%",background:this.state.Pipeline==2?"#2bbc26":"transparent"}}>
                        {this.state.Pipeline==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"1.5em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"1.5em",background:"white"}}></i></>}
                        </div>

                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"8%",background:this.state.Submission==2?"#2bbc26":"transparent"}}>
                        {this.state.Submission==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"2.5em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"1.5em",background:"white"}}></i></>}
                        <br/>
                        <div className="submissiondate">
                        {this.state.SubmissionDate==null||this.state.SubmissionDate==""?null:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.SubmissionDate}</span></>}
                        </div>
                        </div>
                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"15%",background:this.state.ClientSubmission==2?"#2bbc26":"transparent"}}>
                        {this.state.ClientSubmission==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"6em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"6em",background:"white"}}></i></>}
                        <br/>
                        <div className="submissiondate" style={{marginLeft:"10em"}}>
                        {this.state.ClientSubmissionDate==null||this.state.ClientSubmissionDate==""?null:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.ClientSubmissionDate}</span></>}
                        </div>
                        </div>
                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"18%",background:this.state.Interview==2?"#2bbc26":"transparent"}}>
                        {this.state.Interview==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"7em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"7em",background:"white"}}></i></>}
                        <br/>
                        <div className="submissiondate" style={{marginLeft:"12em"}}>
                        {this.state.InterviewDate==null||this.state.InterviewDate==""?null:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.InterviewDate}</span></>}
                        </div>
                        </div>
                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"13%",background:this.state.Confirmation==2?"#2bbc26":"transparent"}}>
                        {this.state.Confirmation==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"5em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"5em",background:"white"}}></i></>}
                        <br/>
                        <div className="submissiondate" style={{marginLeft:"8em"}}>
                        {this.state.ConfirmationDate==null||this.state.ConfirmationDate==""?null:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.ConfirmationDate}</span></>}
                        </div>
                        </div>
                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"15%",background:this.state.Placement==2?"#2bbc26":"transparent"}}>
                        {this.state.Placement==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"6em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"6em",background:"white"}}></i></>}
                        <br/>
                        <div className="submissiondate" style={{marginLeft:"10em"}}>
                        {this.state.PlacementDate==null||this.state.PlacementDate==""?null:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.PlacementDate}</span></>}
                        </div>
                        </div>
                        <div className="progress-bar progress-bar-success progress-bar-striped active" style={{width:"15%",background:this.state.NotJoined==2?"#2bbc26":"transparent"}}>
                        {this.state.NotJoined==2?<><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#2bbc26",fontSize:24,position:"absolute",marginLeft:"6em",background:"white"}}></i></>:<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"#b1aaaa",fontSize:24,position:"absolute",marginLeft:"6em",background:"white"}}></i></>}
                        
                        </div>
                    </div>
                   
                        
                    
                   
                   </td>
               
            </tr>
            </tbody>
            
            </table>
                </div>
               
      
            
            </>
        )
    }
}