import React from "react";
import "./style.css";
import Mainteclogo  from "../images/mrecruit-19.png";
import {Link} from "react-router-dom";
export class Createnewpassword extends React.Component{
    constructor(){
      let data=window.location.pathname.slice(31);
      //console.log(data)
        super();
        this.state={
            email:data,
            password:"",
            showpwd:0,
            forgotpwd:0,
            emailvalidation:""
        }
       this.handleReset=this.handleReset.bind(this)
      }
      handleReset(){
        var passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if(this.state.password==""){
          this.setState({passwordvalidation:"Please enter a correct password."})
        }
        else if(this.state.password.match(passw)){
          const data = {
            email:this.state.email,
            password:this.state.password
          }
       
      
             fetch("https://www.mrecruit.in:5200/api/changepassword", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                console.log(response)
                if(response.message=="New Password Updated Successfully."){
                  this.setState({emailvalidation:response.message})
                  setTimeout(function(){
                    window.location.href=`./`
                 }, 5000);
                  
                }
                else{
                  this.setState({emailvalidation:response.message})
                }
              });
        }
      else{
        this.setState({passwordvalidation:"Please enter a correct password."})
      }
      
      }
      handleChange = (e) => {
        this.setState({
       [e.target.name]: e.target.value, 
       passwordvalidation:"" 
       });      
       };
    render(){
        return(
            <>
              <div>
          {/* Background Image Starts */}
        <div className="backgroundimg">
           <img src={Mainteclogo} className="mainteclogo" style={{padding:"3%"}} />

            <div className="createnewpassbox">
           
            </div>
         
        {/* New Password Field Starts*/}
          
                <div className="row passwordmtop">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="password" placeholder="New Password" name="text" onChange={this.handleChange} />
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
        {/* New Password Field End*/}

        {/* Confirm Password Field Starts*/}
          
             <div className="row mt-4">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="password" placeholder="Confirm Password" name="password" onChange={this.handleChange} />
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* Confirm Password Field End*/}

                {/* password validation text starts */}
                <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-7 passwordvalidtxt">
                <table className="mt-2">
                    <tr>
                        <td className="passwordvalidationtxt">Password Must be 8 Characters or Longer</td>
                    </tr>
                    <tr>
                        <td className="passwordvalidationtxt">Combination of Upper and Lower Case letters, Numbers and Special Character</td>
                    </tr>
                    <tr>
                      <td style={{color:"white",fontFamily:"Inter Bold",paddingTop:"3%",fontSize:13}}>{this.state.emailvalidation==" "?<>
                        
                        </>:<><span >{this.state.emailvalidation}</span></>}</td>
                    </tr>
                </table>
                
                </div>
                </div>
               
                {/* password validation text end */}
          
             {/* Reset Password button  Starts*/}
          
             <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5">
                       {/* Password Validation Text Starts */}
                     <div style={{color:"white",marginBottom:"2%",fontFamily:"Poppins Bold"}}>
                                {this.state.passwordvalidation==""?null:<>
                        <span>{this.state.passwordvalidation}</span>
                        </>}
                                </div>
                       {/* Password Validation Text Ends */}
                      <button className="btn loginbtn" style={{fontWeight:600}} onClick={this.handleReset}>Reset Password</button>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
             {/* Reset Password button End */}
              {/* Return to Login Page Text  Starts*/}
          
              <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5 text-center">
                      <span>
                        <Link className="returntohomepagetxt" to="/">Return to Login Page</Link>
                        </span>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
             {/* Return to Login Page Text End */}
         {/* Mrecruit 2.0 text starts */}
           <div className="row">
                  <div className="col-sm-8"></div>
                <div className="col-sm-3 createnewpasstxt">
                  <span>mRecruit 2.0</span>
                </div>
                </div>
             {/* Mrecruit 2.0 text ends */}
             <span className="mrecruitheadingfpass">mRecruit</span>
             
             <span className="resetpasswordtext">
           
              Reset Your Password
             
              </span>
            
        </div>
      </div>
            </>
        )
    }
}