import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Clientactiveuser from "./Clientactiveuser";
import Clientinactive from "./Clientinactive";
import Clientdelete from "./Clientdelete";
import Select from 'react-select'
import Clientimage  from "../images/clientimage.png";
import ActiveTeam from "./ActiveTeam";
import Clientpending from "./Clientpending";
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import ClientCategory from "./ClientCategory";
import ClientCategoryDelete from "./ClientCategoryDelete";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import FileIcon from "../images/fileicon.png";
import Header from "./Header";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
export default class Client extends React.Component{
    constructor(){
        var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
  var data2=splitURL[3]
  var data3=splitURL[4]
  var data5=splitURL[5]
    let decodedid=window.atob(data1)
    let decodedname=window.atob(data2)
    let decodedemail=window.atob(data3)
    let roleid=window.atob(data5)
 
        super();
        this.state={
            user_name:decodedname,
            clientlogo:" ",
            display:0,
            user_email:decodedemail,
            user_id:decodedid,
          
           encodedid:data1,
           encodedname:data2,
           encodedemail:data3,
          
           displayform:0,
           userroleids:roleid,
           encodedroleid:data5,
           clientmanagementscreen:1,
           clientcategoryscreen:0,
           businessunit:[
            { value: '1', label: 'Maintec Technologies' },
           
          ],
          userstausdata:[
            { value: '1', label: 'Active'},
            { value: '2', label: 'InActive'},
            { value: '3', label: 'Pending'},
           
          ],
          documentdata:[
            
          ],
          ReqDocument:"",
         countrydata:[],
         citydata:[],
         industrydata:[],
         catageroydata:[],
         Countryname:"",
         Countryid:"",
         statedata:[],
         reportingtodata:[],
         ClientLeadname:"",
         ClientLeadids:"",
         Industryname:[],
         Industryid:[],
         cname:"",
         POC1_conactname:"",
         POC1_email:"",
         POC1_contactno:"",
         POC2_conactname:"",
         POC2_email:"",
         POC2_contactno:"",
         POC3_conactname:"",
         POC3_email:"",
         POC3_contactno:"",
         cphone:"",
         Address:"",
         websiteurl:"",
         Stateid:"",
         Cityid:"",
         Countryname:"",
         Statename:"",
         Cityname:"",
         Postalcode:"",
         categoryid:"",
         CategoryName:"",
         cstatus:"",
         BusinessUnit:"Maintec Technologies",
         categoryname:"",
         clientsubmissionscreen:0,
         profilepic:" ",
         data:0,
         elements:[{fullname:"",salary:"",age:""}],
         clientdocname:"",
         uploaddocscreen:0,
         downloadscreen:1,
         docfile:"",
         doctypename:"",
         clientid:"",
         mobileerror:"",
         mobileerror1:"",
         mobileerror2:"",
         mobileerror3:"",
         clientnameerror:"",
         documentids:[],
         CityID:[],
         CityName:[]
      
        }
        this.handleDisplay=this.handleDisplay.bind(this)
        this.handleDisplayClose=this.handleDisplayClose.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.handleJob=this.handleJob.bind(this)
        this.handleUser=this.handleUser.bind(this)
        this.handleHome=this.handleHome.bind(this)
        this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
        this.handleAdmin=this.handleAdmin.bind(this)
        this.handleAdd=this.handleAdd.bind(this)
        this.handleClient=this.handleClient.bind(this)
        this.handleClientcat=this.handleClientcat.bind(this)
        this.handleCancel=this.handleCancel.bind(this)
        this.handleCountry=this.handleCountry.bind(this)
        this.hanldelState=this.handleState.bind(this)
        this.handleCity=this.handleCity.bind(this)
        this.handleReport=this.handleReport.bind(this)
        this.handleRequired=this.handleRequired.bind(this)
        this.handleIndustry=this.handleIndustry.bind(this)
        this.handleUserstatus=this.handleUserstatus.bind(this)
        this.handleRegister=this.handleRegister.bind(this)
        this.handleBusiness=this.handleBusiness.bind(this)
        this.handleCategory=this.handleCategory.bind(this)
        this.handleclosecat=this.handleclosecat.bind(this)
        this.handleSave=this.handleSave.bind(this)
        this.handleApplicant=this.handleApplicant.bind(this)
        this.handleClientSubmission=this.handleClientSubmission.bind(this)
        this.handleMaster=this.handleMaster.bind(this)
       
        this.handlePlus=this.handlePlus.bind(this)
        this.handleminus=this.handleminus.bind(this)
        this.handleupload=this.handleupload.bind(this)
        this.handledownload=this.handledownload.bind(this)
       this.handleclientformat=this.handleclientformat.bind(this)
       this.handleOnboarding=this.handleOnboarding.bind(this)
       this.handleCheckbox=this.handleCheckbox.bind(this)
       this.handleCheckboxclose=this.handleCheckboxclose.bind(this)
       this.handleCheckbox1=this.handleCheckbox1.bind(this)
       this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
       this.handleCheckbox2=this.handleCheckbox2.bind(this)
       this.handleCheckboxclose2=this.handleCheckboxclose2.bind(this)
    }
    handleCheckboxclose2(id,name){
      this.setState({CityID : this.state.CityID.filter(function(val) {return val!==id})})
      this.setState({CityName : this.state.CityName.filter(function(val) {return val!==name})},()=>{
        console.log(this.state.CityName)
      })
  }
  handleCheckbox2(id,name){
    this.setState({CityID:this.state.CityID.concat(id)},()=>{
      console.log(this.state.CityID)
     })
     this.setState({CityName:this.state.CityName.concat(name)},()=>{
      console.log(this.state.CityName)
     })
  
  }
    handleCheckboxclose(id){
      if(id==1){
        this.setState({documentids : []})
      }
      else if(this.state.documentids.includes(1)){
        this.setState({documentids : this.state.documentids.filter(function(val) {return val!==1})},()=>{
          this.setState({documentids : this.state.documentids.filter(function(val) {return val!==id})},()=>{
            //console.log(this.state.documentids)
          })
        })
        
      }
      else{
        this.setState({documentids : this.state.documentids.filter(function(val) {return val!==id})},()=>{
          //console.log(this.state.documentids)
        })
       
      }
      
    }
    handleCheckbox(id){
      if(id==1){
       const ids= this.state.documentdata.map(e=>{
        return e.id       
      })
      //console.log(ids)
      this.setState({documentids:this.state.documentids.concat(ids)},()=>{
        //console.log(this.state.documentids)
       })
      }
      else{
        this.setState({documentids:this.state.documentids.concat(id)},()=>{
         // console.log(this.state.documentids)
         })
      }
  
    }
    handleCheckboxclose1(id,name){
     
        this.setState({Industryid : this.state.Industryid.filter(function(val) {return val!==id})})
        this.setState({Industryname : this.state.Industryname.filter(function(val) {return val!==name})},()=>{
          console.log(this.state.Industryname)
        })
      
      
    }
    handleCheckbox1(id,name){
    
        this.setState({Industryid:this.state.Industryid.concat(id)})
        this.setState({Industryname:this.state.Industryname.concat(name)},()=>{
          console.log(this.state.Industryname)
        })
  
    }

    handleOnboarding(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
    }
    handleupload(){
      this.setState({uploaddocscreen:0,downloadscreen:1})
    }
    handledownload(){
      this.setState({uploaddocscreen:1,downloadscreen:0})
    }
    handleminus(index){
      const rows = [...this.state.elements];
      rows.splice(index, 1);
      this.setState({elements:rows})
    }  
    handlePlus(){
      this.setState({elements:[...this.state.elements, {
        fullName:'',
        emailAddress:'',
        salary:''  
    }]})
    }  

  
    getBase64 = (file) => {
      return new Promise((resolve) => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          console.log("Called", reader);
          baseURL = reader.result;
          console.log(baseURL);
          resolve(baseURL);
        };
        console.log(fileInfo);
      });
    };
    handleImage = (e) => {
      
      // let file = new FileReader();
      console.log(e.target.files[0]);
      let { file } = this.state;
  
      file = e.target.files[0];
  
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;
          console.log("File Is", file);
          this.setState({
            profilepic: result,
            ProfileImg:result,
            file,
          });
        })
        .catch((err) => {
          console.log(err);
        });
  
      this.setState({
        //  file: e.target.files[0]
      });
  
      this.setState({
        //companylogo: e.target.files[0]
      });
      var fileInput = false;
      if (e.target.files[0]) {
        fileInput = true;
      }
      
      if(fileInput) {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              console.log(uri);
              this.setState({ newImage: uri });
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          console.log(err);
        }
      }
    };
    openModal2=()=>{
      this.setState({ showModal2: true });
    }
    closeModal2 = () => {
      this.setState({ showModal2: false });
    };
    openModal3=()=>{
      this.setState({ showModal3: true });
    }
    closeModal3 = () => {
      this.setState({ showModal3: false });
    };
    handleclientformat(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
        const data={ userid:withoutQuotes,
          addctsubmissiondata:"addctsinfo",
          clientid:this.state.clientid,
          doctypename:this.state.doctypename
       
      }
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
               this.setState({message:response.message}) 
            //console.log(response)
         
                  
           
                  
                });
    }
    handleMaster(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    handleSave(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const c_data={userid:withoutQuotes,addclientdata:"addclientcategoryinfo",categoryname:this.state.categoryname}
      console.log(c_data)
      fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
        method: "POST",

        body: JSON.stringify(c_data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {

          Swal.fire(
            response.message,
            
            'success'
          )
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
          2500
        );
          
           console.log(response)
  
              
            });
    }
    handleChangeClientname = (e) => {
      //var regex = /[0-9!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      //e.target.value = e.target.value.replace(regex, "");

      const menudata={ dropdownlist_input: "checkclientinfo",clientname:e.target.value}

    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(menudata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
        if(response.message=="This Client Name already available."){
          this.setState({clientnameerror:response.message})
        }
            
        else{
          this.setState({clientnameerror:response.message})
          this.setState({
            [e.target.name]: e.target.value,

          });
        }
            });
 
    
    };
    handleChangethree = (e) => {
      //var regex = /[0-9!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      //e.target.value = e.target.value.replace(regex, "");
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handleChangePostal = (e) => {
      var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      e.target.value = e.target.value.replace(regex, "");
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handleChangemobile = (e) => {
      var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      e.target.value = e.target.value.replace(regex, "");
      if(e.target.value.length!=10){
        this.setState({mobileerror:"Contact number should be digits only."})
      }
      else{
        this.setState({
          [e.target.name]: e.target.value,
          mobileerror:""
        });
      }
    
      
    };
    handleChangemobile1 = (e) => {
      var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      e.target.value = e.target.value.replace(regex, "");
      if(e.target.value.length!=10){
        this.setState({mobileerror1:"Contact number should be digits only."})
      }
      else{
        this.setState({
          [e.target.name]: e.target.value,
          mobileerror1:""
        });
      }
    
      
    };
    handleChangemobile2 = (e) => {
      var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      e.target.value = e.target.value.replace(regex, "");
      if(e.target.value.length!=10){
        this.setState({mobileerror2:"Contact number should be digits only."})
      }
      else{
        this.setState({
          [e.target.name]: e.target.value,
          mobileerror2:""
        });
      }
    
      
    };
    handleChangemobile3 = (e) => {
      var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
      e.target.value = e.target.value.replace(regex, "");
      if(e.target.value.length!=10){
        this.setState({mobileerror3:"Contact number should be digits only."})
      }
     
      else{
        this.setState({
          [e.target.name]: e.target.value,
          mobileerror3:""
        });
      }
    
      
    };
    handleChangeaddress = (e) => {
      //var regex = /[!@#\$%\^\&*\)\(+=._-``""<>?|{}/-]/g;
      //e.target.value = e.target.value.replace(regex, "");
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handleChange = (e) => {
      console.log(e.target.value)
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handleChangetwo = (e) => {
    	document.getElementById("file-name").innerHTML = e.target.files[0].name;
      
    };
    handleCategory(e){
      this.setState({CategoryName:e.label,categoryid:e.value},()=>{
       
      })
    }
    handleBusiness(e){
      this.setState({BusinessUnit:e.label},()=>{
       
      })
    }
  
    handleRegister(){
      if(this.state.cname==null||this.state.cname==""){
        Swal.fire(
          "Please Enter Client Name"
        )
      }
      
      else if(this.state.POC1_conactname==null||this.state.POC1_conactname==""){
        Swal.fire(
          "Please Enter POC1 Contact name"
        )
      }
      else if(this.state.POC1_email==null||this.state.POC1_email==""){
        Swal.fire(
          "Please Enter POC1 Email"
        )
      }
      else if(this.state.POC1_contactno==null||this.state.POC1_contactno==""){
        Swal.fire(
          "Please Enter POC1 Contactno"
        )
      }
          else if(this.state.cphone==null||this.state.cphone==""){
        Swal.fire(
          "Please Enter Contact Number"
        )
      }
      else if(this.state.cphone.length!=10){
        Swal.fire(
          "Please Enter 10 digits Contact Number"
        )
      }
    
     
     
      else if(this.state.Countryid==null||this.state.Countryid==""){
        Swal.fire(
          "Please Select Country"
        )
      }
      else if(this.state.Stateid==null||this.state.Stateid==""){
        Swal.fire(
          "Please Select State"
        )
      }
      // else if(this.state.Cityid==null||this.state.Cityid==""){
      //   Swal.fire(
      //     "Please Select City"
      //   )
      // }
      else if(this.state.Industryid==null||this.state.Industryid==""){
        Swal.fire(
          "Please Select Industry"
        )
      }
      else if(this.state.categoryid==null||this.state.categoryid==""){
        Swal.fire(
          "Please Choose Category"
        )
      }
      else if(this.state.AboutCompany==null||this.state.AboutCompany==""){
        Swal.fire(
          "Please Enter About Company"
        )
      }
      else if(this.state.cstatus==null||this.state.cstatus==""){
        Swal.fire(
          "Please Choose Client Status"
        )
      }
      else if(this.state.ClientLeadids==null||this.state.ClientLeadids==""){
        Swal.fire(
          "Please Choose Account Manager"
        )
      }
      // else if(this.state.ReqDocument==null||this.state.ReqDocument==""){
      //   Swal.fire(
      //     "Please Enter Req Document"
      //   )
      // }
      else{
        const data={
          userid:this.state.user_id,
          addclientdata:"addclientinfo",
          clientlogo:this.state.clientlogo,
          cname:this.state.cname,
          BusinessUnit:this.state.BusinessUnit,
          POC1_conactname:this.state.POC1_conactname,
          POC1_email:this.state.POC1_email,
          POC1_contactno:this.state.POC1_contactno,
          POC2_conactname:this.state.POC2_conactname,
          POC2_email:this.state.POC2_email,
          POC2_contactno:this.state.POC2_contactno,
          POC3_conactname:this.state.POC3_conactname,
          POC3_contactno:this.state.POC3_contactno,
          POC3_email:this.state.POC3_email,
          cphone:this.state.cphone,
          Address:this.state.Address,
          BusinessUnit:this.state.BusinessUnit,
          websiteurl:this.state.websiteurl,
          Countryid:this.state.Countryid,
          Stateid:this.state.Stateid,
          Cityid:this.state.CityID.toString(),
          Countryname:this.state.Countryname,
          Statename:this.state.Statename,
          Cityname:this.state.CityName.toString(),
          Postalcode:this.state.Postalcode,
          Industryid:this.state.Industryid.toString(),
          Industryname:this.state.Industryname.toString(),
          categoryid:this.state.categoryid,
          CategoryName:this.state.CategoryName,
          AboutCompany:this.state.AboutCompany,
          cstatus:this.state.cstatus,
          ClientLeadids:this.state.ClientLeadids,
          ClientLeadname:this.state.ClientLeadname,
          ReqDocument:this.state.documentids.toString()
        }
        console.log(data)
            fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
                method: "POST",
        
                body: JSON.stringify(data), // data can be `string` or {object}!
        
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
        
                .catch((error) => console.error("Error:", error))
        
                .then((response) => {
        
                  Swal.fire(
                    response.message,
                    
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
                  
                   console.log(response)
          
                      
                    });
      }
      
        }
    handleUserstatus(e){
      this.setState({cstatus:e.value}) 
    }
    handleIndustry(e){
      this.setState({Industryname:e.label,Industryid:e.value})
    }
    handleRequired(e){
      this.setState({ReqDocument:e.value})
    }
    handleReport(e){
      this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
    }
    handleCity(e){
      this.setState({Cityname:e.label,Cityid:e.value})
    }
    handleState(e){
      this.setState({Statename:e.label,Stateid:e.value,},()=>{
        const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
            
            this.setState({citydata:data})
                  
                });
        })
    }
    handleCountry(e){
      this.setState({Countryname:e.label,Countryid:e.value,},()=>{
        const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({statedata:options})
                  
                });
        })
    }
    componentDidMount(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const pdata={ userid:withoutQuotes,
        adduserdata:"getsinglephotoinfo",
      
     
    }
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(pdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
            if(response.result.profilepic=="BASE64"){
              this.setState({ProfileImg:""},()=>{
               
              })
          
           }
           else{
              this.setState({ProfileImg:response.result.profilepic},()=>{
               
              })
          
           }
         
                
              });
               /* Required Document  API Call Start */
       const reqdata={ dropdownlist_input: "getdocumenttypeinfo",userid:withoutQuotes}
       
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(reqdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
        
           this.setState({documentdata:data},()=>{
            //console.log(this.state.documentdata)
           })
                 
               });
              
       /* Required Document  API Call End */
       /* Reporting To API Call Start */
       const redata={ dropdownlist_input: "getreportinginfo"}
       console.log(redata)
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(redata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.reportingid,
               "label" : d.reportingname
   
             })) 
      
           this.setState({reportingtodata:options})
                 
               });
              
       /* Reporting To  API Call End */
      /*Category API Call Start */
      const data={ dropdownlist_input: "getclientcategoryinfo"}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.categoryid,
              "label" : d.categoryname
  
            })) 
     
          this.setState({catageroydata:options})
                
              });
      /* Category API Call End */
        /* Industry API Call Start */
        const rdata={ dropdownlist_input: "getindustryinfo"}
        console.log(rdata)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(rdata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
           /** const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              }))  */
       
            this.setState({industrydata:data})
                  
                });
        /* Industry  API Call End */
  
         /* Country API Call Start */
         const tdata={ dropdownlist_input: "getcountryinfo"
        }
         console.log(tdata)
         fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
             method: "POST",
     
             body: JSON.stringify(tdata), // data can be `string` or {object}!
     
             headers: { "Content-Type": "application/json" },
           })
             .then((res) => res.json())
     
             .catch((error) => console.error("Error:", error))
     
             .then((response) => {
                 
              const data = response.result
               //console.log(json)
         
             const options = data.map(d => ({
                 "value" : d.countryid,
                 "label" : d.conuntryname
     
               })) 
        
             this.setState({countrydata:options})
                   
                 });
         /* Country API Call  End */

         
    }
    handleCancel(){
      this.setState({displayform:0})
    }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          clientlogo: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  clientDoc = (e) => {
    document.getElementById("file-name").innerHTML = e.target.files[0].name;
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          docfile: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handleClientSubmission(){
    this.setState({clientcategoryscreen:0,clientmanagementscreen:0,clientsubmissionscreen:1})
        }
    handleClientcat(){
      this.setState({clientcategoryscreen:1,clientmanagementscreen:0,clientsubmissionscreen:0})
    }
    handleClient(){
      this.setState({clientmanagementscreen:1,clientcategoryscreen:0,clientsubmissionscreen:0})
    }
    handleclosecat(){
      this.setState({clientcategoryscreen:0,clientmanagementscreen:1,clientsubmissionscreen:0})
          }
    handlecloseAdmin(){
        this.setState({on:0})
      }
      handleAdmin(){
    this.setState({on:1})
      }
      handleAdd(){
        this.setState({displayform:1})
      }
      handleApplicant(){
        let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      handleJob(){
        let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
      handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      
      }
     handleHome(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
     }
      handleUser(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    render(){
        return(
            <>
              {/* Header Section Starts */}

        <div className="dashboardheader fixed-top">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{cursor:"pointer"}}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleJob}>Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleApplicant} style={{cursor:"pointer"}}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} className="litext">Clients</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleOnboarding} style={{cursor:"pointer"}}>Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleids==9 ?<>
          
           </>:<> <li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul>
         <Header/>
        </div>

        {/* Header Section Ends */}
                 {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily: "Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}



              {/**Client Submission Format Modal starts */}
              {this.state.showModal3 && (
        <div class="ft-modal" id="ft-clientsubmission-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Add Document
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
      <div className="row">
        <div className="col-xl-12">
        <span style={{fontFamily: "Poppins Light",fontWeight:"bold",fontSize:14}}>Type Document Name</span>
        <p><input type="text" className="form-control adduser mt-2" onChange={this.handleChange} name="doctypename" /></p>
        </div>

      </div>

      <div className="row">
        <div className="col-xl-12">
        <span style={{fontFamily: "Poppins Light",fontWeight:"bold",fontSize:14}}>Choose Document</span>
       <p>
       <div class="inputfile-box">
  <input type="file" style={{height:"2.7em"}} id="file" name="docfile" class="inputfile"  onChange={this.clientDoc} />
  <label for="file">
    <span id="file-name" class="file-box"></span>
    <span class="file-button">
      <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;
      Choose a File
    </span>
  </label>
</div>
       </p>

        </div>

      </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-success updatebtn" style={{width:"inherit",backgroundColor:"#26CE55",marginTop:"5%"}} onClick={this.handleclientformat}>Upload</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily: "Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={()=>this.closeModal3()}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Client Submission Format Modal ends */}
        <div>
              {/**Side Bar starts */}
              <div class="sidebar">
                {this.state.clientmanagementscreen==1?<>
                  <a class="active">Client Management</a>
                </>:<>
                <a onClick={this.handleClient} className="usermgmttxt usermgmtmenu">Client Management</a>
                </>}
  
  {/**this.state.clientcategoryscreen==1?<>
    <a  class="access">Client Category</a>
  </>:<>
  <a  onClick={this.handleClientcat} className="usermgmtmenu">Client Category</a>
                </>*/}
  
  
  
 
  
 
</div>
{/**Side bar ends */}
{/* Client Management Screen Starts */}
{this.state.clientmanagementscreen==1?<>
  <div class="content" >
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily: "Poppins Bold"}}>Client Information</h2></div>
     <div className="col-xl-2 text-center">
      {this.state.displayform==1?<>
        <button onClick={this.handleCancel} className="btn btn-primary useraddbtn">Back</button>
      </>:<><button onClick={this.handleAdd} className="btn btn-primary useraddbtn">Add</button></>}
       </div>
     
 </div>
 {this.state.displayform==0?<>
  <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
    
  
  <div className="row" style={{marginLeft:"0%"}}>
            <div className="col-sm-12">

          
               
          
               <div>
               <Tabs
          activeTab="1"
          className=""
          ulClassName=""
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="Active Clients" className="mr-4">
                <div className="mt-3">
                 <Clientactiveuser/>
                </div>
            </Tab>
           
            <Tab title="Deleted Clients" className="mr-4">
                <div className="mt-3">
                <Clientdelete/>
                </div>
            </Tab>
           
        </Tabs>
                <table>
                  <tr>
                    <td> <span style={{fontFamily: "Poppins Bold",borderBottom:"2px solid blue",color:"blue",cursor:"pointer"}}></span></td> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <td> <span style={{fontFamily: "Poppins Bold"}}></span></td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <td> <span style={{fontFamily: "Poppins Bold"}}></span></td>
                  </tr>
                </table>
              
               </div> 
            </div>
          </div>
 </>:<>
 {/**Add user for starts */}
 <div>
  <hr/>
  <div className="row">
    <div className="col-xl-1">
    
{this.state.clientlogo==" "?<>
<img src={Clientimage} 
 
 style={{width:"120%"}}  />
</>:<>  <img src={this.state.clientlogo} 
 
 style={{width:"120%",border:"2px solid #4293fe"}}  /></>}


  

   
    </div>
    <div className="col-xl-2">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Client Name</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Address</span>
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Contact Number</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
  </div>
  
  <div className="row">
    <div className="col-xl-4">
     <input className="form-control adduser mt-2"
      placeholder="Client Name" name="cname" onChange={this.handleChangeClientname}/>
       <span style={{fontFamily: "Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.clientnameerror}</span>
    </div>
    <div className="col-xl-4">
     <input name="Address" onChange={this.handleChangeaddress} className="form-control adduser mt-2" placeholder="Address"/>
    </div>
    <div className="col-xl-4">
     <input 
     name="cphone"
     maxLength="10"
     onChange={this.handleChangemobile}
     className="form-control adduser mt-2" placeholder="Contact Number"/>
      <span style={{fontFamily: "Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror}</span>
    </div>
  </div>
  <div className="row mt-2">
  <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Account Manager</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
    
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Category</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Country</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
  </div>
  <div className="row">
    <div className="col-xl-4">
     
    <Select 
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    
 <Select 
    onChange={(e)=>this.handleCategory(e)}
    options={this.state.catageroydata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    
    <Select 
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
       </div>
  </div>
  
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>State</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    onChange={(e)=>this.handleState(e)}
    options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                              styles={style} />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Location</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <div className="mt-1" style={{height:"5.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.citydata.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.CityID.includes(e.id)==true?<>
          <i className="fa fa-check-square"  onClick={()=>this.handleCheckboxclose2(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.CityID.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox2(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Postal Code</span>
     <input 
     name="Postalcode" maxLength="9" onChange={this.handleChangePostal}
     className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily: "Poppins Bold"}}>POC-1</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Name</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input name="POC1_conactname" onChange={this.handleChangethree} placeholder="Name" 
      className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Contact Number</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input
     maxLength="10" 
     name="POC1_contactno" onChange={this.handleChangemobile1} 
     className="form-control adduser mt-2" />
      <span style={{fontFamily: "Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror1}</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Email</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="email" 
      name="POC1_email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily: "Poppins Bold"}}>POC-2</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Name</span>
     <input 
     name="POC2_conactname" onChange={this.handleChangethree}
      placeholder="Name"  className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Contact Number</span>
     <input  
     maxLength="10"
     name="POC2_contactno" onChange={this.handleChangemobile2}
     className="form-control adduser mt-2" />
      <span style={{fontFamily: "Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror2}</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Email</span>
     <input type="email" 
     name="POC2_email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily: "Poppins Bold"}}>POC-3</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Name</span>
     <input      name="POC3_conactname" 
     onChange={this.handleChangethree} placeholder="Name"  className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Contact Number</span>
     <input 
    name="POC3_contactno"
    maxLength="10" 
    onChange={this.handleChangemobile3}
     className="form-control adduser mt-2" />
      <span style={{fontFamily: "Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror3}</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Email</span>
     <input type="email" 
       name="POC3_email" 
       onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>

  <div className="row mt-2">
    <div className="col-xl-6">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Website</span>
    <input type="text" 
     name="websiteurl" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
   
    
    <div className="col-xl-6">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Status</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    onChange={(e)=>this.handleUserstatus(e)}
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>
  <div className="row mt-2">
  <div className="col-xl-12">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Industry</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    
    <div className="row mt-2" style={{height:"5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.industrydata.map((e)=>(
        <>
        <div className="col-xl-3">
        {this.state.Industryid.includes(e.id)==true?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.Industryid.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>

    </div>
  </div>
  <div className="row mt-2">
  <div className="col-xl-12">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Required Documents</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    
    <div className="row mt-2" style={{border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.documentdata.map((e)=>(
        <>
        <div className="col-xl-3">
        {this.state.documentids.includes(e.id)==true?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose(e.id)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.documentids.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox(e.id)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>

    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-12">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>About Company</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
   <textarea name="AboutCompany" onChange={this.handleChangethree} className="form-control adduser" rows="4"></textarea>
    </div>
   
    
  </div>
  <div className="row">
  <div className="col-xl-8">

  </div>
  <div className="col-xl-2">
<button className="btn btn-primary regbtn" style={{width:"100%"}} onClick={this.handleRegister}>Save</button>
</div>
<div className="col-xl-2">
<button className="btn btn-primary regbtn" style={{width:"100%"}} onClick={this.handleCancel}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
 </>}
 
  
</div>
</>:null}
{/* Client Management Screen Ends */}

{/* Client Category Screen Starts */}
{this.state.clientcategoryscreen==1?<>
  <div class="content">
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily: "Poppins Bold"}}>
      Client Categories
      </h2></div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
   <div className="row">
  <div className="col-xl-2">
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",}}>Client Category</span>
  </div>
  <div className="col-xl-3">
  <input type="text" className="form-control adduser" name="categoryname" onChange={this.handleChange}/>
  </div>
  </div>  
  <div className="row mt-2">
  <div className="col-xl-2"></div>
  <div className="col-xl-1">
    
<button className="btn btn-primary updatebtn" onClick={this.handleSave}>Save</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleclosecat}>Cancel</button>
</div>
  </div>  
 <div className="row">
<div className="col-xl-12">
<Tabs
          activeTab="1"
          className=""
          ulClassName=""
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="Active Client Category" className="mr-4">
                <div className="mt-3">
                <ClientCategory/>
                </div>
            </Tab>
            <Tab title="Deleted Client Category" className="mr-4">
                <div className="mt-3">
               <ClientCategoryDelete/>
                </div>
            </Tab>
          </Tabs>

</div>
 </div>
 
  
</div>
</>:null}
{/* Client Category Screen Ends */}


  {/* Client Submission Screen Starts */}
{this.state.clientsubmissionscreen==1?<>
  <div class="content">
  <div class="row">
    <div class="col-xl-10">
    <h2 style={{fontFamily: "Poppins Bold"}}>Client Submission Format</h2>
    </div>
    <div class="col-xl-1">
      <button className="btn btn-primary updatebtn">Save</button>
    </div>
    <div class="col-xl-1">
      <button className="btn btn-primary updatebtn" onClick={()=>window.location.reload()}>Reset</button>
    </div>
</div>
<hr/>


<div className="text-center" style={{marginTop:"14%"}}>
<img src={FileIcon} style={{width:"7%"}}/>
<p style={{marginTop:"1%",fontFamily: "Poppins Light",fontWeight:"bold"}}>Lets Add Some Documents</p>
<p><a href="#ft-clientsubmission-modal" onClick={()=>this.openModal3()} className="btn btn-success updatebtn" style={{fontSize:14,backgroundColor:"#26CE55",marginTop:"0%",border:"1px #26CE55"}}>Add Documents</a></p>
</div>









</div>
</>:null}
{/* Client Submission  Screen Ends */}   






    


        </div>











           
      
            </>
        )
    }
}