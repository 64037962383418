import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import Swal from "sweetalert2";
import Select from "react-select";
import Tabs, { Tab } from "react-best-tabs";
import Resizer from "react-image-file-resizer";
import Applicantimg from "../images/applicantimg.png";
import "react-best-tabs/dist/index.css";
import Jobpostingdatatable from "./Jobpostingdatatable";
import Activeapplicant from "./Activeapplicant";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import DeletedApplicant from "./DeletedApplicants";
import InternalDB from "./InternalDB";
import Header from "./Header";
const style = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #A0BED8",
    borderRadius: "1px",
    marginTop: "1.5%",
    backgroundColor: "#EAF4FF",
    color: "black",
    borderRadius: "2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius: "1px",
      backgroundColor: "#EAF4FF",
      color: "black",
    },
  }),
};

export default class JobSearch extends React.Component {
  constructor() {
    var username = JSON.stringify(localStorage.getItem("Username"));
    const withoutQuotes = username.replaceAll('"', "");
    var newURL = window.location.pathname;
    var splitURL = newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    var data6=splitURL[6]
    var data7=splitURL[7]
    let decodedid = window.atob(data1);
    let decodedname = window.atob(data2);
    let decodedemail = window.atob(data3);
    let roleid = window.atob(data5);
    let searchtxt=window.atob(data6)
    let jobidencode=window.atob(data7)
    console.log(splitURL)
    //console.log('data1id=', decodedid);
    //console.log('data2name=', decodedname);
    //console.log('data3mail=', decodedemail);
    super();
    this.state = {
      user_name: withoutQuotes,
      display: 0,
      encodedid: data1,
      encodedname: data2,
      encodedemail: data3,
      jobiddecode: jobidencode,
      displayform: 0,
      userroleids: roleid,
      encodedroleid: data5,
      newjob: 0,
      jobpostingscreen: 0,
      countrydata: [],
      statedata: [],
      citydata: [],
      userstausdata: [{ value: "1", label: "Active" }],
      PrefCountryID: "",
      PrefCountryName: "",
      PrefStateID: "",
      PrefStateName: "",
      PrefCityName: "",
      PrefCityID: "",
      clientdata: [],
      HiringManager: "",
      HiringManagerid: "",
      reportingtodata: [],
      SPOCMobile: "",
      SPOCName: "",
      Assingnedid: [],
      businessunit: [{ value: "1", label: "Maintec Technologies" }],
      JobTitle: "",
      ClientName: "",
      BusinessUnit: "",
      languagesdata: [],
      degreedata: [],
      ClientId: "",
      CBR_Coin: "INR",
      PR_Coin: "INR",
      PR_MinAmount: "",
      PR_MaxAmount: "",
      ClientJobID: "CJ505",
      NoofPositions: "",
      ClosedPostions: "",
      JobCreatedDate: "",
      JobClosingDate: "",
      SPOC: "",
      HiringManagerid: "",
      HiringManager: "",
      NoticePeriod: "",
      JobDescription: "",
      DegreeID: "",
      Degreename: "",
      ExperienceMin: "",
      ExperienceMax: "",
      PrimarySkills: "",
      Languages: "",
      JobStatus: "",
      SPOCMobile: "",
      SPOCName: "",
      JobCode: "",
      AssingnedID: "",
      AssingnedName: "",
      Assingnedname: [],
      ProfilePhoto: " ",
      documentscreen:0,
      personaldetailscreen:0,
      fullnamedata:[
        { value: '1', label: 'Mr.'},
        { value: '2', label: 'Mrs.'},
     
       
      ],
      Genderdata:[
        { value: '1', label: 'Male'},
        { value: '2', label: 'Female'},
        {value: '3', label: 'Other'},
     
       
      ],
      loadingicon:0,
      sourcedata:[
        { value: '1', label: 'Naukri'},
        { value: '2', label: 'Monster'},
        { value: '3', label: 'Other'},
       
      ],
      docdata:[
        { value: '1', label: 'PAN'},
        { value: '2', label: 'Aadhar'},
        { value: '3', label: 'Other'},
       
      ],
      ApplicantName:"",
      Gender:"",
      EmailID:"",
      AlternateEmail:"",
      MobileNo:"",
      AlternateMobile:"",
      DateofBirth:"",
      PreviousOrg:"",
      PrevDesignation:"",
      TotalExp:"",
      RelevantExp:"",
      HighestDegree:"",
      CurrentCTC:"",
      ExpectedCTC:"",
      VariableAmount:"",
      FixedAmount:"",
      Address:"",
      PrefPrefCountryID:"",
      PrefPrefCountryName:"",
      PrefPrefStateID:"",
      PrefPrefStateName:"",
      PrefPrefCityID:"",
      PrefPrefCityName:"",
      SourceID:"",
      SourceName:"",
      JobTitle:"",
      Availablity:"",
      NationalityID:"",
      NationalityName:"",
      GovtIDType:"",
      GovtIDNumber:"",
      IndustryID:"",
      IndustryName:"",
      linkedInurl:"",
      KeySkills:"",
      NoticePeriod:"",
      Remarks:"",
      Honorific:"",
      Relocate:"",
      nationdata:[
        { value: '1', label: 'Indian'},
        { value: '2', label: 'Muslim'},
        { value: '3', label: 'Other'},
       
      ],
      industrydata:[],
      searchscreen:0,
      showModal2: false,
      ProfileImg:"",
      profilepic:"",
      searchtext:searchtxt,
      showModal3:false,
      naukriname:"",
      naukripassword:""
    };
    this.handleDisplay = this.handleDisplay.bind(this);
    this.handleDisplayClose = this.handleDisplayClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleUser = this.handleUser.bind(this);
    this.hanldelClient = this.hanldelClient.bind(this);
    this.handleHome = this.handleHome.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleNewclose = this.handleNewclose.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleUserstatus = this.handleUserstatus.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleClient = this.handleClient.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleassigned = this.handleassigned.bind(this);
    this.handleBusiness = this.handleBusiness.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleDegree = this.handleDegree.bind(this);
    this.handleJob = this.handleJob.bind(this);
    this.handleDocument=this.handleDocument.bind(this)
    this.handleFullName=this.handleFullName.bind(this)
    this.handleGender=this.handleGender.bind(this)
    this.handleSource=this.handleSource.bind(this)
    this.handleNationality=this.handleNationality.bind(this)
    this.handleDoc=this.handleDoc.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleSearchscreen=this.handleSearchscreen.bind(this)
    this.handleSearchClose=this.handleSearchClose.bind(this)
    this.handleUpdateImage=this.handleUpdateImage.bind(this)
    this.handleApplicant=this.handleApplicant.bind(this)
    this.handleOnboarding=this.handleOnboarding.bind(this)
    this.handleNaukri=this.handleNaukri.bind(this)
  }
  handleNaukri(){
    var userID=JSON.stringify(localStorage.getItem("UserId"))
    console.log(userID); 
    
    const naukriuserid = userID.replaceAll('"', '');    
    window.open(`https://login.recruit.naukri.com/Login/authenticate#jobid~${this.state.jobiddecode}_${naukriuserid}&&reqid~21&&UID~${this.state.naukriname}&&pwd~${this.state.naukripassword}`,"_blank")
    //window.open("https://login.recruit.naukri.com/Login/authenticate#jobid~'+","_blank")
  }
  handleOnboarding(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
  }
  handleApplicant(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleUpdateImage(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,
        adduserdata:"editsinglephotoinfo",
        profilepic:this.state.profilepic
     
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
         if(response.message=="Profile Pic Updated successfully"){
          const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             //console.log(response)
                 this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                 
                        
                 })
             
                    
                  });
         }
          
                
         
                
              });
  }
  
  openModal2=()=>{
    this.setState({ showModal2: true });
  }
  closeModal2 = () => {
    this.setState({ showModal2: false });
  };
  openModal3=()=>{
    this.setState({ showModal3: true });
  }
  closeModal3 = () => {
    this.setState({ showModal3: false });
  };
  handleSearchClose(){
    this.setState({searchscreen:0})
  }
  handleSearchscreen(){
    this.setState({searchscreen:1})
  }
  handleIndustry(e){
    this.setState({IndustryID:e.value,IndustryName:e.label})
  }
  handleDoc(e){
    this.setState({GovtIDType:e.label})
  }
  handleNationality(e){
this.setState({NationalityID:e.value,NationalityName:e.label})
  }
  handleSource(e){
   this.setState({SourceID:e.value,SourceName:e.label}) 
  }
  handleGender(e){
this.setState({Gender:e.label})
  }
  handleFullName(e){
this.setState({Honorific:e.label})
  }
  handleDocument(){
    this.setState({documentscreen:1})
  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          ProfilePhoto: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handleJob() {
    let data = window.location.pathname.slice(0, 0);
  
    window.location.href = `${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
  }
  handleDegree(e) {
    this.setState({ Degreename: e.label, DegreeID: e.value });
  }
  handleLanguage(e) {
    this.setState({ Languages: e.value });
  }
  handleSave() {
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const data = {
      userid: withoutQuotes,
      addAppliantdata: "addApplicantinfo",
      ApplicantName:this.state.ApplicantName,
      ProfilePhoto:this.state.ProfilePhoto,
      Gender:this.state.Gender,
      EmailID:this.state.EmailID,
      AlternateEmail:this.state.AlternateEmail,
      MobileNo:this.state.MobileNo,
      AlternateMobile:this.state.AlternateMobile,
      DateofBirth:this.state.DateofBirth,
      PreviousOrg:this.state.PreviousOrg,
      PrevDesignation:this.state.PrevDesignation,
      TotalExp:this.state.TotalExp,
      RelevantExp:this.state.RelevantExp,
      HighestDegree:this.state.HighestDegree,
      CurrentCTC:this.state.CurrentCTC,
      ExpectedCTC:this.state.ExpectedCTC,
      VariableAmount:this.state.VariableAmount,
      FixedAmount:this.state.FixedAmount,
      Address:this.state.Address,
      PrefCountryID:this.state.PrefCountryID,
      PrefCountryName:this.state.PrefCountryName,
      PrefStateID:this.state.PrefStateID,
      PrefStateName:this.state.PrefStateName,
      PrefCityID:this.state.PrefCityID,
      PrefCityName:this.state.PrefCityName,
      SourceID:this.state.SourceID,
      SourceName:this.state.SourceName,
      JobTitle:this.state.JobTitle,
      Availablity:this.state.Availablity,
      NationalityID:this.state.NationalityID,
      NationalityName:this.state.NationalityName,
      GovtIDType:this.state.GovtIDType,
      GovtIDNumber:this.state.GovtIDNumber,
      IndustryID:this.state.IndustryID,
      IndustryName:this.state.IndustryName,
      linkedInurl:this.state.linkedInurl,
      KeySkills:this.state.KeySkills,
      NoticePeriod:this.state.NoticePeriod,
      Remarks:this.state.Remarks,
      Honorific:this.state.Honorific,
      Relocate:this.state.Relocate

    };
    console.log(data);
    fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
     Swal.fire(
          response.message,

          "success"
        );
        setTimeout(
          function () {
            window.location.reload();
          }.bind(this),
          2500
        ); 

        //console.log(response);
      });
  }
  handleBusiness(e) {
    this.setState({ BusinessUnit: e.label }, () => {});
  }
  handleassigned(event, value) {
    if (value.action == "select-option") {
      event.map((e) => {
        this.setState(
          { Assingnedid: this.state.Assingnedid.concat(e.value) },
          () => {
            console.log(this.state.Assingnedid);
          }
        );
        this.setState(
          { Assingnedname: this.state.Assingnedname.concat(e.value) },
          () => {
            console.log(this.state.Assingnedname);
          }
        );
      });
    } else if (value.action == "remove-value") {
      this.setState({ Assingnedid: [] });
    } else {
      console.log("error");
    }
  }
  handleReport(e) {
    this.setState({ HiringManager: e.label, HiringManagerid: e.value });
  }
  handleCity(e) {
    this.setState({ PrefCityName: e.label, PrefCityID: e.value });
  }
  handleClient(e) {
    this.setState({ ClientName: e.label, ClientId: e.value });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleUserstatus(e) {
    this.setState({ JobStatus: e.value });
  }
  handleState(e) {
    this.setState({ PrefStateName: e.label, PrefStateID: e.value }, () => {
      const data = { dropdownlist_input: "getcityinfo", stateid: e.value };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          const data = response.result;
          //console.log(json)

          const options = data.map((d) => ({
            value: d.id,
            label: d.name,
          }));

          this.setState({ citydata: options });
        });
    });
  }
  handleCountry(e) {
  
    this.setState({ PrefCountryName: e.label, PrefCountryID: e.value }, () => {
      const data = { dropdownlist_input: "getstateinfo", countryid: e.value };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          const data = response.result;
          //console.log(json)

          const options = data.map((d) => ({
            value: d.id,
            label: d.name,
          }));

          this.setState({ statedata: options });
        });
    });
  }
  handleNewclose() {
    this.setState({ newjob: 0, jobpostingscreen: 0 });
  }
  handleNew() {
    this.setState({ newjob: 1, jobpostingscreen: 1 });
  }
  handleUser() {
    let data = window.location.pathname.slice(0, 0);
    window.location.href = `${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
  }

  handleLogout() {
    localStorage.clear();
    let data = window.location.pathname.slice(0, 0);
    window.location.href = `${data}/`;
  }
  handleHome() {
    let data = window.location.pathname.slice(0, 0);
    window.location.href = `${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`;
  }
  handleDisplay() {
    this.setState({ display: 1 });
  }
  handleDisplayClose() {
    this.setState({ display: 0 });
  }

  hanldelClient() {
    let data = window.location.pathname.slice(0, 0);
    window.location.href = `${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
  }
  componentDidMount() {
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const pdata={ userid:withoutQuotes,
      adduserdata:"getsinglephotoinfo",
    
   
  }
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(pdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          
       if(response.result.profilepic=="BASE64"){
            this.setState({ProfileImg:""},()=>{
             
            })
        
         }
         else{
            this.setState({ProfileImg:response.result.profilepic},()=>{
             
            })
        
         } 
       
              
            });
    /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */
    /* Reporting To API Call Start */
    const redata = { dropdownlist_input: "getreportinginfo" };
    console.log(redata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(redata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.reportingid,
          label: d.reportingname,
        }));

        this.setState({ reportingtodata: options });
      });

    /* Reporting To  API Call End */

    /* Language API Call Start */
    const ledata = { dropdownlist_input: "getlanguagesinfoinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(ledata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ languagesdata: options });
      });

    /*Language  API Call End */

    /* Degree API Call Start */
    const degdata = { dropdownlist_input: "getdegreesinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(degdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ degreedata: options });
      });

    /*Language  API Call End */
    /* Country API Call Start */
    const tdata = { dropdownlist_input: "getcountryinfo" };
    console.log(tdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.countryid,
          label: d.conuntryname,
        }));

        this.setState({ countrydata: options });
      });
    /* Country API Call  End */

    /* Client API Call Start */
    const cdata = { dropdownlist_input: "getclientDropinfo" };
    console.log(cdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(cdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))
      
      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.clientid,
          label: d.clientname,
        }));

        this.setState({ clientdata: options });
      });
    /* Client API Call  End */


      /* Naukri source API Call Start */
      var userId=JSON.stringify(localStorage.getItem("UserId"))
      const user_id = userId.replaceAll('"', '');
      
      var editsourceId=JSON.stringify(localStorage.getItem("SourceId"))
      const editsource_id = editsourceId.replaceAll('"', '');
      const naukridata = {"userid":user_id,
         "addSourceemaildata": "getsinglesourceinfo",
         "editsourceid":editsource_id
        };
      fetch("https://www.mrecruit.in:5200/api/getsourceemailinfo", {
        method: "POST",
  
        body: JSON.stringify(naukridata), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
         //console.log(response)
          this.setState({
            naukriname:btoa(response.result[0].name),
            naukripassword: btoa(response.result[0].password)
          })
        });
      /* Naukri source API Call  End */
  }
  render() {
    return (
      <div>
        {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{cursor:"pointer"}}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext">Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleApplicant}  style={{cursor:"pointer"}}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClient}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleids==9 || this.state.userroleids==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul>
          
         <Header/>
        
                  
        </div>

        {/* Header Section Ends */}
              {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}


  
{this.state.searchscreen==0?<>
  {this.state.jobpostingscreen == 0 ? (
          <>
          
         

            {/* Applicants Data Table started */}

            <div className="row container-fluid">
              <div
                className="col-xl-12"
                style={{ marginLeft: "2%" }}
              >
                <Tabs
                  activeTab="1"
                  className=""
                  ulClassName=""
                  activityClassName="bg-success"
                  onClick={(event, tab) => console.log(event, tab)}
                >
                  <Tab title="Internal DB" className="mr-4">
                    <div style={{marginLeft:"-1%"}}>
                   
                     <InternalDB/>
                     
                    </div>
                  </Tab>
                  <Tab title="Naukri" className="mr-4" >
                    <div style={{textAlign:"center"}}>
                    <button className="btn updatebtn" style={{color:"white",fontSize:15,display:this.state.naukriname==""?"none":null}} onClick={this.handleNaukri}>Click here to Redirect Naukri Website</button>
                    </div>
                  </Tab>
                 
                  <Tab title="Monster" className="mr-4">
                    <div style={{marginLeft:"-2%"}}>
                    
                    </div>
                  </Tab>
                </Tabs>
              </div>
         
            </div>
            {/** Job Posting Data Table end */}
          </>
        ) : null}
      
</>:<>
<div style={{backgroundColor:"rgb(234, 244, 255)",height:"auto",paddingBottom:"3%"}}>
<div className="row">
  <div className="col-xl-12" style={{textAlign:"right"}}>
    <button className="btn btn-primary updatebtn" style={{marginRight:"4%",marginTop:"1%"}} onClick={this.handleSearchClose}>Back</button>

  </div>

</div>
<div className="row container-fluid" style={{marginLeft:"2%"}}>
  <div className="col-xl-8" style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "40em",
                    boxShadow: "0 0 5px #a2a2a2",
                   
                    paddingBottom:"3%"
                  }}>

          <h4 style={{fontFamily:"Inter Bold"}}>Search Candidate</h4>
          <div className="row">
            <div className="col-xl-12">
                <span style={{fontFamily:"Inter Bold",fontSize:13}}>Key Words</span><br/>
                <input type="text" className="form-control adduser mt-1" placeholder="Enter the Keyword" />
            </div>

          </div>

  </div>
  <div className="col-xl-3" style={{padding:"0%",margin:"1%"}}>
    <div style={{
                   
                    background: "#fff",
                    borderRadius: "10px",
                    height: "10em",
                    boxShadow: "0 0 5px #a2a2a2",
                    padding:"5%",
                    paddingBottom:"3%"
                  }}>
                      <h5>
                    <i class="fa fa-hand-o-right" aria-hidden="true"></i>  Recent Searches
                    </h5>

    </div>

    <div style={{
                  
                    background: "#fff",
                    borderRadius: "10px",
                    height: "10em",
                    boxShadow: "0 0 5px #a2a2a2",
                  padding:"5%",
                    paddingBottom:"3%",
                    marginTop:"3%"
                  }}>
                      <h5>
                    <i class="fa fa-hand-o-right" aria-hidden="true"></i>  Saved Searches
                    </h5>

    </div>           

  </div>
</div>
</div>
</>}
       
        {/*New Applicant End*/}
      </div>
    );
  }
}
