import React from "react";
import "./style.css";
import Mainteclogo  from "../images/mrecruit-19.png";
import { Link } from "react-router-dom";
export default class Home extends React.Component {
  constructor(){
    super();
    this.state={
        email:"",
        password:"",
        showpwd:0,
        forgotpwd:0,
        emailvalidation:" ",
        passwordvalidation:" ",
        userid:""
    }
    this.handleLogin=this.handleLogin.bind(this)
    this.handleShowpwd=this.handleShowpwd.bind(this)    
    this.handleClosepwd=this.handleClosepwd.bind(this)
    this.displayForgotpwd=this.displayForgotpwd.bind(this)
  }

/* Forgotpassword Display Function Call Starts */
  displayForgotpwd(){
      this.setState({forgotpwd:1})
  }
/* Forgotpassword Display Function Call End */

/* Show Password function call starts */
handleClosepwd(){
  this.setState({showpwd:0})
}
/*Show password function call end */

/* Show password function call starts */

handleShowpwd(){
  this.setState({showpwd:1})
}

/* Show password function call end */

/* Login API Call Starts */

  handleLogin(){
    var passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if(this.state.email==""){
      this.setState({emailvalidation:"Please Enter User Name",passwordvalidation:""})
    }
    else if(this.state.password==""){
      this.setState({passwordvalidation:"Please Enter Password",emailvalidation:""})
    }
    else if(this.state.password.match(passw)){
      const data = {

        email:this.state.email,
        password:this.state.password
       
  
      }
   
  
         fetch("https://www.mrecruit.in:5200/api/login", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json","mode": "Access-Control-Allow-Origin", },
           
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            console.log(response)
            if (response.message=="Please enter a correct password."){
              this.setState({passwordvalidation:response.message})
            }
            else if(response.message=="Please enter a valid Email Address"){
              this.setState({passwordvalidation:response.message})
            }
            else if(response.user.otpstatus==1){
              let data=window.location.pathname.slice(0,0);
              console.log(data)
              let encodedemail = window.btoa(this.state.email);
              let encodeduserid=window.btoa(response.user.loginuserid)
              let encodedusername=window.btoa(response.user.username)
              let encodedroleid=window.btoa(response.user.userroleids)
              window.location.href=`${data}/otppage/${encodedemail}/${encodeduserid}/${encodedusername}/${encodedroleid}`
              localStorage.setItem("UserId",response.user.loginuserid)
              localStorage.setItem("SourceId",response.user.editsourceid)
              JSON.parse(localStorage.setItem("Username",response.user.username))
             
              JSON.parse(localStorage.setItem("UserType",response.user.user_type))
             
           
            }
            else if(response.user.otpstatus==2){
              let data=window.location.pathname.slice(0,0);
              console.log(data)
              let encodedemail = window.btoa(this.state.email);
              let encodeduserid=window.btoa(response.user.loginuserid)
              let encodedusername=window.btoa(response.user.username)
              let encodedroleid=window.btoa(response.user.userroleids)
              window.location.href=`${data}/dashboard/${encodedemail}/${encodeduserid}/${encodedusername}/${encodedroleid}`
              localStorage.setItem("UserId",response.user.loginuserid)
              JSON.parse(localStorage.setItem("Username",response.user.username))
             
               JSON.parse(localStorage.setItem("UserType",response.user.user_type))
              
            }
            else{
              this.setState({passwordvalidation:response.message})
            }   
          });
    }
    else{
     this.setState({passwordvalidation:"Incorrect Password"})
    }
   
   
  }
  /* Login API Call End */

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     emailvalidation:"",passwordvalidation:""
    });
    
  };
  render() {
    return (
      
      <div>
          {/* Background Image Starts */}
        <div className="backgroundimg">
           <img src={Mainteclogo} className="mainteclogo" style={{padding:"3%"}} />

            <div className="loginbox">
           
            </div>
            {/* User Name Input Field Starts*/}
            {this.state.forgotpwd==1?<>
              <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none inputboxxx" type="text" placeholder="User Name" name="email" onChange={this.handleChange} /><br/>
                       
                    </div>
                    <div className="col-sm-3"></div>
             </div>
            </>:<>
            <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 inputbox">
                                 <input autoComplete="off" className="form-control usernamebox shadow-none inputboxxx" type="text" placeholder="User Name" name="email" onChange={this.handleChange} />
                             {/* Email Validation Text Starts */}
                                <div className="emailvalidation">
                                {this.state.emailvalidation==" "?null:<>
                        <span>{this.state.emailvalidation}</span>
                        </>}
                                </div>
                       {/* Email Validation Text Ends */}
                    </div>
              
                    <div className="col-sm-3"></div>
             </div>
            </>}
            
              {/* User Name Input Field End*/}

              {/* Password Field Starts*/}
              {this.state.forgotpwd==1?<>
                <div className="row" style={{visibility:"hidden"}}>
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5">
                    {this.state.showpwd==0?<>
                      <input autoComplete="off" className="form-control usernamebox shadow-none inputboxxx " type="password" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password}/>
                      </>:null}
                        {this.state.showpwd==1?<>
                          <input autoComplete="off" className="form-control usernamebox shadow-none inputboxxx" type="text" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} />
                        </>:null}
                    </div>
                    <div className="col-sm-3"></div>
             </div>
              </>:<>
              <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5">
           
           
                      {this.state.showpwd==0?<>

                        <div class="input-group">
       
        <input type="password" style={{borderRadius:"4px"}}  class="form-control usernamebox shadow-none inputboxxx"  placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} />
        <button type="button"  class="toggle">
          <i id="eyeIcon" class="fa fa-eye"  onClick={this.handleShowpwd}></i></button>
      </div>
                                  </>:null}
                        {this.state.showpwd==1?<>
                          <div class="input-group">
       
       <input style={{borderRadius:"4px"}} type="text" value={this.state.password}  class="form-control usernamebox shadow-none inputboxxx"  placeholder="Password" name="password" onChange={this.handleChange} />
       <button type="button"  class="toggle">
         <i id="eyeIcon" class="fa fa-eye-slash"  onClick={this.handleClosepwd}></i></button>
     </div>
                         
                        </>:null}
                    </div>
                     
                    <div className="col-sm-3"></div>
             </div>
              </>}
           
            {/* Password Filed End*/}

            {/* Show Password Field Starts*/}
            
            <div className="row">
            <div className="col-sm-3"></div>
                    <div className="col-sm-4">
                    {/* Password Validation Text Starts */}
                     <div className="passwordvalidation">
                                {this.state.passwordvalidation==" "?null:<>
                        <span>{this.state.passwordvalidation}</span>
                        </>}
                                </div>
                       {/* Password Validation Text Ends */}</div>
                    <div className="col-sm-4 showpasswordmargin">
                    <Link to="/forgotpassword" class="forgotpasswordtext"> Forgot Password?</Link>
                    </div>

             </div>
           

             {/* Login button  Starts*/}
             {this.state.forgotpwd==1?<>
              <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5 ">
                      <button className="btn loginbtn">Reset Password</button>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
             </>:<>
             <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5 ">
                      <button className="btn loginbtn" onClick={this.handleLogin}>Login</button>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
             </>}
             
             {/* Login button End*/}
 
             {/* Mrecruit 2.0 text starts */}
                <div className="row">
                  <div className="col-sm-8"></div>
                <div className="col-sm-3 mrecruittxtbelow">
                  <span>mRecruit 2.0</span>
                </div>
                </div>
             {/* Mrecruit 2.0 text ends */}
                      
            <span className="mrecruitheading">mRecruit</span>
             
             <span className="mrecuitsubheading">
              {this.state.forgotpwd==1?<>
                <span style={{marginLeft:"-10%"}}> Please enter your user id and we will send you a link.</span>
              </>:<>
              Please Enter Your User Id & Password
              </>}
              </span>
            
        </div>
      </div>
    );
  }
}
