import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Clientimage  from "../images/clientimage.png";
import Jobpipelinedata from "./Jobpipelinedata";
import PencilIcon  from "../images/pencilicon.png";
import DeleteIcon  from "../images/deleteicon.png";
import EyeIcon  from "../images/eyeicon.png";
import PlusincircleIcon  from "../images/plusincircle.png";
import MinusincircleIcon  from "../images/minusincircle.png";
import Dropdown from 'react-bootstrap/Dropdown';
import LoadingIcon  from "../images/loadingicon.gif";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

function Deleteclient(id){
  Swal.fire({
    title: 'Are you sure to delete this Jobposting?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addjobpostingdata: "deletejobposting",editjobid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}
const tableCustomStyles = {
 
expanderButton:{
  
  style:{
    '&:disabled': {
     display:"none"
    },
  }
}
}

function ViewJob(id){
  console.log(id)
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[2]
  var data2=splitURL[3]
  var data3=splitURL[4]
  var data5=splitURL[5]
  let encodedjobid=window.btoa(id)
  window.location.href=`${data}/viewjob/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
}
class Jobpostingdatatable extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      clientlogo:" ",
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      updatemsg:"",
      countrydata:[],
      citydata:[],
      industrydata:[],
      catageroydata:[],
      Countryname:"",
      Countryid:"",
      statedata:[],
      reportingtodata:[],
      BusinessUnit:"",
      editclientid:"",
      reportingtodata:[],
      languagesdata:[],
      degreedata:[],
      JobTitle:"",
      JobCode:"",
      ClientName:"",
      ClientId:"",
      CBR_Coin:"",
      CBR_Amount:"",
      PR_Coin:"",
      PR_MinAmount:"",
      PR_MaxAmount:"",
      CountryID:"",
      CountryName:"",
      StateID:"",
      StateName:"",
      CityID:[],
      CityName:[],
      ClientJobID:"",
      NoofPositions:"",
      ClosedPostions:"",
      JobCreatedDate:"",
      JobClosingDate:"",
      SPOCMobile:"",
      SPOCName:"",
      HiringManagerid:"",
      HiringManager:"",
      AssingnedID:"",
      AssingnedName:"",
      BusinessUnit:"",
      NoticePeriod:"",
      JobDescription:"",
      DegreeID:"",
      Degreename:"",
      ExperienceMin:"",
      ExperienceMax:"",
      PrimarySkills:"",
      Languages:"",
      JobStatus:"",
      jobstatusname:"",
      CreatedBy:"",
      userstausdata:[
        { value: '1', label: 'Active'},
       
     
       
      ],
      staffingdata:[
        { value: "1", label: "Permanent Staffing" },
        { value: "2", label: "Contract To Hire" },
      ],
      LanguagesName:"",
    editjobid:"",
 prioritydropdown:0,
 original_rows: [], 
 rows: [], 
 search: "",
 clientdata:[],
 jobcodeerror:"",
 jobtitleerror:"",
 nopositionerror:"",
 clientraterrror:"",
 payraterror:"",
 spocnameerror:"",
 spocmobileerror:"",
 noticeperioderror:"",
 minexperror:"",
 maxexperror:"",
 primaryskillserror:"",
 jderror:"",
 loadingicon:0,
 Noticeperiod : [
  { value: "1", label: "Immedidate" },
  { value: "2", label: "7 Days or less " },
  { value: "3", label: "15 Days or less" },
  { value: "4", label: "1 Month" },
  { value: "5", label: "2 Months" },
  { value: "6", label: "3 Months" },
  { value: "7", label: "Currently Serving" },

] ,
 hover:false,
          StaffingTypeid:"",
          StaffingTypename:"",
          pincode:"",
          Additionalskills:"",
          ClientJobID :"",
          dataerrormsg:""

    }
    const caseInsensitiveSort = (rowA, rowB) => {
      const a = rowA.ClientName.toUpperCase();
      const b = rowB.ClientName.toUpperCase();
  
      if (a > b) {
          return 1;
      }
  
      if (b > a) {
          return -1;
      }
  
      return 0;
  };
  
    this.columns = [
    
   
      {
       
        cell: (e) => (
         
            <>
            {e.prioritystatus==1?<>
            
              <div class="tooltipp" onClick={this.handlePriority}>
              <Dropdown>
      <Dropdown.Toggle className="shadow-none"  id="dropdown-basic" style={{background:"transparent",border:"transparent"}}>
      <i className="fa fa-certificate graycolorcircle" ></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <i class="fa fa-times" aria-hidden="true" style={{color:"gray",marginLeft:"5%"}}></i> <span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:14}} onClick={()=>this.Removepriority1(e.jobid)}>Remove Priority</span> <br/>
      <i class="fa fa-certificate" aria-hidden="true" style={{color:"red",cursor:"pointer",marginLeft:"5%"}}></i><span onClick={()=>this.Removepriority2(e.jobid)} style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} >Critical</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"rgb(1 255 21)",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority3(e.jobid)}>Low</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"blue",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority4(e.jobid)}>Medium</span> <br/>
      </Dropdown.Menu>
    </Dropdown>
                
                
  <span class="tooltiptext">&nbsp;&nbsp;
   Priority not defined
  </span><br/>
  
</div>

            </>:null}
            {e.prioritystatus==2?<>
              <div class="tooltipp" onClick={this.handlePriority}>
              <Dropdown>
      <Dropdown.Toggle className="shadow-none"  id="dropdown-basic" style={{background:"transparent",border:"transparent"}}>
      <i className="fa fa-certificate redcolorcircle" ></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <i class="fa fa-times" aria-hidden="true" style={{color:"gray",marginLeft:"5%"}}></i> <span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:14}} onClick={()=>this.Removepriority1(e.jobid)}>Remove Priority</span> <br/>
      <i class="fa fa-certificate" aria-hidden="true" style={{color:"red",cursor:"pointer",marginLeft:"5%"}}></i><span onClick={()=>this.Removepriority2(e.jobid)} style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}}>Critical</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"rgb(1 255 21)",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority3(e.jobid)}>Low</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"blue",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority4(e.jobid)}>Medium</span> <br/>
      </Dropdown.Menu>
    </Dropdown>
                
                
  <span class="tooltiptext">&nbsp;&nbsp;
  <span style={{fontSize:13}}> Critical </span><br/><span style={{color:'transparent'}}> Medium</span>
  </span><br/>
  
</div>
            </>:null}
            {e.prioritystatus==3?<>                 <div class="tooltipp" onClick={this.handlePriority}>
              <Dropdown>
      <Dropdown.Toggle className="shadow-none"  id="dropdown-basic" style={{background:"transparent",border:"transparent"}}>
      <i className="fa fa-certificate lightyellocolorcircle" ></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <i class="fa fa-times" aria-hidden="true" style={{color:"gray",marginLeft:"5%"}}></i> <span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:14}} onClick={()=>this.Removepriority1(e.jobid)}>Remove Priority</span> <br/>
      <i class="fa fa-certificate" aria-hidden="true" style={{color:"red",cursor:"pointer",marginLeft:"5%"}}></i><span onClick={()=>this.Removepriority2(e.jobid)} style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}}>Critical</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"rgb(1 255 21)",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority3(e.jobid)}>Low</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"blue",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority4(e.jobid)}>Medium</span> <br/>
      </Dropdown.Menu>
    </Dropdown>
                
                
  <span class="tooltiptext">&nbsp;&nbsp;
  <span style={{fontSize:13}}>Low</span> <br/>
  </span><br/>
  
</div></>:null}
            {e.prioritystatus==4?<>    <div class="tooltipp" onClick={this.handlePriority}>
              <Dropdown>
      <Dropdown.Toggle className="shadow-none"  id="dropdown-basic" style={{background:"transparent",border:"transparent"}}>
      <i className="fa fa-certificate bluecolorcircle" ></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <i class="fa fa-times" aria-hidden="true" style={{color:"gray",marginLeft:"5%"}}></i> <span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:14}} onClick={()=>this.Removepriority1(e.jobid)}>Remove Priority</span> <br/>
      <i class="fa fa-certificate" aria-hidden="true" style={{color:"red",cursor:"pointer",marginLeft:"5%"}}></i><span onClick={()=>this.Removepriority2(e.jobid)} style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}}>Critical</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"rgb(1 255 21)",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority3(e.jobid)}>Low</span> <br/>
            <i class="fa fa-certificate" aria-hidden="true" style={{color:"blue",cursor:"pointer",marginLeft:"5%"}}></i><span style={{cursor:"pointer",marginLeft:"3%",fontFamily:"Inter",fontWeight:"bold",fontSize:13}} onClick={()=>this.Removepriority4(e.jobid)}>Medium</span> <br/>
      </Dropdown.Menu>
    </Dropdown>
                
                
  <span class="tooltiptext">&nbsp;&nbsp;
 <span style={{fontSize:13}}>Medium</span> <br/>
  </span><br/>
  
</div></>:null}
            
            {e.pipelinecount==0?null:<> <span style={{fontSize:13,padding:"3% 12% 4% 8%",fontSize:12,margin:e.pipelinecount==0||e.pipelinecount==null?"-9%":"-3%",textAlign:"center",cursor:"pointer",borderTopLeftRadius:"9px",backgroundColor:"blueviolet",color:"white",fontFamily:"Inter",fontWeight:"bold",verticalAlign:"sub"}}> {e.pipelinecount}</span></>}
            {e.submissioncount==0?null:<> <span style={{fontSize:13,padding:"3% 12% 4% 10%",fontSize:12,margin:e.submissioncount==0||e.submissioncount==null?"-9%":"-4%",textAlign:"center",cursor:"pointer",borderTopRightRadius:"9px",backgroundColor:"rgb(66, 147, 254)",color:"white",fontFamily:"Inter",fontWeight:"bold",verticalAlign:"sub"}}> {e.submissioncount}</span></>}
         {'     '}
      
          </>
        ),
        
        
      },
        {
            name: "Job Code",
            selector: "JobCode",
            sortable: true
          },
          {
            name:"Job Title",
            selector: (row) =>  <span onClick={() =>ViewJob(row.jobid)} style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",fontSize:12}}>{row.JobTitle}</span>,
         
          sortable: true ,
          sortFunction: caseInsensitiveSort
          
        },
        
          {
            name: "Client",
            selector: "ClientName",
            sortable: true
          },
         
          {
            name: "No of Positions",
            selector: "NoofPositions",
            sortable: true,
            width:"10%"
          },
       
         
          {
            name: "Location",
            selector: "CreatedBy",
            sortable: true
          },
          {
            name: "Created By",
            selector: "CreatedBy",
            sortable: true
          },
          {
            name: "Job Status",
            selector: "jobstatus",
            sortable: true,
            width:"10%"
          },
         
          {
            name:"Action",
          cell: (e) => (
              <>
            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(e.jobid)} 
                          style={{margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={PencilIcon} style={{width:"18%",marginTop:"5%"}} /></a>{'     '}
            <span   onClick={() =>Deleteclient(e.jobid)}  style={{margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={DeleteIcon} style={{width:"18%",marginTop:"5%"}} onClick={() =>Deleteclient(e.jobid)} /></span>{'     '}
          <span   onClick={() =>ViewJob(e.jobid)}  style={{fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={EyeIcon} style={{width:"18%",marginTop:"5%"}} onClick={() =>ViewJob(e.jobid)} /></span> 
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
this.handleUpdate=this.handleUpdate.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.hanldelState=this.handleState.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleRequired=this.handleRequired.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleCategory=this.handleCategory.bind(this)
    this.handleDisable=this.handleDisable.bind(this)
    this.Removepriority1=this.Removepriority1.bind(this)
    this.handlePriority=this.handlePriority.bind(this)
    this.Removepriority2=this.Removepriority2.bind(this)
    this.Removepriority3=this.Removepriority3.bind(this)
    this.Removepriority4=this.Removepriority4.bind(this)
    this.handleDegree=this.handleDegree.bind(this)
    this.handleLanguage=this.handleLanguage.bind(this)
    this.handleNotice=this.handleNotice.bind(this)
    this.handleStaffing=this.handleStaffing.bind(this)
    this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
    this.handleCheckbox1=this.handleCheckbox1.bind(this)
  }
  handleCheckboxclose1(id,name){
    this.setState({CityID : this.state.CityID.filter(function(val) {return val!==id})})
    this.setState({CityName : this.state.CityName.filter(function(val) {return val!==name})},()=>{
      console.log(this.state.CityName)
    })
}
handleCheckbox1(id,name){
  this.setState({CityID:this.state.CityID.concat(id)},()=>{
    console.log(this.state.CityID)
   })
   this.setState({CityName:this.state.CityName.concat(name)},()=>{
    console.log(this.state.CityName)
   })

}
  handleStaffing(e){
    this.setState({StaffingTypeid:e.value,errormsg3:"",StaffingTypename:e.label})
      }
  handleMouseIn() {
    this.setState({ hover: true })
  }
  handleChangeJD = (e) => {
    //var regex = /[!@\$%\^\&*\)\(+=_`;:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleMouseOut() {
    this.setState({ hover: false })
  }
  handleNotice(e){
    this.setState({NoticePeriod:e.label})
  }
  handleLanguage(e){
    this.setState({Languages:e.value})
  }
  handleDegree(e){
    this.setState({Degreename:e.label,DegreeID:e.value})
  }
  handleChangeNopos = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value>500){
      this.setState({nopositionerror:"No of Open Positions between 500 only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        nopositionerror:"",
       
      });
    }
  
    
  };
  handleChangeBillrate = (e) => {
    //var regex = /[a-zA-Z!@#\$%\^\&*\)\(+=._-`;,:<>-]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      clientraterrror:""
    });

    
  };
  handleChangePincode = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
 
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
   
  
    
  };
  handleChangeSkills = (e) => {
    //var regex = /[!@\$%\^\&*\)\(+=._-`;:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleChangeJobTitle = (e) => {
   // var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
jobtitleerror:""
    });

    
  };
  handlePriority(){
    this.setState({prioritydropdown:1})
  }
  Removepriority1(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:1,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  Removepriority2(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:2,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  Removepriority3(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:3,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  Removepriority4(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:4,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  handleDisable(row){
    if(row.pipelinecount==0 && row.submissioncount==0){
     return true;
    
    }
    else{
     return false;
   
    }
   }
  handleCategory(e){
    this.setState({CategoryName:e.label,categoryid:e.value},()=>{
     
    })
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleUpdate(){
   
   if(this.state.JobTitle==""){
      this.setState({jobtitleerror:"Please Enter Job Title"})
     
    }
  
    else if(this.state.NoofPositions==""){
      this.setState({nopositionerror:"Please Enter No of Position"})
    }
  
    else if(this.state.PR_MinAmount==""){
      this.setState({payraterror:"Please Enter Pay Rate Minimum Amount"})
      
    }
   
    else if(this.state.NoticePeriod==""){
      this.setState({noticeperioderror:"Please Enter Notice Period"})
    }
   
    else if(this.state.ExperienceMin==""){
   
      this.setState({minexperror:"Please Enter Minimum Experience"})
    }
    else if(this.state.ExperienceMax==""){
      this.setState({maxexperror:"Please Enter Maximum Experience"})
   
    }
    else if(this.state.PrimarySkills==""){
      this.setState({primaryskillserror:"Please Enter Mandatory Skills"})
    }

    else if(this.state.JobDescription==""){

      this.setState({jderror:"Please Enter Job Description"})
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes,
        addjobpostingdata:"editjobpostinginfo",
        editjobid:this.state.editjobid,
        JobCode:this.state.JobCode,
        JobTitle:this.state.JobTitle,
        ClientId:this.state.ClientId,
        AssingnedID:this.state.AssingnedID,
        AssingnedName:this.state.AssingnedName,
        ClientName:this.state.ClientName,
        CBR_Coin:this.state.CBR_Coin,
        CBR_Amount:this.state.CBR_Amount,
        PR_Coin:this.state.PR_Coin,
        PR_MinAmount:this.state.PR_MinAmount,
        PR_MaxAmount:this.state.PR_MaxAmount,
        CountryID:this.state.CountryID,
        CountryName:this.state.CountryName,
        StateID:this.state.StateID,
        StateName:this.state.StateName,
        CityID:this.state.CityID.toString(),
        CityName:this.state.CityName.toString(),
        
        ClientJobID:this.state.ClientJobID,
        NoofPositions:this.state.NoofPositions,
        ClosedPostions:this.state.NoofPositions,
        JobCreatedDate:this.state.JobCreatedDate,
        JobClosingDate:this.state.JobClosingDate,
        SPOCName:this.state.SPOCName,
        SPOCMobile:this.state.SPOCMobile,
        HiringManagerid:this.state.HiringManagerid,
        HiringManager:this.state.HiringManager,
        NoticePeriod:this.state.NoticePeriod,
        BusinessUnit:this.state.BusinessUnit,
        JobDescription:this.state.JobDescription,
        DegreeID:this.state.DegreeID,
        Degreename:this.state.Degreename,
        ExperienceMin:this.state.ExperienceMin,
        ExperienceMax:this.state.ExperienceMax,
        PrimarySkills:this.state.PrimarySkills,
        Languages:this.state.Languages,
        JobStatus:this.state.JobStatus,
        StaffingTypename:this.state.StaffingTypename,
        StaffingTypeid:this.state.StaffingTypeid,
        pincode:this.state.pincode,
        Additionalskills:this.state.Additionalskills
        
  
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getJobposting", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                this.setState({updatemsg:response.message})
              
             
              console.log(response.message)
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
            

                    
                  });
    }
   
      }
  handleUserstatus(e){
    this.setState({cstatus:e.value}) 
  }
  handleIndustry(e){
    this.setState({Industryname:e.label,Industryid:e.value})
  }
  handleRequired(e){
    this.setState({ReqDocument:e.value})
  }
  handleReport(e){
    this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
  }
  handleCity(e){
    this.setState({Cityname:e.label,Cityid:e.value})
  }
  handleState(e){
    this.setState({Statename:e.label,Stateid:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      })
  }
  handleCountry(e){
    this.setState({Countryname:e.label,Countryid:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }
  handleRowClicked(row){
    //console.log(row.id)
    //console.log(row)
    if(row.pipelinecount!=null && row.submissioncount!=null){
      console.log(row)
      localStorage.setItem("jobPipCont",row.id)
      localStorage.setItem("jobSubCont",row.id)
     }
    else if(row.pipelinecount>=1){
      localStorage.setItem("jobPipCont",row.id)
      localStorage.removeItem("jobSubCont")
    }
   else if(row.submissioncount>=1){
    localStorage.setItem("jobSubCont",row.id)
    localStorage.removeItem("jobPipCont")
   }
  
   else{
    localStorage.removeItem("jobPipCont")
    localStorage.removeItem("jobSubCont")
   }
   
  }
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value.length!=10){
      this.setState({mobileerror:"Contact number should be digits only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
    }
  
    
  };
  handleCancel(){
    this.setState({displayform:0})
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editjobid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addjobpostingdata:"getsinglejobinfo",editjobid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              JobTitle:e.JobTitle,
              JobCode:e.JobCode,
              ClientId:e.ClientId,
              ClientName:e.ClientName,
              CBR_Coin:e.CBR_Coin,
              CBR_Amount:e.CBR_Amount,
              PR_Coin:e.PR_Coin,
              PR_MinAmount:e.PR_MinAmount,
              PR_MaxAmount:e.PR_MaxAmount,
              CountryID:e.CountryID,
              CountryName:e.CountryName,
              StateID:e.StateID,
              StateName:e.StateName,
              CityID:this.state.CityID.concat(e.CityID),
              CityName:this.state.CityName.concat(e.CityName),
              citydata:[{id:e.CityID,name:e.CityName}],
              ClientJobID:e.ClientJobID,
              NoofPositions:e.NoofPositions,
              ClosedPostions:e.ClosedPostions,
              JobCreatedDate:e.JobCreatedDate,
              JobClosingDate:e.JobClosingDate,
              SPOCMobile:e.SPOCMobile,
              SPOCName:e.SPOCName,
              HiringManagerid:e.HiringManagerid,
              HiringManager:e.HiringManager,
              AssingnedID:e.AssingnedID,
              AssingnedName:e.AssingnedName,
              BusinessUnit:e.BusinessUnit,
              NoticePeriod:e.NoticePeriod,
              JobDescription:e.JobDescription,
              DegreeID:e.DegreeID,
              Degreename:e.Degreename,
              ExperienceMin:e.ExperienceMin,
              ExperienceMax:e.ExperienceMax,
              PrimarySkills:e.PrimarySkills,
              Languages:e.Languages,
              JobStatus:e.JobStatus,
              jobstatusname:e.jobstatusname,
              CreatedBy:e.CreatedBy,
              LanguagesName:e.LanguagesName,
              StaffingTypeid:e.StaffingTypeid,
              StaffingTypename:e.StaffingTypename,
              pincode:e.pincode,
              Additionalskills:e.Additionalskills,
              ClientJobID :e.ClientJobID
    



            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
    
         /* Client API Call Start */
         const cdata={ dropdownlist_input: "getclientDropinfo"
        }
         console.log(cdata)
         fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
             method: "POST",
     
             body: JSON.stringify(cdata), // data can be `string` or {object}!
     
             headers: { "Content-Type": "application/json" },
           })
             .then((res) => res.json())
     
             .catch((error) => console.error("Error:", error))
     
             .then((response) => {
                 
              const data = response.result
               //console.log(json)
         
             const options = data.map(d => ({
                 "value" : d.clientid,
                 "label" : d.clientname
     
               })) 
        
             this.setState({clientdata:options})
                   
                 });
         /* Client API Call  End */
     /* Language API Call Start */
     const ledata={ dropdownlist_input: "getlanguagesinfoinfo"}
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
 
         body: JSON.stringify(ledata), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.id,
             "label" : d.name
 
           })) 
    
         this.setState({languagesdata:options})
               
             });
            
     /*Language  API Call End */

      /* Degree API Call Start */
      const degdata={ dropdownlist_input: "getdegreesinfo"}
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(degdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({degreedata:options})
                
              });
             
      /*Language  API Call End */
/* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
/*Category API Call Start */
const data={ dropdownlist_input: "getclientcategoryinfo"}
console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(data), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.categoryid,
       "label" : d.categoryname

     })) 

   this.setState({catageroydata:options})
         
       });
/* Category API Call End */
 /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */

  /* Country API Call Start */
  const tdata={ dropdownlist_input: "getcountryinfo"
 }
  console.log(tdata)
  fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          console.log(response)
       const data = response.result
        //console.log(json)
  
      const options = data.map(d => ({
          "value" : d.countryid,
          "label" : d.conuntryname

        })) 
 
      this.setState({countrydata:options})
            
          });
  /* Country API Call  End */

   /* Reporting To API Call Start */
   var username=JSON.stringify(localStorage.getItem("UserId"))
   const withoutQuotes1 = username.replaceAll('"', '');
   const cdata1={ userid: withoutQuotes1,
     adduserdata:"getteamsinfo"
   }
  
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(cdata1), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({teamdata:options})
              
            });
    /* Reporting To  API Call End */
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const repdata={userid:withoutQuotes,addjobpostingdata:"getjobpostinginfo",statuscode:1}
    console.log(repdata)
    fetch("https://www.mrecruit.in:5200/api/getJobposting", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          if(response.message=="No Data Found."){
            this.setState({dataerrormsg:response.message,loadingicon:2})
           }
             //console.log(response)
    
            else{
              let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data,loadingicon:1 });
              for (var i = 0; i < this.state.original_rows.length; i++) {
                delete this.state.original_rows[i].ClientJobID
                delete this.state.original_rows[i].StaffingTypeid
                delete this.state.original_rows[i].StaffingTypename
                delete this.state.original_rows[i].ClosedPostions
                delete this.state.original_rows[i].budget
                delete this.state.original_rows[i].PR_Coin
                delete this.state.original_rows[i].PR_MinAmount
                delete this.state.original_rows[i].PR_MaxAmount
             //   delete this.state.original_rows[i].CreatedBy
                delete this.state.original_rows[i].created_on
                delete this.state.original_rows[i].Modifieddate
                delete this.state.original_rows[i].ModifiedBy
            }
            }
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    //var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>-]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      jderror:"",
      jobcodeerror:"",
      payraterror:"",
      minexperror:"",
      maxexperror:"",
      primaryskillserror:""
    });
    
  };
  render() {
   
    const tooltipStyle = {
      display: this.state.hover ? 'inline' : 'none',
      fontSize:"10px",
      marginLeft:"-1%",
      backgroundColor:"black",
      color:"white",
      fontFamily:"Poppins Light",
      fontWeight:"bold",
      padding:"0.5%"
    }
    const arrowleft = {
      width: "0px",
      height: "0px",
      borderTop: "7px solid transparent",
      borderBottom: "6px solid transparent",
      borderRight: "15px solid black",
      display: this.state.hover ? 'inline-block' : 'none',
  }
    return (
      <>
      
      {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content  bigmodalcontent" >
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Job Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            {/**Add user for starts */}
 <div>


 <div className="row">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Code</span>&nbsp;<span onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"rgb(66, 147, 254)",fontSize:16,cursor:"pointer"}}><i class="fa fa-question-circle" aria-hidden="true"></i></span>
        <div style={arrowleft}></div>
        <span style={tooltipStyle}>Job Code will generate automatically.</span>
                <input className="form-control adduser mt-1" placeholder="Job Code" name="JobCode" disabled value={this.state.JobCode} onChange={this.handleChange}/>
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.jobcodeerror}</span>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Title</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input className="form-control adduser mt-1" placeholder="Job Title" 
                value={this.state.JobTitle}
                name="JobTitle" onChange={this.handleChangeJobTitle}/>
                 <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.jobtitleerror}</span>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Client</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
                placeholder={this.state.ClientName}
       onChange={(e)=>this.handleClient(e)}
       options={this.state.clientdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>No of Open Positions</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input type="number" className="form-control adduser
                 mt-1" maxLength="3" value={this.state.NoofPositions} placeholder="No of Open Positions" name="NoofPositions" onChange={this.handleChangeNopos}/>
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.nopositionerror}</span>
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Client Bill Rate Monthly</span> <br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" value={this.state.CBR_Amount} placeholder="Client Bill Rate Monthly" 
                  name="CBR_Amount" onChange={this.handleChangeBillrate}/>
                   <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.clientraterrror}</span>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Maintec Offered CTC</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" value={this.state.PR_MinAmount} placeholder="Maintec Offered CTC" name="PR_MinAmount" onChange={this.handleChange}/>
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.payraterror}</span>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Country</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
                placeholder={this.state.CountryName}
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>State</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
                placeholder={this.state.StateName}
       onChange={(e)=>this.handleState(e)}
       options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
               
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Location</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <div className="mt-1" style={{height:"2.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.citydata.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.CityID.includes(e.id)==true?<>
          <i className="fa fa-check-square"  onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.CityID.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Pin Code</span><br/>
                <input className="form-control adduser mt-1" value={this.state.pincode} name="pincode" onChange={this.handleChangePincode} placeholder="Pin Code" maxLength="6"/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>SPOC Name</span><br/>
               
                  
                  <input className="form-control adduser mt-1" value={this.state.SPOCName} name="SPOCName" onChange={this.handleChange} placeholder="SPOC Name"/>
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.spocnameerror}</span>
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>SPOC Mobile</span><br/>
                <input value={this.state.SPOCMobile} className="form-control adduser mt-1" name="SPOCMobile" onChange={this.handleChangemobile} placeholder="SPOC Mobile"/>
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.spocmobileerror}</span>
                </div>
                
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Additional Skills</span><br/>
               
                <input className="form-control adduser mt-1" value={this.state.Additionalskills} placeholder="Additional Skills" onChange={this.handleChangeSkills} name="Additionalskills" />
                </div>
              
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Creating Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
               <input type="date" name="JobCreatedDate" onChange={this.handleChangeJobcreated}  value={this.state.JobCreatedDate} className="form-control adduser mt-1" min={new Date().toISOString().split('T')[0]} />
              
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Closing Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
                  <input type="date" value={this.state.JobClosingDate} className="form-control adduser mt-1" placeholder="Job Closing Date" name="JobClosingDate" onChange={this.handleChangeJobcreated}  min={new Date().toISOString().split('T')[0]}/>
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Notice Period</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
    onChange={(e)=>this.handleNotice(e)}
    placeholder={this.state.NoticePeriod}
    options={this.state.Noticeperiod}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.noticeperioderror}</span>
                </div>
                </div>
 
 
                <div className="row mt-2">
          
             
           
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Status</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
          <Select 
          placeholder={this.state.jobstatusname}
onChange={(e)=>this.handleUserstatus(e)}
options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                        styles={style}/>
          </div>
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Relevant Experience Min</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <input value={this.state.ExperienceMin} 
              name="ExperienceMin" onChange={this.handleChange} 
              className="form-control adduser mt-1" placeholder="Relevant Experience Min"/>
               <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.minexperror}</span>
          </div>
          <div className="col-xl-3">
            
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Relevant Experience Max</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <input name="ExperienceMax" onChange={this.handleChange} value={this.state.ExperienceMax}  className="form-control adduser mt-1" placeholder="Relevant Experience Max"/>
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.maxexperror}</span>
         
      
          </div>
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Mandatory Skills</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
         
            
         <input value={this.state.PrimarySkills} 
         name="PrimarySkills" onChange={this.handleChange}
         className="form-control adduser mt-1" placeholder="Mandatory Skills"/>
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.primaryskillserror}</span>
          </div>
          </div>

          <div className="row mt-2">
          <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Mode of Staffing</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleStaffing(e)}
       placeholder={this.state.StaffingTypename}
       options={this.state.staffingdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
              <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Account Manager</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select
                placeholder={this.state.HiringManager} 
       onChange={(e)=>this.handleReport(e)}
       options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>

              </div>
             
              <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Degree Name</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <Select 
              placeholder={this.state.Degreename}
       onChange={(e)=>this.handleDegree(e)}
       options={this.state.degreedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
             
              </div>
              <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Languages</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <Select 
       onChange={(e)=>this.handleLanguage(e)}
       placeholder={this.state.LanguagesName}
       options={this.state.languagesdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
              </div>
              <div className="row mt-2">
            
             <div className="col-xl-12">
             <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Description</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
             <textarea value={this.state.JobDescription} className="form-control mt-2 adduser" rows="3" name="JobDescription" onChange={this.handleChangeJD}>

             </textarea>
             <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.jderror}</span>
             </div>
             
              </div>

  
              <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Poppins Light",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
            {this.state.loadingicon==0?<>
            <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
           </>:<></>}
            {this.state.loadingicon==1?<>
              <DataTable
                
                //defaultSortAsc={false}
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                expandableRows
                expandableRowsComponent={<Jobpipelinedata/>}
                onRowClicked={this.handleRowClicked}
                onRowExpandToggled={( row,e) => {   
                  if(e.pipelinecount>=1 && e.submissioncount>=1){
                    console.log(e)
                    localStorage.setItem("jobPipCont",e.id)
                    localStorage.setItem("jobSubCont",e.id)
                   }
                  else if(e.pipelinecount>=1){
                    localStorage.setItem("jobPipCont",e.id)
                    localStorage.removeItem("jobSubCont")
                  }
                 else if(e.submissioncount>=1){
                  localStorage.setItem("jobSubCont",e.id)
                  localStorage.removeItem("jobPipCont")
                 }
                
                 else{
                  localStorage.removeItem("jobPipCont")
                  localStorage.removeItem("jobSubCont")
                 } }}
                expandOnRowClicked
                responsive
                customStyles={tableCustomStyles}
                expandableRowDisabled={this.handleDisable }
                expandableIcon={{
                  collapsed: <img src={PlusincircleIcon} style={{width:"60%"}} />,
                  expanded: <img src={MinusincircleIcon} style={{width:"60%"}} />
                }}
              />
            </>:null}
            {this.state.loadingicon==2?<>
            <div className="text-center" style={{paddingBottom:"3%"}}>
            <span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.dataerrormsg}</span>
            </div>
            
            </>:null}
            
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Jobpostingdatatable;
