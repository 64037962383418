import React, { useState } from "react";
import Clientimage  from "../images/clientimage.png";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import PencilIcon  from "../images/pencilicon.png";
import DeleteIcon  from "../images/deleteicon.png";
import UploadIcon  from "../images/uploadicon.png";

import LoadingIcon  from "../images/loadingicon.gif";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

function Deleteclient(id){
  Swal.fire({
    title: 'Are you sure to delete this Client?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addclientdata: "deleteclient",editclientid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

function ViewClient(id){
  //console.log(id)
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[2]
  var data2=splitURL[3]
  var data3=splitURL[4]
  var data5=splitURL[5]
  let encodedjobid=window.btoa(id)
  window.location.href=`${data}/viewclient/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
}
const tableCustomStyles = {
  table: {
    style: {
    },
},
tableWrapper: {
    style: {
        display: "table",
        width:"140%",
       
    },
},
responsiveWrapper: {
    style: {},
},

}
class Clientactiveuser extends React.Component {
  constructor() {
 
    super();
    this.state={
      showModal: false,
      on:0,
     clientlogo:" ",
   
     clientname:[],    
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      firstname:"",
      lastname:"",
      username:"",
      email:"",
      workinglocation:"",
      password:"",
      userrole:"",
      userrolename:"",
      userstatus:"",
      mobile:"",
      reportingto:"",
      reporting_toname:"",
      BusinessUnit:"",
      adminprivileges:"No",
      dob:"",
      doj:"",
      teamids:"",
      teamname:"",
      employeeID:"",
      AdminPrivileges:"",
      edituserid:"",
      userstatusname:"",
      dobchange:"",
      dojchange:"",
      updatemsg:"",
      ReqDocument:"",
      countrydata:[],
      citydata:[],
      industrydata:[],
      catageroydata:[],
      Countryname:"",
      Countryid:"",
      statedata:[],
      ClientLeadname:"",
      ClientLeadids:"",
      
      cname:"",
      POC1_conactname:"",
      POC1_email:"",
      POC1_contactno:"",
      POC2_conactname:"",
      POC2_email:"",
      POC2_contactno:"",
      POC3_conactname:"",
      POC3_email:"",
      POC3_contactno:"",
      cphone:"",
      Address:"",
      websiteurl:"",
      Stateid:"",
      Cityid:"",
      Countryname:"",
      Statename:"",
      Cityname:"",
      Postalcode:"",
      categoryid:"",
      CategoryName:"",
      cstatus:"",
      BusinessUnit:"",
      editclientid:"",
      original_rows: [], 
      rows: [], 
      search: "",
      documentdata:[
      ],
      userstausdata:[
        { value: '1', label: 'Active'},
        { value: '2', label: 'InActive'},
        { value: '3', label: 'Pending'},
       
      ],
      AboutCompany:"",
      addresserror:"",
      contactnumbererror:"",
      websiteerror:"",
      aboutcompanyerror:"",
      poc1nameerror:"",
      poc1contactnoerror:"",
      poc1emailiderror:"",
      loadingicon:0,
      documentids:[],
      Industryname:[],
      Industryid:[],
    }
   
    this.columns = [
     
      {
        name:"Client Name",
  selector: row =><span onClick={() =>ViewClient(row.id)} style={{fontFamily: "Poppins Light",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:12}}>{row.clientname}</span>,
     
    
      sortable: true
    },
          {
            name: "Business Unit",
            selector: "BusinessUnit",
            sortable: true,
           
          },
          {
            name: "Mobile No.",
            selector: "cphone",
            sortable: true
          },
          {
            name: "Website",
            selector: "websiteurl",
            sortable: true
          },
        
         
          {
            name: "Client Lead",
            selector: "ClientLeadname",
            sortable: true
          },
          {
            name: "Created By",
            selector: "createdbyname",
            sortable: true
          },
          {
            name: "Created Date",
            selector: "created_on",
            sortable: true
          },
          {
            name:"Action",
          cell: (e) => (
              <>
               <span   onClick={() =>this.InactiveUser(e.id)}  style={{width:"26%",fontSize:13,fontSize:12,margin:"3%",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={UploadIcon} style={{width:"18%",marginTop:"5%"}} /></span>
            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(e.id)} 
                          style={{width:"26%",fontSize:13,fontSize:12,margin:"3%",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={PencilIcon} style={{width:"18%",marginTop:"5%"}} /></a>{'     '}
            <span   onClick={() =>Deleteclient(e.clientid)}  style={{width:"26%",fontSize:13,fontSize:12,margin:"3%",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={DeleteIcon} style={{width:"18%",marginTop:"5%"}} onClick={() =>Deleteclient(e.clientid)} /></span>{'     '}
          
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
         
    ];

   
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.hanldelState=this.handleState.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleRequired=this.handleRequired.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleCategory=this.handleCategory.bind(this)
    this.InactiveUser=this.InactiveUser.bind(this)
    this.handleCheckbox=this.handleCheckbox.bind(this)
    this.handleCheckboxclose=this.handleCheckboxclose.bind(this)
    this.handleCheckbox1=this.handleCheckbox1.bind(this)
       this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
  }
  handleCheckboxclose1(id,name){
     
    this.setState({Industryid : this.state.Industryid.filter(function(val) {return val!==id})})
    this.setState({Industryname : this.state.Industryname.filter(function(val) {return val!==name})},()=>{
      console.log(this.state.Industryname)
    })
  
  
}
handleCheckbox1(id,name){

    this.setState({Industryid:this.state.Industryid.concat(id)})
    this.setState({Industryname:this.state.Industryname.concat(name)},()=>{
      console.log(this.state.Industryname)
    })

}
  handleCheckboxclose(id){
    if(id==1){
      this.setState({documentids : []})
    }
    else if(this.state.documentids.includes(1)){
      this.setState({documentids : this.state.documentids.filter(function(val) {return val!==1})},()=>{
        this.setState({documentids : this.state.documentids.filter(function(val) {return val!==id})},()=>{
          //console.log(this.state.documentids)
        })
      })
      
    }
    else{
      this.setState({documentids : this.state.documentids.filter(function(val) {return val!==id})},()=>{
        //console.log(this.state.documentids)
      })
     
    }
    
  }
  handleCheckbox(id){
    if(id==1){
     const ids= this.state.documentdata.map(e=>{
      return e.id       
    })
    //console.log(ids)
    this.setState({documentids:this.state.documentids.concat(ids)},()=>{
      //console.log(this.state.documentids)
     })
    }
    else{
      this.setState({documentids:this.state.documentids.concat(id)},()=>{
       // console.log(this.state.documentids)
       })
    }

  }
 InactiveUser(id){
    let data=window.location.pathname.slice(0,0);
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    console.log(splitURL)
    var data1=splitURL[3]
    var data2=splitURL[4]
    var data3=splitURL[5]

    window.location.href=`${data}/clientsubmissionformat/${data1}/${data2}/${data3}/${id}` 
    }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleChangeaddress = (e) => {
    //var regex = /[!@#\$%\^\&*\)\(+=._-``""<>?|{}/-]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      addresserror:""

    });
    
  };
  handleChangePostal = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          clientlogo: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  
  handleCategory(e){
    this.setState({CategoryName:e.label,categoryid:e.value},()=>{
     
    })
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleChangethree = (e) => {
    //var regex = /[0-9!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      poc1nameerror:""

    });
    
  };
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");

   
  
      this.setState({
        [e.target.name]: e.target.value,
        contactnumbererror:"",
        poc1contactnoerror:""
      });
    
  
    
  };
  handleUpdate(){
    if(this.state.Address==""){
      this.setState({addresserror:"Please Enter Address"})
    }
    else if(this.state.cphone==""){
      this.setState({contactnumbererror:"Please Enter Contact Number"})
    }
    else if(this.state.cphone.length!=10){
      this.setState({contactnumbererror:"Please Enter 10 digits Contact Number"})
    }
    
    else if(this.state.AboutCompany==""){
      this.setState({aboutcompanyerror:"Please Enter About Company"})
    }
    else if(this.state.POC1_conactname==""){
      this.setState({poc1nameerror:"Please Enter POC1 Name"})
    }
    else if(this.state.POC1_contactno==""){
      this.setState({poc1contactnoerror:"Please Enter POC1 Contact No"})
    }
    else if(this.state.POC1_contactno.length!=10){
      this.setState({poc1contactnoerror:"Please Enter 10 digits POC1 Contact No"})
    }
    else if(this.state.POC1_email==""){
      this.setState({poc1emailiderror:"Please Enter POC1 Email"})
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={
        userid:withoutQuotes,
        addclientdata:"editclientinfo",
        editclientid:this.state.editclientid,
        clientlogo:this.state.clientlogo,
        cname:this.state.cname,
        BusinessUnit:this.state.BusinessUnit,
        POC1_conactname:this.state.POC1_conactname,
        POC1_email:this.state.POC1_email,
        POC1_contactno:this.state.POC1_contactno,
        POC2_conactname:this.state.POC2_conactname,
        POC2_email:this.state.POC2_email,
        POC2_contactno:this.state.POC1_contactno,
        POC3_conactname:this.state.POC3_conactname,
        POC3_contactno:this.state.POC3_contactno,
        POC3_email:this.state.POC3_email,
        cphone:this.state.cphone,
        Address:this.state.Address,
        BusinessUnit:this.state.BusinessUnit,
        websiteurl:this.state.websiteurl,
        Countryid:this.state.Countryid,
        Stateid:this.state.Stateid,
        Cityid:this.state.Cityid,
        Countryname:this.state.Countryname,
        Statename:this.state.Statename,
        Cityname:this.state.Cityname,
        Postalcode:this.state.Postalcode,
        Industryid:this.state.Industryid.toString(),
        Industryname:this.state.Industryname.toString(),
        categoryid:this.state.categoryid,
        CategoryName:this.state.CategoryName,
        AboutCompany:this.state.AboutCompany,
        cstatus:this.state.cstatus,
        ClientLeadids:this.state.ClientLeadids,
        ClientLeadname:this.state.ClientLeadname,
        ReqDocument:this.state.documentids.toString()
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
               this.setState({updatemsg:response.message})
               setTimeout(
                function() {
                 
                  window.location.reload()
                }
                .bind(this),
                2500
            );
                 
                 console.log(response)
        
                    
                  });
    }
   
      }
  handleUserstatus(e){
    this.setState({cstatus:e.value}) 
  }
  handleIndustry(e){
    this.setState({Industryname:e.label,Industryid:e.value})
  }
  handleRequired(e){
    this.setState({ReqDocument:e.value})
  }
  handleReport(e){
    this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
  }
  handleCity(e){
    this.setState({Cityname:e.label,Cityid:e.value})
  }
  handleState(e){
    this.setState({Statename:e.label,Stateid:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      })
  }
  handleCountry(e){
    this.setState({Countryname:e.label,Countryid:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }

  handleCancel(){
    this.setState({displayform:0})
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editclientid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addclientdata:"getsingleclientinfo",editclientid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              clientname:e.clientname,
              BusinessUnit:e.BusinessUnit,
              Companyphone:e.Companyphone,
              POC1_conactname:e.POC1_conactname,
              POC1_email:e.POC1_email,
              POC1_contactno:e.POC1_contactno,
              POC2_conactname:e.POC2_conactname,
              POC2_email:e.POC2_email,
              POC2_contactno:e.POC2_contactno,
              POC3_conactname:e.POC3_conactname,
              POC3_email:e.POC3_email,
              POC3_contactno:e.POC3_contactno,
              Address:e.Address,
              websiteurl:e.websiteurl,
              Countryid:e.Countryid,
              Stateid:e.Stateid,
              Cityid:e.Cityid,
              Countryname:e.Countryname,
              Statename:e.Statename,
              Cityname:e.Cityname,
              Postalcode:e.Postalcode,
              Industryid:this.state.Industryid.concat(e.Industryid),
              Industryname:this.state.Industryname.concat(e.Industryname),
              categoryid:e.categoryid,
              CategoryName:e.CategoryName,
              AboutCompany:e.AboutCompany,
              cstatus:e.cstatus,
              ClientLeadids:e.ClientLeadids,
              ClientLeadname:e.ClientLeadname,
              ReqDocument:e.ReqDocument,
              clientstatus:e.clientstatus,
              clientlogo:e.clientlogo,
              cphone:e.cphone,
            


            },()=>{

              const data1={ dropdownlist_input: "getstateinfo",countryid:e.Countryid}
      
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data1), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
              const data={ dropdownlist_input: "getcityinfo",stateid:e.Stateid}
      
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      
            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
   
/* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
/*Category API Call Start */
const data={ dropdownlist_input: "getclientcategoryinfo"}
console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(data), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.categoryid,
       "label" : d.categoryname

     })) 

   this.setState({catageroydata:options})
         
       });
/* Category API Call End */
 /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
    /** const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) */ 

     this.setState({industrydata:data})
           
         });
 /* Industry  API Call End */

  /* Country API Call Start */
  const tdata={ dropdownlist_input: "getcountryinfo"
 }
  console.log(tdata)
  fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          console.log(response)
       const data = response.result
        //console.log(json)
  
      const options = data.map(d => ({
          "value" : d.countryid,
          "label" : d.conuntryname

        })) 
 
      this.setState({countrydata:options})
            
          });
  /* Country API Call  End */

   /* Reporting To API Call Start */
   var username=JSON.stringify(localStorage.getItem("UserId"))
   const withoutQuotes1 = username.replaceAll('"', '');
   const cdata={ userid: withoutQuotes1,
     adduserdata:"getteamsinfo"
   }
    console.log(tdata)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(cdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({teamdata:options})
              
            });
    /* Reporting To  API Call End */
       /* Required Document  API Call Start */
   const reqdata={ dropdownlist_input: "getdocumenttypeinfo",userid:withoutQuotes1}
       
   fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
       method: "POST",

       body: JSON.stringify(reqdata), // data can be `string` or {object}!

       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())

       .catch((error) => console.error("Error:", error))

       .then((response) => {
           
        const data = response.result
         //console.log(json)
   
    
       this.setState({documentdata:data},()=>{
        //console.log(this.state.documentdata)
       })
             
           });
          
   /* Required Document  API Call End */ 
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const repdata={userid:withoutQuotes,addclientdata:"getclientinfo",statuscode:1}
    console.log(repdata)
    fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
           //console.log(data)
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data,loadingicon:1 });
              
              for (var i = 0; i < this.state.original_rows.length; i++) {
                delete this.state.original_rows[i].AboutCompany
                delete this.state.original_rows[i].Industryname
                delete this.state.original_rows[i].CategoryName
                delete this.state.original_rows[i].categoryid
            }
            //console.log(this.state.original_rows)
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
   
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }


  do_search() {
    
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()),
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      poc1emailiderror:"",
      websiteerror:"",
      aboutcompanyerror:""

    });
    
  };
  showTable = () => {
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    const repdata={userid:withoutQuotes,addclientdata:"getclientinfo",statuscode:1}
    //console.log(repdata)
    fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
         
             this.setState({rows:data}) 
            });
            try {
              return this.state.rows.map((item, index) => {
                return (
                  <tr>
                  <td className="text-xs font-weight-bold">{index +1}</td>
                 <td className="text-xs font-weight-bold">{item.clientname}</td>
               
      <td></td>
      </tr>
                    );
              });
            } catch (e) {
              alert(e.message);
            }
          
           
  };
  render() {
   
    let data=this.props.user_id;
console.log(data)
    return (
      <>
      {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" >
          <div class="ft-modal-content  bigmodalcontent" style={{top:"0%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Client Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            
 <div>
 <div className="row">
    <div className="col-xl-1">
    
{this.state.clientlogo==" "?<>
<img src={Clientimage} 
 
 style={{width:"120%"}}  />
</>:<>  <img src={this.state.clientlogo} 
 
 style={{width:"120%",border:"2px solid #4293fe"}}  /></>}


  

   
    </div>
    <div className="col-xl-2">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>

  <div className="row mt-2">
    <div className="col-xl-3">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Client Name</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
    <div className="col-xl-3">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Address</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
    <div className="col-xl-3">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Contact Number</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Category</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
   
    </div>
  </div>



  <div className="row">
    <div className="col-xl-3">
     <input className="form-control adduser mt-2"
     value={this.state.clientname}
      placeholder="Client Name" disabled name="cname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
     <input name="Address"
      value={this.state.Address}
      onChange={this.handleChangeaddress} className="form-control adduser mt-2" placeholder="Address"/>
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.addresserror}</span>
    </div>
    <div className="col-xl-3">
     <input 
     name="cphone"
     value={this.state.cphone}
     onChange={this.handleChangemobile}
     maxLength="10"
     className="form-control adduser mt-2"  placeholder="Contact Number"/>
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.contactnumbererror}</span>
    </div>
    <div className="col-xl-3">
    <Select 
    onChange={(e)=>this.handleCategory(e)}
    placeholder={this.state.CategoryName}
    options={this.state.catageroydata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
   
        </div>
  </div>

  <div className="row mt-4">
  <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Country</span> <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    placeholder={this.state.Countryname}
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>State</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    placeholder={this.state.Statename}
    onChange={(e)=>this.handleState(e)}
    options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
   
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>City</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    onChange={(e)=>this.handleCity(e)}
    placeholder={this.state.Cityname}
    options={this.state.citydata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Postal Code</span>
     <input type="email" 
     value={this.state.Postalcode}
     maxLength="10"
     name="Postalcode" onChange={this.handleChangePostal}
     className="form-control adduser mt-2" />
    </div>
  </div>

 
  <div className="row mt-4">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily: "Poppins Bold"}}>POC-1</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Name</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input value={this.state.POC1_conactname} name="POC1_conactname" onChange={this.handleChangethree} placeholder="Name" 
      className="form-control adduser mt-2" />
       <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.poc1nameerror}</span>
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Contact Number</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="text" 
     value={this.state.POC1_contactno}
     name="POC1_contactno" onChange={this.handleChangemobile} 
     className="form-control adduser mt-2" />
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.poc1contactnoerror}</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Email</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="email" 
     value={this.state.POC1_email}
      name="POC1_email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
     <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.poc1emailiderror}</span>
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily: "Poppins Bold"}}>POC-2</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Name</span>
     <input 
      value={this.state.POC2_conactname}
     name="POC2_conactname" onChange={this.handleChangethree}
      placeholder="Name"  className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Contact Number</span>
     <input type="text" 
     value={this.state.POC2_contactno}
     name="POC2_contactno" onChange={this.handleChangemobile}
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Email</span>
     <input type="email" 
     value={this.state.POC2_email}
     name="POC2_email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <h4 style={{fontWeight:"bold",fontFamily: "Poppins Bold"}}>POC-3</h4>
    
    </div>
   
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Name</span>
     <input   value={this.state.POC3_conactname}    name="POC3_conactname" 
     onChange={this.handleChangethree} placeholder="Name"  className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Contact Number</span>
     <input type="text" 
    name="POC3_contactno" 
    value={this.state.POC3_contactno} 
    onChange={this.handleChangemobile}
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Email</span>
     <input type="email" 
       name="POC3_email" 
       value={this.state.POC3_email} 
       onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
  </div>
  
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Website</span>
    <input type="text" 
     value={this.state.websiteurl}
     name="websiteurl" onChange={this.handleChange}
     className="form-control adduser mt-2" />
     <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.websiteerror}</span>
    </div>
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Status</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
   
    onChange={(e)=>this.handleUserstatus(e)}
    placeholder="Active"
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Account Manager</span> <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    placeholder={this.state.ClientLeadname}
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>


  <div className="row mt-2">
   
  
   <div className="col-xl-12">
   <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Industry</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
  
   </div>
  
 </div>
 <div className="row mt-2" style={{height:"5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.industrydata.map((e)=>(
        <>
        <div className="col-xl-3">
        {this.state.Industryid.includes(e.id)==true?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.Industryid.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
    <div className="row mt-2">
   
  
   <div className="col-xl-12">
   <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>Required Documents</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
  
   </div>
 
 </div>
 <div className="row mt-2" style={{border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.documentdata.map((e)=>(
        <>
        <div className="col-xl-3">
        {this.state.documentids.includes(e.id)==true?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose(e.id)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.documentids.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox(e.id)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
 <div className="row mt-2">
 <div className="col-xl-12">

   <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>About Company</span>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
  <textarea name="AboutCompany"
  value={this.state.AboutCompany}
  onChange={this.handleChange} className="form-control adduser" rows="4"></textarea>
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>{this.state.aboutcompanyerror}</span>
   </div>
 </div>
   <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily: "Poppins Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
 
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 

          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**   <div class="container-fluid py-4">
         <div class="table-responsive p-0 pb-2">
       <table id="table" className="table align-items-center justify-content-center mb-0">
           <thead>
               <tr>
               <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
               <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Client Name</th>
              
<th></th>
</tr>
           </thead>

           <tbody>
                   {this.showTable()}
           </tbody>
       </table>
           </div>
           </div> */}
      <div className={styles.pageContainer}>
       
       <div className={styles.pageBox}>
         <Row className={styles.marginBottom1}>
           <Col md={4}>
             
             <div class="input-group">
   <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
               onKeyDown={this.handleKeyDown} />
   <div class="input-group-append">
     <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
               onClick={this.handleKeyDown}>
       <i class="fa fa-search"></i>
     </button>
   </div>
 </div>
           </Col>
          
 
           <Col md={8}>
             <div className={styles.displayFlex}>
               <a to="/add-document-category" exact className={styles.addLink}>
                
               </a>
               
               
                
               
               
              { /*<Button
                 variant="primary"
                 className={`${styles.marginLeft}`}
                 onClick={this.download_pdf}
                 style={{backgroundColor:"#4293FE",fontFamily: "Poppins Light",width:"14%",fontWeight:"bold"}}
               >
                
                Export
                 </Button>*/}
              
             </div>
           </Col>
         </Row>
          <div className={styles.clientContainer_old}>
           <div className={styles.tableContainer}>
          {this.state.loadingicon==0?<>
            <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
          </>:<> <DataTable
              
              customStyles={tableCustomStyles}
              columns={this.columns}
              data={this.state.rows}
              pagination
              striped
              dense
              
            />
           </>}
            
           </div>
         </div> 
        
       </div>
     </div>


      </>
    );
  }
}

export default Clientactiveuser;
