import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import LoadingIcon  from "../images/loadingicon.gif";
import PencilIcon  from "../images/pencilicon.png";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState,convertToRaw} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid black",
   backgroundColor:'#F3F0F0',
    borderRadius:"1px",
   marginTop:"1.5%",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid black",
      borderRadius:"1px",
      backgroundColor:'#F3F0F0',
      color:"black"
    }
  }),
 
};
const styless = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #ced4da",
   backgroundColor:'rgb(233, 236, 239)',
    borderRadius:"1px",
   marginTop:"1.5%",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #ced4da",
      borderRadius:"1px",
      backgroundColor:'rgb(233, 236, 239)',
      color:"black"
    }
  }),
 
};
const tableCustomStyles = {
  table: {
    style: {
    },
},
tableWrapper: {
  style: {
      display: "table",
      width:"100%",
      
  },
},
responsiveWrapper: {
    style: {},
},

expanderButton:{
  
  style:{
    '&:disabled': {
     display:"none"
    },
  }
},

}


class Piplinedata extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      clientlogo:" ",
      rolesdata:[],
      teamdata:[],
   
      updatemsg:"",
      countrydata:[],
      citydata:[],
      industrydata:[],
      catageroydata:[],
      Countryname:"",
      Countryid:"",
      statedata:[],
 
      BusinessUnit:"",
      editclientid:"",
   
      languagesdata:[],
      degreedata:[],
      JobTitle:"",
      JobCode:"",
      ClientName:"",
      ClientId:"",
      CBR_Coin:"",
      CBR_Amount:"",
      PR_Coin:"",
      PR_MinAmount:"",
      PR_MaxAmount:"",
      CountryID:"",
      CountryName:"",
      StateID:"",
      StateName:"",
      CityID:"",
      CityName:"",
      ClientJobID:"",
      NoofPositions:"",
      ClosedPostions:"",
      JobCreatedDate:"",
      JobClosingDate:"",
      SPOCMobile:"",
      SPOCName:"",
      HiringManagerid:"",
      HiringManager:"",
      AssingnedID:"",
      AssingnedName:"",
      BusinessUnit:"",
      NoticePeriod:"",
      JobDescription:"",
      DegreeID:"",
      Degreename:"",
      ExperienceMin:"",
      ExperienceMax:"",
      PrimarySkills:"",
      Languages:"",
      JobStatus:"",
      jobstatusname:"",
      CreatedBy:"",
      userstausdata:[
        { value: '1', label: 'Active'},
       
     
       
      ],
      paymentdata:[{ value: '1', label: 'Hourly'},{ value: '1', label: 'Monthly'},{ value: '1', label: 'Daily'},{ value: '1', label: 'Weekly'},],
      editorState: EditorState.createEmpty(),
    editjobid:"",
    sourcescreen:0,
    pipelinestatus:0,
    taggedon:0,
    client:0,
    pipelinesearch:0,
    loadingicon:0,
    disqualifydata:[ 
    { value: "1", label: "Application Not Considered " },
    { value: "2", label: "Payment Terms Not Accepted" },
   ],
   allOptions : [
    { value: "1", label: "Naukri" },
    { value: "2", label: "Monster" },
    { value: "3", label: "LinkedIn" },
    { value: "4", label: "Other" }
      ],
      PipelineStatus : [ ],
      SubmissionStatus:[],
      Emaildata:[],
      Noticeperiod : [
        { value: "1", label: "15 Days or less" },
        { value: "2", label: "1 Month " },
        { value: "3", label: "2 Months" },
        { value: "4", label: "3 Months" },
        { value: "5", label: "More than 3 Months" },
        { value: "6", label: "Serving Notice Period" },
        { value: "7", label: "7 Days" },
        { value: "8", label: "45 Days" },
        { value: "9", label: "Any" },
        { value: "10", label: "Immediate Joiner" },
        { value: "11", label: "Maintenance" },
      ] , InterviewModedata : [
        { value: "1", label: "Google Hangout" },
        { value: "2", label: "In-person " },
        { value: "3", label: "Microsoft Teams" },
        { value: "4", label: "Skype" },
        { value: "5", label: "Skype-Live" },
        { value: "6", label: "Telephonic" },
        { value: "7", label: "Webex" },
        { value: "8", label: "Zoom" },
       
      ],
      InterviewRounddata : [
        { value: "1", label: "Client Interview" },
        { value: "2", label: "End Client Interview " },
        { value: "3", label: "Internal Interview" },
        { value: "4", label: "Primary Vendor Interview" },
        { value: "5", label: "Vendor Interview" },
        { value: "6", label: "L1 Interview" },
        { value: "7", label: "L2 Interview" },

       
      ]
    ,reportingtodata:[],
    submittedby:0,
    clientdata:[],
    noticeperiod:0,
    original_rows: [], 
    rows: [],
    rows2:[], 
    search: "",
    keyGen:[],
    keyGen1:[],
    keyGen2:[],
    keyGen3:[],
    keyGen4:[],
    gettaggeddate:"",
    original_rows3:[],
    rows3:[],
    submitteddata:0,
    pipelinedata:0,
    checkboxData:[],
    selectcheckbox:0,
    editapplicantid:"",
    showModal2:false,
      showModal3:false,
      showModal4:false,
      successmsg:"",
      showModal5:false,
      showModal6:false,
      showModal7:false,
      showModal8:false,
      showModal9:false,
      showModal10:false,
      showModal11:false,
      showModal12:false,
      showModal13:false,
      showModal14:false,
      pipelinestatusname:"",
      pipelinestatus:"",
      pipelinestatusids:"",
      showModal15:false,
      statuserror:"",
      selectcheckbox2:0,
      PipelineStatusName:[],
      PipelineStatusids:[],
      joblists:[],
      errormsg:"",
      profilestatusids:"",
      profilestatusname:"",
      Profilestatusids:[],
      Profilestatusname:[],
      disqualifyReason:"",
      editjobidtwo:"",
      maildescription:"",
      showModal16:false,
      bccscreen:0,
      submittoclient:[],
      maildescription:"",
      mailsendcc:"",
      mailsendto:"",
      mailsendBCC:"",
      ccscreen:0,
      successmsg1:"",
      Interviewdata:[],
      InterviewMode :"",
      InterviewRound :"",
      InterviewTime:"",
      InterviewDate:"",
      errormsg1:"",
      errormsg2:"",
      errormsg3:"",
      errormsg4:"",
      staffingdata:[
        { value: "1", label: "Permanent Staffing" },
        { value: "2", label: "Contract To Hire" },
      ],
      RectManagername:"",
      RectManagerid:"",
      StaffingTypeid:"",
      StaffingTypename:"",
      BillPayRateType:"",
      CBillRateMonthly:"",
      currencyname:"INR",
      errormsg5:"",
      jobtitle:"",
      attachmentbase64stringt:"",
      attachmentfilename:""
      

    }
    this.columns2 = [
      {
       
      cell: (e) => (
       
          <>
             <div style={{marginLeft:"4em"}}> 
             <input type="checkbox" key={e.ApplicantID} value={[e.ApplicantID,e.applicationstatusname,e.applicationStatusid]}  className="checkboxstyle" /*onClick={(e)=>this.selectJob2(e,e.ApplicantID,e.applicationstatusname)}*/ />
      </div>
      
      
    
        </>
      ),
      
      
    },
    {
      name: "Job Code",
      selector: "JobCode",
      sortable: true,
      
    },
   
    {
      name: "Job Title",
      selector: "JobTitle",
      sortable: true,
      
    },
    {
      name:"Pipeline Status",
      cell: (e) => (
       
          <>
          
    <span style={{fontFamily:"Poppins Light",fontWeight:"bold",verticalAlign:"sub"}}>{e.pipelinestatusname}</span>&nbsp;&nbsp;
        </>
      ),
     
      
    },
   
    {
      name: "Client Name",
      selector: "ClientName",
      sortable: true,
     
    },
  
    {
      name: "Tagged By",
      selector:"taggedbyname",
      sortable: true,
     
    },
       
       
  ];
    this.columns = [
      {
       
        cell: (e) => (
         
            <>
             <div style={{marginLeft:"4em"}}> <input type="checkbox" key={e.ApplicantID} value={[e.ApplicantID,e.pipelinestatusname,e.pipelinestatus]}  className="checkboxstyle" /* onClick={(e)=>this.selectJob(e,e.ApplicantID,e.pipelinestatusname)}*/ />
      </div>
       
       
          </>
        ),
        
        
      },
         
        
          {
            name: "Job Code",
            selector: "JobCode",
            sortable: true,
            
          },
         
          {
            name: "Job Title",
            selector: "JobTitle",
            sortable: true,
            
          },
          {
            name:"Pipeline Status",
            cell: (e) => (
             
                <>
                
          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",verticalAlign:"sub"}}>{e.pipelinestatusname}</span>&nbsp;&nbsp;
              </>
            ),
           
            
          },
         
          {
            name: "Client Name",
            selector: "ClientName",
            sortable: true,
           
          },
        
          {
            name: "Tagged By",
            selector:"taggedbyname",
            sortable: true,
           
          },
        
        
          
       

    ];
    this.columns3 = [
      {
       
        cell: (e) => (
         
            <>
             <div style={{marginLeft:"4em"}}> 
             <input type="checkbox" key={e.ApplicantID} value={[e.ApplicantID,e.applicationstatusname,e.applicationStatusid]}  className="checkboxstyle" /*onClick={(e)=>this.selectJob2(e,e.ApplicantID,e.applicationstatusname)}*/ />
      </div>
          </>
        ),
        
        
      },
     
    
        
      {
        name: "Job Code",
        selector: "JobCode",
        sortable: true,
        
      },
     
      {
        name: "Job Title",
        selector: "JobTitle",
        sortable: true,
        
      },
      {
        name:"Application Status",
        cell: (e) => (
         
            <>
            
      <span style={{fontFamily:"Poppins Light",fontWeight:"bold",verticalAlign:"sub"}}>{e.profilestatusName}</span>&nbsp;&nbsp;<a href="#ft-editsubmissionstatus-modal" onClick={()=>this.openModal3(e.ApplicantID)}><img src={PencilIcon} style={{width:"7.5%",cursor:"pointer",verticalAlign:"text-bottom"}}/></a>
      
          </>
        ),
        
        
      },
     
      {
        name: "Client Name",
        selector: "ClientName",
        sortable: true,
       
      },
    
      {
        name: "Submitted By",
        selector:"submitteddate",
        sortable: true,
       
      },
    
     /** {
        name: "Confirmation Status",
        cell: (e) => (
         
            <>
             {e.confirmationstatus==1?<>
             <span style={{verticalAlign:"sub",fontSize:15,cursor:"pointer"}}><i class="fa fa-eye" aria-hidden="true"></i></span>
             </>:<span style={{verticalAlign:"sub",fontSize:13,fontFamily:"Poppins Light",fontWeight:"bold"}}>N/A</span>}
          </>
        ),
        sortable: true
        
      }, */
     
    
];

    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.hanldelState=this.handleState.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleRequired=this.handleRequired.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleCategory=this.handleCategory.bind(this)
    this.handleSources=this.handleSources.bind(this)
    this.handleSourcesclose=this.handleSourcesclose.bind(this)
    this.handlePipeline=this.handlePipeline.bind(this)
    this.handlePipelineclose=this.handlePipelineclose.bind(this)
    this.handleTaggedon=this.handleTaggedon.bind(this)
    this.handleTaggedonclose=this.handleTaggedonclose.bind(this)
    this.handleSubmittedby=this.handleSubmittedby.bind(this)
    this.handleSubmittedbyclose=this.handleSubmittedbyclose.bind(this)
    this.handleClient=this.handleClient.bind(this)
    this.handleClientclose=this.handleClientclose.bind(this)
    this.handleNoticeperiod=this.handleNoticeperiod.bind(this)
    this.handleNoticeperiodclose=this.handleNoticeperiodclose.bind(this)
    this.updateStateList1=this.updateStateList1.bind(this)
    this.updateStateList2=this.updateStateList2.bind(this)
    this.updateStateList3=this.updateStateList3.bind(this)
    this.updateStateList4=this.updateStateList4.bind(this)
    this.updateStateList5=this.updateStateList5.bind(this)
    this.handleSourcesdisplay=this.handleSourcesdisplay.bind(this)
    this.handleSubmission=this.handleSubmission.bind(this)
    this.handlePipelines=this.handlePipelines.bind(this)
    this.selectJob=this.selectJob.bind(this)
    this.handleUntag=this.handleUntag.bind(this)
    this.handlePipelineupdate=this.handlePipelineupdate.bind(this)
    this.handlePip=this.handlePip.bind(this)
    this.handlePipelinenew=this.handlePipelinenew.bind(this)
    this.handleSubmitJob=this.handleSubmitJob.bind(this)
    this.handleTag=this.handleTag.bind(this)
    this.handleUpdateTagtoother=this.handleUpdateTagtoother.bind(this)
    this.handleRemove=this.handleRemove.bind(this)
    this.handleUpdatesubstatus=this.handleUpdatesubstatus.bind(this)
    this.handleSub=this.handleSub.bind(this)
    this.handleSubstatus=this.handleSubstatus.bind(this)
    this.handleDisqualify=this.handleDisqualify.bind(this)
    this.handleSubmittoother=this.handleSubmittoother.bind(this)
    this.handleTagtwo=this.handleTagtwo.bind(this)
    this.handleEmail=this.handleEmail.bind(this)
    this.handleBCC=this.handleBCC.bind(this)
    this.handleBCCclose=this.handleBCCclose.bind(this)
    this.handleClientSubmission=this.handleClientSubmission.bind(this)
    this.handleCC=this.handleCC.bind(this)
    this.handleCCclose=this.handleCCclose.bind(this)
    this.handleInterview=this.handleInterview.bind(this)
    this.handleInterviewround=this.handleInterviewround.bind(this)
    this.handleInterviewSchedule=this.handleInterviewSchedule.bind(this)
    this.do_search2=this.do_search2.bind(this)
    this.handleKeyDown2=this.handleKeyDown2.bind(this)
    this.handleInputChange2=this.handleKeyDown2.bind(this)
    this.handleManager=this.handleManager.bind(this)
    this.handleStaffing=this.handleStaffing.bind(this)
    this.handlePaytype=this.handlePaytype.bind(this)
    this.handleCreate=this.handleCreate.bind(this)
   
}
handleStaffing(e){
  this.setState({StaffingTypeid:e.value,errormsg3:"",StaffingTypename:e.label})
    }
 handlePaytype(e){
   this.setState({errormsg4:"",BillPayRateType:e.label})
 }
handleManager(e){
  this.setState({RectManagerid:e.value,errormsg2:"",RectManagername:e.label})
    }
    handleCreate(){
      if(this.state.jobtitle==""){
        this.setState({errormsg1:"Please Enter Job Title"})
      }
      else if(this.state.RectManagername==""){
        this.setState({errormsg2:"Please Choose Recruitment Manager Name"})
      }
      else if(this.state.StaffingTypeid==""){
        this.setState({errormsg3:"Please Choose Staffing Type"})
      }
      else if(this.state.BillPayRateType==""){
        this.setState({errormsg4:"Please Choose Bill/Pay Rate Type"})
      }
      else if(this.state.CBillRateMonthly==""){
        this.setState({errormsg5:"Please Enter Client Bill Rate Monthly"})
      }
      else{
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
           const data={userid:withoutQuotes,addJobSubmissiondata:"addconfirmationinfo",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,jobtitle:this.state.jobtitle,RectManagerid:this.state.RectManagerid,RectManagername:this.state.RectManagername,StaffingTypeid:this.state.StaffingTypeid,StaffingTypename:this.state.StaffingTypename,BillPayRateType:this.state.BillPayRateType,CBillRateMonthly:this.state.CBillRateMonthly,currencyname:this.state.currencyname}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
        method: "POST",
    
        body: JSON.stringify(data), // data can be `string` or {object}!
    
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
    
        .catch((error) => console.error("Error:", error))
    
        .then((response) => {
          console.log(response)
             this.setState({successmsg:response.message})
             setTimeout(
              function() {
                window.location.reload(false)
              }
              .bind(this),
              2500
            );
            });
      }
     
     
    
    }
handleInterviewSchedule(){
  if(this.state.InterviewDate==""){
    this.setState({errormsg1:"Please Choose Interview Date"})
  }
  else if(this.state.InterviewTime==""){
    this.setState({errormsg2:"Please Choose Interview Time"})
  }
  else if(this.state.InterviewMode==""){
    this.setState({errormsg3:"Please Choose Interview Mode"})
  }
  else if(this.state.InterviewRound==""){
    this.setState({errormsg4:"Please Choose Interview Round"})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
       const data={userid:withoutQuotes,addJobSubmissiondata:"sendinterviewscheduleinfo",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,InterviewDate:this.state.InterviewDate,InterviewMode:this.state.InterviewMode,InterviewTime:this.state.InterviewTime,InterviewRound:this.state.InterviewRound}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      console.log(response)
         this.setState({successmsg:response.message})
         setTimeout(
          function() {
            window.location.reload(false)
          }
          .bind(this),
          2500
        );
        });
  }
 
 

}
handleChangebillrate = (e) => {
  var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
  e.target.value = e.target.value.replace(regex, "");

    this.setState({
      [e.target.name]: e.target.value,
      mobileerror:"",
      errormsg5:""
    });
 

  
};
handleChangethree = (e) => {
  
  this.setState({
    [e.target.name]: e.target.value,
    errormsg1:""

  });
  
};
handleInterviewround(e){
  this.setState({InterviewRound:e.label,errormsg4:""})
    }
handleInterview(e){
  this.setState({InterviewMode:e.label,errormsg3:""})
    }
handleCC(){
  this.setState({ccscreen:1})
}
handleCCclose(){
  this.setState({ccscreen:0})
}
handleBCCclose(){
  this.setState({bccscreen:0})
}
handleBCC(){
this.setState({bccscreen:1})
}
handleClientSubmission(){
  if(this.state.mailsendto==""){
    this.setState({successmsg1:"Please Enter Email Id."})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    var replacestring = this.state.maildescription.replace('<figure class="table">', '');
    var replacestring1 = replacestring.replace('</figure>', '');
    var replacestring2=replacestring1.replace('<p>,</p>','')
   // console.log(replacestring2)
  
    const data={userid:withoutQuotes,addJobSubmissiondata:"sendemailtoclient",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,mailsendto:this.state.mailsendto,mailsendcc:this.state.mailsendcc,maildescription:replacestring2,mailsendBCC:this.state.mailsendBCC}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      console.log(response)
         this.setState({successmsg:response.message})
         setTimeout(
          function() {
            window.location.reload(false)
          }
          .bind(this),
          2500
        );
        });
  }
 
 

}
handleDisqualify(){
  if(this.state.disqualifyReason==""){
    this.setState({successmsg:"Please Enter Disqualify Reason"})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addJobSubmissiondata:"profiledisqualify",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,disqualifyReason:this.state.disqualifyReason,}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
      method: "POST",
  
      body: JSON.stringify(data), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
          
           this.setState({successmsg:response.message})
           setTimeout(
            function() {
              window.location.reload(false)
            }
            .bind(this),
            2500
          );
          });
  }
}
handleSubstatus(){
  if(this.state.profilestatusids==""){
    this.setState({successmsg:"Please Choose Submission Status"})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addJobSubmissiondata:"addprofilestatusmultiple",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,profilestatusids:this.state.profilestatusids,profilestatusname:this.state.profilestatusname}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
      method: "POST",
  
      body: JSON.stringify(data), // data can be `string` or {object}!
  
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
  
      .catch((error) => console.error("Error:", error))
  
      .then((response) => {
          
           this.setState({successmsg:response.message})
           setTimeout(
            function() {
              window.location.reload(false)
            }
            .bind(this),
            2500
          );
          
          });
  }
   
  
  
  }


handleUpdatesubstatus(){
if(this.state.profilestatusids==""){
  this.setState({errormsg:"Please Choose Submission Status"})
}
else{
  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotes = username.replaceAll('"', '');
  console.log(withoutQuotes)
  const data={userid:withoutQuotes,addJobSubmissiondata:"addprofilestatusmultiple",editapplicantid:this.state.editapplicantid.toString(),editjobid:this.state.editjobid,profilestatusids:this.state.profilestatusids,profilestatusname:this.state.profilestatusname}
  console.log(data)
  fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
         this.setState({errormsg:response.message})
         setTimeout(
          function() {
            window.location.reload(false)
          }
          .bind(this),
          2500
        );
        
        });
}
 


}

handleSub(e){
  this.setState({profilestatusids:e.value,errormsg:"",profilestatusname:e.label},()=>{
    //console.log(this.state.profilestatusids)
  })
}
handleTag(e){
  this.setState({editjobid:e.value,errormsg:""})
    }
    handleTagtwo(e){
      this.setState({editjobidtwo:e.value,successmsg:""})
        }
    handleUpdateTagtoother(){
      if(this.state.editjobid==""){
        this.setState({errormsg:"Please Select a Job !"})
      }
      else{
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
        const data={ 
          userid:withoutQuotes,
          addJobpipelinedata:"addtagtootherjob",
          editapplicantid:this.state.checkboxData.toString(),
          editjobid:this.state.editjobid
        }
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
            // console.log(response)
                this.setState({errormsg:response.message})
                window.location.reload();  
                });
        
      }
    }
    handleSubmittoother(){
      if(this.state.editjobidtwo==""){
        this.setState({successmsg:"Please Select a Job !"})
      }
      else{
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
        const data={ 
          userid:withoutQuotes,
          addJobSubmissiondata:"addsubmittootherjob",
          editapplicantid:this.state.checkboxData.toString(),
          editjobid:this.state.editjobidtwo
        }
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
            // console.log(response)
                this.setState({successmsg:response.message})
                window.location.reload();  
                });
        
      }
    }
handleSubmitJob(){

    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addJobpipelinedata:"addsubmittojob",editapplicantid:this.state.editapplicantid,editjobid:this.state.editjobid,pipelinestatus:this.state.PipelineStatusids.toString(),pipelinestatusname:this.state.PipelineStatusName.toString()}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          
           this.setState({successmsg:response.message})
             
            setTimeout(
              function() {
                window.location.reload(false)
              }
              .bind(this),
              2500
            );
          });
  
  
}
handleRemove(){

  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotes = username.replaceAll('"', '');
  console.log(withoutQuotes)
  const data={userid:withoutQuotes,addJobSubmissiondata:"removeJobsubmission",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,}
  console.log(data)
  fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
         this.setState({successmsg:response.message})
           
          setTimeout(
            function() {
              window.location.reload(false)
            }
            .bind(this),
            2500
          );
        });


}

openModal2=(id)=>{
  this.setState({ showModal2: true ,editapplicantid:id},()=>{
    console.log(id)
  });
}
closeModal2 = () => {
  this.setState({ showModal2: false });
};
openModal3=(id)=>{
  this.setState({ showModal3: true,editapplicantid:id },()=>{
    console.log(id)
  });
}
closeModal3 = () => {
  this.setState({ showModal3: false });
};
openModal4=()=>{
  this.setState({ showModal4: true });
}
closeModal4 = () => {
  this.setState({ showModal4: false });
};
openModal5=()=>{
  this.setState({ showModal5: true },()=>{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={userid:withoutQuotes,addJobpipelinedata:"getpipesendemailids",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          //console.log(response)
          response.result.map((e)=>{
            this.setState({Emaildata:this.state.Emaildata.concat(e.EmailID)},()=>{
              console.log(this.state.Emaildata)
            })
          })
        })
  });
}
closeModal5 = () => {
  this.setState({ showModal5: false,Emaildata:[] })
};
openModal6=()=>{
  this.setState({ showModal6: true });
}
closeModal6 = () => {
  this.setState({ showModal6: false });
};
openModal7=()=>{
  this.setState({ showModal7: true });
}
closeModal7 = () => {
  this.setState({ showModal7: false });
};
openModal8=()=>{
  this.setState({ showModal8: true },()=>{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={userid:withoutQuotes,addJobSubmissiondata:"getinterviewscheduleinfo",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          console.log(response)
        this.setState({Interviewdata:response.result})
        })
  });
}
closeModal8 = () => {
  this.setState({ showModal8: false,Interviewdata:[] });
};
openModal9=()=>{
  this.setState({ showModal9: true });
}
closeModal9 = () => {
  this.setState({ showModal9: false });
};
openModal10=()=>{
  this.setState({ showModal10: true });
}
closeModal10 = () => {
  this.setState({ showModal10: false });
};
openModal11=()=>{
  this.setState({ showModal11: true });
}
closeModal11 = () => {
  this.setState({ showModal11: false });
};
openModal12=()=>{
  this.setState({ showModal12: true },()=>{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={userid:withoutQuotes,addJobSubmissiondata:"getclientsubmissioninfo",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          response.result.map((e)=>{
            this.setState({submittoclient:this.state.submittoclient.concat(`<tbody><td>${e.fullname}</td><td>${e.EmailID}</td><td>${e.MobileNo}</td><td>${e.JobTitle}</td><td>${e.TotalExp} Years</td></tbody>`)},()=>{
              //console.log(this.state.submittoclient)
            })
           
          })
      

        })
  });
}

closeModal12 = () => {
  this.setState({ showModal12: false,submittoclient:[] });
};
openModal13=()=>{
  this.setState({ showModal13: true });
}
closeModal13 = () => {
  this.setState({ showModal13: false });
};
openModal14=()=>{
  this.setState({ showModal14: true });
}
closeModal14 = () => {
  this.setState({ showModal14: false });
};
openModal15=()=>{
  this.setState({ showModal15: true });
}
closeModal15 = () => {
  this.setState({ showModal15: false });
};
openModal16=()=>{
  this.setState({ showModal16: true },()=>{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={userid:withoutQuotes,addJobpipelinedata:"getpipesendemailids",editapplicantid:this.state.checkboxData.toString(),editjobid:1}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          //console.log(response)
          response.result.map((e)=>{
            this.setState({Emaildata:this.state.Emaildata.concat(e.EmailID)},()=>{
              console.log(this.state.Emaildata)
            })
          })
        })
  });
}
closeModal16 = () => {
  this.setState({ showModal16: false,Emaildata:[] });
};
handlePipelineupdate(){
  if(this.state.pipelinestatus==""){
    this.setState({statuserror:"Please Choose Status"})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addJobpipelinedata:"addpipelinestatus",editapplicantid:this.state.editapplicantid,editjobid:1,pipelinestatus:this.state.pipelinestatus,pipelinestatusname:this.state.pipelinestatusname}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
            
             this.setState({successmsg:response.message})
               
              setTimeout(
                function() {
                  window.location.reload(false)
                }
                .bind(this),
                2500
              );
            });
  }
  
}
handlePipelinenew(){
  if(this.state.pipelinestatus==""){
    this.setState({statuserror:"Please Choose Status"})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addJobpipelinedata:"addpipelinestatusmultiple",editapplicantid:this.state.checkboxData.toString(),editjobid:this.state.editjobid,pipelinestatusids:this.state.pipelinestatus,pipelinestatusname:this.state.pipelinestatusname}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
            
             this.setState({successmsg:response.message})
               
              setTimeout(
                function() {
                  window.location.reload(false)
                }
                .bind(this),
                2500
              );
            });
  }
 
}
handleUntag(){
  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotes = username.replaceAll('"', '');
  console.log(withoutQuotes)
  const data={userid:withoutQuotes,addJobpipelinedata:"untaggedjob",editapplicantid:this.state.editapplicantid,editjobid:this.state.editjobid}
  console.log(data)
  fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          
           this.setState({successmsg:response.message})
             
            setTimeout(
              function() {
                window.location.reload(false)
              }
              .bind(this),
              3000
            );
          });
}
selectJob(e,jobid){

  var s1=e.target.value.toString()
  var s2=s1.split(',');
 var d1=s2[0]
 var d2=s2[1]
 var d3=s2[2]

  if (e.target.checked){
    this.setState({selectcheckbox:1,editapplicantid:d1})
    this.setState({checkboxData: this.state.checkboxData.concat([d1])},()=>{
      console.log(this.state.checkboxData)
    })
    this.setState({PipelineStatusName: this.state.PipelineStatusName.concat([d2])},()=>{
      console.log(this.state.PipelineStatusName.toString())
    })
    this.setState({PipelineStatusids: this.state.PipelineStatusids.concat([d3])},()=>{
      //console.log(this.state.PipelineStatusids)
    })
  }
 
   else{
    this.setState({PipelineStatusName : this.state.PipelineStatusName.filter(function(val) {return val!==d2})})
    this.setState({PipelineStatusids : this.state.PipelineStatusids.filter(function(val) {return val!==d3})})
    this.setState({checkboxData : this.state.checkboxData.filter(function(val) {return val!==d1}),},()=>{
      console.log(this.state.checkboxData)
      if(this.state.checkboxData.length==0){
        this.setState({selectcheckbox:0})
      }
      else{
        this.setState({selectcheckbox:1})
      }
    })
   } 
   
}
selectJob2(e,jobid){
  var s1=e.target.value.toString()
  var s2=s1.split(',');
 var d1=s2[0]
 var d2=s2[1]
 var d3=s2[2]
  var s1=e.target.value
  if (e.target.checked){
    this.setState({selectcheckbox2:1,editapplicantid:s1,selectcheckbox:0})
    this.setState({checkboxData: this.state.checkboxData.concat([d1])},()=>{
      //console.log(this.state.checkboxData)
    })
    this.setState({Profilestatusname: this.state.Profilestatusname.concat([d2])},()=>{
      //console.log(this.state.Profilestatusname.toString())
    })
    this.setState({Profilestatusids: this.state.Profilestatusids.concat([d3])},()=>{
      //console.log(this.state.Profilestatusids)
    })
  }
 
   else{
    this.setState({PipelineStatusName : this.state.PipelineStatusName.filter(function(val) {return val!==d2})})
    this.setState({PipelineStatusids : this.state.PipelineStatusids.filter(function(val) {return val!==d3})})
    this.setState({checkboxData : this.state.checkboxData.filter(function(val) {return val!==d1})},()=>{
      console.log(this.state.checkboxData)
      if(this.state.checkboxData.length==0){
        this.setState({selectcheckbox2:0})
      }
      else{
        this.setState({selectcheckbox2:1})
      }
    })
   } 
   
}
handleSubmission(){
  this.setState({submitteddata:1,pipelinedata:2,checkboxData:[],selectcheckbox:0})
}
handlePipelines(){
  this.setState({submitteddata:0,pipelinedata:0,checkboxData:[],selectcheckbox:0,selectcheckbox2:0},()=>{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    var job_id=JSON.stringify(localStorage.getItem("PipCont"))
    const jobid = job_id.replaceAll('"', '');
    this.setState({editjobid:jobid})
    const repdata1={
      userid:withoutQuotes,
      addApppipelinedata:"getJobpipelinedetailsinfo",
      editapplicantid:jobid,
      pipelinestatusids:"",
      gettaggedbyids:"",
      gettaggeddate:"",
   
  
  }
   // console.log(repdata)
    fetch("https://www.mrecruit.in:5200/api/getAppPipelineinfo", {
        method: "POST",

        body: JSON.stringify(repdata1), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
        
          let data=response.result
           
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
          });
          this.setState({ original_rows: data, rows: data,loadingicon:1 });
         
          
              
            });
   
  })
}
handleEmail(){
  if(this.state.maildescription=="" || this.state.maildescription=="<p></p>\n"){
      this.setState({successmsg:"Email Body Can't be empty."})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={ userid: withoutQuotes,addJobpipelinedata:"sendemailtoapplicant",editjobid:this.state.editjobid,editapplicantid:this.state.checkboxData.toString(),maildescription:this.state.maildescription,
    
  }
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
            this.setState({successmsg:response.message})
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
            2500
          );
              
            });
  }

}
getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      baseURL = reader.result;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
};
handleAttachment = (e) => {
  
  // let file = new FileReader();
  console.log(e.target.files[0]);
  let { file } = this.state;

  file = e.target.files[0];

  this.getBase64(file)
    .then((result) => {
      file["base64"] = result;
      console.log("File Is", file);
      this.setState({
        attachmentbase64stringt: result,
       
        file,
      });
    })
    .catch((err) => {
      console.log(err);
    });

  this.setState({
    //  file: e.target.files[0]
  });

  this.setState({
    //companylogo: e.target.files[0]
  });
  var fileInput = false;
  if (e.target.files[0]) {
    fileInput = true;
  }
  
  if(fileInput) {
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        120,
        120,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri);
          this.setState({ newImage: uri });
        },
        "base64",
        120,
        120
      );
    } catch (err) {
      console.log(err);
    }
  }
};
handleImage = (e) => {
  
  // let file = new FileReader();
  console.log(e.target.files[0]);
  let { file } = this.state;

  file = e.target.files[0];

  this.getBase64(file)
    .then((result) => {
      file["base64"] = result;
      console.log("File Is", file);
      this.setState({
        profilepic: result,
       
        file,
      });
    })
    .catch((err) => {
      console.log(err);
    });

  this.setState({
    //  file: e.target.files[0]
  });

  this.setState({
    //companylogo: e.target.files[0]
  });
  var fileInput = false;
  if (e.target.files[0]) {
    fileInput = true;
  }
  
  if(fileInput) {
    try {
      Resizer.imageFileResizer(
        e.target.files[0],
        120,
        120,
        "JPEG",
        100,
        0,
        (uri) => {
          console.log(uri);
          this.setState({ newImage: uri });
        },
        "base64",
        120,
        120
      );
    } catch (err) {
      console.log(err);
    }
  }
};
handleSourcesdisplay(){
  this.setState({sourcescreen:0,taggedon:0,submittedby:0,client:0,pipelinesearch:2,loadingicon:0,rows:[]},()=>{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
     const rdata={ userid:withoutQuotes,
      addJobpipelinedata:"getJobpipelineinfo",
       editjobid:this.state.editjobid,
       getsourceids:this.state.keyGen2.toString(),
       getnoticeperiodname:this.state.keyGen4.toString(),
       pipelinestatusids:this.state.keyGen3.toString(),
       gettaggedbyids:this.state.keyGen1.toString(),
       gettaggeddate:this.state.gettaggeddate,
       getlocationname:this.state.keyGen.toString()
     
     
     }
     console.log(rdata)
     fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
         method: "POST",
 
         body: JSON.stringify(rdata), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
            
            console.log(response)
            if(response.message=="No Data Found."){
              this.setState({  keyGen:[],keyGen2:[],keyGen1:[],keyGen4:[],keyGen3:[],getSubmitteddate:" ",loadingicon:1 }); 
            }
            else{
              let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data,keyGen:[],keyGen2:[],keyGen1:[],keyGen4:[],getSubmitteddate:" " ,keyGen3:[],loadingicon:1}); 
            }
           
                  
             });
  })
}
updateStateList4(e,value){
         
  if (e.target.checked){
    //console.log(e.target.value)
      this.setState({keyGen4: this.state.keyGen4.concat([e.target.value])},()=>{
      
      })
  }
  else{
    this.setState({keyGen4 : this.state.keyGen4.filter(function(val) {return val!==e.target.value})},()=>{
      //console.log(this.state.keyGen)
    })
  }
}
updateStateList1(e,value){
         
  if (e.target.checked){
    //console.log(e.target.value)
      this.setState({keyGen: this.state.keyGen.concat([e.target.value])},()=>{
      
      })
  }
  else{
    this.setState({keyGen : this.state.keyGen.filter(function(val) {return val!==e.target.value})},()=>{
      //console.log(this.state.keyGen)
    })
  }
}
updateStateList2(e,value){
         
  if (e.target.checked){
    //console.log(e.target.value)
      this.setState({keyGen2: this.state.keyGen2.concat([e.target.value])},()=>{
      
      })
  }
  else{
    this.setState({keyGen2 : this.state.keyGen2.filter(function(val) {return val!==e.target.value})},()=>{
      //console.log(this.state.keyGen)
    })
  }
}
updateStateList3(e,value){
         
  if (e.target.checked){
    //console.log(e.target.value)
      this.setState({keyGen3: this.state.keyGen3.concat([e.target.value])},()=>{
      
      })
  }
  else{
    this.setState({keyGen3 : this.state.keyGen3.filter(function(val) {return val!==e.target.value})},()=>{
      //console.log(this.state.keyGen)
    })
  }
}
updateStateList5(e,value){
         
  if (e.target.checked){
    //console.log(e.target.value)
      this.setState({keyGen1: this.state.keyGen1.concat([e.target.value])},()=>{
      
      })
  }
  else{
    this.setState({keyGen1 : this.state.keyGen1.filter(function(val) {return val!==e.target.value})},()=>{
      //console.log(this.state.keyGen)
    })
  }
}
handleNoticeperiod(){
  this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:1})
}
handleNoticeperiodclose(){
  this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}
handleSources(){
    this.setState({sourcescreen:1,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}  
handleSourcesclose(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
} 
handlePipeline(){
    this.setState({sourcescreen:0,pipelinestatus:1,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}  
handlePipelineclose(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}   
handleSubmittedby(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:1,client:0,noticeperiod:0})
}  
handleSubmittedbyclose(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}   

handleTaggedon(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:1,submittedby:0,client:0,noticeperiod:0})
}  
handleTaggedonclose(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}   
handleClient(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:1,noticeperiod:0})
}  
handleClientclose(){
    this.setState({sourcescreen:0,pipelinestatus:0,taggedon:0,submittedby:0,client:0,noticeperiod:0})
}   

  handleCategory(e){
    this.setState({CategoryName:e.label,categoryid:e.value},()=>{
     
    })
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"editjobpostinginfo",
      editjobid:this.state.editjobid,
      JobCode:this.state.JobCode,
      JobTitle:this.state.JobTitle,
      ClientId:this.state.ClientId,
      AssingnedID:this.state.AssingnedID,
      AssingnedName:this.state.AssingnedName,
      ClientName:this.state.ClientName,
      CBR_Coin:this.state.CBR_Coin,
      CBR_Amount:this.state.CBR_Amount,
      PR_Coin:this.state.PR_Coin,
      PR_MinAmount:this.state.PR_MinAmount,
      PR_MaxAmount:this.state.PR_MaxAmount,
      CountryID:this.state.CountryID,
      CountryName:this.state.CountryName,
      StateID:this.state.StateID,
      StateName:this.state.StateName,
      CityID:this.state.CityID,
      CityName:this.state.CityName,
      ClientJobID:this.state.ClientJobID,
      NoofPositions:this.state.NoofPositions,
      ClosedPostions:this.state.NoofPositions,
      JobCreatedDate:this.state.JobCreatedDate,
      JobClosingDate:this.state.JobClosingDate,
      SPOCName:this.state.SPOCName,
      SPOCMobile:this.state.SPOCMobile,
      HiringManagerid:this.state.HiringManagerid,
      HiringManager:this.state.HiringManager,
      NoticePeriod:this.state.NoticePeriod,
      BusinessUnit:this.state.BusinessUnit,
      JobDescription:this.state.JobDescription,
      DegreeID:this.state.DegreeID,
      Degreename:this.state.Degreename,
      ExperienceMin:this.state.ExperienceMin,
      ExperienceMax:this.state.ExperienceMax,
      PrimarySkills:this.state.PrimarySkills,
      Languages:this.state.Languages,
      JobStatus:this.state.JobStatus

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              this.setState({updatemsg:response.message})
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
      }
  handleUserstatus(e){
    this.setState({cstatus:e.value}) 
  }
  handleIndustry(e){
    this.setState({Industryname:e.label,Industryid:e.value})
  }
  handleRequired(e){
    this.setState({ReqDocument:e.value})
  }
  handleReport(e){
    this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
  }
  handleCity(e){
    this.setState({Cityname:e.label,Cityid:e.value})
  }
  handleState(e){
    this.setState({Statename:e.label,Stateid:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      })
  }
  handlePip(e){
    this.setState({pipelinestatusname:e.label,pipelinestatus:e.value,statuserror:""})
  }
  handleCountry(e){
    this.setState({Countryname:e.label,Countryid:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }
  handleRowClicked(row){
    //console.log(row.id)
   localStorage.setItem("RowId",row.id)
  }
  handleCancel(){
    this.setState({displayform:0})
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editjobid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addjobpostingdata:"getsinglejobinfo",editjobid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              JobTitle:e.JobTitle,
              JobCode:e.JobCode,
              ClientId:e.ClientId,
              ClientName:e.ClientName,
              CBR_Coin:e.CBR_Coin,
              CBR_Amount:e.CBR_Amount,
              PR_Coin:e.PR_Coin,
              PR_MinAmount:e.PR_MinAmount,
              PR_MaxAmount:e.PR_MaxAmount,
              CountryID:e.CountryID,
              CountryName:e.CountryName,
              StateID:e.StateID,
              StateName:e.StateName,
              CityID:e.CityID,
              CityName:e.CityName,
              ClientJobID:e.ClientJobID,
              NoofPositions:e.NoofPositions,
              ClosedPostions:e.ClosedPostions,
              JobCreatedDate:e.JobCreatedDate,
              JobClosingDate:e.JobClosingDate,
              SPOCMobile:e.SPOCMobile,
              SPOCName:e.SPOCName,
              HiringManagerid:e.HiringManagerid,
              HiringManager:e.HiringManager,
              AssingnedID:e.AssingnedID,
              AssingnedName:e.AssingnedName,
              BusinessUnit:e.BusinessUnit,
              NoticePeriod:e.NoticePeriod,
              JobDescription:e.JobDescription,
              DegreeID:e.DegreeID,
              Degreename:e.Degreename,
              ExperienceMin:e.ExperienceMin,
              ExperienceMax:e.ExperienceMax,
              PrimarySkills:e.PrimarySkills,
              Languages:e.Languages,
              JobStatus:e.JobStatus,
              jobstatusname:e.jobstatusname,
              CreatedBy:e.CreatedBy

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  onEditorStateChange: Function = (editorState) => {
     //console.log(editorState)
    this.setState({
      editorState,
    });
    const contentState = editorState.getCurrentContent();
    const data=convertToRaw(contentState)
    const data1=draftToHtml(data)
    this.setState({maildescription:data1,successmsg:""})
    //console.log(data1);
  };

  componentDidMount() {
 
    /* Lists of Job API Call Start */
 const rdata111={ dropdownlist_input: "getjobnamelistinfo"}

 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata111), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.jobid,
         "label" : d.jobname

       })) 

     this.setState({joblists:options})
           
         });
 /*Lists of Job  API Call End */
     /* Submission Status API Call Start */
     const repdata11={ dropdownlist_input: "getpipelineoptionslist"}
     
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
    
         body: JSON.stringify(repdata11), // data can be `string` or {object}!
    
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
    
         .catch((error) => console.error("Error:", error))
    
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.id,
             "label" : d.name
    
           })) 
    
         this.setState({PipelineStatus:options})
               
             });
     /* Submission Status  API Call End */

 /* Pipeline Status API Call Start */
 const substatus={ dropdownlist_input: "getsubmissionoptionslist"}
     
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(substatus), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({SubmissionStatus:options})
           
         });
 /* Pipeline Status  API Call End */
     /* Language API Call Start */
     const ledata={ dropdownlist_input: "getlanguagesinfoinfo"}
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
 
         body: JSON.stringify(ledata), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.id,
             "label" : d.name
 
           })) 
    
         this.setState({languagesdata:options})
               
             });
            
     /*Language  API Call End */

      /* Degree API Call Start */
      const degdata={ dropdownlist_input: "getdegreesinfo"}
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(degdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({degreedata:options})
                
              });
             
      /*Language  API Call End */
/* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
/*Category API Call Start */
const data={ dropdownlist_input: "getclientcategoryinfo"}
console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(data), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.categoryid,
       "label" : d.categoryname

     })) 

   this.setState({catageroydata:options})
         
       });
/* Category API Call End */
 /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */

  /* Country API Call Start */
  const tdata={ dropdownlist_input: "getlocationlist"
 }
  console.log(tdata)
  fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          console.log(response)
       const data = response.result
        //console.log(json)
  
      const options = data.map(d => ({
          "value" : d.CityID,
          "label" : d.CityName

        })) 
 
      this.setState({countrydata:options,clientdata:options})
            
          });
  /* Country API Call  End */

   /* Reporting To API Call Start */
   var username=JSON.stringify(localStorage.getItem("UserId"))
   const withoutQuotes1 = username.replaceAll('"', '');
   const cdata={ userid: withoutQuotes1,
     adduserdata:"getteamsinfo"
   }
    console.log(tdata)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(cdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({teamdata:options})
              
            });
    /* Reporting To  API Call End */
    setTimeout(
      function() {
         if(JSON.stringify(localStorage.getItem("PipCont"))!="null" && JSON.stringify(localStorage.getItem("SubCont"))!="null"){
          var username=JSON.stringify(localStorage.getItem("UserId"))
          const withoutQuotes = username.replaceAll('"', '');
          var job_id=JSON.stringify(localStorage.getItem("PipCont"))
          const jobid = job_id.replaceAll('"', '');
          this.setState({editjobid:jobid})
          const repdata1={
            userid:withoutQuotes,
            addApppipelinedata:"getJobpipelinedetailsinfo",
            editapplicantid:jobid,
            pipelinestatusids:"",
            gettaggedbyids:"",
            gettaggeddate:"",
         
        
        }
         // console.log(repdata)
          fetch("https://www.mrecruit.in:5200/api/getAppPipelineinfo", {
              method: "POST",
      
              body: JSON.stringify(repdata1), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
               console.log(response)
              
                let data=response.result
                 
                data = data.map((currentValue, Index) => {
                  currentValue.SNO = Index + 1;
                  return currentValue;
                });
                this.setState({ original_rows: data, rows: data,loadingicon:1 });
               
                
                    
                  });
         
          const repdata={userid:withoutQuotes,
            addAppSubmissiondata:"getAppSubmissioninfoo",
            editapplicantid:jobid,
            profilestatusids:"",
            getsubmittedbyids:"",
            getsubmitteddate:"",
            getclientids:""
        
        }
          console.log(repdata)
          fetch("https://www.mrecruit.in:5200/api/getAppSubmissioninfo", {
              method: "POST",
        
              body: JSON.stringify(repdata), // data can be `string` or {object}!
        
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
        
              .catch((error) => console.error("Error:", error))
        
              .then((response) => {
                if(response.message=="No Data Found."){
                  this.setState({rows3:[]})
                 }
                 else{
                  let data=response.result
                  console.log(response)
                  data = data.map((currentValue, Index) => {
                    currentValue.SNO = Index + 1;
                    return currentValue;
                  });
                  this.setState({ original_rows3: data, rows3: data });
                 }
                 
                  
               
                
               
              });
             
           
         }
        else if(JSON.stringify(localStorage.getItem("PipCont"))!="null") {
          var username=JSON.stringify(localStorage.getItem("UserId"))
          const withoutQuotes = username.replaceAll('"', '');
          var job_id=JSON.stringify(localStorage.getItem("PipCont"))
          const jobid = job_id.replaceAll('"', '');
          this.setState({editjobid:jobid})
          const repdata1={
            userid:withoutQuotes,
            addApppipelinedata:"getJobpipelinedetailsinfo",
            editapplicantid:jobid,
            pipelinestatusids:"",
            gettaggedbyids:"",
            gettaggeddate:"",
         
        
        }
         // console.log(repdata)
          fetch("https://www.mrecruit.in:5200/api/getAppPipelineinfo", {
              method: "POST",
      
              body: JSON.stringify(repdata1), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
               console.log(response)
              
                let data=response.result
                 
                data = data.map((currentValue, Index) => {
                  currentValue.SNO = Index + 1;
                  return currentValue;
                });
                this.setState({ original_rows: data, rows: data,loadingicon:1 });
               
                
                    
                  });
             
           } 
           else if(JSON.stringify(localStorage.getItem("SubCont"))!="null") {
            var username=JSON.stringify(localStorage.getItem("UserId"))
            const withoutQuotes = username.replaceAll('"', '');
            var job_id=JSON.stringify(localStorage.getItem("SubCont"))
            const jobid = job_id.replaceAll('"', '');
            this.setState({editjobid:jobid})
            const repdata={userid:withoutQuotes,
              addAppSubmissiondata:"getAppSubmissioninfoo",
              editapplicantid:jobid,
              profilestatusids:"",
              getsubmittedbyids:"",
              getsubmitteddate:"",
              getclientids:""
          
          }
              console.log(repdata)
              fetch("https://www.mrecruit.in:5200/api/getAppSubmissioninfo", {
                  method: "POST",
            
                  body: JSON.stringify(repdata), // data can be `string` or {object}!
            
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
            
                  .catch((error) => console.error("Error:", error))
            
                  .then((response) => {
                    if(response.message=="No Data Found."){
                      this.setState({rows3:[]})
                     }
                     else{
                      let data=response.result
                      console.log(response)
                      data = data.map((currentValue, Index) => {
                        currentValue.SNO = Index + 1;
                        return currentValue;
                      });
                      this.setState({ original_rows3: data, rows3: data,loadingicon:1 ,submitteddata:1,pipelinedata:2,checkboxData:[],selectcheckbox:0});
                     }
                     
                      
                   
                    
                   
                  });    
             } 

             else{
              console.log("error")
             }
      }
      .bind(this),
      1500
  );
  setTimeout(
    function() {
     
    }
    .bind(this),
    4000
);
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }
  handleInputChange2(event) {
    this.setState({ search: event.target.value },()=>{
      console.log(this.state.search)
    });
  }
  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }
  handleKeyDown2(event) {
    if (event.key === "Enter") {
      this.do_search2();
    }
    else{
      this.do_search2();
    }
  }
  do_search2() {
    const temp_rows = this.state.original_rows3.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()),
      
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

    this.setState({ rows3: temp_rows });
  }
 
  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()),

    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChangetwo = (e) => {
    var regex = /[0-9!@#\$%\^\&*\)\(+=_`;:<>]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      successmsg:"",
      errormsg1:""

    });
    
  };

  handleChange = (e) => {

    this.setState({
      //Emaildata:this.state.Emaildata.concat(e.target.value),
      [e.target.name]: e.target.value,
      successmsg1:"",
     
      errormsg2:""

    });
    
  };
  render() {
   
  
    return (
      <>
          {/**Edit Pipeline Status Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-editpipelinestatus-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Pipeline Status
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Status</span>
                          &nbsp;&nbsp;<span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14,color:"red"}}>*</span>
                        </div>
                        <div className="col-xl-5">
                          <Select onChange={this.handlePip}  options={this.state.PipelineStatus} components={{  IndicatorSeparator:() => null }} styles={style}  />
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14,color:"red"}}>{this.state.statuserror}</span>
                        </div>

                      </div>

                      <div className="row mt-4">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handlePipelineupdate} style={{color:"white",marginTop:"5%",width:"100%"}}>Update</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal2} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
                     <div className="text-center mt-4">
                     <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>
                     </div>

           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Edit Pipeline Status Modal ends */}




    {/**Edit Pipeline Status Modal starts */}
    {this.state.showModal15 && (
        <div class="ft-modal" id="ft-editpipelinestatusnew-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Pipeline Status
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Status</span>
                        &nbsp;&nbsp;<span style={{fontWeight:"bold",fontSize:14,color:"red"}}>*</span>
                        </div>
                        <div className="col-xl-5">
                          <Select onChange={this.handlePip}  options={this.state.PipelineStatus} components={{  IndicatorSeparator:() => null }} styles={style}  />
                    <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14,color:"red"}}>{this.state.statuserror}</span>
                        </div>

                      </div>

                      <div className="row mt-4">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handlePipelinenew} style={{color:"white",marginTop:"5%",width:"100%"}}>Update</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal15} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
                     <div className="text-center mt-4">
                     <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>
                     </div>

           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal15}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Edit Pipeline Status Modal ends */}
     {/**Disqualify Modal starts */}
     {this.state.showModal9 && (
        <div class="ft-modal" id="ft-disqualify-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Disqualify
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      

                      <div className="row mt-4">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Reason</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea name="disqualifyReason" onChange={this.handleChangetwo} style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleDisqualify} style={{color:"white",marginTop:"5%",width:"100%"}}>Save</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal9} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
                     <div className="text-center mt-4">
                     <span style={{color:this.state.successmsg=="Please Enter Disqualify Reason"?"red":"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>
                     </div>

           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal9}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Disqualify Status Modal ends */}
        {/**Remove Submission Modal starts */}
        {this.state.showModal10 && (
        <div class="ft-modal" id="ft-removesubmission-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                  Remove Submission
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>Would You like to Remove the Submission from this job?</span>

                        </div>
                       

                      </div>

                      <div className="row mt-4">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleRemove} style={{color:"white",marginTop:"5%",width:"100%"}}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal10} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
                     <div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal10}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Remove Submission Modal ends */}
       {/**Untag Modal starts */}
       {this.state.showModal4 && (
        <div class="ft-modal" id="ft-untag-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Pipeline
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20}}>Would You like to Remove the Pipeline from this job?</span>

                        </div>
                       

                      </div>

                      <div className="row mt-5">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:16}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleUntag} style={{color:"white",marginTop:"5%",width:"100%"}}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal4} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal4}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Untag Modal ends */}
      {/**Send Email Modal starts */}
      {this.state.showModal5 && (
        <div class="ft-modal" id="ft-sendemail-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"80%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Send Email
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                            <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>From</span>
                            </div>
                          <div className="col-md-11">
                          <input type="text" value="System (jobs@maintec.in)" disabled className="form-control" />
                          </div>
                       {/**   <div className="col-md-2">
                          <button style={{color:"white",marginTop:"1%",width:"100%"}} className="btn updatebtn">Send</button>
                          </div> */}
                          </div>
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>TO</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                            <textarea className="form-control" disabled rows="3" style={{fontSize:12}} value={this.state.Emaildata.toString()}>

                            </textarea>
                         
                          
                         <span  style={{color:"#007bff",fontSize:14}}>(Separate emails will be sent to each applicant)</span>
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13,textDecoration:"underline",color:"#4293FE",cursor:"pointer"}} onClick={this.handleBCC}>CC</span>
                            </div>
                         {/** <div className="col-md-3" style={{marginLeft:"-4em"}}>
                          <div class="input-group mb-3" style={{width:"22em"}}>
                          <input style={{background:"#e9ecef",fontSize:12.5,borderLeftColor:"transparent"}} type="text" class="form-control shadow-none"  name="gettaggeddate" onChange={this.handleChange} aria-describedby="basic-addon2" />
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2" style={{color:"#007bff",fontSize:12}}>CC</span>
                          </div>
                        </div>
                          </div> */}
                         
                          </div>
{this.state.bccscreen==1?<>
  <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>BCC</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                            <textarea className="form-control"  rows="1" style={{fontSize:12,backgroundColor:"#e9ecef"}} onChange={this.handleChange}>

                            </textarea>
                         
                          
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20,textDecoration:"underline",color:"red",cursor:"pointer"}} onClick={this.handleBCCclose}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                            </div>
                         {/** <div className="col-md-3" style={{marginLeft:"-4em"}}>
                          <div class="input-group mb-3" style={{width:"22em"}}>
                          <input style={{background:"#e9ecef",fontSize:12.5,borderLeftColor:"transparent"}} type="text" class="form-control shadow-none"  name="gettaggeddate" onChange={this.handleChange} aria-describedby="basic-addon2" />
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2" style={{color:"#007bff",fontSize:12}}>CC</span>
                          </div>
                        </div>
                          </div> */}
                         
                          </div>
</>:null}

                        </div>
                       

                      </div>

                      <div className="row mt-5">
                        
                        <div className="col-xl-12">
                        <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}    
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
       
        }}
      />
                        </div>

                      </div>
                      <div className="row mt-3">
                        <div className="col-xl-2">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Attachment</span>
                        </div>
              <div className="col-xl-6" style={{border:"1px solid"}}>
                <input type="file" onChange={this.handleAttachment} name="profilepic" />

              </div>
                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleEmail}>Send</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal5} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
<span style={{color:this.state.successmsg=="Email Body Can't be empty."?"red":"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal5}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Send Email Modal ends */}
      {/**Send Email Modal For Submission starts */}
      {this.state.showModal16 && (
        <div class="ft-modal" id="ft-sendemailsubmission-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"70%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Send Email
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                            <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>From</span>
                            </div>
                          <div className="col-md-11">
                          <input type="text" value="System (jobs@maintec.in)" disabled className="form-control" />
                          </div>
                       {/**   <div className="col-md-2">
                          <button style={{color:"white",marginTop:"1%",width:"100%"}} className="btn updatebtn">Send</button>
                          </div> */}
                          </div>
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>CC</span>
                            </div>
                          <div className="col-md-7">
                          <div class="input-group mb-3">
                            <textarea className="form-control" disabled rows="3" style={{fontSize:12}} value={this.state.Emaildata.toString()}>

                            </textarea>
                         
                          
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13,color:"#4293FE",textDecoration:"underline"}}>BCC</span>
                            </div>
                         {/** <div className="col-md-3" style={{marginLeft:"-4em"}}>
                          <div class="input-group mb-3" style={{width:"22em"}}>
                          <input style={{background:"#e9ecef",fontSize:12.5,borderLeftColor:"transparent"}} type="text" class="form-control shadow-none"  name="gettaggeddate" onChange={this.handleChange} aria-describedby="basic-addon2" />
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2" style={{color:"#007bff",fontSize:12}}>CC</span>
                          </div>
                        </div>
                          </div> */}
                          <div className="col-md-3" >
                         
                         
                         <span  style={{color:"#007bff",fontSize:12}}>(Separate emails will be sent to each applicant)</span>
                      
                   
                          </div>
                          </div>

                        </div>
                       

                      </div>

                      <div className="row mt-5">
                        
                        <div className="col-xl-12">
                        <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}    
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
       
        }}
      />
                        </div>

                      </div>
                      <div className="row mt-3">
                        <div className="col-xl-2">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Attachment</span>
                        </div>
              <div className="col-xl-6" style={{border:"1px solid"}}>
                <input type="file" onChange={this.handleImage} name="profilepic" />

              </div>
                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleEmailSubmission}>Send</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal16} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
<span style={{color:this.state.successmsg=="Email Body Can't be empty."?"red":"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal16}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
   {/**Send Email Modal For Submission Ends */}
 {/**Confirmation Modal starts */}
 {this.state.showModal14 && (
        <div class="ft-modal" id="ft-confirmation-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"70%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Confirmation Details
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <span className="jobdetailstxt">Job Details</span>
                      <div className="arrow-right"></div>
                      </div>
                      <div className="row mt-3">
                      <div className="col-md-6">
                      <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Job Title</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
                      <input type="text" className="form-control"  style={{background:"#F3F0F0",border:"1px solid"}} onChange={this.handleChangetwo} name="jobtitle" />
                      <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg1}</span>
                      </div>
                      <div className="col-md-6" style={{marginTop:"-0.5%"}}>
                      <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Recruitment Manager</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
                      <Select  options={this.state.reportingtodata} onChange={(e)=>this.handleManager(e)} components={{  IndicatorSeparator:() => null }} styles={style}  />
                      <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg2}</span>
                      </div>
                      </div>
                      
                      <div className="row mt-3">
                        <span className="jobdetailstxt">Payment Details</span>
                        <div className="arrow-right"></div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Staffing Type</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
                      <Select  options={this.state.staffingdata} onChange={(e)=>this.handleStaffing(e)} components={{  IndicatorSeparator:() => null }} styles={style}  />
                      <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg3}</span>
                        </div>
                   
                      <div className="col-md-6">
                      <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Bill/Pay Rate Type</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
                      <Select  options={this.state.paymentdata} onChange={(e)=>this.handlePaytype(e)} components={{  IndicatorSeparator:() => null }} styles={style}  />
                      <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg4}</span>
                      </div>
                     
                      </div>
                     <div className="row mt-2">
                     <div className="col-md-4">
                      <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Client Bill Rate Monthly</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
                      <input type="text" name="CBillRateMonthly" className="form-control" maxLength="9" onChange={this.handleChangebillrate} style={{background:"rgb(243, 240, 240)",border:"1px solid"}} />
                      <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg5}</span>
                      </div>
                      <div className="col-md-2 mt-4">
                    
                      <input type="text" className="form-control"  value="INR" style={{background:"rgb(243, 240, 240)",border:"1px solid"}} />
                      </div>
                     </div>
                     
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleCreate} style={{color:"white",marginTop:"5%",width:"100%"}}>Create</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal14} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal14}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Confirmation Modal ends */}
      {/**Tag to other job Modal starts */}
      {this.state.showModal6 && (
        <div class="ft-modal" id="ft-tagtootherjob-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Tag To Other Job
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      

                      <div className="row mt-5">
                        <div className="col-xl-4">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:16}}>Select Job</span>

                        </div>
                        <div className="col-xl-8">
                        <Select  options={this.state.joblists} onChange={(e)=>this.handleTag(e)} components={{  IndicatorSeparator:() => null }} styles={style}  />
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleUpdateTagtoother}  style={{color:"white",marginTop:"5%",width:"100%"}}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal6} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.errormsg}</span>

</div>
           </div>
      
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal6}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Tag to other job Modal ends */}
       {/**Submit to other job Modal starts */}
       {this.state.showModal13 && (
        <div class="ft-modal" id="ft-submitootherjob-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Submit To Other Job
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      

                      <div className="row mt-5">
                        <div className="col-xl-4">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:16}}>Select Job</span>

                        </div>
                        <div className="col-xl-8">
                        <Select  options={this.state.joblists} onChange={(e)=>this.handleTagtwo(e)} components={{  IndicatorSeparator:() => null }} styles={style}  />
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleSubmittoother}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal13} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
  <span style={{color:this.state.successmsg=="Please Select a Job !"?"red":"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal13}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Tag to other job Modal ends */}
    {/**Submission Status Modal starts */}
    {this.state.showModal11 && (
        <div class="ft-modal" id="ft-submissionstatus-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Submission Status
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      

                      <div className="row mt-5">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:16}}>Status</span>

                        </div>
                        <div className="col-xl-6">
                        <Select onChange={this.handleSub} options={this.state.SubmissionStatus} components={{  IndicatorSeparator:() => null }} styles={style}  />
                        </div>

                      </div>
                      <div className="row mt-5">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:16}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleSubstatus}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal11} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal11}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Tag to other job Modal ends */}
       {/*Submit to job Modal starts */}
       {this.state.showModal7 && (
        <div class="ft-modal" id="ft-submittojob-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Submit To Job
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                  <div className="text-center">
                    <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20}}>Would you like to review the submission details before submitting?</span>
                    </div>    

                     
                     <div className="mt-5 row">
                      <div className="col-xl-4"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleSubmitJob}  style={{color:"white",marginTop:"5%",width:"100%"}}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal7} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
<div className="col-xl-4"></div>               
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal7}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Submit to job Modal ends */}

 {/*Submit to Client Modal starts */}
 {this.state.showModal12 && (
        <div class="ft-modal" id="ft-submittoclient-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"60%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Submit To Client
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
            <div className="row mt-3">
            <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>To</span>
                            </div>

                            <div className="col-xl-9">
                            <input type="text" onChange={this.handleChange} 
                            name="mailsendto"
                            className="form-control" style={{backgroundColor:"#e9ecef"}} />
                             <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>{this.state.successmsg1}</span>

                            </div>
                            
                            <div className="col-xl-1" style={{textAlign:"right"}}>
                           {this.state.ccscreen==0?<>
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14,textDecoration:"underline",color:"#4293FE",cursor:'pointer'}} onClick={this.handleCC}>CC</span>
                           </>:null}
                            </div>
                            <div className="col-xl-1">
                           {this.state.bccscreen==0?<>
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14,textDecoration:"underline",color:"#4293FE",cursor:'pointer'}} onClick={this.handleBCC}>BCC</span>
                           </>:null}
                            </div>
            </div>
            {this.state.ccscreen==1?<>
  <div className="row mt-3">
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>CC</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                          <input type="text" onChange={this.handleChange} 
                            name="mailsendcc" className="form-control" style={{backgroundColor:"#e9ecef"}} />
                         
                          
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20,textDecoration:"underline",color:"red",cursor:"pointer"}} onClick={this.handleCCclose}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                            </div>
                        
                         
                          </div>
</>:null}
            {this.state.bccscreen==1?<>
  <div className="row mt-3">
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>BCC</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                          <input type="text" onChange={this.handleChange} 
                            name="mailsendBCC" className="form-control" style={{backgroundColor:"#e9ecef"}} />
                         
                          
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20,textDecoration:"underline",color:"red",cursor:"pointer"}} onClick={this.handleBCCclose}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                            </div>
                        
                         
                          </div>
</>:null}


           <div className="row mt-3">
    <div className="col-xl-12">
           <CKEditor
                    editor={ ClassicEditor }
                  
                  data={`<p>Hi @clientname,</p><table><thead><tr><th>Full Name</th><th>Email Id</th><th>Mobile No.</th><th>Job Title</th><th>Total Experience</th></tr></thead>${this.state.submittoclient.toString()}</table>`}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        //console.log( { event, editor, data } );
                        this.setState({maildescription:data},()=>{
                          //console.log(this.state.maildescription)
                        })
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />


    </div>
  </div>  
  <div className="row mt-3">
                        <div className="col-xl-2">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Attachment</span>
                        </div>
              <div className="col-xl-6" style={{border:"1px solid"}}>
                <input type="file" onChange={this.handleImage} name="profilepic" />

              </div>
                      </div>         
                     <div className="mt-5 row">
                      <div className="col-xl-4"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleClientSubmission}>Submit</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal12} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
<div className="col-xl-4"></div>               
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal12}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Submit to Client Modal ends */}
      {/*Schedule Interview Modal starts */}
      {this.state.showModal8 && (
        <div class="ft-modal" id="ft-scheduleinterview-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"60%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Schedule Interview
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                  <div className="row" style={{background:"#e9ecef",padding:"2%",margin:"0%"}}>
                  <div className="col-xl-12">
 
 <table class="table" border="1">
   <thead style={{backgroundColor:"#4293FE",color:"white"}}>
     <tr>
       <th scope="col">Applicant Name</th>
       <th scope="col">Email ID</th>
       <th scope="col">Mobile No</th>
       <th scope="col">Job Title</th>
     </tr>
   </thead>
   <tbody style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>
     {this.state.Interviewdata.map((e,index)=>(
       <tr className={index % 2 !== 0?"accesstbl":null}>
         <td>{e.fullname}</td>
         <td>{e.EmailID}</td>
         <td>{e.MobileNo}</td>
         <td>{e.JobTitle}</td>
       </tr>
     ))}
 
   </tbody>
 </table>
 
     </div>
                  </div>
                 <div className="row mt-2">
                  <div className="col-xl-6 ">
                  <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Interview Date</span><br/>
                  <input type="date" id="inputdate" min={new Date().toISOString().split('T')[0]} name="InterviewDate" onChange={this.handleChangethree} className="form-control" style={{background:"rgb(233, 236, 239)"}}/>
                  <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg1}</span>
                  </div>
                  <div className="col-xl-6 ">
                  <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>Interview Time</span><br/>
                  <input type="time"  name="InterviewTime" onChange={this.handleChange} className="form-control" style={{background:"rgb(233, 236, 239)"}}/>
                  <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg2}</span>
                  </div>
                 </div>
                 <div className="row mt-2">
                  <div className="col-xl-6 ">
                  <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Interview Mode</span><br/>
                  <Select options={this.state.InterviewModedata} onChange={this.handleInterview} styles={styless} components={{  IndicatorSeparator:() => null }}/>
                  <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg3}</span>
                  </div>
                  <div className="col-xl-6 ">
                  <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Interview Round</span><br/>
                  <Select options={this.state.InterviewRounddata} onChange={this.handleInterviewround} styles={styless} components={{  IndicatorSeparator:() => null }}/>
                  <span style={{color:"red",fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg4}</span>
                  </div>
                 </div>
                     <div className="mt-5 row">
                      <div className="col-xl-4"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleInterviewSchedule}  style={{color:"white",marginTop:"5%"}}>Schedule</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal8} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
<div className="col-xl-4"></div>               
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal8}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/** Schedule Interview Modal ends */}
       {/**Edit Application Status Modal starts */}
       {this.state.showModal3 && (
        <div class="ft-modal" id="ft-editsubmissionstatus-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Application Status
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Status</span>

                        </div>
                        <div className="col-xl-5">
                          <Select onChange={this.handleSub} options={this.state.SubmissionStatus} components={{  IndicatorSeparator:() => null }} styles={style}  />

                        </div>

                      </div>

                      <div className="row mt-4">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row" style={{marginBottom:"5%"}}>
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleUpdatesubstatus} style={{color:"white",marginTop:"5%",width:"100%"}}>Update</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal3} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-5">
<span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.errormsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal3}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer} style={{marginLeft:"0%"}}>
       {this.state.pipelinedata==0?<>
        <div className={styles.pageBox}>
       
         
       
                        <Row>  {JSON.stringify(localStorage.getItem("SubCont"))!="null" && JSON.stringify(localStorage.getItem("PipCont"))!="null"?
         <>
         
          <Col md={2}>
         
                     <button onClick={this.handlePipelines}  type="button" class="btn  pipelinearrow pipelinebtn">
                   Pipelines <span class="badge badge-light">{this.state.rows.length}</span>
         </button>
                     
         </Col>
        
        {this.state.rows2.length==0?<> <Col md={2}>
               
                  
               <button onClick={this.handleSubmission}  type="button" class="btn btn-primary" style={{backgroundColor:"rgb(66, 147, 254)",borderRadius:"20px",fontFamily:"Poppins Light",fontWeight:"bold",width:"82%",boxShadow:this.state.submitteddata==1?"0px 1px 6px 3px grey":null,display:this.state.rows3.length==0?"none":null}}>
             Submissions <span class="badge badge-light">{this.state.rows3.length}</span>
   </button>
           
               </Col></>:<>
               {this.state.rows3.length==0?null:<>
                <Col md={2} style={{marginLeft:"1%"}}>
               
                  
                <button  type="button" class="btn  submissionbtnn submisssionarw">
                    Submissions <span class="badge badge-light">{this.state.rows3.length}</span>
          </button>
            
                </Col>
               </>}
               </>}
        
         </>
                     
                     :null}
                   {JSON.stringify(localStorage.getItem("PipCont"))!="null"?
                   <Col md={2} style={{display: JSON.stringify(localStorage.getItem("PipCont"))!="null" && JSON.stringify(localStorage.getItem("SubCont"))!="null" ?"none":"inline"}}>
                   <>
                   <button onClick={this.handlePipelines}  type="button" class="btn  pipelinearrow pipelinebtn">
                   Pipelines <span class="badge badge-light">{this.state.rows.length}</span>
         </button>
                               </>
                   </Col>
                         
                  
                     :null}
                  
                     
             
         
         {JSON.stringify(localStorage.getItem("SubCont"))!="null"?
                 <Col md={2} style={{display: JSON.stringify(localStorage.getItem("PipCont"))!="null" && JSON.stringify(localStorage.getItem("SubCont"))!="null" ?"none":"inline"}}>
                 <>
                
                   <button type="button" class="btn btn-primary" style={{backgroundColor:"rgb(66, 147, 254)",borderRadius:"20px",fontFamily:"Poppins Light",fontWeight:"bold",width:"82%"}}>
                 Submissions <span class="badge badge-light">{this.state.rows.length}</span>
         </button>
                   </>
                   </Col>
                     :null}</Row>
                   
                  {this.state.selectcheckbox==0?<>
                    <Row className={styles.marginBottom1} style={{marginTop:"2%",marginLeft:"0%"}}>
          
          <Col md={2} style={{maxWidth:"13%"}}>
              
              <div class="input-group">
    <input style={{marginBottom:"2em"}} type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button style={{marginBottom:"2em"}} class="btn btn-primary" type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
                <div className="col-md-1" style={{marginTop:"0.5%"}}>
                   {this.state.sourcescreen==0?<>

                            <span onClick={this.handleSources} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Source &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.sourcescreen==1?<>
                            <span onClick={this.handleSourcesclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Source &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            {this.state.allOptions.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox" key={e.value} value={e.value}  onClick={(e)=>this.updateStateList2(e,e.value)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            <button className="btn updatebtn" style={{color:"white"}} onClick={this.handleSourcesdisplay}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleSourcesclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%"}}>
                   {this.state.pipelinestatus==0?<>

                            <span onClick={this.handlePipeline} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Pipeline Status &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.pipelinestatus==1?<>
                            <span onClick={this.handlePipelineclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Pipeline Status &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <div style={{height:"10em",overflowX:"hidden",overflowY:'scroll'}}>
                            {this.state.SubmissionStatus.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox" key={e.value} value={e.value}  onClick={(e)=>this.updateStateList3(e,e.value)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            </div>
                            <button className="btn updatebtn" style={{color:"white"}} onClick={this.handleSourcesdisplay}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handlePipelineclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%",marginLeft:"-5%"}}>
                   {this.state.submittedby==0?<>

                            <span onClick={this.handleSubmittedby} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Tagged By &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.submittedby==1?<>
                            <span onClick={this.handleSubmittedbyclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Tagged By &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <div style={{height:"10em",overflowX:"hidden",overflowY:'scroll'}}>
                            {this.state.reportingtodata.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox" key={e.value} value={e.value}  onClick={(e)=>this.updateStateList5(e,e.value)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            </div>
                            <button className="btn updatebtn" style={{color:"white"}} onClick={this.handleSourcesdisplay}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleSubmittedbyclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%",marginLeft:"-7%"}}>
                   {this.state.taggedon==0?<>

                            <span onClick={this.handleTaggedon} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Tagged On &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.taggedon==1?<>
                            <span onClick={this.handleTaggedonclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Tagged On &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <input type="date" name="gettaggeddate" onChange={this.handleChange} className="form-control editaccess"/>
                            <button className="btn updatebtn" style={{color:"white"}} onClick={this.handleSourcesdisplay}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleTaggedonclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>

                <div className="col-md-2" style={{marginTop:"0.5%",marginLeft:"-7%"}}>
                   {this.state.noticeperiod==0?<>

                            <span onClick={this.handleNoticeperiod} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Client &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.noticeperiod==1?<>
                            <span onClick={this.handleNoticeperiodclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Client &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <div style={{height:"10em",overflowX:"hidden",overflowY:'scroll'}}>
                            {this.state.Noticeperiod.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox"  key={e.label} value={e.label}  onClick={(e)=>this.updateStateList4(e,e.label)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            </div>
                            <button className="btn updatebtn" style={{color:"white"}} onClick={this.handleSourcesdisplay}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleTaggedonclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
            
          </Row>
                  </>:<>
                  <Row style={{marginTop:"3%",marginBottom:"2%"}}>
             <div className="col-md-1" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center"}}>
             <a href="#ft-untag-modal" onClick={()=>this.openModal4()}> <span  
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:14,color:"rgb(66, 147, 254)"}} >Untag</span></a>
            
              </div>  
              <div className="col-md-1" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center"}}>
              <a href="#ft-sendemail-modal" onClick={()=>this.openModal5()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:14,color:"rgb(66, 147, 254)"}}>Send Email</span> </a>
              </div> 
              <div className="col-md-1" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center"}}>
              <a href="#ft-editpipelinestatusnew-modal" onClick={()=>this.openModal15()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13.5,color:"rgb(66, 147, 254)"}}>Update Status</span>
            </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"10%"}}>
              <a href="#ft-submittojob-modal" onClick={()=>this.openModal7()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:14,color:"rgb(66, 147, 254)"}}>Submit to Job</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"11%"}}>
              <a href="#ft-tagtootherjob-modal" onClick={()=>this.openModal6()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:14,color:"rgb(66, 147, 254)"}}>Tag to other Job</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"12%"}}>
              <a href="#ft-scheduleinterview-modal" onClick={()=>this.openModal8()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:14,color:"rgb(66, 147, 254)"}}>Schedule Interview</span>
             </a>
              </div>  
                       
          </Row>
                  </>}
        
          <div className={styles.clientContainer_old} id="data_tble" style={{marginTop:"1%"}}>
            <div className={styles.tableContainer}>
           {this.state.pipelinesearch==0 ||this.state.pipelinesearch==2?<>
            {this.state.loadingicon==0  ?<>
              <img src={LoadingIcon} style={{marginLeft:"38em",width:"5%"}}/>
              </>:<> 
              <DataTable
                
                customStyles={tableCustomStyles}
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
               
              />
             </>}
           </>:null}
              
           {this.state.pipelinesearch==1?<>
            {this.state.loadingicon==0?<>
              <img src={LoadingIcon} style={{marginLeft:"38em",width:"8%"}}/>
              </>:<> 
              <DataTable
               
               customStyles={tableCustomStyles}
               columns={this.columns3}
               data={this.state.rows2}
               pagination
               striped
               dense
              
             />
             </>}
           </>:null}
         
            </div>
          </div>
        </div>
       </>:null}
       {this.state.submitteddata==1?<>
        <div className={styles.pageBox} >
       
         
       
                        <Row>  {JSON.stringify(localStorage.getItem("SubCont"))!="null" && JSON.stringify(localStorage.getItem("PipCont"))!="null"?
         <>
         <Col md={3}>
         
                     <button onClick={this.handlePipelines}  type="button" class="btn btn-primary" style={{backgroundColor:"blueviolet",borderRadius:"20px",fontFamily:"Poppins Light",fontWeight:"bold",width:"60%",boxShadow:this.state.pipelinedata==0?"0px 1px 6px 3px grey":null,marginLeft:"4%"}}>
                   Pipelines <span class="badge badge-light">{this.state.rows.length}</span>
         </button>
                     
         </Col>
         <Col md={3} style={{marginLeft:"-9em"}}>
               
                  
                     <button onClick={this.handleSubmission}  type="button" class="btn  submissionbtn submisssionarw" >
                   Submissions <span class="badge badge-light">{this.state.rows3.length}</span>
         </button>
                 
                     </Col>
         </>
                     
                     :null}
                   {JSON.stringify(localStorage.getItem("PipCont"))!="null"?
                   <Col md={3} style={{display: JSON.stringify(localStorage.getItem("PipCont"))!="null" && JSON.stringify(localStorage.getItem("SubCont"))!="null" ?"none":"inline"}}>
                   <>
                               <button type="button" class="btn btn-primary" style={{backgroundColor:"blueviolet",borderRadius:"20px",fontFamily:"Poppins Light",fontWeight:"bold",width:"70%",marginLeft:"5%"}}>
                             Pipelines <span class="badge badge-light">{this.state.rows.length}</span>
                   </button>
                               </>
                   </Col>
                         
                  
                     :null}
                  
                     
             
         
         {JSON.stringify(localStorage.getItem("SubCont"))!="null"?
                 <Col md={2} style={{display: JSON.stringify(localStorage.getItem("PipCont"))!="null" && JSON.stringify(localStorage.getItem("SubCont"))!="null" ?"none":"inline",marginLeft:"1%"}}>
                 <>
                
                 <button   type="button" class="btn  submissionbtnn submisssionarw" >
                   Submissions <span class="badge badge-light">{this.state.rows3.length}</span>
         </button>
                   </>
                   </Col>
                     :null}</Row>
                   
                {this.state.selectcheckbox2==0?<>
                  <Row className={styles.marginBottom1} style={{marginTop:"2%",marginLeft:"0%",width:"86%"}}>
          
          <Col md={2}>
              
              <div class="input-group">
    <input style={{marginBottom:"2em"}} type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange2}
                onKeyDown={this.handleKeyDown2} />
    <div class="input-group-append">
      <button style={{marginBottom:"2em"}} class="btn btn-primary" type="button" onChange={this.handleInputChange2}
                onClick={this.handleKeyDown2}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
                <div className="col-md-1" style={{marginTop:"0.5%",}}>
                   {this.state.sourcescreen==0?<>

                            <span onClick={this.handleSources} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Source &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.sourcescreen==1?<>
                            <span onClick={this.handleSourcesclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Source &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            {this.state.allOptions.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox" key={e.value} value={e.value}  onClick={(e)=>this.updateStateList2(e,e.value)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            <button className="btn updatebtn" style={{color:"white"}} >Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleSourcesclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%"}}>
                   {this.state.pipelinestatus==0?<>

                            <span onClick={this.handlePipeline} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Application Status &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.pipelinestatus==1?<>
                            <span onClick={this.handlePipelineclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Application Status &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <div style={{height:"10em",overflowX:"hidden",overflowY:'scroll'}}>
                            {this.state.SubmissionStatus.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox" key={e.value} value={e.value}  onClick={(e)=>this.updateStateList3(e,e.value)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            </div>
                            <button className="btn updatebtn" style={{color:"white"}}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handlePipelineclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%",marginLeft:"-5%"}}>
                   {this.state.submittedby==0?<>

                            <span onClick={this.handleSubmittedby} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Submitted By &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.submittedby==1?<>
                            <span onClick={this.handleSubmittedbyclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Submitted By&nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <div style={{height:"10em",overflowX:"hidden",overflowY:'scroll'}}>
                            {this.state.reportingtodata.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox" key={e.value} value={e.value}  onClick={(e)=>this.updateStateList5(e,e.value)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            </div>
                            <button className="btn updatebtn" style={{color:"white"}} >Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleSubmittedbyclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%",marginLeft:"-7%"}}>
               
                   {this.state.taggedon==0?<>

                            <span onClick={this.handleTaggedon} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Submitted On &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.taggedon==1?<>
                            <span onClick={this.handleTaggedonclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Submitted On &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <input type="date" name="gettaggeddate" onChange={this.handleChange} className="form-control editaccess"/>
                            <button className="btn updatebtn" style={{color:"white"}} >Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleTaggedonclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
                <div className="col-md-2" style={{marginTop:"0.5%",marginLeft:"-7%"}}>
                   {this.state.noticeperiod==0?<>

                            <span onClick={this.handleNoticeperiod} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Client &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            
                            </>:null}
                            {this.state.noticeperiod==1?<>
                            <span onClick={this.handleNoticeperiodclose} style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13}}>Client &nbsp;&nbsp;&nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <div class="dropdown-contentt">
                            <div style={{height:"10em",overflowX:"hidden",overflowY:'scroll'}}>
                            {this.state.Noticeperiod.map((e)=>(
                              <>
                             
                             <div className="row">
                                <div className="col-md-12" style={{marginLeft:"3%",marginTop:"3%"}}>
                                <input type="checkbox"  key={e.label} value={e.label}  onClick={(e)=>this.updateStateList4(e,e.label)}/><span style={{fontFamily:"Poppins Light",fontWeight
                            :"bold",marginLeft:"2%",fontSize:13,verticalAlign:"text-bottom"}}>{e.label}</span>
                                </div>

                              </div>
                             
                             
                              </>
                            ))}
                            </div>
                            <button className="btn updatebtn" style={{color:"white"}} onClick={this.handleSourcesdisplay}>Search</button><button className="btn updatebtn" style={{color:"white",float:"right"}}  onClick={this.handleTaggedonclose}>Cancel</button>
                            </div>
                            
                            </>:null}
                </div>
               
            
          </Row>
                </>:<>
                <Row style={{marginTop:"3%",marginBottom:"2%"}}>
            {/** <div className="col-md-1" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"6%"}}>
             <span  
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:12,color:"rgb(66, 147, 254)"}} onClick={this.handleUntag}>Add Note</span>
              </div>   */}
              <div className="col-md-1" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"8%"}}>
              <a href="#ft-sendemail-modal" onClick={()=>this.openModal5()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)",maxWidth:"8%"}}>Send Email</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"9%"}}>
              <a href="#ft-confirmation-modal" onClick={()=>this.openModal14()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)",maxWidth:"7%"}}>Add Confirmation</span>
            </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"8%"}}>
              <a href="#ft-disqualify-modal" onClick={()=>this.openModal9()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)"}}>Disqualify</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"8%"}}>
              <a href="#ft-removesubmission-modal" onClick={()=>this.openModal10()}>  
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)"}}>Remove</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"8%"}}>
              <a href="#ft-submissionstatus-modal" onClick={()=>this.openModal11()}>  
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)"}}>Update Status</span>
             </a>
              </div>  
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"9%"}}>
              <a href="#ft-submittoclient-modal" onClick={()=>this.openModal12()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)"}}>Submit to Client</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"10%"}}>
              <a href="#ft-scheduleinterview-modal" onClick={()=>this.openModal8()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)"}}>Schedule Interview</span>
             </a>
              </div> 
              <div className="col-md-2" style={{height:"1.5em",borderRight:"2px solid",textAlign:"center",maxWidth:"10%"}}>
              <a href="#ft-submitootherjob-modal" onClick={()=>this.openModal13()}>
             <span 
             style={{fontFamily:"Poppins Light",fontWeight:"bold",cursor:"pointer",fontSize:13,color:"rgb(66, 147, 254)"}}>Submit To Other Job</span>
             </a>
              </div>        
          </Row>
                </>}  
         
          <div className={styles.clientContainer_old} id="data_tble" style={{marginTop:"1%"}}>
            <div className={styles.tableContainer}>
            <DataTable
               
                customStyles={tableCustomStyles}
                columns={this.columns3}
                data={this.state.rows3}
                pagination
                striped
                dense
               
              />
           {/** {this.state.pipelinesearch==0?<>
            
              <DataTable
               
                customStyles={tableCustomStyles}
                columns={this.columns3}
                data={this.state.rows3}
                pagination
                striped
                dense
               
              />
             </>:<>
            
             </>}
              
             {this.state.pipelinesearch==1?<>
              <DataTable
               
                customStyles={tableCustomStyles}
                columns={this.columns2}
                data={this.state.rows}
                pagination
                striped
                dense
               
              />
             </>:null} */}
            </div>
          </div>
        </div>
       </>:null}
      </div>
      </>
    );
  }
}

export default Piplinedata;
