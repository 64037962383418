import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Header from "./Header";
import { Chart } from "react-google-charts";
import LoadingIcon  from "../images/loadingicon.gif";
export const data = [
  ["Language", "Speakers (in millions)"],
  ["Career Builder", 74],
  ["Linkedin", 10],
  ["Naukri", 10],
  ["Other", 6],
];

export const options = {
  legend: "none",
  pieSliceText: "label",
  title: "Swiss Language Use (100 degree rotation)",
  pieStartAngle: 100,
};
const tableCustomStyles = {
  table: {
    style: {
     
    },
},
tableWrapper: {
  style: {
      display: "table",
    
      fontFamily:"Poppins Light",

      fontWeight:"bold",
      fontSize:"14px"
      
  },
},
headCells: {
  style: {
    fontSize:"14px",
    border:"1px solid #C9C9C9",
    padding:"1%",
    textAlign:"left"
  },
 
},
cells: {
  style: {
   textAlign:"center"
  },
  
},
}
export default class Dashboard extends React.Component {

  constructor(){
    //var username=JSON.stringify(localStorage.getItem("Username"))
   // const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let decodedemail=window.atob(data1)
   // console.log(decodedemail)
    let decodedid=window.atob(data2)
    //console.log(decodedid)
    let decodedname=window.atob(data3)
    //console.log(decodedname)
    let decodedroleid=window.atob(data5)
    //console.log(decodedroleid)
    
    
    super();
    this.state={
          user_name:decodedname,
          display:0,
          user_email:decodedemail,
          user_id:decodedid,
          encodedemail:data1,
          encodedid:data2,
          encodedname:data3,
          userroleid:decodedroleid,
          encodedroleid:data5,
          original_rows:"",
          rows:"",
          search:"",
          loadingicon:0,
          getFROMdate:"",
          getTOdate:"",
          rows2:[],
          original_rows2:"",
          loadingiconjobposting:0,
          errormsg:""


    }
    this.columns=[
      {
        name: "Recruiter's name",
        selector:row=><span className="recruitername" title={row.recruitername}>{row.recruitername}</span> ,
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9"
        }
          ,
      },
      {
        name: "Internal Submission",
        selector: "internalsubmission",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Internal Rejects",
        selector: "InternalReject",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "L1 Interview",
        selector: "l1inteview",
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "L2 Interview",
        selector: "l2inteview",
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Final Interview Completed",
        selector: "finalinteview",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Selection",
        selector: "selected",
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
     /** {
        name: "Rejected",
        selector: "rejected",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      }, */
      {
        name: "Joinees",
        selector: "Onboarding",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "No Show / Drop Joinees",
        selector: "NoShowOnboarding",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
    ]
    this.columns2=[
     
      {
        name: "Job Code",
        selector: "JobCode",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Job Title",
        selector: "JobTitle",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Internal Submission",
        selector: "internalsubmission",
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "L1 Interview",
        selector: "L1interview",
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Final Interview",
        selector: "Finalinterview",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Selection",
        selector: "Selected",
        
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Joinees",
        selector: "Onboarding",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "Pipeline",
        selector: "Pipeline",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
      {
        name: "No Show / Drop Joinees",
        selector: "NoShowOnboarding",
       
        style:{
        
          fontFamily:"Poppins Light",
          fontWeight:"bold",
          border:"1px solid #C9C9C9",
          textAlign:"center"
        }
          ,
      },
    ]
    this.handleDisplay=this.handleDisplay.bind(this)
    this.handleDisplayClose=this.handleDisplayClose.bind(this)
    this.handleLogout=this.handleLogout.bind(this)
    this.handleJob=this.handleJob.bind(this)
    this.handleUser=this.handleUser.bind(this)
    this.handleClient=this.handleClient.bind(this)
    this.handleApplicant=this.handleApplicant.bind(this)
    this.handleMaster=this.handleMaster.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.handleOnboarding=this.handleOnboarding.bind(this)
  }
  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }
  handleChange = (e) => {
    this.setState({
      getFROMdate:"",
      [e.target.name]: e.target.value,
     
    });

    
  };
  handleChangethree = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      loadingiconjobposting:0
    },()=>{
      /**Applicant Status Report API Call Starts */

    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
  const repdata={userid:withoutQuotes,getAppdashboardinfo:"getjobdashboardstatusdata",
  getFROMdate:this.state.getFROMdate,
  getTOdate:this.state.getTOdate
}
  console.log(repdata)
  fetch("https://www.mrecruit.in:5200/api/getdashboardappsttausinfo", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
        if(response.status==0){
          
          this.setState({errormsg:response.message ,loadingiconjobposting:2 });
        }
        else{
          let data=response.result
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
            
          });
          this.setState({ original_rows2: data, rows2: data,loadingiconjobposting:1 });
        }
           
            
                
            });
   
    /**Applicant Status API Call Ends */ 
    });

    
  };
  handleChangetwo = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     loadingicon:0
    },()=>{
      /**Applicant Status Report API Call Starts */

    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
  const repdata={userid:withoutQuotes,getAppdashboardinfo:"getappdashboardstatusdata",
  getFROMdate:this.state.getFROMdate,
  getTOdate:this.state.getTOdate
}
  console.log(repdata)
  fetch("https://www.mrecruit.in:5200/api/getdashboardappsttausinfo", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
        if(response.message=="No Data Found"){
          
          this.setState({errormsg:response.message ,loadingicon:1 });
        }
        else{
          let data=response.result
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
            
          });
          this.setState({ original_rows: data, rows: data,loadingicon:1 });
        }
           
            
                
            });
   
    /**Applicant Status API Call Ends */ 
    });

    
  };
  componentDidMount(){
    
/**Job Posting API Call Starts */
var username=JSON.stringify(localStorage.getItem("UserId"))
const withoutQuotes1 = username.replaceAll('"', '');
const date1 = new Date();
let day1 = date1.getDate();
let month1 = date1.getMonth() + 1;
let year1 = date1.getFullYear();
let currentDate1 = `${day1}-${month1}-${year1}`;
const repdata1={userid:withoutQuotes1,getAppdashboardinfo:"getjobdashboardstatusdata",
getFROMdate:currentDate1,
getTOdate:currentDate1
}
console.log(repdata1)
fetch("https://www.mrecruit.in:5200/api/getdashboardappsttausinfo", {
    method: "POST",

    body: JSON.stringify(repdata1), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
     console.log(response)
    if(response.status==0){
      
      this.setState({errormsg:response.message ,loadingiconjobposting:2 });
    }
    else{
      //console.log(response)
      let data=response.result
      data = data.map((currentValue, Index) => {
        currentValue.SNO = Index + 1;
        return currentValue;
        
      });
      this.setState({ original_rows2: data, rows2: data,loadingiconjobposting:1 });
    }
       
        
            
        });

/**Job Posting API Call Ends */


    /**Applicant Status Report API Call Starts */

    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  const repdata={userid:withoutQuotes,getAppdashboardinfo:"getappdashboardstatusdata",
  getFROMdate:currentDate,
  getTOdate:currentDate
}
  console.log(repdata)
  fetch("https://www.mrecruit.in:5200/api/getdashboardappsttausinfo", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
        if(response.status==0){
          
          this.setState({errormsg:response.message ,loadingicon:2 });
        }
        else{
          let data=response.result
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
            
          });
          this.setState({ original_rows: data, rows: data,loadingicon:1 });
        }
           
            
                
            });
   
    /**Applicant Status API Call Ends */
  }
  handleJob(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleApplicant(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleDisplay(){
    this.setState({display:1})
  }
  handleDisplayClose(){
    this.setState({display:0})
  }
  
  handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  
  }
  handleMaster(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleClient(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleUser(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
  }
  handleOnboarding(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
  }
  render() {
    return (
      <div>
        {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li className="litext">Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleJob}>Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleApplicant}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClient}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleid==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleid==9 || this.state.userroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
            
           
          </ul>
         <Header/>
        </div>

        {/* Header Section Ends */}

     


        {/**Dashbord Second Section starts */}
        <div className="row" style={{backgroundColor:"rgb(235, 243, 255)",padding:"1%"}}>
          <div className="col-xl-12">
          <h5 style={{fontFamily:"Poppins Light",color:"#000b4b"}}>DASHBOARD</h5><h5 style={{fontFamily:"Poppins Bold",color:"#000b4b"}}>
          {this.state.userroleid==1?<>Super Admin </>:null}
          {this.state.userroleid==3?<>Associate Account Manager </>:null}
          {this.state.userroleid==4?<>Delivery Manager</>:null}
          {this.state.userroleid==5?<>Delivery Lead</>:null}
          {this.state.userroleid==8?<>Sourcing Specialist</>:null}
          {this.state.userroleid==11?<>Management</>:null}
            {this.state.userroleid==10?<>IT Admin Dashboard </>:null}
            {this.state.userroleid==9?<>Recruiter Manager Dashboard </>:null}
            {this.state.userroleid==6?<>Recruiter Dashboard </>:null}
            </h5>
          </div>

        </div>
        <div className="row"style={{padding:"1%"}}>
          <div className="col-xl-2" style={{borderRight:"1.5px solid"}}>
            <span><i className="fa fa-star" style={{color:"rgb(0, 11, 75)"}}></i></span>
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:14,fontWeight:"bold",marginLeft:"3%",cursor:"pointer"}} onClick={this.handleJob}>Create Job Posting</span>
          </div>
          {/** <div className="col-xl-2" style={{borderRight:"1.5px solid",maxWidth:"15%"}}>
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:14,fontWeight:"bold",cursor:"pointer"}}>Create Job Template</span>
          </div> <div className="col-xl-2" style={{borderRight:"1.5px solid",maxWidth:"15%"}}>
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:14,fontWeight:"bold",cursor:"pointer"}}>Create Employee Test</span>
          </div> <div className="col-xl-2" style={{borderRight:"1.5px solid",maxWidth:"16%"}}>
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold",cursor:"pointer"}}>Create Evaluation Template</span>
          </div> <div className="col-xl-1" style={{borderRight:"1.5px solid",maxWidth:"9%"}}>
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:14,fontWeight:"bold",cursor:"pointer"}}>SetUp</span>
          </div>
          <div className="col-xl-2" >
          <input type="checkbox" style={{verticalAlign:"middle"}} className="checkboxstyle" /> <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold",cursor:"pointer"}}>&nbsp;Make as Default Dashbord</span>
          </div>*/}
         
         
          
          <div className="col-xl-2" >
            <span><i class="fa fa-briefcase" aria-hidden="true" style={{color:"rgb(0, 11, 75)"}}></i></span>&nbsp;&nbsp;
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:14,fontWeight:"bold",cursor:"pointer"}} onClick={this.handleApplicant}>Create an Applicant</span>
          </div>
          
        </div>
        {/**First Section Starts */}
        <div className="row" style={{backgroundColor:"rgb(235, 243, 255)",padding:"1%",margin:"1%"}}>
        <div className="col-xl-12" style={{backgroundColor:"rgb(235, 243, 255)",marginLeft:"-1%"}}>
         <div className="row">
          <div className="col-xl-7">
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:17,fontWeight:"bold"}}>Applicant Status - Report </span>
          <br/>
          
          </div>
        <div className="col-xl-1" style={{maxWidth:"5%",marginLeft:"11%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>From :</span>
          

        </div>
        <div className="col-xl-1" style={{marginLeft:"-2%"}}>
        <input type="date" style={{width:"auto"}}  name="getFROMdate" onChange={this.handleChange} className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
        <div className="col-xl-1" style={{maxWidth:"2%",marginLeft:"4%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>To:</span>
          

        </div>
        <div className="col-xl-1">
        <input type="date" style={{width:"auto"}} data-date-format="DD MMMM YYYY" name="getTOdate" onChange={this.handleChangetwo}  className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
         </div>
         <div className="row" style={{marginTop:"1%"}}>
          <div className="col-xl-12">
            {this.state.loadingicon==0?<>
              <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
            </>:<> </>}
         {this.state.loadingicon==1?<><DataTable
                defaultSortField="id"
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                customStyles={tableCustomStyles}
                /></>:null}
{this.state.loadingicon==2?<>
            <div className="text-center" style={{paddingBottom:"3%"}}>
            <span style={{fontSize:15,fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.errormsg}</span>
            </div>
            </>:null}
          </div>

         </div>
          </div>

        </div>
        {/**First Section Ends */}

        {/**Second Section Starts */}
        <div className="row" style={{backgroundColor:"rgb(235, 243, 255)",padding:"1%",margin:"1%"}}>
        <div className="col-xl-12" style={{backgroundColor:"rgb(235, 243, 255)",marginLeft:"-1%"}}>
         <div className="row">
          <div className="col-xl-7">
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:17,fontWeight:"bold"}}>Job Posting </span>
          <br/>
          
          </div>
          <div className="col-xl-1" style={{maxWidth:"5%",marginLeft:"11%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>From :</span>
          

        </div>
        <div className="col-xl-1" style={{marginLeft:"-2%"}}>
        <input type="date" style={{width:"auto"}}  name="getFROMdate" onChange={this.handleChange} className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
        <div className="col-xl-1" style={{maxWidth:"2%",marginLeft:"4%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>To:</span>
          

        </div>
        <div className="col-xl-1">
        <input type="date" style={{width:"auto"}} data-date-format="DD MMMM YYYY" name="getTOdate" onChange={this.handleChangethree}  className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
         
         </div>
         <div className="row" style={{marginTop:"1%"}}>
          <div className="col-xl-12">
          {/**Job Posting Data Table starts */}
          {this.state.loadingiconjobposting==0?<>
              <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
            </>:<> </>}
            {this.state.loadingiconjobposting==1?<>
              <DataTable
                defaultSortField="id"
                columns={this.columns2}
                data={this.state.rows2}
                pagination
                striped
                dense
                customStyles={tableCustomStyles}
                />
            </>:null}
            {this.state.loadingiconjobposting==2?<>
            <div className="text-center" style={{paddingBottom:"3%"}}>
            <span style={{fontSize:15,fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.errormsg}</span>
            </div>
            </>:null}
         
          {/**Job Posting Data Table Ends */}
          </div>

         </div>
          </div>

        </div>
        {/**Second Section Ends */}
        {/**Third Section Start */}
        <div className="row" style={{padding:"1%",marginTop:"-1%"}}>
          {this.state.userroleid==6?<>
          
            <div className="col-xl-12" style={{backgroundColor:"rgb(235, 243, 255)",padding:"1%",margin:"1%",maxWidth:"98%"}}>
         <div className="row">
          <div className="col-xl-7">
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:17,fontWeight:"bold"}}>Applicants by Source</span>
          <br/>
          
          </div>
          <div className="col-xl-1" style={{maxWidth:"4%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>From:</span>
          

        </div>
        <div className="col-xl-2">
        <input type="date" className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
          <div className="col-xl-1" style={{maxWidth:"3%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>To:</span>
          

        </div>
        <div className="col-xl-2">
        <input type="date" className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
        
       
         
         </div>
         
        
        <Chart
      chartType="PieChart"
      data={data}
     
      width={"100%"}
      height={"400px"}
      options={{
        backgroundColor: "transparent",
        chart: {
          title: "Spend Uplift"
        },
       
      }}
    />
         </div>
       
        
          </>:<>
          <div className="col-xl-4" style={{backgroundColor:"rgb(235, 243, 255)",padding:"1%",margin:"1%"}}>
         <div className="row">
          <div className="col-xl-12">
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:17,fontWeight:"bold"}}>Applicants by Source</span>
          <br/>
          
          </div>
         
        
       
         
         </div>
         <div className="row mt-3">
         <div className="col-xl-1" style={{maxWidth:"9%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>From:</span>
          

        </div>
        <div className="col-xl-5">
        <input type="date" className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
          <div className="col-xl-1" style={{maxWidth:"4%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>To:</span>
          

        </div>
        <div className="col-xl-5">
        <input type="date" className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
        <Chart
      chartType="PieChart"
      data={data}
     
      width={"100%"}
      height={"400px"}
      options={{
        backgroundColor: "transparent",
        chart: {
          title: "Spend Uplift"
        },
       
      }}
    />
         </div>
       
          </div>
          </>}
          
          {this.state.userroleid==6?<></>:<>
          <div className="col-xl-8" style={{backgroundColor:"rgb(235, 243, 255)",padding:"1%",margin:"1%",maxWidth:"62%"}}>
         <div className="row">
          <div className="col-xl-7">
          <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:17,fontWeight:"bold"}}>Revenue Earned</span>
          <br/>
          
          </div>
          <div className="col-xl-1" style={{maxWidth:"8%",marginLeft:"-6%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>From :</span>
          

        </div>
        <div className="col-xl-1" style={{marginLeft:"-2%"}}>
        <input type="date" style={{width:"auto"}}  name="getFROMdate"  className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
        <div className="col-xl-1" style={{maxWidth:"2%",marginLeft:"10%"}}>
        <span style={{fontFamily:"Poppins Light",color:"#000b4b",fontSize:13,fontWeight:"bold"}}>To:</span>
          

        </div>
        <div className="col-xl-1">
        <input type="date" style={{width:"auto"}} data-date-format="DD MMMM YYYY" name="getTOdate"   className="form-control" max={new Date().toISOString().split('T')[0]} />
        </div>
         </div>
         <div className="row" style={{marginTop:"5%"}}>
          <div className="col-xl-12">
          <table border="1" style={{width:"inherit",border:"1px solid #C9C9C9"}} cellPadding="10px" >
          <thead style={{backgroundColor:"#4293FE",color:"white",fontSize:14}}>
    <tr>
      <th scope="col" rowSpan="3" >Recruiter Name</th>
      <th scope="col"  rowSpan="3">Client</th>
      <th scope="col" className="text-center" colSpan="2">OnBoarding</th>
      <th scope="col" className="text-center" colSpan="4">Revenue</th>
    </tr>
    <tr>
      <th scope="col" rowSpan="2" className="text-center">FTE</th>
      <th scope="col" rowSpan="2" className="text-center">C2H</th>
      <th scope="col" className="text-center" colSpan="2">FTE</th>
      <th scope="col" className="text-center" colSpan="2">C2H</th>
    </tr>
    <tr>
     
      <th scope="col" className="text-center" >Revenue </th>
      <th scope="col" className="text-center" >Pending</th>
      <th scope="col" className="text-center" >Net Revenue </th>
      <th scope="col" className="text-center" >Gross Pending</th>
    </tr>
  </thead>
  <tbody style={{fontFamily:"Poppins Light",fontSize:12,fontWeight:"bold",background:"white"}}>
        <tr>
          <td>Senthil</td>
          <td>Maintec</td>
          <td className="text-center">12</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
        </tr>
        <tr>
        <td>Senthil</td>
          <td>Maintec</td>
          <td className="text-center">12</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          
        </tr>
        <tr>
        <td>Senthil</td>
          <td>Maintec</td>
          <td className="text-center">12</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          
        </tr>
        <tr>
        <td>Senthil</td>
          <td>Maintec</td>
          <td className="text-center">12</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center"></td>
          <td className="text-center">23</td>
          
        </tr>
        <tr>
        <td>Senthil</td>
          <td>Maintec</td>
          <td className="text-center">12</td>
          <td className="text-center"></td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          
        </tr>
        <tr>
        <td>Senthil</td>
          <td>Maintec</td>
          <td className="text-center">12</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          <td className="text-center">23</td>
          
        </tr>
       
  </tbody>
          </table>
         

          </div>

         </div>
          </div>
          </>}
          
        
        </div>
        {/**Third Section Ends */}
         {/**Dashbord Second Section ends */}
      </div>
    );
  }
}
