import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import { Chart } from "react-google-charts";
import DTable from "./DTable";
import Text1 from "../images/text1.png";
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Activeuserdatatable from "./Activeuserdatatable";
import Select from 'react-select'
import Inactiveuser from "./Inactiveuser";
import PendingUser from "./PendingUser";
import DeletedUser from "./DeletedUser";
import Swal from "sweetalert2";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
export const data = [
  ["Pizza", "Popularity"],
  ["Client 01", 33],
  ["Client 02", 26],
  ["Client 03", 20],
  ["Client 04", 20], // Below limit.
  
];

export const options = {
 
  sliceVisibilityThreshold: 0.2, // 20%,
  slices: {
    0: { color: '#010c4e' },
    1: { color: '#4293fe' },
    2: { color: '#c6c6c4' },
    3: { color: '#7fc200' }
  }
};
export default class EditUser extends React.Component{
    
  constructor(){
   
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data4=splitURL[5]
    let encodeddataid=window.atob(data1)
    let encodedid=window.atob(data2)
    let encodedemail=window.atob(data3)
    let encodedname=window.atob(data4)
    super();
    this.state={
        
        user_name:encodedname,
        user_id:encodedid,
      
          display:0,
          displayform:0,
          options : [
            { value: '1', label: 'Bangalore' },
            { value: '2', label: 'Pune' },
            { value: '3', label: 'Chennai' }
          ],
         
          on:0,
          usermanagementscreen:1,
          accesscontrolscreen:0,
          rolesscreen:0,
          userfieldscreen:0,
          teamsscreen:0,
          businessunit:[
            { value: '1', label: 'Maintec Technologies' },
           
          ],
          userstausdata:[
            { value: '1', label: 'Active'},
            {value: '2', label: 'Inactive'},
            {value: '3', label: 'Pending' },
           
          ],
          rolesdata:[],
          teamdata:[],
          reportingtodata:[],
          firstname:"",
          lastname:"",
          username:"",
          email:"",
          workinglocation:"",
          password:"",
          userrole:"",
          userrolename:"",
          userstatus:"",
          mobile:"",
          reportingto:"",
          reporting_toname:"",
          BusinessUnit:"",
          adminprivileges:"No",
          dob:"",
          doj:"",
          teamids:"",
          teamname:"",
          employeeID:"",
    }
    this.handleDisplay=this.handleDisplay.bind(this)
    this.handleDisplayClose=this.handleDisplayClose.bind(this)
    this.handleLogout=this.handleLogout.bind(this)
    this.handleJob=this.handleJob.bind(this)
    this.handleHome=this.handleHome.bind(this)
    this.handleAdd=this.handleAdd.bind(this)
    this.handleCancel=this.handleCancel.bind(this)
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleAccess=this.handleAccess.bind(this)
    this.handleUser=this.handleUser.bind(this)
    this.hanldelRoles=this.hanldelRoles.bind(this)
    this.handleUsefield=this.handleUsefield.bind(this)
    this.handleTeam=this.handleTeam.bind(this)
    this.hanldelClient=this.hanldelClient.bind(this)
    this.handleRegister=this.handleRegister.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
  }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handleRegister(){
const data={
  userid:this.state.user_id,
  adduserdata:"adduserinfo",
  firstname:this.state.firstname,
  lastname:this.state.lastname,
  username:this.state.username,
  email:this.state.email,
  workinglocation:this.state.workinglocation,
  password:this.state.password,
  userrole:this.state.userrole,
  userrolename:this.state.userrolename,
  userstatus:this.state.userstatus,
  mobile:this.state.mobile,
  reportingto:this.state.reportingto,
  reporting_toname:this.state.reporting_toname,
  BusinessUnit:this.state.BusinessUnit,
  adminprivileges:this.state.adminprivileges,
  dob:this.state.dob,
  doj:this.state.doj,
  teamids:this.state.teamids,
  teamname:this.state.teamname,
  timezoneid:1,
  timezonename:"US Time",
  employeeID:this.state.employeeID
}
console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {

          Swal.fire(
            response.message,
            
            'success'
          )
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
            3000
        );
          
           console.log(response)
  
              
            });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  hanldelClient(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedutype}/${this.state.encodedroleid}` 
  }
  handleTeam(){
    this.setState({rolesscreen:0,accesscontrolscreen:0,usermanagementscreen:0,teamsscreen:1,userfieldscreen:0})
  
  }
  handleUsefield(){
    this.setState({rolesscreen:0,accesscontrolscreen:0,usermanagementscreen:0,userfieldscreen:1,teamsscreen:0})
  
  }
  hanldelRoles(){
    this.setState({rolesscreen:1,accesscontrolscreen:0,usermanagementscreen:0,userfieldscreen:0,teamsscreen:0})
  }
  handleUser(){
    this.setState({accesscontrolscreen:0,usermanagementscreen:1,rolesscreen:0,userfieldscreen:0,teamsscreen:0})
  }
  handleAccess(){
    this.setState({accesscontrolscreen:1,usermanagementscreen:0,rolesscreen:0,userfieldscreen:0,teamsscreen:0})
  }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  handleCancel(){
    this.setState({displayform:0})
  }
  handleAdd(){
    this.setState({displayform:1})
  }
  handleJob(){
    window.location.href=`./jobposting`
  }
  handleDisplay(){
    this.setState({display:1})
  }
  handleDisplayClose(){
    this.setState({display:0})
  }
  handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
  handleHome(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedutype}/${this.state.encodedroleid}`
  }
  componentDidMount(){
    /* Roles API Call Start */
    const data={ dropdownlist_input: "getuserrole"}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });
    /* Roles API Call End */
      /* Reporting To API Call Start */
      const rdata={ dropdownlist_input: "getreportinginfo"}
      console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
      /* Reporting To  API Call End */

       /* Reporting To API Call Start */
       const tdata={ userid: this.state.user_id,
        adduserdata:"getteamsinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
       /* Reporting To  API Call End */
  }
    render(){
        return(
            <div>

               
        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li style={{cursor:"pointer"}} onClick={this.handleHome}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleJob}>Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.hanldelClient} style={{cursor:"pointer"}}>Clients</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li>Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.encodedroleid==10 || this.state.user_name=="Admin" || this.state.user_name=="Administrator"?<>
           <li style={{cursor:"pointer"}} className="litext">User Management</li>
           
           </>:<></>}
          </ul>
          <div class="socialWrap">
            <i class="fa fa-refresh icondashboard" aria-hidden="true"></i>
            <i class="fa fa-plus-square icondashboard" aria-hidden="true"></i>
            <i class="fa fa-search icondashboard" aria-hidden="true"></i>
            <i class="fa fa-bars icondashboard" aria-hidden="true"></i>
           
          {this.state.display==1?<>
            <i class="fa fa-user-circle icondashboard" onClick={this.handleDisplayClose} aria-hidden="true"></i>
          </>:<> <i class="fa fa-user-circle icondashboard" onClick={this.handleDisplay} aria-hidden="true"></i></>}
          
            <span className="username">Hi,{this.state.user_name}</span>
            {this.state.display==1?<>
              <div class="dropdown-content">
              <span onClick={this.handleLogout}><i class="fa fa-reply" aria-hidden="true"></i> Logout</span>
            </div>
            </>:<div style={{display:"none"}}>
              <span><i class="fa fa-reply" aria-hidden="true" ></i> Logout</span>
            </div>}
            
          </div>
          
        </div>
            </div>
        )
    }
}