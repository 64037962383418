import React, { Component } from "react";
import ReactPaginate from "react-paginate";
//import { PAGE_COUNT } from "./userManagement/helper";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      minRange: 0,
      maxRange: 25
    };
  }

  handlePageChange = data => {
    const { onPageChangeApplicant } = this.props;

    console.log(data)
    if(data.isBreak==true){
      //onPageChangeApplicant(data.selected+1)
    }
    else if(data.selected>=0 && data.nextSelectedPage==undefined){
      onPageChangeApplicant(data.selected+1)
    }
    else{
      onPageChangeApplicant(data.nextSelectedPage+1)
    }
     //onPageChangeApplicant(data.selected+1)
    // typeof onPageChange === "function" &&
    //   onPageChange({
    //     ...data,
    //     minRange:
    //       (limit && limit * data.selected) || PAGE_COUNT * data.selected,
    //     maxRange:
    //       (limit && limit * data.selected + limit) ||
    //       PAGE_COUNT * data.selected + PAGE_COUNT
    //   });
  };

  render() {
    const { pageCount, offset } = this.props;
    return (
 
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 20,
          boxSizing: 'border-box',
          width: '100%',
          height: '100%',
          color:"#007bff"
        }}
      >
        <ReactPaginate
          //previousLabel={"previous"}
          //nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          onClick={this.handlePageChange}
          containerClassName={"pagination align-centre"}
          pageLinkClassName={"page"}
          //activeLinkClassName={"active-pagination"}
          //forcePage={offset}
        />
        </div>
      )
   
  }
}

export default Pagination;
