import React from "react";
import Resizer from "react-image-file-resizer";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
export default class Header extends React.Component {
    constructor(){
       
               
        if (localStorage.getItem("Username") == null) {
            

          localStorage.clear()
          let data=window.location.pathname.slice(0,0);
          window.location.href=`${data}/`


         } else {
          var username=JSON.stringify(localStorage.getItem("Username"))
          
         }

        const withoutQuotes = username.replaceAll('"', '');
        var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
      var data1=splitURL[2]
      var data2=splitURL[3]
      var data3=splitURL[4]
      var data5=splitURL[5]
    let decodedemail=window.atob(data1)
    let decodedid=window.atob(data2)
    let decodedname=window.atob(data3)
    let decodedroleid=window.atob(data5)
        //console.log(decodedroleid)
        super();
        this.state={
            user_name:withoutQuotes,
            display:0,
            user_email:decodedemail,
            user_id:decodedid,
            encodedemail:data1,
            encodedid:data2,
            encodedname:data3,
            userroleid:decodedroleid,
            encodedroleid:data5,
            display:0,
            displayform:0,
            showModal2:false,
            ProfileImg:"",
            profilepic:" ",
            imagename:"",
            secondarybtn:0,
            errormsg:""
        }
this.handleMaster=this.handleMaster.bind(this)
this.handleLogout=this.handleLogout.bind(this)
this.handleDisplay=this.handleDisplay.bind(this)
this. handleDisplayClose=this. handleDisplayClose.bind(this)
this.handleUpdateImage=this.handleUpdateImage.bind(this)
    }
    handleUpdateImage(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"editsinglephotoinfo",
            profilepic:this.state.profilepic,
            imagename:this.state.imagename
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
               
             if(response.message=="Profile Pic Updated successfully"){
              const withoutQuotes = username.replaceAll('"', '');
              const data={ userid:withoutQuotes,
                adduserdata:"getsinglephotoinfo",
              
             
            }
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                    
                 //console.log(response)
                     this.setState({ProfileImg:response.result.profilepic,secondarybtn:1,errormsg:""},()=>{
                     
                            
                     })
                 
                        
                      });
             }
              
                    
             
                    
                  });
      }
    handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
    openModal2=()=>{
        this.setState({ showModal2: true,display:0 });
      }
      closeModal2 = () => {
        this.setState({ showModal2: false,display:0 });
      };
    componentDidMount(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
          const withoutQuotes = username.replaceAll('"', '');
          const pdata={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(pdata), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             console.log(response)
             if(response.result.profilepic=="BASE64"){
                this.setState({ProfileImg:""},()=>{
                 
                })
            
             }
             else{
                this.setState({ProfileImg:response.result.profilepic},()=>{
                 
                })
            
             }
                 
                    
                  });
                }
    handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      }
      handleMaster(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };
      handleImage = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              profilepic: result,
              errormsg:"Please Wait...",
              ProfileImg:result,
              imagename:file["name"],
              file,
            },()=>{
              this.handleUpdateImage()
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
  render() {
    return (
      <>
       <div class="socialWrap">
     
 <table style={{marginLeft:"auto",marginRight:"12%"}}>
            <tr>
            {/**  <th> <img src={PlusIcon} className="icondashboard"/></th>
              <th> <img src={SearchIcon} className="icondashboard"/></th>
              <th> <img src={Menues} className="icondashboard"/></th> */}
              <th >          {this.state.display==1?<>
                <img src={this.state.ProfileImg==""||this.state.ProfileImg==null?`${User}`:`${`https://www.mrecruit.in/sysload/profiledocuments/${this.state.ProfileImg}`}`} className={this.state.ProfileImg==""||this.state.ProfileImg==null?"icondashboard":"changeimg"} onClick={this.handleDisplayClose} />
          </>:<> <img src={this.state.ProfileImg==""||this.state.ProfileImg==null?`${User}`:`${`https://www.mrecruit.in/sysload/profiledocuments/${this.state.ProfileImg}`}`} className={this.state.ProfileImg==""||this.state.ProfileImg==null?"icondashboard":"changeimg"} onClick={this.handleDisplay}/></>} </th>&nbsp;&nbsp;
          <th>
          <span className="username" style={{marginLeft:"auto"}}>Hi, {this.state.user_name} </span>
            {this.state.display==1?<>
                <div class="dropdown-content" style={{marginLeft:this.state.ProfileImg==""?null:"-8em"}}>
                <i class="fa fa-user-circle" aria-hidden="true"></i>&nbsp;&nbsp;{this.state.user_name}<br/>
                <i class="fa fa-picture-o" aria-hidden="true"></i><a href="#ft-changephoto-modal" style={{cursor:"pointer",lineHeight:"30px"}} onClick={()=>this.openModal2()} className="changeprofilepic">&nbsp;&nbsp;Change Profile Photo</a><br/>
              <i class="fa fa-cog" aria-hidden="true"></i><span span  onClick={this.handleMaster} style={{cursor:"pointer",lineHeight:"30px"}}>&nbsp;&nbsp;Master Settings</span><br/>
              <span style={{lineHeight:"22px"}} onClick={this.handleLogout}><i class="fa fa-reply" aria-hidden="true"></i>&nbsp;&nbsp;Logout</span>
    
            </div>
            </>:<div style={{display:"none"}}>
              <span><i style={{lineHeight:"30px"}} class="fa fa-reply" aria-hidden="true" ></i> Logout</span>
            </div>}
          </th>
            </tr>
          </table>
          </div>
        {/**Change Profile Image Modal starts */}
        {this.state.showModal2 && (
          <div class="ft-modal" id="ft-changephoto-modal" style={{ top: "0%" }}>
            <div class="ft-modal-content" style={{ width: "43%" }}>
              <div class="ft-modal-header">
                <h5
                  class="modal-title"
                  id="exampleModalLabel"
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Change Profile Photo
                </h5>

                <hr />
              </div>
              <div class="ft-modal-body">
                <div className="row mt-1">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-3">
                    {this.state.ProfileImg =="" ||
                    this.state.ProfileImg == null  ? (
                      <>
                        <img
                          src={UserImg}
                          style={{ width: "90%", border: "2px solid #4293fe" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={`https://www.mrecruit.in/sysload/profiledocuments/${this.state.ProfileImg}`}
                          style={{ width: "80%", border: "2px solid #4293fe" }}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-xl-3">
                    <input
                      id="upload"
                      type="file"
                      name="clientlogo"
                      onChange={this.handleImage}
                      hidden
                    />
                    <label id="actual-btn" for="upload">
                      Upload
                    </label>
                  </div>
                </div>
                <div className="text-center mt-2">
                      <span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE",fontSize:14}}>{this.state.errormsg}</span>
                </div>
                <div
                  className="row mt-3"
                  style={{ marginBottom: "3%", marginTop: "4%" }}
                >
                  <div className="col-md-4"></div>
                  <div className="col-md-4" style={{ textAlign: "center" }}>
                    {this.state.secondarybtn==0?<><button
                      className="btn btn-secondary"
                      
                      disabled
                    >
                      Update
                    </button></>:<><button
                      className="btn btn-primary"
                      onClick={this.closeModal2}
                    >
                      Save
                    </button></>}
                    
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div
                  className="row"
                  style={{ marginBottom: "2%", marginTop: "2%" }}
                >
                  <div className="col-md-4"></div>
                  <div className="col-md-8">
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        color: "#4293FE",
                      }}
                    >
                      {this.state.message}
                    </span>
                  </div>
                </div>
              </div>
              <div class="ft-modal-footer">
                <a
                  class="ft-modal-close"
                  data-dismiss="modal"
                  onClick={this.closeModal2}
                >
                  <i
                    class="fa fa-times"
                    style={{ color: "black", cursor: "pointer" }}
                  ></i>{" "}
                </a>
              </div>
            </div>
          </div>
        )}
        {/**Change Profile Image Modal ends */}
      </>
    );
  }
}
