import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch
} from "react-router-dom";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink, CSVDownload } from "react-csv";
import styles from "./App.module.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

import DataTable from "react-data-table-component";

class DTable extends React.Component {
  constructor() {
    super();

    this.columns = [
      
      {
        name: "Candidate Name",
        selector: "docCategoryUID",
        sortable: true
      },
      {
        name: "Contact No",
        selector: "docCategoryName",
        sortable: true
      },
      {
        name: "Email Id",
        selector: "docCategoryUID",
        sortable: true
      },
      {
        name: "Skills",
        selector: "docCategoryName",
        sortable: true
      },
      {
        name: "Client Name",
        selector: "docCategoryName",
        sortable: true
      },
      {
        name: "Work Location",
        selector: "docCategoryName",
        sortable: true
      },
      {
        name: "Status",
        selector: "docCategoryName",
        sortable: true
      },
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
  }

  componentDidMount() {
    axios({
      method: "get",
      url: "http://localhost:8080/documentCategory/getAll",
      data: {}
    }).then(
      (response) => {
        let data = response.data.map((currentValue, Index) => {
          currentValue.SNO = Index + 1;
          return currentValue;
        });
        console.log(data);
        this.setState({ original_rows: data, rows: data });
      },
      (error) => {
        console.log(error);
        let data = [
          {
            docCategoryUID: "senthil",
            docCategoryName: "docate1",
            createdOn: "2020-01-01T00:00:00.000+00:00",
            createdBy: "noname",
            active: 0
          },
          {
            docCategoryUID: "senthil123",
            docCategoryName: "doccat2",
            createdOn: null,
            createdBy: null,
            active: 0
          },
          {
            docCategoryUID: "Candiate 1234",
            docCategoryName: "docate123",
            createdOn: "2020-01-02T00:00:00.000+00:00",
            createdBy: "noname",
            active: 0
          },
          {
            docCategoryUID:"Candiate 12345",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          },
          {
            docCategoryUID: "Candiate 123456",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          },
          {
            docCategoryUID: "Candiate 123456",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 12347",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 1234567",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 1234578",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 123459",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 123450",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 123450",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 1234510",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 12345101",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 12345102",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: "Candiate 12345103",
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
          ,
          {
            docCategoryUID: 76,
            docCategoryName: "dc1",
            createdOn: "1970-01-01T00:00:02.000+00:00",
            createdBy: "dc2",
            active: 0
          }
        ];
        data = data.map((currentValue, Index) => {
          currentValue.SNO = Index + 1;
          return currentValue;
        });
        this.setState({ original_rows: data, rows: data });
      }
    );

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).indexOf(this.state.search) >= 0
    );
    this.setState({ rows: temp_rows });
  }

  download_pdf() {
    const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("client_list.pdf");
  }

  render() {
    return (
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                 
                 {/*  <Button variant="primary" className={styles.primaryBtn}> 
                 <CSVLink
                    data={this.rows_data_for_export}
                    headers={this.columns_data_for_export}
                    filename={"client_list.csv"}
                  >
                    Excel
                  </CSVLink>
                   </Button> */}
                 
               
                <Button
                  variant="primary"
                  className={`${styles.marginLeft} ${styles.primaryBtn}`}
                  onClick={this.download_pdf}
                >
                 
                 Download as Pdf
                </Button>
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
              <DataTable
               
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DTable;
