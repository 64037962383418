import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

function Deleteuser(id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"deleteteam",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              console.log(response)
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

function EditUser(e,id){
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  let data=window.location.pathname.slice(0,12);
  let encodedid=window.btoa(id)
  //window.location.href=`${data}/edituser/${encodedid}/${data1}/${data2}/${data3}`
}

function InactiveUser(id){
  Swal.fire({
    title: 'Are you sure to Inactive the User?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Inactive it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"inactiveuser",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Inactiveted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}
class ActiveTeam extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      showModal2:false,
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      teammembersdata:[],
      firstname:"",
      lastname:"",
      username:"",
      email:"",
      workinglocation:"",
      password:"",
      userrole:"",
      userrolename:"",
      userstatus:"",
      mobile:"",
      reportingto:"",
      reporting_toname:"",
      BusinessUnit:"",
      adminprivileges:"No",
      dob:"",
      doj:"",
      teamids:"",
      teamname:"",
      employeeID:"",
      AdminPrivileges:"",
      edituserid:"",
      userstatusname:"",
      dobchange:"",
      dojchange:"",
      updatemsg:"",
      firstnametwo:"",
      userrolenametwo:"",
      teamnametwo:"",
      BussinessUnit:"",
      TLroleids:"",
      TLrolename:"",
      TLname:"",
      Description:"",
      DataPermission:"",
      tstatus:"",
      updated_by:"",
      totalmembers:"",
      
    }
   
    this.columns = [
      {
        name:"Team Name",
      cell: (e) => (
          <>
        <a  className="teamview"  href="#ft-viewteammember-modal"
                      onClick={()=>this.openModal2(e.id)} 
                      >{e.name}</a>{'     '}
        
       
        </>
      ),
      
      sortable: true,
      allowOverflow: true,
      button: true,
    },
        
          {
            name: "Business Unit",
            selector: "BussinessUnit",
            sortable: true
          },
          {
            name: "Team Lead",
            selector: "TLname",
            sortable: true
          },
          {
            name: "No of Members",
            selector: "totalmembers",
            sortable: true
          },
          
        
          {
            name:"Action",
          cell: (e) => (
              <>
            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(e.id)} 
                          style={{width:"26%",fontSize:13,backgroundColor:"green",padding:"0% 7% 4% 6%",fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><i className="fa fa-edit" ></i></a>{'     '}
            <span   onClick={() =>Deleteuser(e.id)}  style={{width:"26%",fontSize:13,backgroundColor:"red",padding:"0% 7% 4% 6%",fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><i className="fa fa-trash" onClick={() =>Deleteuser(e.id)}></i></span>{'     '}
           
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleUpdateone=this.handleUpdateone.bind(this)
  }
  handleUpdateone(){
    if(this.state.teamname==""||this.state.teamname==null){
      Swal.fire(
        "Please Enter Team Name"
        
      )
    }
    else if(this.state.BussinessUnit==""||this.state.BussinessUnit==null){
      Swal.fire(
        "Please Select Business Unit"
        
      )
    }
    else if(this.state.TLrolename==""||this.state.TLrolename==null){
      Swal.fire(
        "Please Select Team Role"
        
      )
    }
    else if(this.state.TLroleids==""||this.state.TLroleids==null){
      Swal.fire(
        "Please Select Team Lead"
        
      )
    }
    else if(this.state.Description==""||this.state.Description==null){
      Swal.fire(
        "Please Enter Description"
        
      )
    }
    else if(this.state.DataPermission==""||this.state.DataPermission==null){
      Swal.fire(
        "Please Choose Data Permission"
        
      )
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes1 = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes1,
        adduserdata:"editteaminfo",
        editteamid:this.state.editteamid,
        teamname:this.state.teamname,
        BussinessUnit:this.state.BussinessUnit,
        TLroleids:this.state.TLroleids,
        TLrolename:this.state.TLrolename,
        TLids:this.state.TLroleids,
        TLname:this.state.TLname,
        Description:this.state.Description,
        DataPermission:this.state.DataPermission
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                
                this.setState({updatemsg:response.message})
                setTimeout(
                  function() {
                   
                    window.location.reload()
                  }
                  .bind(this),
                  2500
              );
        
                    
                  });
    }
    
      }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes1 = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes1,
      edituserid:this.state.edituserid,
      adduserdata:"edituserinfo",
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userrolename:this.state.userrolename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:this.state.BusinessUnit,
      adminprivileges:this.state.AdminPrivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      teamname:this.state.teamname,
      timezoneid:1,
      timezonename:"US Time",
      employeeID:this.state.employeeID
    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
    
              this.setState({updatemsg:response.message})
            setTimeout(
              function() {
               
                window.location.reload()
              }
              .bind(this),
              2500
          );
               console.log(response)
      
                  
                });
      }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editteamid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,adduserdata:"getsingleteamsinfo",editteamid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              BussinessUnit:e.BussinessUnit,
              TLrolename:e.TLrolename,
              TLroleids:e.TLids,
              TLname:e.TLname,
              Description:e.Description,
              DataPermission:e.DataPermission,
              tstatus:e.tstatus,
              updated_by:e.updated_by,
              teamname:e.name,
              //totalmembers:e.totalmembers

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false,showModal2:false });
  };
openModal2(id){
  
  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotes = username.replaceAll('"', '');
    const data={ userid:withoutQuotes,adduserdata:"getteammemberinfo",editteamid:id}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
        console.log(response)
    if(response.message=="No Teams Found"){

    }
    else{
      this.setState({teammembersdata:response.result,showModal2: true},()=>{
        //console.log(this.state.teammembersdata)
      })
      
    }
        
      
              
            });
}
  componentDidMount() {
    const rodata={ dropdownlist_input: "getuserrole"}
    console.log(rodata)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(rodata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });

      const rdata={ dropdownlist_input: "getreportinginfo"}
      console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes1 = username.replaceAll('"', '');
       const tdata={ userid: withoutQuotes1,
        adduserdata:"getteamsinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,adduserdata:"getteamsinfo",statuscode:1}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data });
              
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );
    this.setState({ rows: temp_rows });
  }
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
   
    let data=this.props.user_id;
console.log(data)
    return (
      <>{this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content" style={{width:"60%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Team Information
                  </h5>
              <hr/>
           
            </div>
            <div class="ft-modal-body">
           
            <div>
 
     <div className="row mt-3">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Business Unit</span>
  <Select 
  placeholder={this.state.BussinessUnit}
    onChange={(e)=>this.handleBusiness(e)}
    options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Name</span>
  <input type="text" value={this.state.teamname} placeholder="Team Name" onChange={this.handleChange} className="form-control mt-2 adduser" name="teamname" />
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Lead</span>
  <Select 
  placeholder={this.state.TLrolename}
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
     </div>
     <div className="row mt-3">
      <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Role</span>
      <Select 
      placeholder={this.state.TLname}
    onChange={(e)=>this.handleRoles(e)}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
      </div>
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Description</span>
  <textarea value={this.state.Description} style={{height:"37px"}} rows="1" className="form-control adduser mt-2" placeholder="Description" 
  name="Description" onChange={this.handleChange}>
   </textarea>
  </div>
 
     </div>
     <div className="row mt-3">
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Data Access Permissions
  </span><br/>
  {this.state.DataPermission==1?<>
    <input type="radio" checked value="1" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Data is shared across the team members</span>
  </>:<> <input type="radio" value="1" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Data is shared across the team members</span></>}
  
  <br/>
  {this.state.DataPermission==2?<>
    <input type="radio" checked value="2" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Only team lead can view the data of team members</span>
  </>:<><input type="radio" value="2" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Only team lead can view the data of team members</span></>}
  
  </div>
 
     </div>
 
     <div className="row mt-3">
  <div className="col-xl-5">

  </div>

<div className="col-xl-2">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdateone}>Update</button>
</div>
  </div>
  <div className="row mt-3">
  <div className="col-xl-4">

  </div>

<div className="col-xl-7">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.updatemsg}</span>
</div>
  </div>
  
</div>
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}

{this.state.showModal2 && (
        <div class="ft-modal" id="ft-viewteammember-modal">
          <div class="ft-modal-content" style={{width:"45%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Memebrs Of Team
                  </h5>
              <hr/>
           
            </div>
            <div class="ft-modal-body" style={{marginBottom:"5%",height:this.state.teammembersdata.length>=10?"20em":"auto",overflowY:"scroll"}}>
            <table border="1" style={{width:"-webkit-fill-available"}}>
              <thead><tr>
              <th colSpan="2" style={{background:"#007bff",textAlign:"center"}}><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:20,textAlign:"center",color:"white",}}>Team Memebrs</span></th>
            </tr></thead>
            <tbody style={{textAlign:"center"}}>
              
              {this.state.teammembersdata.map((e)=>(
    <tr>
     <td><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{e.firstname}</span></td>
     
    
      <td><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{e.userrolename}</span></td>
      
      
      </tr>
     ))}
              
            </tbody>
    
            </table>
           
           
          
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button style={{marginBottom:"2em"}} class="btn btn-primary" type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
           
              <DataTable
                defaultSortField="id"
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                
              />
            
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default ActiveTeam;
