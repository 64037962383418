import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import PencilIcon  from "../images/pencilicon.png";
import DeleteIcon  from "../images/deleteicon.png";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
const data = [
  {
      id: 1,
      title: 'Beetlejuice',
      year: '1988',
  },
  {
      id: 2,
      title: 'Ghostbusters',
      year: '1984',
  },
]
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

function Deleteclient(id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addjobpostingdata: "deletejobposting",editjobid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

function EditUser(e,id){
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  let data=window.location.pathname.slice(0,12);
  let encodedid=window.btoa(id)
  //window.location.href=`${data}/edituser/${encodedid}/${data1}/${data2}/${data3}`
}

function InactiveUser(id){
  Swal.fire({
    title: 'Are you sure to Inactive the User?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Inactive it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"inactiveuser",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Inactiveted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}
const tableCustomStyles = {
  table: {
    style: {
    },
},
tableWrapper: {
    style: {
        display: "table",
       
    },
},
responsiveWrapper: {
    style: {},
},

}
class DeletedApplicant extends React.Component {
  constructor() {
   
    super();
    this.state = {
      
      display: 0,
  
      displayform: 0,
    
      newjob: 0,
      jobpostingscreen: 0,
      countrydata: [],
      statedata: [],
      citydata: [],
      userstausdata: [{ value: "1", label: "Active" }],
      PrefCountryID: "",
      PrefCountryName: "",
      PrefStateID: "",
      PrefStateName: "",
      PrefCityName: "",
      PrefCityID: "",
      clientdata: [],
      HiringManager: "",
      HiringManagerid: "",
      reportingtodata: [],
      SPOCMobile: "",
      SPOCName: "",
      Assingnedid: [],
      businessunit: [{ value: "1", label: "Role" }],
   
      JobTitle: "",
      ClientName: "",
      BusinessUnit: "",
      languagesdata: [],
      degreedata: [],
      ClientId: "",
      CBR_Coin: "INR",
      PR_Coin: "INR",
      PR_MinAmount: "",
      PR_MaxAmount: "",
      ClientJobID: "CJ505",
      NoofPositions: "",
      ClosedPostions: "",
      JobCreatedDate: "",
      JobClosingDate: "",
      SPOC: "",
      HiringManagerid: "",
      HiringManager: "",
      NoticePeriod: "",
      JobDescription: "",
      DegreeID: "",
      Degreename: "",
      ExperienceMin: "",
      ExperienceMax: "",
      PrimarySkills: "",
      Languages: "",
      JobStatus: "",
      SPOCMobile: "",
      SPOCName: "",
      JobCode: "",
      AssingnedID: "",
      AssingnedName: "",
      Assingnedname: [],
      ProfilePhoto: " ",
      documentscreen:0,
      editAppliantid:"",
      personaldetailscreen:0,
      fullnamedata:[
        { value: '1', label: 'Mr.'},
        { value: '2', label: 'Mrs.'},
     
       
      ],
      Genderdata:[
        { value: '1', label: 'Male'},
        { value: '2', label: 'Female'},
        {value: '3', label: 'Other'},
     
       
      ],
      sourcedata:[
        { value: '1', label: 'Naukri'},
        { value: '2', label: 'Monster'},
        { value: '3', label: 'Other'},
       
      ],
      docdata:[
        { value: '1', label: 'PAN'},
        { value: '2', label: 'Aadhar'},
        { value: '3', label: 'Other'},
       
      ],
      ApplicantName:"",
      Gender:"",
      EmailID:"",
      AlternateEmail:"",
      MobileNo:"",
      AlternateMobile:"",
      DateofBirth:"",
      PreviousOrg:"",
      PrevDesignation:"",
      TotalExp:"",
      RelevantExp:"",
      HighestDegree:"",
      CurrentCTC:"",
      ExpectedCTC:"",
      VariableAmount:"",
      FixedAmount:"",
      Address:"",
     
      SourceID:"",
      SourceName:"",
      JobTitle:"",
      Availablity:"",
      NationalityID:"",
      NationalityName:"",
      GovtIDType:"",
      GovtIDNumber:"",
      IndustryID:"",
      IndustryName:"",
      linkedInurl:"",
      KeySkills:"",
      NoticePeriod:"",
      Remarks:"",
      Honorific:"",
      Relocate:"",
      updatemsg:"",
      nationdata:[
        { value: '1', label: 'Indian'},
        { value: '2', label: 'Muslim'},
        { value: '3', label: 'Other'},
       
      ],
      industrydata:[]
    };
   
    this.columns = [
        
          {
            name: "Applicant Name",
            selector: "ApplicantName",
            sortable: true
          },
        
          {
            name: "Email",
            selector: row =><span className="keyskills" title={row.EmailID}>{row.EmailID}</span>,
            sortable: true,
            width:"14%",
            style:{
              whiteSpace:"nowrap",
             
              textOverflow: "clip",
            
              fontFamily:"Poppins Light",
              fontWeight:"bold"
            }
          },
          {
            name: "MobileNo",
            selector: "MobileNo",
            sortable: true
          },
         
          {
            name: "Exp",
            selector: "experience",
            sortable: true
          },
          {
            name:"Current Location",
           
       
              cell: (e) => (
               
                  <>
                    {e.location==null?<>
                    <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>Not Available</span>
                    </>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.location}</span></>}
    
                </>),
        
       
        },
        {
          name:"Previous Location",
         
     
            cell: (e) => (
             
                <>
                  {e.preferred_location==null?<>
                  <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>Not Available</span>
                  </>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.preferred_location}</span></>}
    
              </>),
      
       
      },
          {
            name: "Source Name",
            selector: "SourceName",
            sortable: true
          },
         
          {
            name: "Created By",
            selector: "CreatedByname",
            sortable: true
          },
         
         
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.hanldelState=this.handleState.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleRequired=this.handleRequired.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleCategory=this.handleCategory.bind(this)
    this.handleDocument=this.handleDocument.bind(this)
    this.handleFullName=this.handleFullName.bind(this)
    this.handleGender=this.handleGender.bind(this)
    this.handleSource=this.handleSource.bind(this)
    this.handleNationality=this.handleNationality.bind(this)
    this.handleDoc=this.handleDoc.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleSave=this.handleSave.bind(this)
  }
  handleIndustry(e){
    this.setState({IndustryID:e.value,IndustryName:e.label})
  }
  handleDoc(e){
    this.setState({GovtIDType:e.label})
  }
  handleNationality(e){
this.setState({NationalityID:e.value,NationalityName:e.label})
  }
  handleSource(e){
   this.setState({SourceID:e.value,SourceName:e.label}) 
  }
  handleGender(e){
this.setState({Gender:e.label})
  }
  handleFullName(e){
this.setState({Honorific:e.label})
  }
  handleDocument(){
    this.setState({documentscreen:1})
  }
  handleCategory(e){
    this.setState({CategoryName:e.label,categoryid:e.value},()=>{
     
    })
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleSave() {
    var username = JSON.stringify(localStorage.getItem("UserId"));
    const withoutQuotes = username.replaceAll('"', "");
    const data = {
      userid: withoutQuotes,
      addAppliantdata: "editApplicantinfo",
      editapplicantid:this.state.editAppliantid,
      ApplicantName:this.state.ApplicantName,
      ProfilePhoto:"this.state.ProfilePhoto",
      Gender:this.state.Gender,
      EmailID:this.state.EmailID,
      AlternateEmail:this.state.AlternateEmail,
      MobileNo:this.state.MobileNo,
      AlternateMobile:this.state.AlternateMobile,
      DateofBirth:this.state.DateofBirth,
      PreviousOrg:this.state.PreviousOrg,
      PrevDesignation:this.state.PrevDesignation,
      TotalExp:this.state.TotalExp,
      RelevantExp:this.state.RelevantExp,
      HighestDegree:this.state.HighestDegree,
      CurrentCTC:this.state.CurrentCTC,
      ExpectedCTC:this.state.ExpectedCTC,
      VariableAmount:this.state.VariableAmount,
      FixedAmount:this.state.FixedAmount,
      Address:this.state.Address,
      PrefCountryID:this.state.PrefCountryID,
      PrefCountryName:this.state.PrefCountryName,
      PrefStateID:this.state.PrefStateID,
      PrefStateName:this.state.PrefStateName,
      PrefCityID:this.state.PrefCityID,
      PrefCityName:this.state.PrefCityName,
      SourceID:this.state.SourceID,
      SourceName:this.state.SourceName,
      JobTitle:this.state.JobTitle,
      Availablity:this.state.Availablity,
      NationalityID:this.state.NationalityID,
      NationalityName:this.state.NationalityName,
      GovtIDType:this.state.GovtIDType,
      GovtIDNumber:this.state.GovtIDNumber,
      IndustryID:this.state.IndustryID,
      IndustryName:this.state.IndustryName,
      linkedInurl:this.state.linkedInurl,
      KeySkills:this.state.KeySkills,
      NoticePeriod:this.state.NoticePeriod,
      Remarks:this.state.Remarks,
      Honorific:this.state.Honorific,
      Relocate:this.state.Relocate

    };
    console.log(data);
    fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      method: "POST",

      body: JSON.stringify(data), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
    
        this.setState({updatemsg:response.message})
        setTimeout(
          function() {
           
            window.location.reload()
          }
          .bind(this),
          2500
      );
        console.log(response);
      });
  }
  handleUserstatus(e){
    this.setState({cstatus:e.value}) 
  }
  handleIndustry(e){
    this.setState({Industryname:e.label,Industryid:e.value})
  }
  handleRequired(e){
    this.setState({ReqDocument:e.value})
  }
  handleReport(e){
    this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
  }
  handleCity(e){
    this.setState({Cityname:e.label,Cityid:e.value})
  }
  handleState(e){
    this.setState({Statename:e.label,Stateid:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      })
  }
  handleCountry(e){
    this.setState({Countryname:e.label,Countryid:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }

  handleCancel(){
    this.setState({displayform:0})
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (ApplicantID) => {
   
    this.setState({editAppliantid:ApplicantID})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addAppliantdata:"getsingleApplicant",editAppliantid:ApplicantID}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
            
              Honorific:e.Honorific,
              fullname:e.fullname,
              Gender:e.Gender,
              EmailID:e.EmailID,
              AlternateEmail:e.AlternateEmail,
              MobileNo:e.MobileNo,
              AlternateMobile:e.AlternateMobile,
              DateofBirth:e.DateofBirth,
              PreviousOrg:e.PreviousOrg,
              PrevDesignation:e.PrevDesignation,
              TotalExp:e.TotalExp,
              RelevantExp:e.RelevantExp,
              HighestDegree:e.HighestDegree,
              CurrentCTC:e.CurrentCTC,
              ExpectedCTC:e.ExpectedCTC,
              VariableAmount:e.VariableAmount,
              FixedAmount:e.FixedAmount,
              Address:e.Address,
              CountryID:e.CountryID,
              CountryName:e.CountryName,
              StateID:e.StateID,
              StateName:e.StateName,
              CityID:e.CityID,
              CityName:e.CityName,
              SourceID:e.SourceID,
              SourceName:e.SourceName,
              JobTitle:e.JobTitle,
              Availablity:e.Availablity,
              NationalityID:e.NationalityID,
              NationalityName:e.NationalityName,
              Relocate:e.Relocate,
              GovtIDType:e.GovtIDType,
              GovtIDNumber:e.GovtIDNumber,
              IndustryID:e.IndustryID,
              IndustryName:e.IndustryName,
              linkedInurl:e.linkedInurl,
              KeySkills:e.KeySkills,
              NoticePeriod:e.NoticePeriod,
              Remarks:e.Remarks,
              AppStatus:e.AppStatus,
              AppStatusnam:e.AppStatusname,
              PrefCityID:e.CityID,
              PrefCityName:e.CityName,
              PrefCountryID:e.CountryID,
              PrefCountryName:e.CountryName,
              PrefStateID:e.StateID,
              PrefStateName:e.StateName,
              ProfilePhoto:e.ProfilePhoto,
              ApplicantName:e.fullname

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
     /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */
    /* Reporting To API Call Start */
    const redata = { dropdownlist_input: "getreportinginfo" };
    console.log(redata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(redata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.reportingid,
          label: d.reportingname,
        }));

        this.setState({ reportingtodata: options });
      });

    /* Reporting To  API Call End */

    /* Language API Call Start */
    const ledata = { dropdownlist_input: "getlanguagesinfoinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(ledata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ languagesdata: options });
      });

    /*Language  API Call End */

    /* Degree API Call Start */
    const degdata = { dropdownlist_input: "getdegreesinfo" };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(degdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.id,
          label: d.name,
        }));

        this.setState({ degreedata: options });
      });

    /*Language  API Call End */
    /* Country API Call Start */
    const tdata = { dropdownlist_input: "getcountryinfo" };
    console.log(tdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.countryid,
          label: d.conuntryname,
        }));

        this.setState({ countrydata: options });
      });
    /* Country API Call  End */

    /* Client API Call Start */
    const cdata = { dropdownlist_input: "getclientDropinfo" };
    console.log(cdata);
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(cdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.clientid,
          label: d.clientname,
        }));

        this.setState({ clientdata: options });
      });
    /* Client API Call  End */
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:4}
    console.log(repdata)
    fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
         if(response.message=="No Data Found."){
          this.setState({ original_rows: [], rows: [] });
         }
         else{
          let data=response.result
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
          });
          this.setState({ original_rows: data, rows: data });
          
       
         }
        });   
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).indexOf(this.state.search) >= 0
    );
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
   
 
    return (
      <>{this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content  bigmodalcontent">
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Applicant Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            <div className="row">
             

                <div
                  className="col-xl-12"
                
                >
                  {/** Personal Details Starts */}
                 
                  <div className="row">
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Candidate Full Name</span>
                        <Select 
       onChange={(e)=>this.handleFullName(e)}
       placeholder={this.state.Honorific}
       options={this.state.fullnamedata}   components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>   
                    </div>
                    
                    <div className="col-xl-3">
                      <br/>
                    <input name="ApplicantName" 
                    value={this.state.ApplicantName}
                    onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" placeholder="Enter Full Name"/>    
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}} >Gender</span> <br/>
                    <Select 
                    placeholder={this.state.Gender}
       onChange={(e)=>this.handleGender(e)}
       options={this.state.Genderdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>   
                    </div>
                    
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Email ID</span>
                    <input style={{height:"38px"}} 
                    value={this.state.EmailID}
                    name="EmailID" onChange={this.handleChange} className="form-control adduser mt-1" type="email" placeholder="Email ID"/> 
                    </div>
                  </div>
                  <div className="row mt-2">
                 
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Email ID</span>
                    <input name="AlternateEmail"
                    value={this.state.AlternateEmail} onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="email" placeholder="Alternate Email ID"/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Mobile Number</span>
                    <input name="MobileNo" onChange={this.handleChange} style={{height:"38px"}} className="form-control 
                    
                    adduser mt-1"value={this.state.MobileNo} type="email" placeholder="Mobile Number"/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Mobile Number</span>
                    <input name="AlternateMobile" 
                    value={this.state.AlternateMobile}
                    onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="email" placeholder="Alternate Mobile Number"/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Date Of Birth</span>
                    <input   value={this.state.DateofBirth} name="DateofBirth" onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="date"/>  
                    </div>
                  </div>
                  <div className="row mt-2">
                  
                  
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>LinkedIn Profile URL</span>
                    <input name="linkedInurl" value={this.state.linkedInurl}  onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter LinkedIn URL"/>  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Nationality</span>
                    <Select 
                    onChange={(e)=>this.handleNationality(e)}
                    placeholder={this.state.NationalityName}
                    options={this.state.nationdata}  components={{  IndicatorSeparator:() => null }}
                                              styles={style}/>    
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Source</span>
                    <Select 
                    placeholder={this.state.SourceName}
       onChange={(e)=>this.handleSource(e)}
       options={this.state.sourcedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>    
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Industry</span>
                    <Select
                    placeholder={this.state.IndustryName} 
       onChange={(e)=>this.handleIndustry(e)}
       options={this.state.industrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                    </div>
                  </div>
                  <div className="row mt-2">
                
                   
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Title</span>
                    <input style={{height:"38px"}}
                    value={this.state.JobTitle}
                    name="JobTitle" onChange={this.handleChange}
                    className="form-control adduser mt-1" placeholder="Job Title"/> 
                    </div>
                    <div className="col-xl-3 mt-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Open To Reclocate</span><br/>
                    <input type="radio"  name="Relocate" onChange={this.handleChange}  value="Yes"/>&nbsp;&nbsp;Yes&nbsp;&nbsp;
                    <input type="radio" className="mt-2" name="Relocate" onChange={this.handleChange} value="No" />&nbsp;&nbsp;No
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Previous Organization</span>
                    <input className="form-control adduser mt-1"
                    value={this.state.PreviousOrg} 
                    name="PreviousOrg" onChange={this.handleChange}
                    placeholder="Previous Organization" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Key Skills</span>
                    <input  value={this.state.KeySkills}  name="KeySkills" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Key Skills" style={{height:"38px"}} />  
                    </div>
                  </div>
                  <div className="row mt-2">
              
                 
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Notice Period</span>
                    <input value={this.state.NoticePeriod}  name="NoticePeriod" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Notice Period" style={{height:"38px"}}/> 
                    </div>
                            <div className="col-xl-3">
                            <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Availablity</span>
                    <input value={this.state.Availablity}  name="Availablity" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Availablity" style={{height:"38px"}} />
                            </div>
                  
                            <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Previous Designation</span>
                    <input  value={this.state.PrevDesignation}  className="form-control adduser mt-1"
                    name="PrevDesignation" onChange={this.handleChange} 
                    placeholder="Previous Designation" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Total Experience</span>
                    <input value={this.state.TotalExp}   name="TotalExp" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Total Experience" style={{height:"38px"}} />  
                    </div>
                  </div>
                  <div className="row mt-2">
                 
                   
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Relevant Experience</span>
                    <input value={this.state.RelevantExp}  name="RelevantExp" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Relevant Experience" style={{height:"38px"}}/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Highest Degree</span>
                    <input value={this.state.HighestDegree} name="HighestDegree" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Highest Degree" style={{height:"38px"}}/>
                    </div>
                  
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current CTC</span>
                    <input value={this.state.CurrentCTC}  name="CurrentCTC" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Current CTC" style={{height:"38px"}} />  
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Expected CTC</span>
                    <input  value={this.state.ExpectedCTC} name="ExpectedCTC" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Expected CTC" style={{height:"38px"}} />  
                    </div>
                  </div>
                  <div className="row mt-2">
                
                   
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Variable Amount</span>
                    <input value={this.state.VariableAmount} name="VariableAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="VariableAmount" style={{height:"38px"}}/> 
                    </div>
                    <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Fixed Amount</span>
                    <input value={this.state.FixedAmount} name="FixedAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Fixed Amount" style={{height:"38px"}}/>
                    </div>
                  
                    <div className="col-xl-2">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Type</span>
                            <Select 
                            placeholder={this.state.GovtIDType}
              onChange={(e)=>this.handleDoc(e)}
              options={this.state.docdata}  components={{  IndicatorSeparator:() => null }}
                                        styles={style}/>
                    </div>
                    <div className="col-xl-2">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Number</span>
                    <input value={this.state.GovtIDNumber} name="GovtIDNumber" onChange={this.handleChange}  className="form-control adduser mt-1" placeholder="GovtID Number" style={{height:"38px"}}/> 
                    </div>
                    <div className="col-xl-2">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Fixed Amount</span>
                    <input value={this.state.FixedAmount} name="FixedAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Fixed Amount" style={{height:"38px"}}/>
                    </div>
                  </div>
                
                  <div className="row mt-2">
                    <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Preferred Location</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                 <div className="col-xl-4">
                 <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Country</span>
                    <Select 
                    placeholder={this.state.CountryName}
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                 </div>
                 <div className="col-xl-4">
                 <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>State</span>
                    <Select 
                    placeholder={this.state.StateName}
       onChange={(e)=>this.handleState(e)}
       options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                 </div>
                 <div className="col-xl-4">
                 <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>City</span>
                    <Select 
       onChange={(e)=>this.handleCity(e)}
       placeholder={this.state.CityName}
       options={this.state.citydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                 </div>
                
                  </div>
                  <div className="row mt-2">
                    <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Address</span>
                     <textarea  placeholder={this.state.Address} name="Address" onChange={this.handleChange} className="adduser form-control mt-1" rows="2">

                     </textarea>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Remarks</span>
                     <textarea placeholder={this.state.Remarks} name="Remarks" onChange={this.handleChange} className="adduser form-control mt-1" rows="2">

                     </textarea>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-10" >
                            
                    </div>
                            <div className="col-xl-1" style={{textAlign:"right"}}>
                            <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={this.handleSave}>Save</button>
                            </div>
                            <div className="col-xl-1" style={{textAlign:"right"}}>
                            <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}}>Reset</button>
                            </div>
                  </div>
                  <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
                  {/**Personal Details Ends*/}
                </div>
              </div>
         
        
      
        {/*New Applicant End*/}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
           
              <DataTable
                defaultSortField="id"
                customStyles={tableCustomStyles}
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                
              />
            
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default DeletedApplicant;
