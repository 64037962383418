import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import UserImg from "../images/usering.png";
import 'react-best-tabs/dist/index.css';
import Resizer from "react-image-file-resizer";
import User from "../images/user.png";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import LoadingIcon  from "../images/loadingicon.gif";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PencilIcon  from "../images/pencilicon.png";
import CrossIcon  from "../images/deleteicon.png";
import Header from "./Header";
import Select from 'react-select'
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
const tableCustomStyles = {
    table: {
      style: {
       
      },
  },
  tableWrapper: {
      style: {
          display: "table",
          width:"100%",
          fontFamily:"Poppins Light",
          
      },
  },
  responsiveWrapper: {
      style: {},
  },
  headCells: {
    style: {
      fontSize:"13px",
    },
   
  },
  }
  const tableCustomStyles2 = {
    table: {
      style: {
       
      },
  },
  tableWrapper: {
      style: {
          display: "table",
          width:"100%",
          fontFamily:"Poppins Light",
          
      },
  },
  responsiveWrapper: {
      style: {},
  },
  headCells: {
    style: {
      fontSize:"13px",
    },
   
  },
  }
  function ViewApplicant(id){
 
    let data=window.location.pathname.slice(0,0);
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let encodedjobid=window.btoa(id)
    window.location.href=`/viewapplicant/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
  }
export default class Onboardings extends React.Component {

  constructor(){
    if (localStorage.getItem("Username") == null) {
            

      localStorage.clear()
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/`


     } else {
      var username=JSON.stringify(localStorage.getItem("Username"))
      
     }
    const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let decodedid=window.atob(data1)
    let decodedname=window.atob(data2)
    let decodedemail=window.atob(data3)
  
    let decodedroleid=window.atob(data5)
    
    super();
    this.state={
          encodedid:data1,
          encodedname:data2,
          encodedemail:data3,
         user_email:decodedemail,
          user_name:decodedname,
          user_id:decodedid,
          roleid:data5,
          encodedroleid:decodedroleid,
          display:0,
          displayform:0,
          usermanagementscreen:1,
          accesscontrolscreen:0,
          rolesscreen:0,
          showModal: false,
          showModal2: false,
          showModal3:false,
          original_rows: [], 
          rows: [], 
          search: "" ,
        loadingicon:0,
        onboardingdata:[{ value: "Onboarded", label: "Onboarded" },
                        { value: "No Show", label: "No Show" },
      ]
        }
        this.columns = [
      
          {
            name:"Candidate Name",
      selector: row =><span onClick={() =>ViewApplicant(row.applicantid)} style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:13}}>{row.applicantname}</span>,
         
        
          sortable: true
        }, {
          name: "Job Title",
          selector: "jobtitle",
          sortable: true
        },
        , {
          name: "Job Code",
          selector: "jobcode",
          sortable: true,
          width:"10%"
        },   {
                name: "Email",
                selector: "EmailID",
                sortable: true,
                fontSize:13
              },
              {
                  name: "Mobile",
                  selector: "MobileNo",
                  sortable: true,
                  width:"10%"
                },
              {
                name: "Location",
                selector: row =><>{row.location==null?<><span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>Not Avaliable</span></>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>{row.location}</span></>}</>,
                sortable: true
              },
             
            
              {
                name: "Client Name",
                selector: "clientname",
                sortable: true,
                width:"10%"
              },
              {
                  name: "Date of Joining",
                  selector: "dateofjoining",
                  sortable: true,
                  width:"10%"
                },
              {
                name: "Resume link",
                selector: row => <span onClick={() =>this.viewResume(row.resume_name)} style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:13}}>{row.resume_name}</span>,
                sortable: true
              },
               
          ];
          this.columns2 = [
      
            {
              name:"Candidate Name",
        selector: row =><span onClick={() =>ViewApplicant(row.applicantid)} style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:13}}>{row.applicantname}</span>,
           
          
            sortable: true
          }, {
            name: "Job Title",
            selector: "jobtitle",
            sortable: true
          },
          , {
            name: "Job Code",
            selector: "jobcode",
            sortable: true,
            width:"10%"
          },   {
                  name: "Email",
                  selector: "EmailID",
                  sortable: true,
                  fontSize:13
                },
                {
                    name: "Mobile",
                    selector: "MobileNo",
                    sortable: true,
                    width:"10%"
                  },
                {
                  name: "Location",
                  selector: row =><>{row.location==null?<><span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>Not Avaliable</span></>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>{row.location}</span></>}</>,
                  sortable: true
                },
               
              
                {
                  name: "Client Name",
                  selector: "clientname",
                  sortable: true,
                  width:"10%"
                },
                {
                    name: "Date of Joining",
                    selector: "dateofjoining",
                    sortable: true,
                    width:"10%"
                  },
                {
                  name: "Resume link",
                  selector: row => <span onClick={() =>this.viewResume(row.resume_name)} style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:13}}>{row.resume_name}</span>,
                  sortable: true
                },
                {
                    name:"Action",
                  cell: (e) => (
                      <>
                    <a   href="#ft-edituser-modal"
                                  onClick={this.openModal3}
                                  style={{width:"26%",fontSize:13,fontSize:12,margin:"3em",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><img onClick={this.openModal3} src={PencilIcon} style={{width:"20%",marginTop:"5%"}} /></a>{'     '}
                 
                    </>
                  ),
                  
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
          ];
    this.handleDisplay=this.handleDisplay.bind(this)
    this.handleDisplayClose=this.handleDisplayClose.bind(this)
    this.handleLogout=this.handleLogout.bind(this)
    this.handleJob=this.handleJob.bind(this)
    this.handleApplicant=this.handleApplicant.bind(this)
    this.handleHome=this.handleHome.bind(this)
    this.handleUser=this.handleUser.bind(this)
    this.hanldelRoles=this.hanldelRoles.bind(this)
    this.hanldelClient=this.hanldelClient.bind(this)
    this.handleMaster=this.handleMaster.bind(this)
    this.handleUpdateImage=this.handleUpdateImage.bind(this)
    this.handleuser=this.handleuser.bind(this)
    this.viewResume=this.viewResume.bind(this)
  }
  viewResume(resume_name){
    //console.log(this.state.resume_name)
    window.open(`https://www.mrecruit.in/sysload/pdf/${resume_name}`)
  }

  handleuser(){
    let data = window.location.pathname.slice(0,0);
    window.location.href = `${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}`;
  }
  
  handleUpdateImage(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,
        adduserdata:"editsinglephotoinfo",
        profilepic:this.state.profilepic
     
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
         if(response.message=="Profile Pic Updated successfully"){
          const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             //console.log(response)
                 this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                 
                        
                 })
             
                    
                  });
         }
          
                
         
                
              });
  }
  handleMaster(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          profilepic: result,
          ProfileImg:result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  openModal2=()=>{
    this.setState({ showModal2: true });
  }
  closeModal2 = () => {
    this.setState({ showModal2: false });
  };
  openModal3=()=>{
    this.setState({ showModal3: true });
  }
  closeModal3 = () => {
    this.setState({ showModal3: false });
  };
  hanldelClient(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }

  hanldelRoles(){
    this.setState({accesscontrolscreen:1,usermanagementscreen:0,rolesscreen:1})
  }

  handleUser(){
   
    this.setState({accesscontrolscreen:0,usermanagementscreen:1,rolesscreen:0,userfieldscreen:0,teamsscreen:0,addteam:0})
  }
  handleApplicant(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleJob(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleDisplay(){
    this.setState({display:1})
  }
  handleDisplayClose(){
    this.setState({display:0})
  }
  handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
  handleHome(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.roleid}`
  }
  componentDidMount(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const pdata={ userid:withoutQuotes,
        adduserdata:"getsinglephotoinfo",
      
     
    }
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(pdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
            if(response.result.profilepic=="BASE64"){
              this.setState({ProfileImg:""},()=>{
               
              })
          
           }
           else{
              this.setState({ProfileImg:response.result.profilepic},()=>{
               
              })
          
           }
         
                
              });

              /**Onboarding API Call Starts */
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes1 = username.replaceAll('"', '');
              console.log(withoutQuotes1)
              const data={userid:withoutQuotes1,addonboardingdata:"getonboardingdetails",}
             // console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getonboardinginfo", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                  //console.log(response.result)
                     let data=response.result
                        data = data.map((currentValue, Index) => {
                          currentValue.SNO = Index + 1;
                          return currentValue;
                        });
                        this.setState({ original_rows: data, rows: data,loadingicon:1 });
                       
                      //console.log(this.state.original_rows)
                      });

                      /**Onboarding API Call Ends */
  }
  render() {
    return (
      <div>
        {/* Header Section Starts */}

        <div className="dashboardheader fixed-top">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li style={{cursor:"pointer"}} onClick={this.handleHome}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleJob}>Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleApplicant}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.hanldelClient} style={{cursor:"pointer"}}>Clients</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext">Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.encodedroleid==9?null:<><li style={{cursor:"pointer"}} onClick={this.handleuser}>User Management</li></>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          </ul>
          <Header/>
          
        </div>
         {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}
  {/**Edit Final Selected Candidate Modal starts */}
  {this.state.showModal3 && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"45%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Onboarding Status
                  </h5>
          
           <hr/>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>Update Onboarding Status</span>
              </div>
              <div className="col-xl-8">
              <Select 
       
       options={this.state.onboardingdata} placeholder="Onboarded" components={{  IndicatorSeparator:() => null }}
      styles={style}/>
              </div>
              </div>
            <div class="ft-modal-body">
                    <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary updatebtn" >Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal3}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
  )}
  {/**Edit Final Selected Candidate Modal Ends */}
        <div>
              {/**Side Bar starts */}



              <div class="sidebar">
                {this.state.usermanagementscreen==1?<>
                  <a class="active">Onboarding</a>
                </>:<>
                <a onClick={this.handleUser} className="usermgmttxt usermgmtmenu">Onboarding</a>
                </>}

  {this.state.rolesscreen==1?<>
    <a className="access">Final Selected</a>
  </>:<> <a onClick={this.hanldelRoles} className="usermgmtmenu">Final Selected</a></>}

</div>
{/**Side bar ends */}
{/* Onboarding Screen Starts */}
{this.state.usermanagementscreen==1?<>
  <div class="content" >
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Poppins Bold"}}>Onboarding Information</h2></div>
  
 </div>
 {this.state.displayform==0?<>
  <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
  <div className="row" style={{marginLeft:"0%"}}>
    <div className="col-xl-12">
    <div className={styles.pageContainer}>
       
       <div className={styles.pageBox}>
       <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
            </Row>
    <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>

            {this.state.loadingicon==0?<>
          <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
        </>:<>  <DataTable
               
               customStyles={tableCustomStyles}
               columns={this.columns}
               data={this.state.rows}
               pagination
               striped
               dense
               
             /></>}
        </div>
        </div>
        </div>
        </div>

    </div>

  </div>

 </>:<>
 
 </>}
 
  
</div>
</>:null}
{/* Onboarding Screen Ends */}

{/* Final Selected Screen Starts */}
{this.state.accesscontrolscreen==1?<>
    <div class="content" >
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Poppins Bold"}}>Final Selection Information</h2></div>
  
 </div>
 
  <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
  <div className="row" style={{marginLeft:"0%"}}>
    <div className="col-xl-12">
    <div className={styles.pageContainer}>
       
       <div className={styles.pageBox}>
       <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
            </Row>
    <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>

            {this.state.loadingicon==0?<>
          <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
        </>:<>  <DataTable
               
               customStyles={tableCustomStyles2}
               columns={this.columns2}
               data={this.state.rows}
               pagination
               striped
               dense
               
             /></>}
        </div>
        </div>
        </div>
        </div>
   

    </div>

  </div>
</div>
</>:null}
{/* Final Selected Screen Ends */}

        </div>
      
      </div>
    );
  }
}










