import React, { useState } from "react";
import Button from "react-bootstrap/Button";

import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
function ReactivateUser(id){
  var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"reactivateuser",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
}
function ViewUser(id){
  //console.log(id)
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  var data5=splitURL[6]
  let encodedjobid=window.btoa(id)
  window.location.href=`${data}/viewuser/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
}
const tableCustomStyles = {
  table: {
    style: {
    },
},
tableWrapper: {
    style: {
        display: "table",
        width:"140%"
    },
},
responsiveWrapper: {
    style: {},
},

}
class Inactiveuser extends React.Component {
  constructor() {
   
    super();

    this.columns = [
      {
        name:"Name",
  selector: row =><span onClick={() =>ViewUser(row.id)} style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:12}}>{row.username}</span>,
     
    
      sortable: true
    },
          {
            name: "Email",
            selector: "email",
            sortable: true
          },
          {
            name: "Location",
            selector: "branchlocation",
            sortable: true
          },
          {
            name: "Role",
            selector: "userrolename",
            sortable: true
          },
          {
            name: "Admin Priviledges",
            selector: "AdminPrivileges",
            sortable: true
          },
          {
            name: "Reporting To",
            selector: "reporting_toname",
            sortable: true
          },
         
          {
            name: "Created By",
            selector: "createdby",
            sortable: true
          },
          {
            name: "Created Date",
            selector: "created_on",
            sortable: true
          },
          {
            name:"Action",
          cell: (e) => (
              <>
            <span style={{color:"blue",fontFamily:"Inter Bold",cursor:"pointer",verticalAlign:"sub"}} onClick={() =>ReactivateUser(e.id)} >Reactivate</span>{'     '}
            
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    
  }

  componentDidMount() {
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,adduserdata:"getuserinfo",statuscode:2}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         if(response.message=="No Data Found."){

         }
         else{
          let data=response.result
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
          });
          this.setState({ original_rows: data, rows: data });
          for (var i = 0; i < this.state.original_rows.length; i++) {
            delete this.state.original_rows[i].timezonename
            delete this.state.original_rows[i].teamname
        }
       
         }
          
        });

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

 
  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }

  download_pdf() {
    const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf");
  }

  render() {
    let data=this.props.user_id;
console.log(data)
    return (
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button style={{marginBottom:"4em"}}  class="btn btn-primary" type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                 
                 {/*  <Button variant="primary" className={styles.primaryBtn}> 
                 <CSVLink
                    data={this.rows_data_for_export}
                    headers={this.columns_data_for_export}
                    filename={"client_list.csv"}
                  >
                    Excel
                  </CSVLink>
                   </Button> */}
                 
               
               {/* <Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                </Button> */}
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
           
              <DataTable
                customStyles={tableCustomStyles}
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                
              />
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inactiveuser;
