import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};



class StateTable extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      showModal2:false,
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      teammembersdata:[],
      firstname:"",
      lastname:"",
      username:"",
      email:"",
      workinglocation:"",
      password:"",
      userrole:"",
      userstatename:"",
      userstatus:"",
      mobile:"",
      reportingto:"",
      reporting_toname:"",
      BusinessUnit:"",
      adminprivileges:"No",
      dob:"",
      doj:"",
      teamids:"",
      statename:"",
      employeeID:"",
      AdminPrivileges:"",
      edituserid:"",
      userstatusname:"",
      dobchange:"",
      dojchange:"",
      updatemsg:"",
      firstnametwo:"",
      userstatenametwo:"",
      statenametwo:"",
      BussinessUnit:"",
      TLroleids:"",
      TLstatename:"",
      TLname:"",
      Description:"",
      DataPermission:"",
      tstatus:"",
      updated_by:"",
      totalmembers:"",
      editStatesid:"",
      country_id:""
      
    }
   
    this.columns = [
     
         
          {
            name: "Name",
            selector: "name",
            sortable: true
          },
         
        
          {
            name:"Action",
          cell: (e) => (
              <>
            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(e.id)} 
                          style={{width:"26%",fontSize:13,backgroundColor:"green",padding:"0% 7% 4% 6%",fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><i className="fa fa-edit" ></i></a>{'     '}
            <span   onClick={() =>this.Deleteuser(e.id)}  style={{width:"26%",fontSize:13,backgroundColor:"red",padding:"0% 7% 4% 6%",fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><i className="fa fa-trash" onClick={() =>this.Deleteuser(e.id)}></i></span>{'     '}
           
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleUpdateone=this.handleUpdateone.bind(this)
  }
  Deleteuser(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
  
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
        console.log(withoutQuotes)
        const data={userid:withoutQuotes,addLocationdata:"deleteState",editStatesid:id}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                console.log(response)
                    Swal.fire(
                      'Deleted!',
                       response.message,
                      'success'
                    )
                    
                    setTimeout(
                      function() {
                          var username=JSON.stringify(localStorage.getItem("UserId"))
                      const withoutQuotes = username.replaceAll('"', '');
                      console.log(withoutQuotes)
                      const data={userid:withoutQuotes,addLocationdata:"getStatesinfo"}
                      console.log(data)
                      fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
                          method: "POST",
                  
                          body: JSON.stringify(data), // data can be `string` or {object}!
                  
                          headers: { "Content-Type": "application/json" },
                        })
                          .then((res) => res.json())
                  
                          .catch((error) => console.error("Error:", error))
                  
                          .then((response) => {
                           
                             let data=response.result
                                data = data.map((currentValue, Index) => {
                                  currentValue.SNO = Index + 1;
                                  return currentValue;
                                });
                                this.setState({ original_rows: data, rows: data });
                                
                              });
                     
                  
                      const node = loadCSS(
                        "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
                        document.querySelector("#font-awesome-css")
                      );
                      
                      return () => {
                        node.parentNode.removeChild(node);
                      };
                      }
                      .bind(this),
                      1000
                  );
                });
       
      }
    })
  }
  
  handleUpdateone(){
    if(this.state.statename==""){
      this.setState({updatemsg:"Please Enter State Name."})
     
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes1 = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes1,
        addLocationdata:"editStateinfo",
        editStatesid:this.state.editStatesid,
        statename:this.state.statename,
        countryid:this.state.country_id
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                
                this.setState({updatemsg:response.message})
                setTimeout(
                  function() {
                   
                      var username=JSON.stringify(localStorage.getItem("UserId"))
                      const withoutQuotes = username.replaceAll('"', '');
                      console.log(withoutQuotes)
                      const data={userid:withoutQuotes,addLocationdata:"getStatesinfo"}
                      console.log(data)
                      fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
                          method: "POST",
                  
                          body: JSON.stringify(data), // data can be `string` or {object}!
                  
                          headers: { "Content-Type": "application/json" },
                        })
                          .then((res) => res.json())
                  
                          .catch((error) => console.error("Error:", error))
                  
                          .then((response) => {
                           
                             let data=response.result
                                data = data.map((currentValue, Index) => {
                                  currentValue.SNO = Index + 1;
                                  return currentValue;
                                });
                                this.setState({ original_rows: data, rows: data });
                                
                              });
                     
                  
                      const node = loadCSS(
                        "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
                        document.querySelector("#font-awesome-css")
                      );
                      this.setState({showModal:false})
                      return () => {
                        node.parentNode.removeChild(node);
                      };
                     
                  }
                  .bind(this),
                  1000
              );
        
                    
                  });
    }
   
      }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes1 = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes1,
      edituserid:this.state.edituserid,
      adduserdata:"edituserinfo",
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userstatename:this.state.userstatename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:this.state.BusinessUnit,
      adminprivileges:this.state.AdminPrivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      statename:this.state.statename,
      timezoneid:1,
      timezonename:"US Time",
      employeeID:this.state.employeeID
    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
    
              this.setState({updatemsg:response.message})
            setTimeout(
              function() {
               
                window.location.reload()
              }
              .bind(this),
              2500
          );
               console.log(response)
      
                  
                });
      }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userstatename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({statename:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editStatesid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addLocationdata:"getsingleStatesinfo",editStatesid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
          console.log(response)
          response.result.map((e)=>{
            this.setState({
             
              statename:e.name,
              editStatesid:e.editStatesid,
              country_id:e.country_id
            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false,showModal2:false });
  };
openModal2(id){
  
  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotes = username.replaceAll('"', '');
    const data={ userid:withoutQuotes,adduserdata:"getteammemberinfo",editusrroleid:id}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
        console.log(response)
    if(response.message=="No Teams Found"){

    }
    else{
      this.setState({teammembersdata:response.result,showModal2: true},()=>{
        //console.log(this.state.teammembersdata)
      })
      
    }
        
      
              
            });
}
  componentDidMount() {
    const rodata={ dropdownlist_input: "getuserrole"}
    console.log(rodata)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(rodata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });

      const rdata={ dropdownlist_input: "getreportinginfo"}
      console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes1 = username.replaceAll('"', '');
       const tdata={ userid: withoutQuotes1,
        adduserdata:"getteamsinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,addLocationdata:"getStatesinfo"}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data });
              
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }


  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
   

    return (
      <>{this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content" style={{width:"40%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit State Information
                  </h5>
              <hr/>
           
            </div>
            <div class="ft-modal-body">
           
            <div>
 
     <div className="row mt-3">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>State Name</span>
 
  </div>
  <div className="col-xl-8">
 
  <input type="text" value={this.state.statename}  onChange={this.handleChange} className="form-control  adduser" name="statename" />
  </div>

     </div>
    
 
     <div className="row mt-3">
  <div className="col-xl-5">

  </div>

<div className="col-xl-2">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdateone}>Update</button>
</div>
  </div>
  <div className="row mt-3">
  <div className="col-xl-4">

  </div>

<div className="col-xl-7">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.updatemsg}</span>
</div>
  </div>
  
</div>
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}


      <div className={styles.pageContainer} style={{marginLeft:"2%"}}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button style={{marginBottom:"2em"}} class="btn btn-primary" type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
           
              <DataTable
                defaultSortField="id"
                
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                
              />
            
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default StateTable;
