import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import Swal from "sweetalert2";
import Select from 'react-select'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Jobpostingdatatable from "./Jobpostingdatatable";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import Resizer from "react-image-file-resizer";
import DeletedJobposting from "./DeletedJobposting";
import Header from "./Header";

const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

export default class Jobposting extends React.Component {

  constructor(){
    if (localStorage.getItem("Username") == null) {
            

      localStorage.clear()
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/`


     } else {
      var username=JSON.stringify(localStorage.getItem("Username"))
      
     }
    
    const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let decodedemail=window.atob(data3)
    let roleid=window.atob(data5)
    super();
    this.state={
          user_name:withoutQuotes,
          display:0,
          encodedid:data1,
          encodedname:data2,
          encodedemail:data3,
          user_email:decodedemail,
          displayform:0,
          userroleids:roleid,
          encodedroleid:data5,
          newjob:0,
          jobpostingscreen:0,
          countrydata:[],
          statedata:[],
          citydata:[],
          userstausdata:[
            { value: '1', label: 'Active'},
           
         
           
          ],
          CountryID:"",
          CountryName:"",
          StateID:"",
          StateName:"",
          CityName:"",
          CityID:"",
          clientdata:[],
          HiringManager:"",
          HiringManagerid:"",
          reportingtodata:[],
          SPOCMobile:"",
          SPOCName:"",
          Assingnedid:[],
          businessunit:[
            { value: '1', label: 'Maintec Technologies' },
           
          ],
          JobTitle:"",
          ClientName:"",
          BusinessUnit:"Maintec Technologies",
          languagesdata:[],
          degreedata:[],
          ClientId:"",
          CBR_Coin:"",
          PR_Coin:"",
          PR_MinAmount:"",
          PR_MaxAmount:"",
          ClientJobID:"CJ505",
          NoofPositions:"",
          ClosedPostions:"",
          JobCreatedDate:"",
          JobClosingDate:"",
          SPOC:"",
          HiringManagerid:"",
          HiringManager:"",
          NoticePeriod:"",
          JobDescription:"",
          DegreeID:"",
          Degreename:"",
          ExperienceMin:"",
          ExperienceMax:"",
          PrimarySkills:"",
          Languages:"",
          JobStatus:"",
          SPOCMobile:"",
          SPOCName:"",
          JobCode:"",
          AssingnedID :"",
          AssingnedName :"",
          Assingnedname:[],
          showModal2:false,
          ProfileImg:"",
          profilepic:" ",
          positionerror:"",
          CBR_Amount:"",
          Noticeperiod : [
            { value: "1", label: "Immedidate" },
            { value: "2", label: "7 Days or less " },
            { value: "3", label: "15 Days or less" },
            { value: "4", label: "1 Month" },
            { value: "5", label: "2 Months" },
            { value: "6", label: "3 Months" },
            { value: "7", label: "Currently Serving" },
          
          ] ,
          staffingdata:[
            { value: "1", label: "Permanent Staffing" },
            { value: "2", label: "Contract To Hire" },
          ],
          currencydata:[
            { value: "INR", label: "INR" },
            { value: "USD", label: "USD" },
          ],
          randomnum:"MTPL-",
          hover:false,
          StaffingTypeid:"",
          StaffingTypename:"",
          pincode:"",
          Additionalskills:"",
          ClientJobID :"",
          

          
    }
    this.handleDisplay=this.handleDisplay.bind(this)
    this.handleDisplayClose=this.handleDisplayClose.bind(this)
    this.handleLogout=this.handleLogout.bind(this)
    this.handleUser=this.handleUser.bind(this)
    this.hanldelClient=this.hanldelClient.bind(this)
    this.handleHome=this.handleHome.bind(this)
    this.handleNew=this.handleNew.bind(this)
    this.handleNewclose=this.handleNewclose.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.handleState=this.handleState.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleClient=this.handleClient.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleassigned=this.handleassigned.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleSave=this.handleSave.bind(this)
    this.handleLanguage=this.handleLanguage.bind(this)
    this.handleDegree=this.handleDegree.bind(this)
    this.handleApplicant=this.handleApplicant.bind(this)
    this.handleUpdateImage=this.handleUpdateImage.bind(this)
    this.handleMaster=this.handleMaster.bind(this)
    this.handleNotice=this.handleNotice.bind(this)
    this.handleStaffing=this.handleStaffing.bind(this)
    this.handleOnboarding=this.handleOnboarding.bind(this)
    this.handleClientnew=this.handleClientnew.bind(this)
    this.handleCurrency=this.handleCurrency.bind(this)
    this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
    this.handleCheckbox1=this.handleCheckbox1.bind(this)
  }
  handleCheckboxclose1(id,name){
    this.setState({CityID : this.state.CityID.filter(function(val) {return val!==id})})
    this.setState({CityName : this.state.CityName.filter(function(val) {return val!==name})},()=>{
      console.log(this.state.CityName)
    })
}
handleCheckbox1(id,name){
  this.setState({CityID:this.state.CityID.concat(id)},()=>{
    console.log(this.state.CityID)
   })
   this.setState({CityName:this.state.CityName.concat(name)},()=>{
    console.log(this.state.CityName)
   })

}
  handleClientnew(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleOnboarding(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
  }
  handleStaffing(e){
    this.setState({StaffingTypename:e.label,StaffingTypeid:e.value})
  }
  handleMouseIn() {
    this.setState({ hover: true })
  }
  
  handleMouseOut() {
    this.setState({ hover: false })
  }
  handleCurrency(e){
    this.setState({CBR_Coin:e.label,PR_Coin:e.label})
  }
  handleNotice(e){
    this.setState({NoticePeriod:e.label})
  }
  handleChangeNoticeperiod=(e)=>{
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
    
    });
  }
 
  handleChangePincode = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
 
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
   
  
    
  };
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value.length!=10){
      this.setState({mobileerror:"Contact number should be digits only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
    }
  
    
  };
  handleChangeJobcreated = (e) => {
    // var today = new Date().toISOString().split('T')[0];
    // document.getElementsByName("JobCreatedDate")[0].setAttribute('min', today);
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleMaster(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  openModal2=()=>{
    this.setState({ showModal2: true });
  }
  closeModal2 = () => {
    this.setState({ showModal2: false });
  };
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          profilepic: result,
          ProfileImg:result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handleUpdateImage(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,
        adduserdata:"editsinglephotoinfo",
        profilepic:this.state.profilepic
     
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
         if(response.message=="Profile Pic Updated successfully"){
          const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             //console.log(response)
                 this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                 
                        
                 })
             
                    
                  });
         }
          
                
         
                
              });
  }
  handleDegree(e){
    this.setState({Degreename:e.label,DegreeID:e.value})
  }
  handleLanguage(e){
    this.setState({Languages:e.value})
  }
  handleSave(){
    if(this.state.JobTitle==""){
      Swal.fire("Please Enter Job Title")
    }
    else if(this.state.JobTitle==""){
      Swal.fire("Please Enter Job Title")
    }
    else if(this.state.ClientId==""){
      Swal.fire("Please Client Name")
    }
    else if(this.state.AssingnedID==" "){
      Swal.fire("Please Select Assigned To")
    }
    else if(this.state.NoofPositions==""){
      Swal.fire("Please Enter No of Position")
    }
   
    else if(this.state.PR_MinAmount==""){
      Swal.fire("Please Enter Maintec Offered CTC Minimum Amount")
    }
    else if(this.state.PR_MaxAmount==""){
      Swal.fire("Please Enter Maintec Offered CTC Maximum Amount")
    }
    else if(this.state.CountryID==""){
      Swal.fire("Please Select Country")
    }
    else if(this.state.StateName==""){
      Swal.fire("Please Select State")
    }
    else if(this.state.CityName==""){
      Swal.fire("Please Select Location")
    }
    else if(this.state.JobCreatedDate==""){
      Swal.fire("Please Choose Job Created Date")
    }
    else if(this.state.JobClosingDate==""){
      Swal.fire("Please Choose Job Closing Date")
    }
    
    else if(this.state.HiringManager==""){
      Swal.fire("Please Choose Hiring Manager")
    }
    else if(this.state.NoticePeriod==""){
      Swal.fire("Please Enter Notice Period")
    }
    else if(this.state.JobStatus==""){
      Swal.fire("Please Choose Job Status")
    }
    else if(this.state.ExperienceMin==""){
      Swal.fire("Please Enter Minimum Experience")
    }
    else if(this.state.ExperienceMax==""){
      Swal.fire("Please Enter Maximum Experience")
    }
    else if(this.state.PrimarySkills==""){
      Swal.fire("Please Enter Mandatory Skills")
    }
    else if(this.state.Degreename==""){
      Swal.fire("Please Select Degree")
    }
    
    else if(this.state.JobDescription==""){
      Swal.fire("Please Enter Job Description")
    }
    else if(this.state.StaffingTypename==""){
      Swal.fire("Please Choose Staffing")
    }
   
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes,
        addjobpostingdata:"addjobpostinginfo",
        JobCode:this.state.JobCode,
        JobTitle:this.state.JobTitle,
        ClientId:this.state.ClientId,
        AssingnedID:this.state.Assingnedid.toString(),
        AssingnedName:this.state.AssingnedName.toString(),
        ClientName:this.state.ClientName,
        CBR_Coin:this.state.CBR_Coin,
        CBR_Amount:this.state.CBR_Amount,
        PR_Coin:this.state.PR_Coin,
        PR_MinAmount:this.state.PR_MinAmount,
        PR_MaxAmount:this.state.PR_MaxAmount,
        CountryID:this.state.CountryID,
        CountryName:this.state.CountryName,
        StateID:this.state.StateID,
        StateName:this.state.StateName,
        CityID:this.state.CityID,
        CityName:this.state.CityName,
        ClientJobID:this.state.ClientJobID,
        NoofPositions:this.state.NoofPositions,
        ClosedPostions:this.state.NoofPositions,
        JobCreatedDate:this.state.JobCreatedDate,
        JobClosingDate:this.state.JobClosingDate,
        SPOCName:this.state.SPOCName,
        SPOCMobile:this.state.SPOCMobile,
        HiringManagerid:this.state.HiringManagerid,
        HiringManager:this.state.HiringManager,
        NoticePeriod:this.state.NoticePeriod,
        BusinessUnit:this.state.BusinessUnit,
        JobDescription:this.state.JobDescription,
        DegreeID:this.state.DegreeID,
        Degreename:this.state.Degreename,
        ExperienceMin:this.state.ExperienceMin,
        ExperienceMax:this.state.ExperienceMax,
        PrimarySkills:this.state.PrimarySkills,
        Languages:this.state.Languages,
        JobStatus:this.state.JobStatus,
        StaffingTypename:this.state.StaffingTypename,
        StaffingTypeid:this.state.StaffingTypeid,
        pincode:this.state.pincode,
        Additionalskills:this.state.Additionalskills
  
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getJobposting", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                Swal.fire(
                  response.message,
                  
                  
                )
                setTimeout(
                  function() {
                  window.location.reload()
                  }
                  .bind(this),
                2500
              );
                
                 console.log(response)
        
                    
                  });
    }
    
      }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleassigned(e){
    this.setState({Assingnedname:e.label,Assingnedid:e.value})
  }
  handleApplicant(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  handleReport(e){
    this.setState({HiringManager:e.label,HiringManagerid:e.value})
  }
  handleCity(e){
    this.setState({CityName:e.label,CityID:e.value})
  }
  handleClient(e){
    this.setState({ClientName:e.label,ClientId:e.value})
  }
  handleChange = (e) => {
    //var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>-]/g;
   // e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleChangeBillrate = (e) => {
    var regex = /[a-zA-Z!@#\$%\^\&*\)\(+=`;:<>]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleChangeNopos = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value>500){
      this.setState({positionerror:"No of Open Positions between 500 only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        positionerror:""
      });
    }
  
    
  };
  handleChangeJobTitle = (e) => {
    //var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleChangeSkills = (e) => {
    //var regex = /[!@\$%\^\&*\)\(+=._-`;:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleChangeJD = (e) => {
    //var regex = /[!@\$%\^\&*\)\(+=_`;:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });

    
  };
  handleUserstatus(e){
    this.setState({JobStatus:e.value}) 
  }
  handleState(e){
    this.setState({StateName:e.label,StateID:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            console.log(data)
      
          
     
          this.setState({citydata:data})
                
              });
      })
  }
  handleCountry(e){
    this.setState({CountryName:e.label,CountryID:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }
  handleNewclose(){
    this.setState({newjob:0,jobpostingscreen:0})
  }
  handleNew(){
this.setState({newjob:1,jobpostingscreen:1})
  }
  handleUser(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
  }
 
  handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
  handleHome(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
  }
  handleDisplay(){
    this.setState({display:1})
  }
  handleDisplayClose(){
    this.setState({display:0})
  }

  hanldelClient(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
  }
  componentDidMount(){
   // var num=Math.floor(Math.random() * 1001);
    //this.setState({randomnum:this.state.randomnum.concat(num.toString())})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const pdata={ userid:withoutQuotes,
      adduserdata:"getsinglephotoinfo",
    
   
  }
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(pdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          //console.log(response)
        if(response.result.profilepic=="BASE64"){
            this.setState({ProfileImg:""},()=>{
             
            })
        
         }
         else{
            this.setState({ProfileImg:response.result.profilepic},()=>{
             
            })
        
         } 
              
            });
  
      /* Reporting To API Call Start */
      const redata={ dropdownlist_input: "getreportinginfo"}
      console.log(redata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(redata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
             
      /* Reporting To  API Call End */

       /* Language API Call Start */
       const ledata={ dropdownlist_input: "getlanguagesinfoinfo"}
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(ledata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({languagesdata:options})
                 
               });
              
       /*Language  API Call End */

        /* Degree API Call Start */
        const degdata={ dropdownlist_input: "getdegreesinfo"}
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(degdata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({degreedata:options})
                  
                });
               
        /*Language  API Call End */
       /* Country API Call Start */
       const tdata={ dropdownlist_input: "getcountryinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.countryid,
               "label" : d.conuntryname
   
             })) 
      
           this.setState({countrydata:options})
                 
               });
       /* Country API Call  End */

         /* Client API Call Start */
         const cdata={ dropdownlist_input: "getclientDropinfo"
        }
         console.log(cdata)
         fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
             method: "POST",
     
             body: JSON.stringify(cdata), // data can be `string` or {object}!
     
             headers: { "Content-Type": "application/json" },
           })
             .then((res) => res.json())
     
             .catch((error) => console.error("Error:", error))
     
             .then((response) => {
                 
              const data = response.result
               //console.log(json)
         
             const options = data.map(d => ({
                 "value" : d.clientid,
                 "label" : d.clientname
     
               })) 
        
             this.setState({clientdata:options})
                   
                 });
         /* Client API Call  End */
        
  }
  render() {
    const tooltipStyle = {
      display: this.state.hover ? 'inline' : 'none',
      fontSize:"11px",
      marginLeft:"-1%",
      backgroundColor:"black",
      color:"white",
      fontFamily:"Poppins Light",
      fontWeight:"bold",
      padding:"0.5%"
    }
    const arrowleft = {
      width: "0px",
      height: "0px",
      borderTop: "7px solid transparent",
      borderBottom: "6px solid transparent",
      borderRight: "15px solid black",
      display: this.state.hover ? 'inline-block' : 'none',
  }
    return (
      <div>
        {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" style={{marginLeft:"1%"}} />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{cursor:"pointer"}}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext">Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleApplicant}  style={{cursor:"pointer"}}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClientnew}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleids==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleids==9 || this.state.userroleids==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul>
          <Header/>
        </div>

        {/* Header Section Ends */}

           {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}     


                {this.state.jobpostingscreen==0?<>
                 {/* Job Posting Section started */}
                 <div className="row">
              <div className="col-xl-12">
                <h2 style={{ padding: "1%", fontFamily: "Poppins Light",fontWeight:"bold",marginLeft:"1%" }}>
                  Job Posting
                </h2>
                <span  style={{float:"right",marginRight:"2%",marginTop:"-4em"}}> <button
                  className="btn btn-primary updatebtn"
                  onClick={this.handleNew}
                >
                  Add New Job
                </button></span>
              </div>
             
            </div>

              {/* Job Posting Section ended */}

              {/* Job Posting Data Table started */}

                <div className="row container-fluid">
                  <div className="col-xl-12" style={{marginTop:"-2%",marginLeft:"2%"}}>
                
                  <Tabs
          activeTab="1"
          className=""
          ulClassName=""
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="Active Jobs" className="mr-4">
            <div style={{marginLeft:"0%"}}>
                <Jobpostingdatatable/>
                </div>
            </Tab>
            <Tab title="Closed Jobs" className="mr-4">
            <div style={{marginLeft:"0%"}}>
               <DeletedJobposting/>
              </div>
            </Tab>
            </Tabs>
               
         
                
                  </div>

                </div>
              {/** Job Posting Data Table end */}
                </>:null}
              {/* NewJob Start */ }
              {this.state.newjob==1?<>
           <div style={{backgroundColor:"#EAF4FF",height:"auto",paddingBottom:"3%"}}>
           <div className="row">
              <div className="col-xl-10">
                  <h3 style={{fontFamily:"Poppins Light",fontWeight:"bold",padding:"1%",marginLeft:"1%"}}>New Job Posting</h3>
              </div>
              <div className="col-xl-2" style={{textAlign:"end",paddingRight:"2%"}}>
                <button className="btn btn-primary updatebtn" onClick={this.handleNewclose}>Back</button>
                  </div>
             </div>
             <div className="row container-fluid">
            
                <div className="col-xl-12" style={{padding:"2%",margin:"1%",background:"#fff",borderRadius:"10px"}}>
                <h4 style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>Job Details</h4>
                <hr/>
                <div className="row">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Job Code</span>&nbsp;&nbsp;<span onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"rgb(66, 147, 254)",fontSize:16,cursor:"pointer"}}><i class="fa fa-question-circle" aria-hidden="true"></i></span>
        <div style={arrowleft}></div>
          <span style={tooltipStyle}>Job Code will generate automatically.</span>
        
               
                <br/>
                <input className="form-control adduser mt-1" placeholder="Job Code" name="JobCode" value={this.state.randomnum} disabled onChange={this.handleChange}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Client Job Code</span><br/>
                <input className="form-control adduser mt-1" placeholder="Client Job Code" name="ClientJobID" onChange={this.handleChange}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Job Title</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input className="form-control adduser mt-1" placeholder="Job Title" name="JobTitle" onChange={this.handleChangeJobTitle}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Client</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleClient(e)}
       options={this.state.clientdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
               
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Client Bill Rate Monthly</span><br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled name="CBR_Coin" onChange={this.handleChange}/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" placeholder="Client Bill Rate Monthly" maxLength="10" name="CBR_Amount" onChange={this.handleChangeBillrate}/>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Maintec Offered CTC </span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <div className="row">
                  <div className="col-xl-4">
                  <Select 
       onChange={(e)=>this.handleCurrency(e)}
       options={this.state.currencydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                  </div>
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="Min" name="PR_MinAmount" onChange={this.handleChangeBillrate}/>
                  </div>
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="Max" name="PR_MaxAmount" onChange={this.handleChangeBillrate}/>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Country</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>State</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleState(e)}
       options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
               
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Location</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <div className="mt-1" style={{height:"2.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.citydata.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.CityID.includes(e.id)==true?<>
          <i className="fa fa-check-square"  onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.CityID.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
                </div>
                
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Pin Code</span><br/>
                <input className="form-control adduser mt-1" name="pincode" onChange={this.handleChangePincode} placeholder="Pin Code" maxLength="6"/>
                </div>
          
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>SPOC Name</span><br/>
               
                  
                  <input className="form-control adduser mt-1" name="SPOCName" onChange={this.handleChangeJobTitle} placeholder="SPOC Name"/>
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>SPOC Mobile</span><br/>
                <input className="form-control adduser mt-1" maxLength="10" name="SPOCMobile" onChange={this.handleChangemobile} placeholder="SPOC Mobile"/>
                <span style={{fontFamily:"Inter",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror}</span>
                </div>
              
                </div>
                <div className="row mt-2">
                {/* <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Assigned TO</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e,value)=>this.handleassigned(e,value)}

       options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div> */}
              
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Job Created Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
               <input type="date" 
               //min={new Date().toISOString().split('T')[0]} 
               name="JobCreatedDate" onChange={this.handleChangeJobcreated} className="form-control adduser mt-1"/>
                
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Job Closing Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
               <input type="date" 
               //min={new Date().toISOString().split('T')[0]} 
               name="JobClosingDate" onChange={this.handleChangeJobcreated} className="form-control adduser mt-1" />
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Notice Period</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
    onChange={(e)=>this.handleNotice(e)}
    options={this.state.Noticeperiod}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
                </div>
                </div>
                <div className="row mt-2">
          
             
           
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Job Status</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
    onChange={(e)=>this.handleUserstatus(e)}
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Relevant Experience Min</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input className="form-control adduser mt-1" name="ExperienceMin" onChange={this.handleChangeNoticeperiod} placeholder="Relevant Experience Min"/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Relevant Experience Max</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input className="form-control adduser mt-1"  placeholder="Relevant Experience Max" name="ExperienceMax" onChange={this.handleChangeNoticeperiod}/>
               
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Mandatory Skills</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
               <input className="form-control adduser mt-1" placeholder="Mandatory Skills" name="PrimarySkills" onChange={this.handleChangeSkills}/>
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Additional Skills</span><br/>
               
                  
               <input className="form-control adduser mt-1" placeholder="Additional Skills" onChange={this.handleChangeSkills} name="Additionalskills" />
              </div>
             
               
             
                <div className="col-xl-3">
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Degree Name</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleDegree(e)}
       options={this.state.degreedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Languages</span>  <br/>
                <Select 
       onChange={(e)=>this.handleLanguage(e)}
       options={this.state.languagesdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>No of Open Positions</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input  maxLength="3" className="form-control adduser mt-1" placeholder="No of Open Positions" name="NoofPositions" onChange={this.handleChangeNopos}/>
                
              <span style={{fontFamily:"Inter",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.positionerror}</span>
                </div>
                </div>
                <div className="row mt-2">
              
                <div className="col-xl-6">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Account Manager</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleReport(e)}
       options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
              <div className="col-xl-6">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Mode of Staffing</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleStaffing(e)}
       options={this.state.staffingdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
                </div>
                <div className="row mt-2">
               
               <div className="col-xl-12">
               <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:15}}>Job Description</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               <textarea className="form-control mt-2 adduser" rows="3" name="JobDescription" onChange={this.handleChangeJD}>

               </textarea>
               </div>
               
                </div>
                <div className="row">
                  <div className="col-xl-10">

                  </div>
                  <div className="col-xl-2" style={{textAlign:"right"}}>
                    <button className="btn btn-primary updatebtn" onClick={this.handleSave}>Save</button>
                  </div>
                </div>
                </div>
                
             </div>
           </div>
              </>:<></>}
              {/*NewJob End*/ }
      </div>
    );
  }
}
