import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import UserImg from "../images/usering.png";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Resizer from "react-image-file-resizer";
import PencilIcon  from "../images/pencilicon.png";
import DeleteIcon  from "../images/deleteicon.png";
import CrossIcon  from "../images/crossicon.png";
import LoadingIcon  from "../images/loadingicon.gif";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

function Deleteuser(id){
  Swal.fire({
    title: 'Are you sure?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"deleteuser",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

function EditUser(e,id){
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  let data=window.location.pathname.slice(0,0);
  let encodedid=window.btoa(id)
  //window.location.href=`${data}/edituser/${encodedid}/${data1}/${data2}/${data3}`
}

function InactiveUser(id){
  Swal.fire({
    title: 'Are you sure to Inactive the User?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Inactive it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,adduserdata:"inactiveuser",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Inactiveted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}
function ViewUser(id){
  //console.log(id)
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[2]
  var data2=splitURL[3]
  var data3=splitURL[4]
  var data5=splitURL[5]
  let encodedjobid=window.btoa(id)
  window.location.href=`${data}/viewuser/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
}
const tableCustomStyles = {
  table: {
    style: {
     
    },
},
tableWrapper: {
    style: {
        display: "table",
        width:"140%",
        fontFamily:"Poppins Light",
        
    },
},
responsiveWrapper: {
    style: {},
},

}
class Activeuserdatatable extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
     
    
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      firstname:"",
      lastname:"",
      username:"",
      email:"",
      workinglocation:"",
      password:"",
      userrole:"",
      userrolename:"",
      userstatus:"",
      mobile:"",
      reportingto:"",
      reporting_toname:"",
      BusinessUnit:"",
      adminprivileges:"No",
      dob:"",
      doj:"",
      teamids:"",
      teamname:"",
      employeeID:"",
      AdminPrivileges:"",
      edituserid:"",
      userstatusname:"",
      dobchange:"",
      dojchange:"",
      updatemsg:"",
      profilepic:" ",
      timezonedata:[],
      timezonename:"",
      timezoneid:"",  
       userstausdata:[
        { value: '1', label: 'Active'},
       
       
      ],
      middlename:"",
      showpwd:0 ,original_rows: [], 
      rows: [], 
      search: "" ,
      firstnameerror:"",
      lastnameerror:"",
      displaynameerror:"",
      passworderror:"",
      mobileerror:"",
      emailiderror:"",
      empiderror:"",
      locationerror:"",
      loadingicon:0,
      imagename :"",
      Sourcesemail:[],
      sourceemailid:"",
      sourceemailname:""
    }
   
    this.columns = [
      
      {
        name:"Name",
  selector: row =><span onClick={() =>ViewUser(row.id)} style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:12}}>{row.username}</span>,
     
    
      sortable: true
    },
          {
            name: "Email",
            selector: "email",
            sortable: true
          },
          {
            name: "Location",
            selector: "branchlocation",
            sortable: true
          },
          {
            name: "Role",
            selector: "userrolename",
            sortable: true
          },
          {
            name: "Reporting To",
            selector: "reporting_toname",
            sortable: true
          },
          {
            name: "Admin Priviledges",
            selector: "AdminPrivileges",
            sortable: true
          },
         
          {
            name: "Created By",
            selector: "createdby",
            sortable: true
          },
          {
            name: "Created Date",
            selector: "created_on",
            sortable: true
          },
          {
            name:"Action",
          cell: (e) => (
              <>
            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(e.id)} 
                          style={{width:"26%",fontSize:13,fontSize:12,margin:"2em",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><img src={PencilIcon} style={{width:"18%",marginTop:"5%"}} /></a>{'     '}
          {/**  <span   onClick={() =>Deleteuser(e.id)}  style={{fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><img src={DeleteIcon} style={{width:"18%",marginTop:"5%"}} onClick={() =>Deleteuser(e.id)} /></span> */}{'     '}
            <span   onClick={() =>InactiveUser(e.id)}  style={{fontSize:12,margin:"-1em",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><img src={CrossIcon} onClick={() =>InactiveUser(e.id)} style={{width:"18%",marginTop:"5%"}}/></span>
            </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleTime=this.handleTime.bind(this)
    this.handleClosepwd=this.handleClosepwd.bind(this)
    this.handleShowpwd=this.handleShowpwd.bind(this)
    this.handleUpdatenew=this.handleUpdatenew.bind(this)
    this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
    this.handleCheckbox1=this.handleCheckbox1.bind(this)
  }
  handleCheckboxclose1(){
     
    this.setState({sourceemailid : ""})
    this.setState({sourceemailname : ""})
  
  
}
handleCheckbox1(id,name){
  this.setState({sourceemailid : ""})
  this.setState({sourceemailname : ""})
  this.setState({sourceemailid:id})
  this.setState({sourceemailname:name},()=>{
    console.log(this.state.sourceemailname)
  })

}
  handleUpdatenew(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes1 = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes1,
      edituserid:this.state.edituserid,
      adduserdata:"edituserinfo",
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userrolename:this.state.userrolename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:this.state.BusinessUnit,
      adminprivileges:this.state.AdminPrivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      teamname:this.state.teamname,
      timezoneid:this.state.timezoneid,
      timezonename:this.state.timezonename,
      employeeID:this.state.employeeID,
      profilepic:this.state.profilepic,
      middlename:this.state.middlename,
      imagename:this.state.imagename,
      sourceemailid:this.state.sourceemailid,
      sourceemailname:this.state.sourceemailname
    }
    console.log(data)

        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
               console.log(response)
               var username=JSON.stringify(localStorage.getItem("UserId"))
               const withoutQuotes = username.replaceAll('"', '');
                 const data={ userid:withoutQuotes,adduserdata:"getsingleuserinfo",edituserid:this.state.edituserid}
                 console.log(data)
                 fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                     method: "POST",
             
                     body: JSON.stringify(data), // data can be `string` or {object}!
             
                     headers: { "Content-Type": "application/json" },
                   })
                     .then((res) => res.json())
             
                     .catch((error) => console.error("Error:", error))
             
                     .then((response) => {
                         
                     console.log(response)
                     response.result.map((e)=>{
                       this.setState({
                         firstname:e.firstname,
                         middlename:e.middlename,
                         lastname:e.lastname,
                         username:e.displayname,
                         email:e.email,
                         workinglocation:e.branchlocation,
                         employeeID:e.employeeID,
                         userrolename:e.userrolename,
                         userrole:e.userroleids,
                         reportingto:e.reporting_to,
                         reporting_toname:e.reporting_toname,
                         teamids:e.teamids,
                         teamname:e.teamname,
                         AdminPrivileges:e.AdminPrivileges,
                         dob:e.dobchange,
                         doj:e.dojchange,
                         dobchange:e.dob,
                         dojchange:e.doj,
                         profilepic:e.profilepic,
                         userstatus:e.userstatus,
                         BusinessUnit:e.BusinessUnit,
                         mobile:e.mobile,
                         userstatusname:e.userstatusname,
                         timezonename:e.timezonename,
                         timezoneid:e.timezoneid
           
                       })
                     })
                    })
                });
      }
  handleChangeEmpID = (e) => {
    var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      empiderror:""
    });
    
  };
  handleChangeDate = (e) => {
    let currentDate = new Date().toJSON().slice(0, 10);
    if(e.target.value==currentDate ){
      e.target.value = e.target.value.replace(currentDate, "");
   
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
  
      }); 
    }
  };
  handleChangetwo = (e) => {
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByName("doj")[0].setAttribute('min', today);
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleClosepwd(){
    this.setState({showpwd:0})
  }
  handleChangeEmail = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
emailiderror:"",
//passworderror:""
    });
    
  };
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
  
 
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
    
  
  
    
  };
  
  handleShowpwd(){
    this.setState({showpwd:1})
  }
  handleTime(e){
    this.setState({timezoneid:e.value,timezonename:e.label})
      }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          imagename: file["name"],
          profilepic:result,
          ProfileImg:result,
          file,
        },()=>{
          this.handleUpdatenew()
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handleUpdate(){
  if(this.state.firstname==null||this.state.firstname==""){
    this.setState({firstnameerror:"Please Enter Firstname"})
  }
  else if(this.state.lastname==null||this.state.lastname==""){
    this.setState({lastnameerror:"Please Enter Lastname"})
  }
  else if(this.state.username==null||this.state.username==""){
    this.setState({displaynameerror:"Please Enter Display Name"})
  }
  // else if(this.state.password==null||this.state.password==""){
  //   this.setState({passworderror:"Please Enter Password"})
  // }
  else if(this.state.mobile==null||this.state.mobile==""){
    this.setState({mobileerror:"Please Enter Contact Number"})
  }
  else if(this.state.mobile.length!=10){
    this.setState({mobileerror:"Contact Number Should be 10 Digits"})
  }
  else if(this.state.email==null||this.state.email==""){
    this.setState({emailiderror:"Please Enter Email Id"})
  }
  else if(this.state.employeeID==null||this.state.employeeID==""){
    this.setState({empiderror:"Please Enter Employee ID"})
  }
  else if(this.state.workinglocation==null||this.state.workinglocation==""){
    this.setState({locationerror:"Please Enter Work Location"})
  }
  else{
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes1 = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes1,
      edituserid:this.state.edituserid,
      adduserdata:"edituserinfo",
      firstname:this.state.firstname,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userrolename:this.state.userrolename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:"Maintec Technologies",
      adminprivileges:this.state.AdminPrivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      teamname:this.state.teamname,
      timezoneid:this.state.timezoneid,
      timezonename:this.state.timezonename,
      employeeID:this.state.employeeID,
      profilepic:"",
      middlename:this.state.middlename,
      imagename:this.state.imagename,
        sourceemailid:this.state.sourceemailid,
      sourceemailname:this.state.sourceemailname
      
    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
    
              this.setState({updatemsg:response.message})
            setTimeout(
              function() {
               
                window.location.reload()
              }
              .bind(this),
              2500
          );
               console.log(response)
      
                  
                });
  }
      }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No",AdminPrivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes",AdminPrivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({edituserid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,adduserdata:"getsingleuserinfo",edituserid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              firstname:e.firstname,
              middlename:e.middlename,
              lastname:e.lastname,
              username:e.displayname,
              email:e.email,
              workinglocation:e.branchlocation,
              employeeID:e.employeeID,
              userrolename:e.userrolename,
              userrole:e.userroleids,
              reportingto:e.reporting_to,
              reporting_toname:e.reporting_toname,
              teamids:e.teamids,
              teamname:e.teamname,
              AdminPrivileges:e.AdminPrivileges,
              dob:e.dobchange,
              doj:e.dojchange,
              dobchange:e.dob,
              dojchange:e.doj,
              profilepic:e.profilepic,
              userstatus:e.userstatus,
              BusinessUnit:e.BusinessUnit,
              mobile:e.mobile,
              userstatusname:e.userstatusname,
              timezonename:e.timezonename,
              timezoneid:e.timezoneid,
              sourceemailid:e.sourceemailid

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
    
    /* Time Zone API Call Start */
    const timedata={ dropdownlist_input: "gettimezoneinfo"}
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(timedata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({timezonedata:options})
              
            });
    /* Time Zone API Call End */
    const rodata={ dropdownlist_input: "getuserrole"}
    console.log(rodata)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(rodata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });

      const rdata={ dropdownlist_input: "getreportinginfo"}
      console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes1 = username.replaceAll('"', '');
       const tdata={ userid: withoutQuotes1,
        adduserdata:"getteamsinfo"
      }
       console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
      /* Source Email API Start */
      const semail={ dropdownlist_input: "getsourceemaillistinfo",userid:withoutQuotes1}
      //console.log(menudata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(semail), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          this.setState({Sourcesemail:data})
                
              });
      /* Source Email API Call End */
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const data={userid:withoutQuotes,adduserdata:"getuserinfo",statuscode:1}
    console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
        // console.log(response.result)
           let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows: data, rows: data,loadingicon:1 });
              for (var i = 0; i < this.state.original_rows.length; i++) {
                delete this.state.original_rows[i].timezonename
                delete this.state.original_rows[i].teamname
            }
            //console.log(this.state.original_rows)
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
   
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()),

    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    var regex = /[0-9!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      firstnameerror:"",
      lastnameerror:"",
      displaynameerror:"",
     // passworderror:"",
      locationerror:"",
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
 
    return (
      <>
      {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content  bigmodalcontent">
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit User Information
                  </h5>
          
           
            </div>
            <div class="ft-modal-body">
           
            {/**Add user for starts */}
 <div>
  <hr/>
  <div className="row mt-1">
    <div className="col-xl-1">
    {this.state.profilepic==" "||this.state.profilepic==null?<>
<img src={UserImg} 
 
 style={{width:"107%"}}  />
</>:<>  <img src={`https://www.mrecruit.in/sysload/profiledocuments/${this.state.profilepic}`}
 
 style={{width:"120%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-2">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
  <div className="row">
    <div className="col-xl-12">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>User Name</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
  </div>
 
  <div className="row">
    <div className="col-xl-4">
     <input className="form-control adduser mt-2" 
     value={this.state.firstname}
     placeholder="First Name" name="firstname" onChange={this.handleChange}/>
     <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.firstnameerror}</span>
    </div>
    <div className="col-xl-4">
     <input className="form-control  adduser mt-2" name="middlename" onChange={this.handleChange}  value={this.state.middlename} placeholder="Middle Name"/>
    </div>
    <div className="col-xl-4">
     <input name="lastname"
     value={this.state.lastname}
     onChange={this.handleChange} className="form-control adduser mt-2" placeholder="Last Name"/>
      <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.lastnameerror}</span>
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Display Name</span>   <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="text" name="username"
     value={this.state.username}
     onChange={this.handleChange} className="form-control adduser mt-2" />
      <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.displaynameerror}</span>
    </div>
   
    <div className="col-xl-4">
    {/* <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Password</span>   <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span> */}
    {this.state.showpwd==0?<>

<div class="input-group mt-2">

<input type="password" style={{borderRadius:"4px"}}  class="form-control  shadow-none adduser"  placeholder="Password" name="password" onChange={this.handleChangeEmail} value={this.state.password} />

<button type="button"  class="toggle">
<i id="eyeIcon" class="fa fa-eye" style={{color:"black"}}  onClick={this.handleShowpwd}></i></button>

</div>
{/* <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.passworderror}</span> */}
          </>:null}
{this.state.showpwd==1?<>
  <div class="input-group mt-2">

<input style={{borderRadius:"4px"}} type="text" value={this.state.password}  class="form-control  shadow-none adduser"  placeholder="Password" name="password" onChange={this.handleChangetwo } />

<button type="button"  class="toggle">
<i id="eyeIcon" class="fa fa-eye-slash" style={{color:"black"}}  onClick={this.handleClosepwd}></i></button>

</div>
<span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.passworderror}</span>
</>:null} 
    
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Contact Number</span>   <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
     <input name="mobile" 
      value={this.state.mobile}
     onChange={this.handleChangemobile} maxLength="10"  className="form-control adduser mt-2" />
     <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.mobileerror}</span>
    </div>
  </div>
  <div className="row mt-2">
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Email ID</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="email" 
     value={this.state.email}
     disabled
     name="email" onChange={this.handleChangeEmail}
     className="form-control adduser mt-2" />
       <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.emailiderror}</span>
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Branch</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
      <input type="text" 
       value={this.state.workinglocation}
      name="workinglocation" onChange={this.handleChange}className="form-control adduser mt-2" />
        <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.locationerror}</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Date of Birth</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="text"
      name="dob"
      onFocus={(e) =>
        (e.currentTarget.type = "date")
      }
      onBlur={(e) =>
        (e.currentTarget.type = "date")
      }
      value={this.state.dob} min="1965-01-01" max="2006-01-01"
      onChange={this.handleChangeDate} className="form-control adduser mt-2" />
    </div>
  </div>
 
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Joining Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="text"
      value={this.state.doj}
      onFocus={(e) =>
        (e.currentTarget.type = "date")
      }
      onBlur={(e) =>
        (e.currentTarget.type = "date")
      }
      name="doj" onChange={this.handleChangetwo}
     className="form-control adduser mt-2" />
     
    
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Time Zone</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
   onChange={this.handleTime}
   placeholder={this.state.timezonename}
   options={this.state.timezonedata}  components={{  IndicatorSeparator:() => null }}
                             styles={style}/>

    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Employee ID</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    <input type="text"
    name="employeeID"
    value={this.state.employeeID}
    className="form-control adduser mt-2" onChange={this.handleChangeEmpID} />
        <span span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.empiderror}</span>
    
    </div>
  </div>
  <div className="row mt-2">
    
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Admin Privilleges</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
   {this.state.on==1 || this.state.AdminPrivileges=="Yes"?<>
    <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handlecloseAdmin}
    type="checkbox" id="myToggle" checked />
    <div class="toggle__fill"></div>
</label>
   </>:<> <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handleAdmin}
    type="checkbox" id="myToggle"  />
    <div class="toggle__fill"></div>
</label></>}
   
<div style={{fontWeight:"bold",fontFamily:"Poppins Light",marginLeft:"25%",marginTop:"-9%"}}>
  {this.state.on==1 || this.state.AdminPrivileges=="Yes"?<>
    <span>On</span>
  </>:<> <span>Off</span></>}
 </div>

    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>User Status</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    placeholder={this.state.userstatusname}
    onChange={(e)=>this.handleUserstatus(e)}
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Add Source Email ID</span>
    <div className="mt-1" style={{height:"5.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.Sourcesemail.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.sourceemailid==e.id?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.sourceemailid!=e.id?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
    </div>
  </div>
  <div className="row mt-2">
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Roles</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select
    onChange={(e)=>this.handleRoles(e)}
    placeholder={this.state.userrolename}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Reporting To</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    placeholder={this.state.reporting_toname}
    onChange={(e)=>this.handleReport(e)} options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
     
    
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:14}}>Team</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select
    placeholder={this.state.teamname}
    onChange={this.handleTeamdata} options={this.state.teamdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>
 
   <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
 
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Poppins Light",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
        {this.state.loadingicon==0?<>
          <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
        </>:<>  <DataTable
               
               customStyles={tableCustomStyles}
               columns={this.columns}
               data={this.state.rows}
               pagination
               striped
               dense
               
             /></>}
            
              
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Activeuserdatatable;
