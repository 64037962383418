import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import DataTable from "react-data-table-component";
import Applicantimg from "../images/applicantimg.png";
import "react-data-table-component-extensions/dist/index.css";
import Header from "./Header";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
export class Viewapplicant extends React.Component{
    
    constructor(){
        super();
        var newURL=window.location.pathname;
        var splitURL=newURL.toString().split("/");
        var data1=splitURL[2]
        var data2=splitURL[3]
        var data3=splitURL[4]
        var data4=splitURL[5]
        var data5=splitURL[6]
       let decodedid=window.atob(data5)
       let decodedrolename=window.atob(data3)
       let roleid=window.atob(data4)

       if (localStorage.getItem("Username") == null) {
            

        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`


       } else {
        var username=JSON.stringify(localStorage.getItem("Username"))
        
       }

        const withoutQuotes = username.replaceAll('"', '');
        this.state={
            user_name:withoutQuotes,
            encodedid:data1,
            encodedname:data2,
            encodedemail:data3,
            user_email:decodedrolename,
            encodedroleid:data4,
            userroleid:roleid,
            display:0,
            jobid:decodedid,
            fullname:"",
            showModal2:false,
            JobTitle:"",
            CountryName:"",
            ApplicantID:"",
            EmailID:"",
            MobileNo:"",
            created_on:"",
            AppStatusname:"",
            TotalExp:"",
            SourceName:"",
            KeySkills:"",
            NoticePeriod:"",
            ExpectedCTC:"",
            DateofBirth:"",
            Relocate:"",
            HighestDegree:"",
            linkedInurl:"",
            PreviousOrg:"",
            PrevDesignation:"",
            resume_name:"",
            showModal:false,
            currentlocation:"",
            preferredlocation:"",
            editAppliantid:"",
            resume_name:"",
            uploadresumebase64:"",
            fileerrormsg:"",
            updatingmsg:"",
            Noticeperiod : [
              { value: "1", label: "15 Days or less" },
              { value: "2", label: "1 Month " },
              { value: "3", label: "2 Months" },
              { value: "4", label: "3 Months" },
              { value: "5", label: "More than 3 Months" },
              { value: "6", label: "Currently Serving Notice Period" },
              { value: "7", label: "7 Days" },
              { value: "8", label: "45 Days" },
              { value: "9", label: "Any" },
              { value: "10", label: "Immediate Joiner" },
              { value: "11", label: "Maintenance" },
            ] ,
              showModal3:false,
              docdata:[
       
                { value: '2', label: 'Aadhar Card'},
                { value: '1', label: 'PAN Card'},
                { value: '3', label: 'Other'},
               
              ],
             
              doctype:"Aadhar Card",
              pleasewaittxt:"",
              candidateDoc:[],
              doctypename:[],
              original_rows: [], 
              rows: [], 
              search: "",
              GovtIDNumber:"",
              GovtIDType:"",
              sourcedata:[
                { value: '1', label: 'Naukri'},
                { value: '2', label: 'Monster'},
                { value: '3', label: 'Other'},
               
              ],
              ProfilePhoto:"",
              showModal4:false,
              tagtojob:[],
              errormsg:"",
              editjobid:"",
              showModal5:false,
              docname:"",
              imagename:"",
              resumenewpath:''

              
        }
        this.columns = [
    
             
               {
                 name: "Document Name",
                 selector: "doctype",
                 sortable: true
               },
               {
                name: "Created By",
                selector: "createdbyname",
                sortable: true
              },
              {
                name: "Created Date",
                selector: "CreatedDate",
                sortable: true
              },
              {
                name:"View Document",
              cell: (e) => (
               
                  <>
                  <button style={{fontSize:12,margin:"4%"}} onClick={()=>this.viewDocument(e.docname)} className="btn btn-primary"><i className="fa fa-eye"></i> View</button>
            </>
              ),
              
              ignoreRowClick: true,
              allowOverflow: true,
              button: true,
            },
              
          
               {
                 name:"Action",
               cell: (e) => (
                
                   <>
                  <button onClick={()=>this.deleteDocument(e.documentid)} style={{fontSize:12,margin:"4%"}} className="btn btn-danger"><i className="fa fa-trash"></i> Delete</button> 
             </>
               ),
               
               ignoreRowClick: true,
               allowOverflow: true,
               button: true,
             },
         ];
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.do_search = this.do_search.bind(this);
        this.handleDisplay=this.handleDisplay.bind(this)
        this.handleDisplayClose=this.handleDisplayClose.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.handleUpdateImage=this.handleUpdateImage.bind(this)
        this.handleMaster=this.handleMaster.bind(this)
        this.handleUser=this.handleUser.bind(this)
        this.handleHome=this.handleHome.bind(this)
        this.handleJob=this.handleJob.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.hanldelClient=this.hanldelClient.bind(this) 
        this.viewResume=this.viewResume.bind(this) 
        this.handleSave=this.handleSave.bind(this)    
        this.handleNoticePeriod=this.handleNoticePeriod.bind(this)
        this.handleFullName=this.handleFullName.bind(this)
        this.handleAddDoc=this.handleAddDoc.bind(this)
        this.viewDocument=this.viewDocument.bind(this) 
        this.deleteDocument=this.deleteDocument.bind(this)
        this.handleDoc=this.handleDoc.bind(this)
        this.handleOnboarding=this.handleOnboarding.bind(this)
        this.handleApplicant=this.handleApplicant.bind(this)
        this.handleUpdate=this.handleUpdate.bind(this)
        this.handleTag=this.handleTag.bind(this)
        this.handleUntag=this.handleUntag.bind(this)
        this. handleSavenew=this. handleSavenew.bind(this)
      }
      handleUntag(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
        console.log(withoutQuotes)
        const data={userid:withoutQuotes,addJobpipelinedata:"untaggedjob",editapplicantid:this.state.jobid,editjobid:this.state.editjobid}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
            method: "POST",
      
            body: JSON.stringify(data), // data can be `string` or {object}!
      
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
      
            .catch((error) => console.error("Error:", error))
      
            .then((response) => {
              this.setState({successmsg:response.message})
              setTimeout(
                function() {
                  this.setState({showModal4:false,successmsg:"",selectcheckbox:0})
                  
                }
                .bind(this),
              2700
            );
              /** if(response.message=="UnTagged successfully."){
                   setTimeout(
                      function() {
                        this.setState({showModal4:false})
                      }
                      .bind(this),
                      2500
                    );
                  this.plusButtonData()
                }
                else{
                  window.location.reload()
                }  */
                   
                });
      }
      handleTag(e){
        this.setState({editjobid:e.value,errormsg:""})
          }
      handleUpdate(){
        if(this.state.editjobid==""){
          this.setState({errormsg:"Please Select a Job !"})
        }
        else{
          var username=JSON.stringify(localStorage.getItem("UserId"))
          const withoutQuotes = username.replaceAll('"', '');
          const data={ 
            userid:withoutQuotes,
            addTaggeddata:"apptagtojob",
            editapplicantid:this.state.jobid,
            editjobid:this.state.editjobid
          }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getTaggedinfo", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
              // console.log(response)
                  this.setState({errormsg:response.message})
                  window.location.reload();  
                  });
          
        }
      }
      handleApplicant(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      handleOnboarding(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
      handleDoc(e){
        this.setState({GovtIDType:e.label})
      }   
  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }
      deleteDocument(documentid){
        Swal.fire({
          title: 'Are you sure to delete this Document?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
      
            var username=JSON.stringify(localStorage.getItem("UserId"))
            const withoutQuotes = username.replaceAll('"', '');
            console.log(withoutQuotes)
            const data={userid:withoutQuotes,addAppliantdata: "deleteApplicantdoc",editapplicantid:this.state.jobid,documentid:documentid}
            console.log(data)
            fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
                method: "POST",
        
                body: JSON.stringify(data), // data can be `string` or {object}!
        
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
        
                .catch((error) => console.error("Error:", error))
        
                .then((response) => {
                  Swal.fire(
                    'Deleted!',
                     response.message,
                   
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                   2700
                );
                    });
           
          }
        })
      }
      handleFullName(e){
        this.setState({doctype:e.label,fileerrormsg:""})
          }
      handleNoticePeriod(e){
        this.setState({NoticePeriod:e.label})
          }
    openModal = () => {
   
      this.setState({editAppliantid:this.state.jobid})
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
        const data={ userid:withoutQuotes,addAppliantdata:"getsingleApplicant",editAppliantid:this.state.jobid}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
            console.log(response)
            response.result.map((e)=>{
              this.setState({
              
                Honorific:e.Honorific,
                fullname:e.fullname,
                Gender:e.Gender,
                EmailID:e.EmailID,
                NoticePeriod:e.NoticePeriod,
                AlternateEmail:e.AlternateEmail,
                MobileNo:e.MobileNo,
                AlternateMobile:e.AlternateMobile,
                DateofBirth:e.DateofBirth,
                PreviousOrg:e.PreviousOrg,
                PrevDesignation:e.PrevDesignation,
                TotalExp:e.TotalExp,
                RelevantExp:e.RelevantExp,
                HighestDegree:e.HighestDegree,
                CurrentCTC:e.CurrentCTC,
                ExpectedCTC:e.ExpectedCTC,
                VariableAmount:e.VariableAmount,
                FixedAmount:e.FixedAmount,
                Address:e.Address,
                CountryID:e.CountryID,
                CountryName:e.CountryName,
                StateID:e.StateID,
                StateName:e.StateName,
                CityID:e.CityID,
                CityName:e.CityName,
                SourceID:e.SourceID,
                SourceName:e.SourceName,
                JobTitle:e.JobTitle,
                Availablity:e.Availablity,
                NationalityID:e.NationalityID,
                NationalityName:e.NationalityName,
                Relocate:e.Relocate,
                GovtIDType:e.GovtIDType,
                GovtIDNumber:e.GovtIDNumber,
                IndustryID:e.IndustryID,
                IndustryName:e.IndustryName,
                linkedInurl:e.linkedInurl,
                KeySkills:e.KeySkills,
                Remarks:e.Remarks,
                AppStatus:e.AppStatus,
                AppStatusnam:e.AppStatusname,
                resume_name:e.resume_name,
                ProfilePhoto:e.ProfilePhoto,
                ApplicantName:e.fullname,
                SourceName:e.SourceName,
                preferredlocation:e.preferred_location,
                currentlocation:e.location
  
              })
            })
                  
                });
       
      this.setState({ showModal: true });
    };
    openModal5=()=>{
      this.setState({ showModal5: true });
    }
    closeModal5 = () => {
      this.setState({ showModal5: false });
    };
    openModal4=()=>{
      this.setState({ showModal4: true });
    }
    closeModal4 = () => {
      this.setState({ showModal4: false });
    };
    openModal3=()=>{
      this.setState({ showModal3: true });
    }
    closeModal = () => {
      this.setState({ showModal: false });
    };
    closeModal3 = () => {
      this.setState({ showModal3: false });
    };
    getBase64 = (file) => {
      return new Promise((resolve) => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          console.log("Called", reader);
          baseURL = reader.result;
          console.log(baseURL);
          resolve(baseURL);
        };
        console.log(fileInfo);
      });
    };
    handleSavenew() {
      if(this.state.IndustryID==""||this.state.IndustryID==null){
        this.setState({IndustryID:14,IndustryName:"Computer Software"})
      }
      else if(this.state.NationalityID==""||this.state.NationalityID==null){
        this.setState({NationalityID:"1",NationalityName:"Indian"})
      }
     
      else{
        this.setState({secondarybtn:1})
        var username = JSON.stringify(localStorage.getItem("UserId"));
        const withoutQuotes = username.replaceAll('"', "");
        const data = {
          userid: withoutQuotes,
          addAppliantdata: "editApplicantinfo",
          ApplicantName:this.state.ApplicantName,
          ProfilePhoto:this.state.ProfilePhoto,
          imagename:this.state.imagename,
          Gender:this.state.Gender,
          AlternateEmail:this.state.AlternateEmail,
          MobileNo:this.state.MobileNo,
          AlternateMobile:this.state.AlternateMobile,
          DateofBirth:this.state.DateofBirth,
          PreviousOrg:this.state.PreviousOrg,
          PrevDesignation:this.state.PrevDesignation,
          TotalExp:this.state.TotalExp,
          RelevantExp:this.state.RelevantExp,
          HighestDegree:this.state.HighestDegree,
          CurrentCTC:this.state.CurrentCTC,
          ExpectedCTC:this.state.ExpectedCTC,
          VariableAmount:this.state.VariableAmount,
          FixedAmount:this.state.FixedAmount,
          Address:this.state.Address,
          SourceID:this.state.SourceID,
          SourceName:this.state.SourceName,
          Availablity:this.state.Availablity,
          NationalityID:this.state.NationalityID,
          NationalityName:this.state.NationalityName,
          GovtIDType:this.state.GovtIDType,
          GovtIDNumber:this.state.GovtIDNumber,
          IndustryID:this.state.IndustryID,
          IndustryName:this.state.IndustryName,
          linkedInurl:this.state.linkedInurl,
          KeySkills:this.state.KeySkills,
          NoticePeriod:this.state.NoticePeriod,
          Remarks:this.state.Remarks,
          Relocate:this.state.Relocate,
          currentlocation:this.state.currentlocation,
          preferredlocation:this.state.preferredlocation,
          editapplicantid:this.state.editAppliantid,
          resume_name:"",
          uploadresumebase64:"",
    
        };
        console.log(data);
        fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
          method: "POST",
    
          body: JSON.stringify(data), // data can be `string` or {object}!
    
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
    
          .catch((error) => console.error("Error:", error))
    
          .then((response) => {
           
            console.log(response);
            var username=JSON.stringify(localStorage.getItem("UserId"))
            const withoutQuotes = username.replaceAll('"', '');
              const data={ userid:withoutQuotes,addAppliantdata:"getsingleApplicant",editAppliantid:this.state.editAppliantid}
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                      
                  console.log(response)
                  response.result.map((e)=>{
                    this.setState({
                    
                      Honorific:e.Honorific,
                      fullname:e.fullname,
                      Gender:e.Gender,
                      EmailID:e.EmailID,
                      NoticePeriod:e.NoticePeriod,
                      AlternateEmail:e.AlternateEmail,
                      MobileNo:e.MobileNo,
                      AlternateMobile:e.AlternateMobile,
                      DateofBirth:e.DateofBirth,
                      PreviousOrg:e.PreviousOrg,
                      PrevDesignation:e.PrevDesignation,
                      TotalExp:e.TotalExp,
                      RelevantExp:e.RelevantExp,
                      HighestDegree:e.HighestDegree,
                      CurrentCTC:e.CurrentCTC,
                      ExpectedCTC:e.ExpectedCTC,
                      VariableAmount:e.VariableAmount,
                      FixedAmount:e.FixedAmount,
                      Address:e.Address,
                      CountryID:e.CountryID,
                      CountryName:e.CountryName,
                      StateID:e.StateID,
                      StateName:e.StateName,
                      CityID:e.CityID,
                      CityName:e.CityName,
                      SourceID:e.SourceID,
                      SourceName:e.SourceName,
                      JobTitle:e.JobTitle,
                      Availablity:e.Availablity,
                      NationalityID:e.NationalityID,
                      NationalityName:e.NationalityName,
                      Relocate:e.Relocate,
                      GovtIDType:e.GovtIDType,
                      GovtIDNumber:e.GovtIDNumber,
                      IndustryID:e.IndustryID,
                      IndustryName:e.IndustryName,
                      linkedInurl:e.linkedInurl,
                      KeySkills:e.KeySkills,
                      Remarks:e.Remarks,
                      AppStatus:e.AppStatus,
                      AppStatusnam:e.AppStatusname,
                      resume_name:e.resume_name,
                      ProfilePhoto:e.ProfilePhoto,
                      ApplicantName:e.fullname,
                      SourceName:e.SourceName,
                      preferredlocation:e.preferred_location,
                      currentlocation:e.location
        
                    })
                  })
                })       
          });
      }
       
      
     
    }
    handleImage = (e) => {
      // let file = new FileReader();
      console.log(e.target.files[0]);
      let { file } = this.state;
  
      file = e.target.files[0];
  
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;
          console.log("File Is", file);
          this.setState({
            ProfilePhoto: result,
            file,
            //imagename:file["name"]
          },()=>{
            //this.handleSavenew()
          });
        })
        .catch((err) => {
          console.log(err);
        });
  
      this.setState({
        //  file: e.target.files[0]
      });
  
      this.setState({
        //companylogo: e.target.files[0]
      });
      var fileInput = false;
      if (e.target.files[0]) {
        fileInput = true;
      }
  
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              console.log(uri);
              this.setState({ newImage: uri });
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          console.log(err);
        }
      }
    };
    handleImageone = (e) => {
      // let file = new FileReader();
      console.log(e.target.files[0]);
      let { file } = this.state;
  
      file = e.target.files[0];
      if(file["size"]>500000){
        this.setState({fileerrormsg:"File Size should not be more than 500kb"})
        
      }
      else{
        this.getBase64(file)
     
        .then((result) => {
         
            file["base64"] = result;
        
            console.log("File Is", file);
            this.setState({
              uploadresumebase64: result,
              resume_name:file["name"],
              fileerrormsg:"",
              file,
            },()=>{
              //console.log(this.state.resume_name)
            });
          
          
        })
        .catch((err) => {
          console.log(err);
        });
  
      this.setState({
        //  file: e.target.files[0]
      });
  
      this.setState({
        //companylogo: e.target.files[0]
      });
      var fileInput = false;
      if (e.target.files[0]) {
        fileInput = true;
      }
  
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              console.log(uri);
              this.setState({ newImage: uri });
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          console.log(err);
        }
      }
      }
      
    };

    handleChange = (e) => {
      var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>-]/g;
      e.target.value = e.target.value.replace(regex, "");
      this.setState({
        [e.target.name]: e.target.value,
        jderror:"",
        jobcodeerror:"",
        payraterror:"",
        minexperror:"",
        maxexperror:"",
        primaryskillserror:""
      });
      
    };
    handleSave() {
      if(this.state.IndustryID==""||this.state.IndustryID==null){
        this.setState({IndustryID:14,IndustryName:"Computer Software"})
      }
      else if(this.state.NationalityID==""||this.state.NationalityID==null){
        this.setState({NationalityID:"1",NationalityName:"Indian"})
      }
     
      else{
        this.setState({secondarybtn:1})
        var username = JSON.stringify(localStorage.getItem("UserId"));
        const withoutQuotes = username.replaceAll('"', "");
        const data = {
          userid: withoutQuotes,
          addAppliantdata: "editApplicantinfo",
          ApplicantName:this.state.ApplicantName,
          ProfilePhoto:"",
          Gender:this.state.Gender,
          AlternateEmail:this.state.AlternateEmail,
          MobileNo:this.state.MobileNo,
          AlternateMobile:this.state.AlternateMobile,
          DateofBirth:this.state.DateofBirth,
          PreviousOrg:this.state.PreviousOrg,
          PrevDesignation:this.state.PrevDesignation,
          TotalExp:this.state.TotalExp,
          RelevantExp:this.state.RelevantExp,
          HighestDegree:this.state.HighestDegree,
          CurrentCTC:this.state.CurrentCTC,
          ExpectedCTC:this.state.ExpectedCTC,
          VariableAmount:this.state.VariableAmount,
          FixedAmount:this.state.FixedAmount,
          Address:this.state.Address,
          SourceID:this.state.SourceID,
          SourceName:this.state.SourceName,
          Availablity:this.state.Availablity,
          NationalityID:this.state.NationalityID,
          NationalityName:this.state.NationalityName,
          GovtIDType:this.state.GovtIDType,
          GovtIDNumber:this.state.GovtIDNumber,
          IndustryID:this.state.IndustryID,
          IndustryName:this.state.IndustryName,
          linkedInurl:this.state.linkedInurl,
          KeySkills:this.state.KeySkills,
          NoticePeriod:this.state.NoticePeriod,
          Remarks:this.state.Remarks,
          Relocate:this.state.Relocate,
          currentlocation:this.state.currentlocation,
          preferredlocation:this.state.preferredlocation,
          editapplicantid:this.state.editAppliantid,
          resume_name:this.state.resume_name,
          uploadresumebase64:this.state.uploadresumebase64,
          imagename:""
    
        };
        console.log(data);
        fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
          method: "POST",
    
          body: JSON.stringify(data), // data can be `string` or {object}!
    
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
    
          .catch((error) => console.error("Error:", error))
    
          .then((response) => {
            Swal.fire(
              response.message
            );
            setTimeout(
              function () {
                window.location.reload();
              }.bind(this),
              2500
            );
           this.setState({updatemsg:response.message})
    
            console.log(response);
          });
      }
       
      
     
    }
    viewDocument(docname){
      
      window.open(` https://mrecruit.in/sysload/Applicantdocuments/${docname}`)
    }
    viewResume(){
      //console.log(this.state.resume_name)
      window.open(`https://www.mrecruit.in/sysload/pdf/${this.state.resume_name}`)
    }
    viewResume(){
      //console.log(this.state.resume_name)
      if(this.state.resumenewpath==1){
        window.open(`https://www.mrecruit.in/sysload/pdf/${this.state.resume_name}`)
      }
      else if(this.state.resumenewpath==2){
        window.open(`https://www.mrecruit.in/sysload/resumenewpath/${this.state.resume_name}`)
      }
      else{

      }
    }

    hanldelClient(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
    handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      }
      handleJob() {
        let data = window.location.pathname.slice(0, 0);
      
        window.location.href = `${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
      }
    handleHome(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
      }
    handleUser(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    handleMaster(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    openModal2=()=>{
        this.setState({ showModal2: true });
      }
      closeModal2 = () => {
        this.setState({ showModal2: false,display:0 });
      };
    getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };
      handleImage = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              profilepic: result,
              ProfileImg:result,
              ProfilePhoto:result,
              file,
              imagename:file["name"]
            },()=>{
              this.handleSavenew()
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
      handleUpdateImage(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"editsinglephotoinfo",
            profilepic:this.state.profilepic
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 this.setState({message:response.message}) 
              //console.log(response)
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
               2500
            );
             if(response.message=="Profile Pic Updated successfully"){
              const withoutQuotes = username.replaceAll('"', '');
              const data={ userid:withoutQuotes,
                adduserdata:"getsinglephotoinfo",
              
             
            }
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                    
                 //console.log(response)
                     this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                     
                            
                     })
                 
                        
                      });
             }
              
                    
             
                    
                  });
      }
  

    handleAddDoc(){
      if(this.state.doctypename.includes(this.state.doctype)){
        this.setState({fileerrormsg:"Document Already Uploaded."})
      }
      else if(this.state.uploadresumebase64==""){
        this.setState({fileerrormsg:"Please Choose a File."})
      }
      else{
        this.setState({pleasewaittxt:"Please Wait...",fileerrormsg:""})
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes2 = username.replaceAll('"', '');
        const pdata1={ userid:withoutQuotes2,
          addAppliantdata:"Addapplicantdocinfo",
          editapplicantid:this.state.jobid,
          doctype:this.state.doctype,
          docname:this.state.resume_name,
          uploadresumebase64:this.state.uploadresumebase64
        
       
      }
      console.log(pdata1)
        fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
            method: "POST",
    
            body: JSON.stringify(pdata1), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              console.log(response)
              if(response.message=="Applicant Document successfully Added."){
                this.setState({pleasewaittxt:response.message,uploadresumebase64:""})
                window.location.reload()
                
              }
              else{
                console.log(response)
              }
               
                  
                });
      }
     
    }
    componentDidMount(){
      /* Tag To Job Get API Call Start */

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes12 = username.replaceAll('"', '');
      const pdatadrop={ userid:withoutQuotes12,
        dropdownlist_input:"getjobnamelistinfo",     
     
    }



 //const rdata111={ dropdownlist_input: "getjobnamelistinfo"}

 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(pdatadrop), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
      console.log(response)
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.jobid,
         "label" : d.jobname

       })) 

     this.setState({tagtojob:options})
           
         });
 /* Tag To Job Get  API Call End */
      /**Get All Documents API Starts */
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes2 = username.replaceAll('"', '');
      const pdata1={ userid:withoutQuotes2,
        addAppliantdata:"getApplicantdocinfo",
        editapplicantid:this.state.jobid
      
     
    }
    //console.log(pdata1)
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
          method: "POST",
  
          body: JSON.stringify(pdata1), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            if(response.message=="Applicant Documents Retrieved  Successfully."){
              let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
                
              });
              this.setState({ original_rows: data, rows: data,candidateDoc:response.result });
              console.log(response)
              response.result.map((e)=>{
                this.setState({doctypename:this.state.doctypename.concat(e.doctype)},()=>{
                  ///console.log(this.state.doctypename)
                })
              })
            }
            else{
              console.log(response)
            }
             
                
              });
      /**Get All Documents API Ends */
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes1 = username.replaceAll('"', '');
        const pdata={ userid:withoutQuotes1,
          adduserdata:"getsinglephotoinfo",
        
       
      }
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(pdata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              
              if(response.result.profilepic=="BASE64"){
                this.setState({ProfileImg:""},()=>{
                 
                })
            
             }
             else{
                this.setState({ProfileImg:response.result.profilepic},()=>{
                 
                })
            
             }
                  
                });
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,addAppliantdata:"getsingleApplicant",editAppliantid:this.state.jobid}
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
              console.log(response)
             response.result.map((e)=>{
              this.setState({
                fullname:e.fullname,
                JobTitle:e.JobTitle,
                CountryName:e.location,
                ApplicantID:e.ApplicantID,
                EmailID:e.EmailID,
                MobileNo:e.MobileNo,
                created_on:e.created_on,
                AppStatusname:e.AppStatusname,
                TotalExp:e.TotalExp,
                SourceName:e.SourceName,
                KeySkills:e.KeySkills,
                NoticePeriod:e.NoticePeriod,
                ExpectedCTC:e.ExpectedCTC,
                DateofBirth:e.DateofBirth,
                Relocate:e.Relocate,
                HighestDegree:e.HighestDegree,
                PrevDesignation:e.PrevDesignation,
                PreviousOrg:e.PreviousOrg,
                linkedInurl:e.linkedInurl,
                resume_name:e.resume_name,
                ProfilePhoto:e.ProfilePhoto,
                resumenewpath:e.resumenewpath



              })
             })
                    
                  });
    }
    handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
        handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
    render(){
        return(
            <div>
               {/**Untag Modal starts */}
       {this.state.showModal5 && (
        <div class="ft-modal" id="ft-untag-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Pipeline
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20}}>Would You like to Remove the Pipeline from this job?</span>

                        </div>
                       

                      </div>

                      <div className="row mt-5">
                        <div className="col-xl-2">
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:16}}>Comments</span>

                        </div>
                        <div className="col-xl-10">
                         <textarea style={{border:"1px solid black",backgroundColor:"#F3F0F0"}} rows={3} className="form-control editaccess">

                         </textarea>
                        </div>

                      </div>
                     <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2"> <button className="btn updatebtn" onClick={this.handleUntag} style={{color:"white",marginTop:"5%",width:"100%"}}>Yes</button>
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeModal5} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
  <span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal5}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Untag Modal ends */}
                {/**Add Document Modal Starts */}
                {this.state.showModal3 && (
         <div class="ft-modal" id="ft-adddocument-modal" style={{top:"0%"}}>
         <div class="ft-modal-content" style={{maxWidth:"50%"}}>
           <div class="ft-modal-header">
             
             <h5
                   class="modal-title"
                   id="exampleModalLabel"
                   style={{
                     textAlign: "center",
                     fontSize: 25,
                     fontWeight: "bold",
                   }}
                 >
                   Add Document
                 </h5>
               <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
          
           </div>
           <div className="ft-modal-body">
           <div className="row">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
  <Select 
       onChange={(e)=>this.handleFullName(e)}
       options={this.state.docdata} placeholder="Aadhar Card" components={{  IndicatorSeparator:() => null }}
      styles={style}/>
  </div>
<div className="col-xl-8">
<span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>&nbsp;(You Can Upload only PDF File)</span>
<input
                        id="upload"
                        type="file"
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white",width:"inherit",border: "2px solid rgb(66, 147, 254)"}}
                       onChange={this.handleImageone}
                        accept=".pdf"
                      />

</div>
<div className="text-center" style={{margin:"auto",marginTop:"5%"}}>
<span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>{this.state.fileerrormsg}</span><br/>
{this.state.fileerrormsg=="File Size should not be more than 500kb" || this.state.pleasewaittxt=="Please Wait..." ||this.state.fileerrormsg=="Document Already Uploaded."?<><button disabled className="btn btn-secondary updatebtn">Submit</button> </>:<><button onClick={this.handleAddDoc} className="btn btn-primary updatebtn">Submit</button> </>}<br/><br/>
<span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"blue",fontSize:15}}>{this.state.pleasewaittxt}</span>

</div>
</div>
            </div>
           <div class="ft-modal-footer">
             <a class="ft-modal-close"  data-dismiss="modal"
                      onClick={this.closeModal3}>
               <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
             </a>
           </div>
         </div>
       </div>
       )}
                {/**Add Document Modal Ends */}
              {/**Edit Applicant Modal Starts */}
              {this.state.showModal && (
         <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
         <div class="ft-modal-content  bigmodalcontent" >
           <div class="ft-modal-header">
             
             <h5
                   class="modal-title"
                   id="exampleModalLabel"
                   style={{
                     textAlign: "center",
                     fontSize: 25,
                     fontWeight: "bold",
                   }}
                 >
                   Edit Applicant Information
                 </h5>
               <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
          
           </div>
           <div class="ft-modal-body">
          
           <div className="row">
            

               <div
                 className="col-xl-12"
               
               >
                 {/** Personal Details Starts */}
                 <div className="row">
                <div className="col-xl-1">
                
                {this.state.ProfilePhoto == " " ? (
                        <>
                          <img src={Applicantimg} style={{ width: "100%" }} />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            src={`https://www.mrecruit.in/sysload/pdf/${this.state.ProfilePhoto}`} 
                            style={{
                              width: "135%",
                              border: "2px solid #4293fe",
                            }}
                          />
                        </>
                      )}
                      </div>
                     <div className="col-xl-2 text-center">
                      <input
                        id="upload"
                        type="file"
                        name="ProfilePhoto"
                        onChange={this.handleImage}
                        hidden

                      />
                      <label id="actual-btn" for="upload">
                        Upload
                      </label>
                    </div>
                    <div className="col-xl-9">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Candidate Full Name</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input name="ApplicantName" 
                   value={this.state.ApplicantName}
                   onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" placeholder="Enter Full Name"/>    
                   </div>
                   
                </div>
                 <div className="row mt-2">
                  
                   
                  
                   <div className="col-xl-4">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Key Skills</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input  value={this.state.KeySkills}  name="KeySkills" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Key Skills" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-4">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}} >Gender</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span> <br/>
                   <Select 
                   placeholder={this.state.Gender}
      onChange={(e)=>this.handleGender(e)}
      options={this.state.Genderdata}  components={{  IndicatorSeparator:() => null }}
                                styles={style}/>   
                   </div>
                   
                   <div className="col-xl-4">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Email ID</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input style={{height:"38px"}} 
                   value={this.state.EmailID} disabled
                   name="EmailID" onChange={this.handleChange} className="form-control adduser mt-1" type="email" placeholder="Email ID"/> 
                   </div>
                 </div>
                 <div className="row mt-2">
                
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Email ID</span>
                   <input name="AlternateEmail"
                   value={this.state.AlternateEmail} onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="email" placeholder="Alternate Email ID"/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Mobile Number</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input name="MobileNo" onChange={this.handleChange} style={{height:"38px"}} className="form-control 
                   
                   adduser mt-1"value={this.state.MobileNo} placeholder="Mobile Number"/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Mobile Number</span>
                   <input name="AlternateMobile" 
                   value={this.state.AlternateMobile}
                   onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="email" placeholder="Alternate Mobile Number"/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Date Of Birth</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input   value={this.state.DateofBirth} name="DateofBirth" onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="date"/>  
                   </div>
                 </div>
                 <div className="row mt-2">
                 
                 
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>LinkedIn Profile URL</span>
                   <input name="linkedInurl" value={this.state.linkedInurl}  onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter LinkedIn URL"/>  
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Nationality</span>
                   <Select 
                   onChange={(e)=>this.handleNationality(e)}
                   placeholder={this.state.NationalityName}
                   options={this.state.nationdata}  components={{  IndicatorSeparator:() => null }}
                                             styles={style}/>    
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Source</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <Select 
                   placeholder={this.state.SourceName}
      onChange={(e)=>this.handleSource(e)}
      options={this.state.sourcedata}  components={{  IndicatorSeparator:() => null }}
                                styles={style}/>    
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Industry</span>
                   <Select
                   placeholder={this.state.IndustryName} 
      onChange={(e)=>this.handleIndustry(e)}
      options={this.state.industrydata}  components={{  IndicatorSeparator:() => null }}
                                styles={style}/>
                   </div>
                 </div>
                 <div className="row mt-2">
               
                  
                 
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Open To Reclocate</span><br/>
                   <input type="radio"  name="Relocate" onChange={this.handleChange}  value="Yes"/>&nbsp;&nbsp;Yes&nbsp;&nbsp;
                   <input type="radio" className="mt-2" name="Relocate" onChange={this.handleChange} value="No" />&nbsp;&nbsp;No
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current / Last Organization</span>
                   <input className="form-control adduser mt-1"
                   value={this.state.PreviousOrg} 
                   name="PreviousOrg" onChange={this.handleChange}
                   placeholder="Previous Organization" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Preferred Location</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input value={this.state.preferredlocation} name="preferredlocation"  type="text" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Enter Preferred Location" style={{height:"38px"}}/>
                    </div>
                   
                   <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current Location</span>     <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span><br/>
                    <input name="currentlocation" value={this.state.currentlocation} onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter Current Location"/> 
                    </div>
                 </div>
                 <div className="row mt-2">
             
                
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Notice Period</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <Select 
       onChange={(e)=>this.handleNoticePeriod(e)}
       options={this.state.Noticeperiod}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                   </div>
                           <div className="col-xl-3">
                           <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Availablity</span>
                   <input value={this.state.Availablity}  name="Availablity" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Availablity" style={{height:"38px"}} />
                           </div>
                 
                           <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Previous Designation</span>
                   <input  value={this.state.PrevDesignation}  className="form-control adduser mt-1"
                   name="PrevDesignation" onChange={this.handleChange} 
                   placeholder="Previous Designation" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Total Experience</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input value={this.state.TotalExp}   name="TotalExp" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Total Experience" style={{height:"38px"}} />  
                   </div>
                 </div>
                 <div className="row mt-2">
                
                  
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Relevant Experience</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input value={this.state.RelevantExp}  name="RelevantExp" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Relevant Experience" style={{height:"38px"}}/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Highest Degree</span>
                   <input value={this.state.HighestDegree} name="HighestDegree" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Highest Degree" style={{height:"38px"}}/>
                   </div>
                 
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current CTC</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input value={this.state.CurrentCTC}  name="CurrentCTC" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Current CTC" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Expected CTC</span>
                   <input  value={this.state.ExpectedCTC} name="ExpectedCTC" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Expected CTC" style={{height:"38px"}} />  
                   </div>
                 </div>
                 <div className="row mt-2">
               
                  
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Variable Amount</span>
                   <input value={this.state.VariableAmount} name="VariableAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="VariableAmount" style={{height:"38px"}}/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Fixed Amount</span>
                   <input value={this.state.FixedAmount} name="FixedAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Fixed Amount" style={{height:"38px"}}/>
                   </div>
                 
                   <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Type</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                            <Select 
                            placeholder={this.state.GovtIDType}
              onChange={(e)=>this.handleDoc(e)}
              options={this.state.docdata}  components={{  IndicatorSeparator:() => null }}
                                        styles={style}/>
                    </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Number</span>
                   <input value={this.state.GovtIDNumber} name="GovtIDNumber" onChange={this.handleChange}  className="form-control adduser mt-1" placeholder="GovtID Number" style={{height:"38px"}}/> 
                   </div>
                  
                 </div>
                 <div className="row mt-2">
                  <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Candidate Resume</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13,color:"red"}}>(You can Upload only PDF File)</span>
                      <br/>
                     <div className="adduser text-center" style={{padding:"0.5%"}}>
                     <input
                        id="upload"
                        type="file"
                        
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white"}}
                        onChange={this.handleImageone}
                        accept=".pdf"
                      />
                     <br/>
                     <span style={{fontFamily:"Inter",fontWeight:"bold",color:"red",fontSize:14}}>{this.state.fileerrormsg}</span>
                     </div>
                    </div>
                  </div>
                 <div className="row mt-2">
                   <div className="col-xl-12">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Address</span>
                    <textarea  placeholder={this.state.Address} name="Address" onChange={this.handleChange} className="adduser form-control mt-1" rows="2">

                    </textarea>
                   </div>
                 </div>
                 <div className="row mt-2">
                   <div className="col-xl-12">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Remarks</span>
                    <textarea placeholder={this.state.Remarks} name="Remarks" onChange={this.handleChange} className="adduser form-control mt-1" rows="2">

                    </textarea>
                   </div>
                 </div>
                 <div className="row mt-3">
                   <div className="col-xl-10" >
                           
                   </div>
                           <div className="col-xl-1" style={{textAlign:"right"}}>
                            {this.state.fileerrormsg=="File Size should not be more than 500kb"?<>  <button className="btn btn-secondary updatebtn" style={{marginTop:"2%"}} disabled>Update</button></>:<>
                            <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={this.handleSave}>Update</button>
                            </>}
                         
                           </div>
                           <div className="col-xl-1" style={{textAlign:"right"}}>
                           <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={this.closeModal}>Close</button>
                           </div>
                 </div>
                 <div className="row">
     <div className="col-xl-4"></div>
     <div className="col-xl-4 mt-3">
       <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
     </div>
     <div className="col-xl-4"></div>
  </div>
                 {/**Personal Details Ends*/}
               </div>
             </div>
        
       
     
       {/*New Applicant End*/}
         
           </div>
           <div class="ft-modal-footer">
             <a class="ft-modal-close"  data-dismiss="modal"
                      onClick={this.closeModal}>
               <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
             </a>
           </div>
         </div>
       </div>
       )}
       {/**Edit Applicant Modal Ends */}
                         {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          {/* <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{ cursor: "pointer" }}>
              Home
            </li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleJob} style={{ cursor: "pointer" }}>
              Job Posting
            </li>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext">Applicants</li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClient}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleid==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleid==9 || this.state.userroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul> */}
          <Header/>
        </div>

        {/* Header Section Ends */}
         {/**Tag To Job Modal starts */}
      {this.state.showModal4 && (
        <div class="ft-modal" id="ft-tagtojob-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Tag To Job
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-3">
                          <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Select Job</span>

                        </div>
                        <div className="col-xl-8">
                         <Select options={this.state.tagtojob}   onChange={(e)=>this.handleTag(e)} components={{  IndicatorSeparator:() => null }}
                                 styles={style} />

                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-xl-8">

                        </div>
                        <div className="col-xl-2">
                          <button className="btn updatebtn" style={{width:"100%",color:"white"}} onClick={this.handleUpdate}>Update</button>

                        </div>
                        <div className="col-xl-2">
                          <button style={{width:"100%",color:"white"}} className="btn updatebtn" onClick={()=>this.closeModal3()}>Cancel</button>

                        </div>
                      </div>
                      <div className="text-center mt-5">
                        <span style={{color:this.state.errormsg=="Please Select a Job !"?"red":"#4293FE",fontFamily:"Inter",fontWeight:"bold"}}>{this.state.errormsg}</span>

                      </div>
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal4}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Tag To Job Modal ends */}
           {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}  
        <div
              style={{
                backgroundColor: "#EAF4FF",
                height: "auto",
                paddingBottom: "3%",
              }}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            {/**View Job Page Starts */}
                           <div style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                        <table>
                            <tr>
                                <th style={{width:"6%"}}>
                                  {this.state.ProfilePhoto==" "||this.state.ProfilePhoto==null?<><img src={UserImg} style={{width:"85%",borderRadius:"50%",marginTop:"-9em"}} /></>:<><img  src={`https://www.mrecruit.in/sysload/pdf/${this.state.ProfilePhoto}`} style={{width:"85%",borderRadius:"50%",marginTop:"-9em"}} /></>}
                                  </th>
                                <th style={{width:"65%"}}><h3>
                                {this.state.ApplicantID==null?<>{this.state.fullname}</>:<>{this.state.ApplicantID} - {this.state.fullname}</>}
                                  </h3>
                                    <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.JobTitle}</span><br/>
                                   <i className="fa fa-map-marker"></i> <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.CountryName==null||this.state.CountryName==""?<>Not Available</>:<>{this.state.CountryName}</>}</span><br/>
                                   <i className="fa fa-envelope-o"></i> <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.EmailID}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-mobile" style={{fontSize:20,verticalAlign:"text-bottom"}}></i> <span style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.MobileNo}</span><br/>
                                   <span style={{fontFamily:"Inter",fontWeight:"bold"}}>
                                   {this.state.NoticePeriod==null?<>Notice Period - Not Available</>:<>Notice Period - {this.state.NoticePeriod}</>}
                                    </span><br/>
                                    
                                    {this.state.resumenewpath==0?<>
                                      <button onClick={this.viewResume} disabled className="btn btn-primary updatebtn" style={{marginTop:"1%"}}>Resume Not Available</button>
                                    </>:<>
                                    <button onClick={this.viewResume} className="btn btn-primary updatebtn" style={{marginTop:"1%"}}>View Resume</button>
                                    </>}

                                </th>
                                {/**<th>
                                <a   href="#ft-untag-modal"  onClick={()=>this.openModal5()} ><button className="btn btn-primary updatebtn" style={{marginTop:"-12em"}}>Untag</button></a>
                                  </th> */}
                                <th>
                                <a   href="#ft-tagtojob-modal"  onClick={()=>this.openModal4()} ><button className="btn btn-primary updatebtn" style={{marginTop:"-12em"}}>Tag To Job</button></a>
                                  </th>
                                <th>
                                <a   href="#ft-edituser-modal"  onClick={()=>this.openModal()} ><button className="btn btn-primary updatebtn" style={{marginTop:"-12em"}}>Edit Applicant</button></a>
                                  </th>
                                {/* <th><button className="btn btn-primary updatebtn" style={{marginTop:"-12em"}} onClick={this.handleApplicant}>Back</button></th> */}
                            </tr>
                        </table>

                           </div>
                     {/**View Job Page End */}
                              {/**Documents Section Starts */}
                              <div   style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: this.state.candidateDoc.length==0?"34em":"auto",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-sm-9">
                                <h5 style={{fontFamily:"Inter",fontWeight:"bold"}}>
                                   Documents
                                </h5>
                            </div>
                            <div className="col-xl-3" style={{textAlign:"right"}}>
                            <a   href="#ft-adddocument-modal"  onClick={()=>this.openModal3()} > <button className="btn btn-primary" style={{fontSize:13,fontFamily:"Poppins Light",fontWeight:"bold"}}><i className="fa fa-plus-circle" style={{fontSize:17,cursor:"pointer"}}></i>&nbsp;&nbsp;Add Document</button></a>
                             
                         
                            </div>
                        </div>

                        <hr/>
                        {this.state.candidateDoc.length==0?<>
                          <div className="text-center" style={{marginTop:"10%"}}>
                          <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>No Data Available.</span>
                          


                        </div>
                        <div className="row">
                        <iframe srcdoc="{this.state.EmailID}" title="{this.state.EmailID}"style={{fontFamily:"Inter",fontWeight:"bold"}} onChange={this.handleChange}></iframe>

                        </div>
                        </>:<>
                        <div className="row">
                          <div className="col-md-4">
                          <div class="input-group">
                  <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                              onKeyDown={this.handleKeyDown} />
                  <div class="input-group-append">
                    <button class="btn btn-primary" style={{marginBottom:"1em"}} type="button" onChange={this.handleInputChange}
                              onClick={this.handleKeyDown}>
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>

                          </div>

                        </div>
                        <DataTable
                defaultSortField="id"
               
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                responsive
                />
                       {/** <table border="1" style={{width:"-webkit-fill-available",border:"1px solid #C9C9C9",textAlign:"center"}} cellPadding="10px">
          <thead style={{backgroundColor:"#4293FE",color:"white",fontSize:14}} >
                  <tr>
                    
                    <th>Document Name</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>View Document</th>
                    <th>Action</th>

                  </tr>
            </thead>
            <tbody style={{fontFamily:"Poppins Light",fontSize:12,fontWeight:"bold",background:"white"}}>
              {this.state.candidateDoc.map((e)=>(
                <tr>
                  <td>{e.doctype}</td>
                  <td>{e.createdbyname}</td>
                  <td>{e.CreatedDate}</td>
                  <td><button style={{fontSize:12}} onClick={()=>this.viewDocument(e.docname)} className="btn btn-primary"><i className="fa fa-eye"></i> View</button></td>
                  <td><button onClick={()=>this.deleteDocument(e.documentid)} style={{fontSize:12}} className="btn btn-danger"><i className="fa fa-trash"></i> Delete</button></td>
                </tr>
              ))}

              </tbody>
                          </table> */}
                          </>}
                        
                       
                    </div>
                        {/**Documents Section Ends */}
                       

                  
                        </div>
                     <div className="col-xl-3" style={{
                    padding: "2%",
                    marginTop: "0.8%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                   marginLeft:"-1%",
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%",
                    marginBottom:"0.7%"
                   
                  }}>
                    <div className="row">
                       
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Created By & On</span><br/>
                        <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>
                        {this.state.created_on==null?<>Not Available</>:<> Career Portal On {this.state.created_on}</>}
                         </span>
                        </div>

                    </div>
                   
                    <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Applicant Status</span><br/>
                        <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.AppStatusname}</span>
                        </div>

                    </div>
        
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Source</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.SourceName}</span>
                       </div>

                   </div>
                 
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Skype ID</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>N/A</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-8">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Skills</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.KeySkills==null||this.state.KeySkills==""?<>Not Available</>:<>{this.state.KeySkills}</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Experience</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.TotalExp==""||this.state.TotalExp==null?<>Not Available</>:<>{this.state.TotalExp} Years</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Expected Pay</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.ExpectedCTC==""||this.state.ExpectedCTC==null?<>Not Available</>:<>₹ {this.state.ExpectedCTC}</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Date Of Birth</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.DateofBirth==null || this.state.DateofBirth==""?<>
                       Not Available
                       </>:<>{this.state.DateofBirth}</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Relocation</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.Relocate==null || this.state.Relocate==""?<>Not Available</>:<>{this.state.Relocate}</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Highest Degree</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.HighestDegree==null || this.state.HighestDegree==""?<>Not Available</>:<>{this.state.HighestDegree}</>}</span>
                       </div>

                   </div>
                 
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Current / Last Organization</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.PreviousOrg==null?<>Not Available</>:<>{this.state.PreviousOrg}</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>Previous Designation</span><br/>
                       <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>{this.state.PrevDesignation==null || this.state.PrevDesignation==""?<>Not Available</>:<>{this.state.PrevDesignation}</>}</span>
                       </div>

                   </div>
                   <div className="row" style={{marginTop:"5%",lineHeight:"18px"}}>
                       
                       <div className="col-xl-12">
                       <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15,display:"contents"}}>LinkedIn Profile</span><br/>
                       <a className="editaccess" target="_blank" href={this.state.linkedInurl} style={{fontFamily:"Poppins Light",fontSize:14,color:"#00539c",fontWeight:"bold"}}>{this.state.linkedInurl==null || this.state.linkedInurl==""?<>Not Available</>:<>{this.state.linkedInurl}</>}</a>
                       </div>

                   </div>
                     </div>

                    </div>
                   
                  
                  
                </div>
        </div>
            </div>
        )
    }
}