import React from "react";
import "./style.css";
import Mainteclogo  from "../images/mrecruit-19.png";
export default class Otppage extends React.Component {
  constructor(){
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]

    var data5=splitURL[5]
    let decodedemail=window.atob(data1)
    //console.log(splitURL)
    super();
    this.state={
      userotp:"",
      otpvalidation:"",
      email:decodedemail,
      useremail:data1,
      userid:data2,
      username:data3,
      roleid:data5
    }
   this.handleConfirm=this.handleConfirm.bind(this)
  }



/* OTP API Call Starts */

  handleConfirm(){
    if(this.state.userotp==""){
     this.setState({otpvalidation:"Please Enter OTP"})
    }
    
    else {
      const data = {

        email:this.state.email,
        userotp:this.state.userotp
       
  
      }
      console.log(data)
  
         fetch("https://www.mrecruit.in:5200/api/usergetOTP", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            console.log(response)
            if(response.message=="Your OTP has been verified successfully."){
              this.setState({otpvalidation:response.message})
              let data=window.location.pathname.slice(0,0);
             window.location.href=`${data}/dashboard/${this.state.useremail}/${this.state.userid}/${this.state.username}/${this.state.roleid}`
            }
            else{
            //  let data=window.location.pathname.slice(0,0);
            //  window.location.href=`${data}/dashboard/${this.state.useremail}/${this.state.userid}/${this.state.username}/${this.state.roleid}`
              this.setState({otpvalidation:response.message})
            }
            
          });
    }
    
   
   
  }
  /* OTP API Call End */

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
     otpvalidation:""
    });
    
  };
  render() {
    return (
      
      <div>
          {/* Background Image Starts */}
        <div className="backgroundimg">
           <img src={Mainteclogo} className="mainteclogo" style={{padding:"3%"}} />

            <div className="otpbox">
           
            </div>
            {/* OTP Field Starts*/}
           
              <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4" style={{marginTop:"12%"}}>
                        <input autoComplete="off" className="form-control usernamebox shadow-none inputboxxx" type="text" placeholder="Enter OTP" name="userotp" onChange={this.handleChange} />
                    </div>
                    <div className="col-sm-3"></div>
             </div>
            
            
              {/* OTP Field End*/}

             

         
             {/* Confirm button  Starts*/}
             <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-5 ">
                      <button className="btn loginbtn" onClick={this.handleConfirm}>Confirm</button>
                     {/** OTP Confirm text starts */}
                  <div className="emailvalidation">
                    <span>{this.state.otpvalidation}</span>
                    </div>
                  {/** OTP confirm text ends*/}
                    </div>
                    <div className="col-sm-3"></div>
             </div>
             {/* Confirm button End*/}
           
            <span className="otpmrecruitheading">mRecruit</span>
             
             <span className="confirmotptxt">
             
             Confirm OTP
              
              </span>
            
        </div>
      </div>
    );
  }
}
