import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import ClockIMG from "../images/clockimg.png";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import Resizer from "react-image-file-resizer";
import UserImg from "../images/usering.png";
import Clientimage  from "../images/clientimage.png";
import Select from 'react-select'
import Header from "./Header";
const style = {
    control: (base, state) => ({
      ...base,
     border:"1px solid #A0BED8",
      borderRadius:"1px",
     marginTop:"1.5%",
     backgroundColor:"#EAF4FF",
     color:"black",
     borderRadius:"2px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: "1px solid #A0BED8",
        borderRadius:"1px",
        backgroundColor:"#EAF4FF",
        color:"black"
      }
    }),
   
  };
  
export class ViewUser extends React.Component{
    
    constructor(){
        super();
        var newURL=window.location.pathname;
        var splitURL=newURL.toString().split("/");
        var data1=splitURL[2]
        var data2=splitURL[3]
        var data3=splitURL[4]
        var data4=splitURL[5]
        var data5=splitURL[6]
       let decodedid=window.atob(data5)
       let decodedrolename=window.atob(data3)
       if (localStorage.getItem("Username") == null) {
            

        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`


       } else {
        var username=JSON.stringify(localStorage.getItem("Username"))
        
       }

        const withoutQuotes = username.replaceAll('"', '');
        this.state={
            user_name:withoutQuotes,
            encodedid:data1,
            encodedname:data2,
            encodedemail:data3,
            user_email:decodedrolename,
            encodedroleid:data4,
            display:0,
            clientid:decodedid,
           
            showModal2:false,
          
            showModal: false,
            on:0,
            ProfileImg:"",
    
            rolesdata:[],
            teamdata:[],
            reportingtodata:[],
            firstname:"",
            lastname:"",
            username:"",
            email:"",
            workinglocation:"",
            password:"",
            userrole:"",
            userrolename:"",
            userstatus:"",
            mobile:"",
            reportingto:"",
            reporting_toname:"",
            BusinessUnit:"",
            adminprivileges:"No",
            dob:"",
            doj:"",
            teamids:"",
            teamname:"",
            employeeID:"",
            AdminPrivileges:"",
            edituserid:"",
            userstatusname:"",
            dobchange:"",
            dojchange:"",
            updatemsg:"",
            profilepic:" ",
            timezonedata:[],
            timezonename:"",
            timezoneid:"",  
             userstausdata:[
              { value: '1', label: 'Active'},
             
             
            ],
            middlename:"",
            showpwd:0 ,original_rows: [], rows: [], search: "" ,
            imagename :"",
            Sourcesemail:[],
      sourceemailid:"",
      sourceemailname:""



        }
        this.handleDisplay=this.handleDisplay.bind(this)
        this.handleDisplayClose=this.handleDisplayClose.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.handleBack=this.handleBack.bind(this)
        this.handleUpdateImage=this.handleUpdateImage.bind(this)
        this.handleMaster=this.handleMaster.bind(this)
        this.handleUser=this.handleUser.bind(this)
        this.handleHome=this.handleHome.bind(this)
        this.handleJob=this.handleJob.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.hanldelClient=this.hanldelClient.bind(this) 
        this.handleCountry=this.handleCountry.bind(this)
        this.handleUpdate=this.handleUpdate.bind(this) 
        this.handleReport=this.handleReport.bind(this)  
        this.handleIndustry=this.handleIndustry.bind(this)
        this.handleClosepwd=this.handleClosepwd.bind(this)
        this.handleShowpwd=this.handleShowpwd.bind(this)   
        this.handleUserstatus=this.handleUserstatus.bind(this) 
        this.handleBusiness=this.handleBusiness.bind(this)
        this.handleRoles=this.handleRoles.bind(this)
        this.handleTime=this.handleTime.bind(this)
        this.handleTeamdata=this.handleTeamdata.bind(this)
        this.handleOnboarding=this.handleOnboarding.bind(this)
        this.handleUpdatenew=this.handleUpdatenew.bind(this)
        this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
    this.handleCheckbox1=this.handleCheckbox1.bind(this)
      }
      handleCheckboxclose1(){
     
        this.setState({sourceemailid : ""})
        this.setState({sourceemailname : ""})
      
      
    }
    handleCheckbox1(id,name){
      this.setState({sourceemailid : ""})
      this.setState({sourceemailname : ""})
      this.setState({sourceemailid:id})
      this.setState({sourceemailname:name},()=>{
        console.log(this.state.sourceemailname)
      })
    
    }
      handleOnboarding(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    handleTeamdata(e){
      this.setState({teamname:e.label,teamids:e.value},()=>{
        
      })
        }
    handleTime(e){
      this.setState({timezoneid:e.value,timezonename:e.label})
        }
    handleRoles(e){
      this.setState({userrolename:e.label,userrole:e.value},()=>{
        //console.log(this.state.userrole)
      })
        }
    handleBusiness(e){
      this.setState({BusinessUnit:e.label,},()=>{
       
      })
        }
    handleUserstatus(e){
      this.setState({userstatus:e.value}) 
    }
    handleClosepwd(){
      this.setState({showpwd:0})
    }
  
    
    handleShowpwd(){
      this.setState({showpwd:1})
    }
    handleIndustry(e){
      this.setState({Industryname:e.label,Industryid:e.value})
    }
    handleReport(e){
      this.setState({reporting_toname:e.label,reportingto:e.value},()=>{
        //console.log(this.state.userrole)
      })
    }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handleUpdatenew(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes1 = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes1,
        edituserid:this.state.edituserid,
        adduserdata:"edituserinfo",
        firstname:this.state.firstname,
        lastname:this.state.lastname,
        username:this.state.username,
        email:this.state.email,
        workinglocation:this.state.workinglocation,
        password:this.state.password,
        userrole:this.state.userrole,
        userrolename:this.state.userrolename,
        userstatus:this.state.userstatus,
        mobile:this.state.mobile,
        reportingto:this.state.reportingto,
        reporting_toname:this.state.reporting_toname,
        BusinessUnit:"Maintec Technologies",
        adminprivileges:this.state.AdminPrivileges,
        dob:this.state.dob,
        doj:this.state.doj,
        teamids:this.state.teamids,
        teamname:this.state.teamname,
        timezoneid:this.state.timezoneid,
        timezonename:this.state.timezonename,
        employeeID:this.state.employeeID,
        profilepic:this.state.profilepic,
        middlename:this.state.middlename,
        imagename:this.state.imagename
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 console.log(response)
                 var username=JSON.stringify(localStorage.getItem("UserId"))
                 const withoutQuotes = username.replaceAll('"', '');
                   const data={ userid:withoutQuotes,adduserdata:"getsingleuserinfo",edituserid:this.state.edituserid}
                   console.log(data)
                   fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                       method: "POST",
               
                       body: JSON.stringify(data), // data can be `string` or {object}!
               
                       headers: { "Content-Type": "application/json" },
                     })
                       .then((res) => res.json())
               
                       .catch((error) => console.error("Error:", error))
               
                       .then((response) => {
                           
                       console.log(response)
                       response.result.map((e)=>{
                         this.setState({
                           firstname:e.firstname,
                           middlename:e.middlename,
                           lastname:e.lastname,
                           username:e.displayname,
                           email:e.email,
                           workinglocation:e.branchlocation,
                           employeeID:e.employeeID,
                           userrolename:e.userrolename,
                           userrole:e.userroleids,
                           reportingto:e.reporting_to,
                           reporting_toname:e.reporting_toname,
                           teamids:e.teamids,
                           teamname:e.teamname,
                           AdminPrivileges:e.AdminPrivileges,
                           dob:e.dobchange,
                           doj:e.dojchange,
                           dobchange:e.dob,
                           dojchange:e.doj,
                           profilepic:e.profilepic,
                           userstatus:e.userstatus,
                           BusinessUnit:e.BusinessUnit,
                           mobile:e.mobile,
                           userstatusname:e.userstatusname,
                           timezonename:e.timezonename,
                           timezoneid:e.timezoneid
             
                         })
                       })
                      })
                  });
        }
    handleUpdate(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes1 = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes1,
        edituserid:this.state.edituserid,
        adduserdata:"edituserinfo",
        firstname:this.state.firstname,
        lastname:this.state.lastname,
        username:this.state.username,
        email:this.state.email,
        workinglocation:this.state.workinglocation,
        password:this.state.password,
        userrole:this.state.userrole,
        userrolename:this.state.userrolename,
        userstatus:this.state.userstatus,
        mobile:this.state.mobile,
        reportingto:this.state.reportingto,
        reporting_toname:this.state.reporting_toname,
        BusinessUnit:this.state.BusinessUnit,
        adminprivileges:this.state.AdminPrivileges,
        dob:this.state.dob,
        doj:this.state.doj,
        teamids:this.state.teamids,
        teamname:this.state.teamname,
        timezoneid:this.state.timezoneid,
        timezonename:this.state.timezonename,
        employeeID:this.state.employeeID,
        profilepic:"",
        middlename:this.state.middlename,
        imagename:this.state.imagename,
        sourceemailid:this.state.sourceemailid,
        sourceemailname:this.state.sourceemailname
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                this.setState({updatemsg:response.message})
                setTimeout(
                  function() {
                   
                    window.location.reload()
                  }
                  .bind(this),
                  2500
              );
                 console.log(response)
        
                    
                  });
        }
    handleCountry(e){
      this.setState({Countryname:e.label,Countryid:e.value,},()=>{
        const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({statedata:options})
                  
                });
        })
    }
    closeModal = () => {
        this.setState({ showModal: false });
      };
    handleBack(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
    }
    hanldelClient(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
    handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      }
      handleJob() {
        let data = window.location.pathname.slice(0, 0);
      
        window.location.href = `${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`;
      }
    handleHome(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
      }
    handleUser(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    handleMaster(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    openModal = (id) => {
      this.setState({edituserid:id})
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
        const data={ userid:withoutQuotes,adduserdata:"getsingleuserinfo",edituserid:id}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
            console.log(response)
            response.result.map((e)=>{
              this.setState({
                firstname:e.firstname,
                middlename:e.middlename,
                lastname:e.lastname,
                username:e.displayname,
                email:e.email,
                workinglocation:e.branchlocation,
                employeeID:e.employeeID,
                userrolename:e.userrolename,
                userrole:e.userroleids,
                reportingto:e.reporting_to,
                reporting_toname:e.reporting_toname,
                teamids:e.teamids,
                teamname:e.teamname,
                AdminPrivileges:e.AdminPrivileges,
                dob:e.dobchange,
                doj:e.dojchange,
                dobchange:e.dob,
                dojchange:e.doj,
                profilepic:e.profilepic,
                userstatus:e.userstatus,
                BusinessUnit:e.BusinessUnit,
                mobile:e.mobile,
                userstatusname:e.userstatusname,
                timezonename:e.timezonename,
                timezoneid:e.timezoneid,
                sourceemailid:e.sourceemailid
              })
            })
                  
                });
       
      this.setState({ showModal: true });
    };
    
    openModal2=()=>{
        this.setState({ showModal2: true });
      }
      closeModal2 = () => {
        this.setState({ showModal2: false });
      };
    getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };
      handleImage = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            //console.log("File Is", file);
            this.setState({
              imagename: file["name"],
              profilepic:result,
              ProfileImg:result,
              file,
            },()=>{
              this.handleUpdatenew()
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
      handleImage1 = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              clientlogo: result,
             
              file,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
      handleUpdateImage(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"editsinglephotoinfo",
            profilepic:this.state.profilepic
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 this.setState({message:response.message}) 
              //console.log(response)
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
               2500
            );
             if(response.message=="Profile Pic Updated successfully"){
              const withoutQuotes = username.replaceAll('"', '');
              const data={ userid:withoutQuotes,
                adduserdata:"getsinglephotoinfo",
              
             
            }
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                    
                 //console.log(response)
                     this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                     
                            
                     })
                 
                        
                      });
             }
              
                    
             
                    
                  });
      }
   
    componentDidMount(){
       /* Time Zone API Call Start */
    const timedata={ dropdownlist_input: "gettimezoneinfo"}
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(timedata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({timezonedata:options})
              
            });
    /* Time Zone API Call End */
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes11 = username.replaceAll('"', '');
const tdata1={ userid: withoutQuotes11,
adduserdata:"getteamsinfo"
}
console.log(tdata1)
fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
   method: "POST",

   body: JSON.stringify(tdata1), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.id,
       "label" : d.name

     })) 

   this.setState({teamdata:options})
         
       });
       /* Source Email API Start */
      const semail={ dropdownlist_input: "getsourceemaillistinfo",userid:withoutQuotes11}
      //console.log(menudata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(semail), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          this.setState({Sourcesemail:data})
                
              });
      /* Source Email API Call End */
      const rodata={ dropdownlist_input: "getuserrole"}
      console.log(rodata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rodata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({rolesdata:options})
                
              });
      /* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
       /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */
       /* Country API Call Start */
  const tdata={ dropdownlist_input: "getcountryinfo"
}
 console.log(tdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(tdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         console.log(response)
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.countryid,
         "label" : d.conuntryname

       })) 

     this.setState({countrydata:options})
           
         });
 /* Country API Call  End */

        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes1 = username.replaceAll('"', '');
        const pdata={ userid:withoutQuotes1,
          adduserdata:"getsinglephotoinfo",
        
       
      }
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(pdata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              
              if(response.result.profilepic=="BASE64"){
                this.setState({ProfileImg:""},()=>{
                 
                })
            
             }
             else{
                this.setState({ProfileImg:response.result.profilepic},()=>{
                 
                })
            
             }
                  
                });
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,adduserdata:"getsingleuserinfo",edituserid:Number(this.state.clientid)}
         console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
              console.log(response)
              response.result.map((e)=>{
                this.setState({
                  firstname:e.firstname,
                  middlename:e.middlename,
                  lastname:e.lastname,
                  username:e.displayname,
                  email:e.email,
                  workinglocation:e.branchlocation,
                  employeeID:e.employeeID,
                  userrolename:e.userrolename,
                  userrole:e.userroleids,
                  reportingto:e.reporting_to,
                  reporting_toname:e.reporting_toname,
                  teamids:e.teamids,
                  teamname:e.teamname,
                  AdminPrivileges:e.AdminPrivileges,
                  dob:e.dobchange,
                  doj:e.dojchange,
                  dobchange:e.dob,
                  dojchange:e.doj,
                  profilepic:e.profilepic,
                  userstatus:e.userstatus,
                  BusinessUnit:e.BusinessUnit,
                  mobile:e.mobile,
                  userstatusname:e.userstatusname,
                  timezonename:e.timezonename,
                  timezoneid:e.timezoneid
    
                })
              })
                    
                  });
    }
    handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
        handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
    render(){
        return(
           
            <div>
                {/**Edit User Modal Starts */}
                {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content  bigmodalcontent">
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit User Information
                  </h5>
          
           
            </div>
            <div class="ft-modal-body">
           
            {/**Add user for starts */}
 <div>
  <hr/>
  <div className="row mt-1">
    <div className="col-xl-1">
    {this.state.profilepic.length==1||this.state.profilepic==null||this.state.profilepic=="BASE64"?<>
<img src={UserImg} 
 
 style={{width:"107%"}}  />
</>:<>  <img src={`https://www.mrecruit.in/sysload/profiledocuments/${this.state.profilepic}`}
 
 style={{width:"120%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-2">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
  <div className="row">
    <div className="col-xl-12">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>User Name</span>
    </div>
  </div>
 
  <div className="row">
    <div className="col-xl-4">
     <input className="form-control adduser mt-2" 
     value={this.state.firstname}
     placeholder="First Name" name="firstname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-4">
     <input className="form-control  adduser mt-2" name="middlename" onChange={this.handleChange}  value={this.state.middlename} placeholder="Middle Name"/>
    </div>
    <div className="col-xl-4">
     <input name="lastname"
     value={this.state.lastname}
     onChange={this.handleChange} className="form-control adduser mt-2" placeholder="Last Name"/>
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Display Name</span>
     <input type="text" name="username"
     value={this.state.username}
     onChange={this.handleChange} className="form-control adduser mt-2" />
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Password</span>
    {this.state.showpwd==0?<>

<div class="input-group mt-2">

<input type="password" style={{borderRadius:"4px"}}  class="form-control  shadow-none adduser"  placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} />
<button type="button"  class="toggle">
<i id="eyeIcon" class="fa fa-eye" style={{color:"black"}}  onClick={this.handleShowpwd}></i></button>
</div>
          </>:null}
{this.state.showpwd==1?<>
  <div class="input-group mt-2">

<input style={{borderRadius:"4px"}} type="text" value={this.state.password}  class="form-control  shadow-none adduser"  placeholder="Password" name="password" onChange={this.handleChange} />
<button type="button"  class="toggle">
<i id="eyeIcon" class="fa fa-eye-slash" style={{color:"black"}}  onClick={this.handleClosepwd}></i></button>
</div>
 
</>:null} 
    
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Contact Number</span>
     <input name="mobile" 
      value={this.state.mobile}
     onChange={this.handleChange}  className="form-control adduser mt-2" />
    </div>
  </div>
  <div className="row mt-2">
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Email ID</span>
     <input type="email" 
     value={this.state.email}
     name="email" onChange={this.handleChange}
     className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Working Location</span>
      <input type="text" 
       value={this.state.workinglocation}
      name="workinglocation" onChange={this.handleChange}className="form-control adduser mt-2" />
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Date of Birth</span>
     <input type="text"
      name="dob"
      onFocus={(e) =>
        (e.currentTarget.type = "date")
      }
      onBlur={(e) =>
        (e.currentTarget.type = "date")
      }
      value={this.state.dob}
      onChange={this.handleChange} className="form-control adduser mt-2" />
    </div>
  </div>
 
  <div className="row mt-2">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Joining Date</span>
     <input type="text"
      value={this.state.doj}
      onFocus={(e) =>
        (e.currentTarget.type = "date")
      }
      onBlur={(e) =>
        (e.currentTarget.type = "date")
      }
      name="doj" onChange={this.handleChange}
     className="form-control adduser mt-2" />
     
    
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Time Zone</span>
    <Select 
   onChange={this.handleTime}
   placeholder={this.state.timezonename}
   options={this.state.timezonedata}  components={{  IndicatorSeparator:() => null }}
                             styles={style}/>

    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Employee ID</span>
    <input type="text"
    name="employeeID"
    value={this.state.employeeID}
    className="form-control adduser mt-2" onChange={this.handleChange} />
     
    
    </div>
  </div>
  <div className="row mt-2">
    
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Admin Privilleges</span><br/>
   {this.state.on==1 || this.state.AdminPrivileges=="Yes"?<>
    <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handlecloseAdmin}
    type="checkbox" id="myToggle" checked />
    <div class="toggle__fill"></div>
</label>
   </>:<> <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handleAdmin}
    type="checkbox" id="myToggle"  />
    <div class="toggle__fill"></div>
</label></>}
   
<div style={{fontWeight:"bold",fontFamily:"Montserrat",marginLeft:"25%",marginTop:"-9%"}}>
  {this.state.on==1 || this.state.AdminPrivileges=="Yes"?<>
    <span>On</span>
  </>:<> <span>Off</span></>}
 </div>

    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>User Status</span>
    <Select 
    placeholder={this.state.userstatusname}
    onChange={(e)=>this.handleUserstatus(e)}
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Add Source Email ID</span>
    <div className="mt-1" style={{height:"5.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.Sourcesemail.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.sourceemailid==e.id?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.sourceemailid!=e.id?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
    </div>
  </div>
  <div className="row mt-2">
   
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Roles</span>
    <Select
    onChange={(e)=>this.handleRoles(e)}
    placeholder={this.state.userrolename}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Reporting To</span>
    <Select 
    placeholder={this.state.reporting_toname}
    onChange={(e)=>this.handleReport(e)} options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
     
    
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team</span>
    <Select
    placeholder={this.state.teamname}
    onChange={this.handleTeamdata} options={this.state.teamdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>
 
   <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
 
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
                {/**Edit User Modal Ends */}
                         {/* Header Section Starts */}

        <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{ cursor: "pointer" }}>
              Home
            </li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleJob} style={{ cursor: "pointer" }}>
              Job Posting
            </li>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li >Applicants</li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li  onClick={this.hanldelClient} style={{ cursor: "pointer" }}>
              Clients
            </li>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleOnboarding} style={{cursor:"pointer"}}>Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext" style={{ cursor: "pointer" }} onClick={this.handleUser}>
                  User Management
                </li>
          </ul>
          <Header/>
        </div>

        {/* Header Section Ends */}
        
           {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={`https://www.mrecruit.in/sysload/profiledocuments/${this.state.ProfileImg}`}
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}  
        <div
              style={{
                backgroundColor: "#EAF4FF",
                height: "45em",
                paddingBottom: "3%",
              }}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            {/**View Client Page Starts */}
                           <div style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                        <table>
                            <tr>
                                <th style={{width:"6%",verticalAlign:"sub"}}>
                                  {this.state.profilepic==null||this.state.profilepic=="BASE64"||this.state.profilepic.length==1||this.state.profilepic.length==0?<>
                                  <img src={UserImg} style={{width:"74%",borderRadius:"53%"}} /></>:<>
                                  <img src={`https://www.mrecruit.in/sysload/profiledocuments/${this.state.profilepic}`} className="userlogo" />
                                  </>}
</th>
                                <th style={{width:"90%",lineHeight:"30px"}}><h3>{this.state.firstname} {this.state.middlename} {this.state.lastname} - EMP ID {this.state.employeeID==null || this.state.employeeID==""?<>N/A</>:<>{this.state.employeeID}</>}</h3>
                                <span style={{fontFamily:"Inter Bold",fontWeight:"bold",fontSize:14}}>Role - </span><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.userrolename==null||this.state.userrolename==""?<>N/A</>:<>{this.state.userrolename}</>}</span> &nbsp;&nbsp;&nbsp;<span style={{fontFamily:"Inter Bold",fontWeight:"bold",fontSize:14}}>Team - </span><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.teamname==null||this.state.teamname==""?<>N/A</>:<>{this.state.teamname}</>}</span><br/>
                                   <i className="fa fa-map-marker"></i> <span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.workinglocation
},{this.state.CountryName}</span><br/>
                                   <i className="fa fa-envelope-o"></i> <span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.email==null || this.state.email==""?<>N/A</>:<>{this.state.email}</>}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-mobile" style={{fontSize:20,verticalAlign:"text-bottom"}}></i> <span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.mobile==null || this.state.mobile==""?<>N/A</>:<>{this.state.mobile}</>}</span><br/>
                                  
                                   <span style={{fontFamily:"Inter Bold",fontWeight:"bold",fontSize:14}}>Joining Date - </span><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.doj}</span>
                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                   <span style={{fontFamily:"Inter Bold",fontWeight:"bold",fontSize:14}}>Date of Birth - </span><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.doj}</span><br/>
                                   <span style={{fontFamily:"Inter Bold",fontWeight:"bold",fontSize:14}}>Status - </span><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.userstatusname}</span><br/>
                                   <span style={{fontFamily:"Inter Bold",fontWeight:"bold",fontSize:14}}>Reporting To - </span><span style={{fontFamily:"Inter",fontWeight:"bold",fontSize:14}}>{this.state.reporting_toname}</span>
                                </th>
                                <th>
                                <a   href="#ft-edituser-modal"  onClick={()=>this.openModal(this.state.clientid)}
                        ><button className="btn btn-primary updatebtn" style={{marginTop:"-12em",width:"10em"}} >Edit User Info</button></a>
                                    </th>&nbsp;&nbsp;
                                <th><button className="btn btn-primary updatebtn" style={{marginTop:"-12em"}} onClick={this.handleBack}>Back</button></th>
                            </tr>
                        </table>

                           </div>
                     {/**View Client Page End */}
             

                  
                        </div>
                     

                    </div>
                   
                  
                  
                </div>
        </div>
            </div>
        )
    }
}