import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import ClockIMG from "../images/clockimg.png";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import User from "../images/user.png";
import Resizer from "react-image-file-resizer";
import UserImg from "../images/usering.png";
import Swal from "sweetalert2";
import Select from 'react-select'
import styles from "./App.module.css";
import LoadingIcon  from "../images/loadingicon.gif";
import PlusincircleIcon  from "../images/plusincircle.png";
import MinusincircleIcon  from "../images/minusincircle.png";
import { SubmissionsTable } from "./SubmissionsTable";
import Header from "./Header";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
   backgroundColor:'#EAF4FF',
    borderRadius:"4px",
   marginTop:"1.5%",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"4px",
      backgroundColor:'#EAF4FF',
      color:"black"
    }
  }),
 
};
const tableCustomStyles = {
  table: {
    style: {
     
    },
},
tableWrapper: {
  style: {
      display: "table",
     minWidth:"100%",
      fontFamily:"Poppins",
      
      fontWeight:"bold",
      fontSize:"14px"
      
  },
},
headCells: {
  style: {
   
  },
 
},
expanderButton:{
  tableWrapper: {
    style: {
        display: "table",
       
        fontFamily:"Poppins",
        
    },
},
  style:{
    '&:disabled': {
     display:"none"
    },
  }
}
}

export class ViewJob extends React.Component{
    
    constructor(){
        super();
        var newURL=window.location.pathname;
        var splitURL=newURL.toString().split("/");
        var data1=splitURL[2]
        var data2=splitURL[3]
        var data3=splitURL[4]
        var data4=splitURL[5]
        var data5=splitURL[6]

  
        let decodeduserid=window.atob(data1)
       let decodedid=window.atob(data5)
       let decodedrolename=window.atob(data3)
       let roleid=window.atob(data4) 
       console.log('decodedid =', decodedid);
       console.log('decodedrolename =', decodedrolename);
       console.log('role =', roleid);
       
    

       if (localStorage.getItem("Username") == null) {
            

        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`


       } else {
        var username=JSON.stringify(localStorage.getItem("Username"))
        
       }

      
        const withoutQuotes = username.replaceAll('"', '');
        this.state={
          decodeduserid:decodeduserid,
            user_name:withoutQuotes,
            encodedid:data1,
            encodedname:data2,
            encodedemail:data3,
            encodedroleid:data4,
            decodedid:data5,
            user_email:decodedrolename,
            userroleid:roleid,
            display:0,
            jobid:decodedid,
            JobCode:"",
            JobTitle:"",
            CountryName:"",
            AssingnedName:"",
            HiringManager:"",
            CreatedBy:"",
            created_on:"",
            PR_MinAmount:"",
            PR_MaxAmount:"",
            jobstatusname:"",
            BusinessUnit:"",
            CBR_Coin:"",
            CBR_Amount:"",
            PR_Coin:"",
            created_on:"",
            CreatedBy:"",
            jobstatusname:"",
            BusinessUnit:"",
            JobDescription:"",
            ProfileImg:"",
            showModal2:false,
            showModal:false,
            getkeywordtext:"",
            getminvalue:"",
            getmaxvalue:"",
            countrydata:[],
            Noticeperiod : [
              { value: "1", label: "15 Days or less" },
              { value: "2", label: "1 Month " },
              { value: "3", label: "2 Months" },
              { value: "4", label: "3 Months" },
              { value: "5", label: "More than 3 Months" },
              { value: "6", label: "Currently Serving Notice Period" },
              { value: "7", label: "7 Days" },
              { value: "8", label: "45 Days" },
              { value: "9", label: "Any" },
              { value: "10", label: "Immediate Joiner" },
              { value: "11", label: "Maintenance" },
            ],
            hover: false,
            getnoticeperiodname:"",
            getlocationname:"",
            statedata:[],
            citydata:[],
            countrydata1:[],
            staffingdata:[
              { value: "1", label: "Permanent Staffing" },
              { value: "2", label: "Contract To Hire" },
            ],
            showModal3:false,
            recentsearch:[],
            errormsg:"",
            savedsearch:[],
            original_rows:[],
            rows:[],
            loadingicon:0,
            candidateDoc:[],
            original_rows2:[],
            rows2:[],
            doctypename:[],
            showModal3:false,
            uploadresumebase64:"",
            resume_name:"",
            doctype:"Aadhar Card",
            docdata:[
       
              { value: '2', label: 'Aadhar Card'},
              { value: '1', label: 'PAN Card'},
              { value: '3', label: 'Other'},
             
            ],
            CityID:[],
            CityName:[]
        }
        this.columns = [
      
          {
            name:"Name",
      selector: row =><span  style={{fontFamily:"Poppins Light",fontWeight:"bold",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:12}}>{row.ApplicationName}</span>,
          sortable: true
        },{
          name: "Email",
          selector: row =><span className="keyskills" title={row.EmailID}>{row.EmailID}</span>,
          sortable: true,
          width:"18%",
          style:{
            whiteSpace:"nowrap",
            textOverflow: "clip",
            fontWeight:"bold"
          }
        },
              {
                name:"Resume",
          selector: row =><>{row.resume_name==null?<>Not Available</>:<><span onClick={()=>this.viewResume(row.resume_name)} style={{fontFamily:"Poppins Light",color:"#4293FE",verticalAlign:"sub",cursor:"pointer",display:"flex",fontSize:22,marginLeft:"1em"}}><i class="fa fa-file-pdf-o" aria-hidden="true"></i></span></>}</>,
              sortable: true,
              width:"15%",
              style:{
                whiteSpace:"nowrap",
               
                textOverflow: "clip",
              
                fontFamily:"Poppins",
                fontWeight:"bold"
              }
            },
              {
                name: "Mobile",
                selector: "MobileNo",
                sortable: true
              },
              {
                name: "Location",
                selector:row =><>{row.Location==""||row.Location==null?<>Not Avaliable</>:<>{row.Location}</>}</>,
                sortable: true,
                width:"18%"
              },
              {
                name: "Submitted By",
                selector: "submittedbyname",
                sortable: true,
                width:"10%"
              },
              {
                name: "Submitted On",
                selector: "submitteddate",
                sortable: true
              },
              
             
            
             
        ];
        this.columns2 = [
    
             
          {
            name: "Document Name",
            selector: "doctype",
            sortable: true
          },
          {
           name: "Created By",
           selector: "createdbyname",
           sortable: true
         },
         {
           name: "Created Date",
           selector: "CreatedDate",
           sortable: true
         },
         {
           name:"View Document",
         cell: (e) => (
          
             <>
             <button style={{fontSize:12,margin:"4%"}} onClick={()=>this.viewDocument(e.docname)} className="btn btn-primary"><i className="fa fa-eye"></i> View</button>
       </>
         ),
         
         ignoreRowClick: true,
         allowOverflow: true,
         button: true,
       },
         
     
          {
            name:"Action",
          cell: (e) => (
           
              <>
             <button onClick={()=>this.deleteDocument(e.documentid)} style={{fontSize:12,margin:"4%"}} className="btn btn-danger"><i className="fa fa-trash"></i> Delete</button> 
        </>
          ),
          
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];
        this.handleDisplay=this.handleDisplay.bind(this)
        this.handleDisplayClose=this.handleDisplayClose.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.handleBack=this.handleBack.bind(this)
        this.handleUpdateImage=this.handleUpdateImage.bind(this)
        this.handleMaster=this.handleMaster.bind(this)
        this.handleUser=this.handleUser.bind(this)
        this.handleHome=this.handleHome.bind(this)
        this.handleApplicant=this.handleApplicant.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.hanldelClient=this.hanldelClient.bind(this) 
        this.handlejobSearch=this.handlejobSearch.bind(this)   
        this.handleOnboarding=this.handleOnboarding.bind(this)  
        this.handleUpdate=this.handleUpdate.bind(this)
        this.handleLocation=this.handleLocation.bind(this)
        this.handleNoticeperiod=this.handleNoticeperiod.bind(this)
        this.handleCountry=this.handleCountry.bind(this)
        this.handleCity=this.handleCity.bind(this)
        this.handleState=this.handleState.bind(this)
        this.handleStaffing=this.handleStaffing.bind(this)
        this.handleReport=this.handleReport.bind(this)
        this.handleLanguage=this.handleLanguage.bind(this)
        this.handleDegree=this.handleDegree.bind(this)  
        this.handleSearch=this.handleSearch.bind(this)
        this.handlejobSearch2=this.handlejobSearch2.bind(this)
        this.viewResume=this.viewResume.bind(this)
        this.handleAddDoc=this.handleAddDoc.bind(this)
        this.handleFullName=this.handleFullName.bind(this)
        this.viewDocument=this.viewDocument.bind(this)
        this.deleteDocument=this.deleteDocument.bind(this)
        this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
        this.handleCheckbox1=this.handleCheckbox1.bind(this)
        this.handleClientnew=this.handleClientnew.bind(this)
      }
      handleClientnew(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      handleCheckboxclose1(id,name){
        this.setState({CityID : this.state.CityID.filter(function(val) {return val!==id})})
        this.setState({CityName : this.state.CityName.filter(function(val) {return val!==name})},()=>{
          console.log(this.state.CityName)
        })
    }
    handleCheckbox1(id,name){
      this.setState({CityID:this.state.CityID.concat(id)},()=>{
        console.log(this.state.CityID)
       })
       this.setState({CityName:this.state.CityName.concat(name)},()=>{
        console.log(this.state.CityName)
       })
    
    }
    handleChangeJD = (e) => {
      //var regex = /[!@\$%\^\&*\)\(+=_`;:<>]/g;
      //e.target.value = e.target.value.replace(regex, "");
      this.setState({
        [e.target.name]: e.target.value,
  
      });
  
      
    };
    deleteDocument(documentid){
      Swal.fire({
        title: 'Are you sure to delete this Document?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
    
          var usernameid=JSON.stringify(localStorage.getItem("UserId"))
          const withoutQuotesid = usernameid.replaceAll('"', '');
          console.log(withoutQuotesid)
          const data={userid:this.state.decodedid,addjobpostingdata:"deleteJobPostingdoc",editjobid:this.state.jobid,documentid:documentid}
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getJobposting", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                Swal.fire(
                  'Deleted!',
                   response.message,
                 
                )
                setTimeout(
                  function() {
                    window.location.reload()
                  }
                  .bind(this),
                 2700
              );
                  });
         
        }
      })
    }
    viewDocument(docname){
      
      window.open(`https://mrecruit.in/sysload/jobPostingdocuments/${docname}`)
    }
    handleImageone = (e) => {
      // let file = new FileReader();
      console.log(e.target.files[0]);
      let { file } = this.state;
  
      file = e.target.files[0];
      if(file["size"]>500000){
        this.setState({fileerrormsg:"File Size should not be more than 500kb"})
        
      }
      else{
        this.getBase64(file)
     
        .then((result) => {
         
            file["base64"] = result;
        
            console.log("File Is", file);
            this.setState({
              uploadresumebase64: result,
              resume_name:file["name"],
              fileerrormsg:"",
              file,
            },()=>{
              //console.log(this.state.resume_name)
            });
          
          
        })
        .catch((err) => {
          console.log(err);
        });
  
      this.setState({
        //  file: e.target.files[0]
      });
  
      this.setState({
        //companylogo: e.target.files[0]
      });
      var fileInput = false;
      if (e.target.files[0]) {
        fileInput = true;
      }
  
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              console.log(uri);
              this.setState({ newImage: uri });
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          console.log(err);
        }
      }
      }
      
    };
    viewResume(resume_name){
      //console.log(this.state.resume_name)
      window.open(`https://www.mrecruit.in/sysload/pdf/${resume_name}`)
    }
    handleSearch(jobtitle,minexp,maxexp,noticeperiod,location){
      //console.log(location)
      localStorage.setItem("ButtonStatus",JSON.stringify("3"))
      let data=window.location.pathname.slice(0,0); 
      let d1=window.btoa(jobtitle)   
       window.location.href=`${data}/jobsearch/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}/${d1}/${this.state.decodedid}` 
       if(minexp==null || minexp==0){
        localStorage.setItem("Minvalue",JSON.stringify(""))
       }
       else{
        localStorage.setItem("Minvalue",JSON.stringify(minexp))
       }
        if(maxexp==null || maxexp==0){
        localStorage.setItem("Maxvalue",JSON.stringify(""))
       } 
       else {
        localStorage.setItem("Maxvalue",JSON.stringify(maxexp))
       }
       
       if(noticeperiod==null){
        localStorage.setItem("Noticeperiod",JSON.stringify(""))
       }
       else{
        localStorage.setItem("Noticeperiod",JSON.stringify(noticeperiod))
       } 
       
       if(location==null){
        localStorage.setItem("Location",JSON.stringify(""))
       }
       else{
       
       localStorage.setItem("Location",JSON.stringify(location))
       }
      
    }
    handleLanguage(e){
      this.setState({Languages:e.value})
    }
    handleDegree(e){
      this.setState({Degreename:e.label,DegreeID:e.value})
    }
    handleReport(e){
      this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
    }
    handleStaffing(e){
      this.setState({StaffingTypeid:e.value,errormsg3:"",StaffingTypename:e.label})
        }
    handleCity(e){
      this.setState({Cityname:e.label,Cityid:e.value})
    }
    handleState(e){
      this.setState({Statename:e.label,Stateid:e.value,},()=>{
        const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
           
       
            this.setState({citydata:data})
                  
                });
        })
    }
    handleCountry(e){
      this.setState({Countryname:e.label,Countryid:e.value,},()=>{
        const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({statedata:options})
                  
                });
        })
    }
    handleUpdate(){
      if(this.state.JobCode==""){
        this.setState({jobcodeerror:"Please Enter Job Code"})
      }
      else if(this.state.JobTitle==""){
        this.setState({jobtitleerror:"Please Enter Job Title"})
       
      }
    
      else if(this.state.NoofPositions==""){
        this.setState({nopositionerror:"Please Enter No of Position"})
      }
    
      else if(this.state.PR_MinAmount==""){
        this.setState({payraterror:"Please Enter Pay Rate Minimum Amount"})
        
      }
     
   
     
    
     
      else if(this.state.NoticePeriod==""){
        this.setState({noticeperioderror:"Please Enter Notice Period"})
      }
     
      else if(this.state.ExperienceMin==""){
     
        this.setState({minexperror:"Please Enter Minimum Experience"})
      }
      else if(this.state.ExperienceMax==""){
        this.setState({maxexperror:"Please Enter Maximum Experience"})
     
      }
      else if(this.state.PrimarySkills==""){
        this.setState({primaryskillserror:"Please Enter Mandatory Skills"})
      }
  
      else if(this.state.JobDescription==""){
  
        this.setState({jderror:"Please Enter Job Description"})
      }
      else{
        var usernameid=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotesid = usernameid.replaceAll('"', '');
        const data={
          userid:this.state.decodeduserid,
          addjobpostingdata:"editjobpostinginfo",
          editjobid:this.state.editjobid,
          JobCode:this.state.JobCode,
          JobTitle:this.state.JobTitle,
          ClientId:this.state.ClientId,
          AssingnedID:this.state.AssingnedID,
          AssingnedName:this.state.AssingnedName,
          ClientName:this.state.ClientName,
          CBR_Coin:this.state.CBR_Coin,
          CBR_Amount:this.state.CBR_Amount,
          PR_Coin:this.state.PR_Coin,
          PR_MinAmount:this.state.PR_MinAmount,
          PR_MaxAmount:this.state.PR_MaxAmount,
          CountryID:this.state.CountryID,
          CountryName:this.state.CountryName,
          StateID:this.state.StateID,
          StateName:this.state.StateName,
          CityID:this.state.CityID.toString(),
          CityName:this.state.CityName.toString(),
          ClientJobID:this.state.ClientJobID,
          NoofPositions:this.state.NoofPositions,
          ClosedPostions:this.state.NoofPositions,
          JobCreatedDate:this.state.JobCreatedDate,
          JobClosingDate:this.state.JobClosingDate,
          SPOCName:this.state.SPOCName,
          SPOCMobile:this.state.SPOCMobile,
          HiringManagerid:this.state.HiringManagerid,
          HiringManager:this.state.HiringManager,
          NoticePeriod:this.state.NoticePeriod,
          BusinessUnit:this.state.BusinessUnit,
          JobDescription:this.state.JobDescription,
          DegreeID:this.state.DegreeID,
          Degreename:this.state.Degreename,
          ExperienceMin:this.state.ExperienceMin,
          ExperienceMax:this.state.ExperienceMax,
          PrimarySkills:this.state.PrimarySkills,
          Languages:this.state.Languages,
          JobStatus:this.state.JobStatus,
          StaffingTypename:this.state.StaffingTypename,
          StaffingTypeid:this.state.StaffingTypeid,
          pincode:this.state.pincode,
          Additionalskills:this.state.Additionalskills
          
    
        }
        console.log(data)
            fetch("https://www.mrecruit.in:5200/api/getJobposting", {
                method: "POST",
        
                body: JSON.stringify(data), // data can be `string` or {object}!
        
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
        
                .catch((error) => console.error("Error:", error))
        
                .then((response) => {
                  this.setState({updatemsg:response.message})
                
               
                console.log(response.message)
                setTimeout(
                  function() {
                    window.location.reload()
                  }
                  .bind(this),
                2500
              );
              
  
                      
                    });
      }
     
        }
        handleLocation(e){
          this.setState({getlocationname:e.label})
        }
        handleNoticeperiod(e){
          this.setState({getnoticeperiodname:e.label})
        }
    handleMouseIn() {
      this.setState({ hover: true })
    }
    handleMouseOut() {
      this.setState({ hover: false })
    }
    openModal = (id) => {
      this.setState({editjobid:id})
      var usernameid=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotesid = usernameid.replaceAll('"', '');
        const data={ userid:this.state.decodeduserid,addjobpostingdata:"getsinglejobinfo",editjobid:id}
       // alert("getJobposting_senthil=",usernameid)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
            console.log(response)
           // alert("getJobposting_senthil=",username)
            response.result.map((e)=>{
              this.setState({
                JobTitle:e.JobTitle,
                JobCode:e.JobCode,
                ClientId:e.ClientId,
                ClientName:e.ClientName,
                CBR_Coin:e.CBR_Coin,
                CBR_Amount:e.CBR_Amount,
                PR_Coin:e.PR_Coin,
                PR_MinAmount:e.PR_MinAmount,
                PR_MaxAmount:e.PR_MaxAmount,
                CountryID:e.CountryID,
                CountryName:e.CountryName,
                StateID:e.StateID,
                StateName:e.StateName,
                CityID:this.state.CityID.concat(e.CityID),
                CityName:this.state.CityName.concat(e.CityName),
                citydata:[{id:e.CityID,name:e.CityName}],
                ClientJobID:e.ClientJobID,
                NoofPositions:e.NoofPositions,
                ClosedPostions:e.ClosedPostions,
                JobCreatedDate:e.JobCreatedDate,
                JobClosingDate:e.JobClosingDate,
                SPOCMobile:e.SPOCMobile,
                SPOCName:e.SPOCName,
                HiringManagerid:e.HiringManagerid,
                HiringManager:e.HiringManager,
                AssingnedID:e.AssingnedID,
                AssingnedName:e.AssingnedName,
                BusinessUnit:e.BusinessUnit,
                NoticePeriod:e.NoticePeriod,
                JobDescription:e.JobDescription,
                DegreeID:e.DegreeID,
                Degreename:e.Degreename,
                ExperienceMin:e.ExperienceMin,
                ExperienceMax:e.ExperienceMax,
                PrimarySkills:e.PrimarySkills,
                Languages:e.Languages,
                JobStatus:e.JobStatus,
                jobstatusname:e.jobstatusname,
                CreatedBy:e.CreatedBy,
                LanguagesName:e.LanguagesName,
                StaffingTypeid:e.StaffingTypeid,
                StaffingTypename:e.StaffingTypename,
                pincode:e.pincode,
                Additionalskills:e.Additionalskills,
                ClientJobID :e.ClientJobID
      
  
  
  
              })
            })
                  
                });
       
      this.setState({ showModal: true });
    };
    openModal3=()=>{
      this.setState({ showModal3: true });
    }
    closeModal3 = () => {
      this.setState({ showModal3: false });
    };
    closeModal = () => {
      this.setState({ showModal: false });
    };
    handleOnboarding(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
    }
    handleChange = (e) => {
 // console.log(e.target.value)
      this.setState({
        [e.target.name]: e.target.value,
  
      });
      
    };
    handlejobSearch(){
      
    if(this.state.getkeywordtext==""){
      Swal.fire(
        "Please Enter Search Criteria"
      )
    }
    else{
     let data=window.location.pathname.slice(0,0);
     let d1=window.btoa(this.state.getkeywordtext)
      window.location.href=`${data}/jobsearch/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}/${d1}/${this.state.decodedid}` 
      Storage.setItem("Minvalue",JSON.stringify(this.state.getminvalue))
      localStorage.setItem("Maxvalue",JSON.stringify(this.state.getmaxvalue))
      localStorage.setItem("Noticeperiod",JSON.stringify(this.state.getnoticeperiodname))
      localStorage.setItem("Location",JSON.stringify(this.state.getlocationname))
      localStorage.setItem("ButtonStatus",JSON.stringify("2"))
      localStorage.setItem("JobID",JSON.stringify(this.state.jobid))
        localStorage.setItem("JobTitle",JSON.stringify(this.state.JobTitle))
    }
    }
    handlejobSearch2(){
      
      if(this.state.getkeywordtext==""){
        Swal.fire(
          "Please Enter Search Criteria"
        )
      }
      else{
       let data=window.location.pathname.slice(0,0);
       let d1=window.btoa(this.state.getkeywordtext)
       window.location.href=`${data}/jobsearch/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}/${d1}/${this.state.decodedid}` 
      
        localStorage.setItem("Minvalue",JSON.stringify(this.state.getminvalue))
        localStorage.setItem("Maxvalue",JSON.stringify(this.state.getmaxvalue))
        localStorage.setItem("Noticeperiod",JSON.stringify(this.state.getnoticeperiodname))
        localStorage.setItem("Location",JSON.stringify(this.state.getlocationname))
        localStorage.setItem("ButtonStatus",JSON.stringify("1"))
        localStorage.setItem("JobID",JSON.stringify(this.state.jobid))
        localStorage.setItem("JobTitle",JSON.stringify(this.state.JobTitle))
      }
      }
    hanldelClient(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
    handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      }
    handleApplicant(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
    handleHome(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
      }
    handleUser(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    handleMaster(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    openModal2=()=>{
        this.setState({ showModal2: true });
      }
      closeModal2 = () => {
        this.setState({ showModal2: false });
      };
    getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
      };
      handleImage = (e) => {
        
        // let file = new FileReader();
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              profilepic: result,
              ProfileImg:result,
              file,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          //  file: e.target.files[0]
        });
    
        this.setState({
          //companylogo: e.target.files[0]
        });
        var fileInput = false;
        if (e.target.files[0]) {
          fileInput = true;
        }
        
        if(fileInput) {
          try {
            Resizer.imageFileResizer(
              e.target.files[0],
              120,
              120,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                this.setState({ newImage: uri });
              },
              "base64",
              120,
              120
            );
          } catch (err) {
            console.log(err);
          }
        }
      };
      handleUpdateImage(){
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"editsinglephotoinfo",
            profilepic:this.state.profilepic
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 this.setState({message:response.message}) 
              //console.log(response)
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
               2500
            );
             if(response.message=="Profile Pic Updated successfully"){
              const withoutQuotes = username.replaceAll('"', '');
              const data={ userid:withoutQuotes,
                adduserdata:"getsinglephotoinfo",
              
             
            }
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                    
                 //console.log(response)
                     this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                     
                            
                     })
                 
                        
                      });
             }
              
                    
             
                    
                  });
      }
      handleChangeNopos = (e) => {
        var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
        e.target.value = e.target.value.replace(regex, "");
        if(e.target.value>500){
          this.setState({nopositionerror:"No of Open Positions between 500 only."})
        }
        else{
          this.setState({
            [e.target.name]: e.target.value,
            nopositionerror:"",
           
          });
        }
      
        
      };
      handleChangeBillrate = (e) => {
        var regex = /[a-zA-Z!@#\$%\^\&*\)\(+=`;,:<>-]/g;
        e.target.value = e.target.value.replace(regex, "");
        this.setState({
          [e.target.name]: e.target.value,
          clientraterrror:""
        });
    
        
      };
      handleChangePincode = (e) => {
        var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
        e.target.value = e.target.value.replace(regex, "");
     
          this.setState({
            [e.target.name]: e.target.value,
            mobileerror:""
          });
       
      
        
      };
      handleFullName(e){
        this.setState({doctype:e.label,fileerrormsg:""})
          }
      handleChangeSkills = (e) => {
        //var regex = /[!@\$%\^\&*\)\(+=._-`;:<>]/g;
        //e.target.value = e.target.value.replace(regex, "");
        this.setState({
          [e.target.name]: e.target.value,
    
        });
    
        
      };
      handleChangeJobTitle = (e) => {
       // var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
        //e.target.value = e.target.value.replace(regex, "");
        this.setState({
          [e.target.name]: e.target.value,
    jobtitleerror:""
        });
    
        
      };
    handleBack(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    handleAddDoc(){
      if(this.state.doctypename.includes(this.state.doctype)){
        this.setState({fileerrormsg:"Document Already Uploaded."})
      }
      else if(this.state.uploadresumebase64==""){
        this.setState({fileerrormsg:"Please Choose a File."})
      }
      else{
        this.setState({pleasewaittxt:"Please Wait...",fileerrormsg:""})
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes2 = username.replaceAll('"', '');
        const pdata1={ userid:this.state.decodeduserid,
          addjobpostingdata:"AddJobPostingdocinfo",
          editjobid:this.state.jobid,
          doctype:this.state.doctype,
          docname:this.state.resume_name,
          uploadresumebase64:this.state.uploadresumebase64
        
       
      }
      //console.log(pdata1)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(pdata1), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              console.log(response)
              if(response.message=="Document added Successfully."){
                this.setState({pleasewaittxt:response.message,uploadresumebase64:""})
                window.location.reload()
                
              }
              else{
                console.log(response)
              }
               
                  
                });
      }
     
    }
    componentDidMount(){
        /**Get All Documents API Starts */
       var usernameid=JSON.stringify(localStorage.getItem("UserId"));
       const withoutQuotesid2 = usernameid.replaceAll('"', ''); 
       const pdata1={ userid:this.state.decodeduserid, 
        addjobpostingdata :"getJobPostingdocinfo", 
         editjobid :this.state.jobid
       
      
     }
     console.log(pdata1)
       fetch("https://www.mrecruit.in:5200/api/getJobposting", {
           method: "POST",
   
           body: JSON.stringify(pdata1), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
            console.log(response)
             if(response.message=="Documents Retrieved Successfully."){
               let data=response.result
               data = data.map((currentValue, Index) => {
                 currentValue.SNO = Index + 1;
                 return currentValue;
                 
               });
               this.setState({ original_rows2: data, rows2: data,candidateDoc:response.result });
               console.log(response)
               response.result.map((e)=>{
                 this.setState({doctypename:this.state.doctypename.concat(e.doctype)},()=>{
                   ///console.log(this.state.doctypename)
                 })
               })
             }
             else{
               console.log(response)
             }
              
                 
               });
       /**Get All Documents API Ends */
      /**Submissions APi call starts */
      var usernameid=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes2 = usernameid.replaceAll('"', '');
      
      const repdata={userid:withoutQuotes2,
        addJobSubmissiondata:"getJobSubmissioninfoo",
        editjobid:this.state.jobid,
      
      }
        console.log(repdata)
        fetch("https://www.mrecruit.in:5200/api/getJobSubmissioninfo", {
            method: "POST",
      
            body: JSON.stringify(repdata), // data can be `string` or {object}!
      
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
      
            .catch((error) => console.error("Error:", error))
      
            .then((response) => {
              if(response.message=="No Data Found."){
                this.setState({rows:[],errormsg:response.message,loadingicon:2})
               }
               else{
                let data=response.result
                console.log(response)
                data = data.map((currentValue, Index) => {
                  currentValue.SNO = Index + 1;
                  return currentValue;
                });
                this.setState({ original_rows: data, rows: data,loadingicon:1,errormsg:"" });
               }
                
               
               
                
             
              
             
            });
      /**Submissions API call ends */
  /* Saved Search API Call Start */
  var username=JSON.stringify(localStorage.getItem("UserId"))
  const withoutQuotesid4  = username.replaceAll('"', '');
  const ledata1={ userid:withoutQuotesid4,addAppsearchdata: "getSavedsearchinfo"}

 
  fetch("https://www.mrecruit.in:5200/api/getAppsearchlistinfo", {
      method: "POST",

      body: JSON.stringify(ledata1), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          console.log(response)
          if(response.status==1){
            this.setState({savedsearch:response.result,errormsg:""})
          }
          else{
            this.setState({errormsg:"No data found!"})
          }
                
          });
         
  /*Saved Search  API Call End */
 /* Recent Search API Call Start */


 var usernameid=JSON.stringify(localStorage.getItem("UserId"))
 const withoutQuotesid7 = usernameid.replaceAll('"', '');
 const ledata11={ userid:withoutQuotesid7,addAppsearchdata: "getRecentsearchinfo"}
 fetch("https://www.mrecruit.in:5200/api/getAppsearchlistinfo", {
     method: "POST",

     body: JSON.stringify(ledata11), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         console.log(response)
         if(response.status==1){
           this.setState({recentsearch:response.result,errormsg:""})
         }
         else{
           this.setState({errormsg:"No data found!"})
         }
               
         });
        
 /*Recent Search  API Call End */
        /* Language API Call Start */
     const ledata={ dropdownlist_input: "getlanguagesinfoinfo"}
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
 
         body: JSON.stringify(ledata), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.id,
             "label" : d.name
 
           })) 
    
         this.setState({languagesdata:options})
               
             });
            
     /*Language  API Call End */

      /* Degree API Call Start */
      const degdata={ dropdownlist_input: "getdegreesinfo"}
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(degdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({degreedata:options})
                
              });
             
      /*Language  API Call End */
      /* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
       /* Country API Call Start */
  const tdata1={ dropdownlist_input: "getcountryinfo"
}

 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(tdata1), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         //console.log(response)
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.countryid,
         "label" : d.conuntryname

       })) 

     this.setState({countrydata1:options})
           
         });
 /* Country API Call  End */
      const tdata={ dropdownlist_input: "getalllocationlist"
    }
     console.log(tdata)
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
   
         body: JSON.stringify(tdata), // data can be `string` or {object}!
   
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
   
         .catch((error) => console.error("Error:", error))
   
         .then((response) => {
             console.log(response)
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.location,
             "label" : d.location
   
           })) 
    
         this.setState({countrydata:options})
               
             });
        var usernameid=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotesid = usernameid.replaceAll('"', '');
        const pdata={ userid:withoutQuotesid,
          adduserdata:"getsinglephotoinfo",
        
       
      }
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(pdata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              
              if(response.result.profilepic=="BASE64"){
                this.setState({ProfileImg:""},()=>{
                 
                })
            
             }
             else{
                this.setState({ProfileImg:response.result.profilepic},()=>{
                 
                })
            
             }
                  
                });
             if (localStorage.getItem("Username") == null) {
            

            localStorage.clear()
            let data=window.location.pathname.slice(0,0);
            window.location.href=`${data}/`


           } else {
            var username=JSON.stringify(localStorage.getItem("Username"))
            
           }
        const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:this.state.decodeduserid,addjobpostingdata:"getsinglejobinfo",editjobid:this.state.jobid}
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getJobposting", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                  
              console.log(response)
              response.result.map((e)=>{
                this.setState({
                    JobTitle:e.JobTitle,
                    JobCode:e.JobCode,
                    CountryName:e.CountryName,
                    AssingnedName:e.AssingnedName,
                    HiringManager:e.HiringManager,
                    created_on:e.created_on,
                    PR_MinAmount:e.PR_MinAmount,
                    PR_MaxAmount:e.PR_MaxAmount,
                    jobstatusname:e.jobstatusname,
                    BusinessUnit:e.BusinessUnit,
                    CBR_Amount:e.CBR_Amount,
                    CBR_Coin:e.CBR_Coin,
                    PR_Coin:e.PR_Coin,
                    CreatedBy:e.createdbyname,
                    JobDescription:e.JobDescription
                })
              })
                    
                  });
    }
    handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
        handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
    render(){
      const tooltipStyle = {
        display: this.state.hover ? 'inline' : 'none',
        fontSize:"10px",
        marginLeft:"-1%",
        backgroundColor:"black",
        color:"white",
        fontFamily:"Poppins",
        fontWeight:"bold",
        padding:"0.5%"
      }
      const arrowleft = {
        width: "0px",
        height: "0px",
        borderTop: "7px solid transparent",
        borderBottom: "6px solid transparent",
        borderRight: "15px solid black",
        display: this.state.hover ? 'inline-block' : 'none',
    }
        return(
            <div>
                {/**Add Document Modal Starts */}
                {this.state.showModal3 && (
         <div class="ft-modal" id="ft-adddocument-modal" style={{top:"0%"}}>
         <div class="ft-modal-content" style={{maxWidth:"50%"}}>
           <div class="ft-modal-header">
             
             <h5
                   class="modal-title"
                   id="exampleModalLabel"
                   style={{
                     textAlign: "center",
                     fontSize: 25,
                     fontWeight: "bold",
                   }}
                 >
                   Add Document
                 </h5>
               <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
          
           </div>
           <div className="ft-modal-body">
           <div className="row">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Document</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
  <Select 
       onChange={(e)=>this.handleFullName(e)}
       options={this.state.docdata} placeholder="Aadhar Card" components={{  IndicatorSeparator:() => null }}
      styles={style}/>
  </div>
<div className="col-xl-8">
<span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>&nbsp;(You Can Upload only PDF File)</span>
<input
                        id="upload"
                        type="file"
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white",width:"inherit",border: "2px solid rgb(66, 147, 254)"}}
                       onChange={this.handleImageone}
                        accept=".pdf"
                      />

</div>
<div className="text-center" style={{margin:"auto",marginTop:"5%"}}>
<span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>{this.state.fileerrormsg}</span><br/>
{this.state.fileerrormsg=="File Size should not be more than 500kb" || this.state.pleasewaittxt=="Please Wait..." ||this.state.fileerrormsg=="Document Already Uploaded."?<><button disabled className="btn btn-secondary updatebtn">Submit</button> </>:<><button onClick={this.handleAddDoc} className="btn btn-primary updatebtn">Submit</button> </>}<br/><br/>
<span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"blue",fontSize:15}}>{this.state.pleasewaittxt}</span>

</div>
</div>
            </div>
           <div class="ft-modal-footer">
             <a class="ft-modal-close"  data-dismiss="modal"
                      onClick={this.closeModal3}>
               <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
             </a>
           </div>
         </div>
       </div>
       )}
                {/**Add Document Modal Ends */}
              {/**Edit Job Modal Starts */}
              {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content  bigmodalcontent" >
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Job Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            {/**Add user for starts */}
 <div>


 <div className="row">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Code</span>&nbsp;<span onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"rgb(66, 147, 254)",fontSize:16,cursor:"pointer"}}><i class="fa fa-question-circle" aria-hidden="true"></i></span>
        <div style={arrowleft}></div>
        <span style={tooltipStyle}>Job Code will generate automatically.</span>
                <input className="form-control adduser mt-1" placeholder="Job Code" name="JobCode" disabled value={this.state.JobCode} onChange={this.handleChange}/>
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.jobcodeerror}</span>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Title</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input className="form-control adduser mt-1" placeholder="Job Title" 
                value={this.state.JobTitle}
                name="JobTitle" onChange={this.handleChangeJobTitle}/>
                 <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.jobtitleerror}</span>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Client</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
                placeholder={this.state.ClientName}
       onChange={(e)=>this.handleClient(e)}
       options={this.state.clientdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>No of Open Positions</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <input type="number" className="form-control adduser
                 mt-1" maxLength="3" value={this.state.NoofPositions} placeholder="No of Open Positions" name="NoofPositions" onChange={this.handleChangeNopos}/>
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.nopositionerror}</span>
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Client Bill Rate Monthly</span> <br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" value={this.state.CBR_Amount} placeholder="Client Bill Rate Monthly" 
                  name="CBR_Amount" onChange={this.handleChangeBillrate}/>
                   <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.clientraterrror}</span>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Maintec Offered CTC</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" value={this.state.PR_MinAmount} placeholder="Maintec Offered CTC" name="PR_MinAmount" onChange={this.handleChange}/>
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.payraterror}</span>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Country</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
                placeholder={this.state.CountryName}
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>State</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
                placeholder={this.state.StateName}
       onChange={(e)=>this.handleState(e)}
       options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
               
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Location</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <div className="mt-1" style={{height:"2.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.citydata.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.CityID.includes(e.id)==true?<>
          <i className="fa fa-check-square"  onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.CityID.includes(e.id)==false?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer",display:this.state.citydata.length==0?"none":"inline"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Pin Code</span><br/>
                <input className="form-control adduser mt-1" value={this.state.pincode} name="pincode" onChange={this.handleChangePincode} placeholder="Pin Code" maxLength="6"/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>SPOC Name</span><br/>
               
                  
                  <input className="form-control adduser mt-1" value={this.state.SPOCName} name="SPOCName" onChange={this.handleChange} placeholder="SPOC Name"/>
                  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.spocnameerror}</span>
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>SPOC Mobile</span><br/>
                <input value={this.state.SPOCMobile} className="form-control adduser mt-1" name="SPOCMobile" onChange={this.handleChangemobile} placeholder="SPOC Mobile"/>
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.spocmobileerror}</span>
                </div>
                
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Additional Skills</span><br/>
               
                <input className="form-control adduser mt-1" value={this.state.Additionalskills} placeholder="Additional Skills" onChange={this.handleChangeSkills} name="Additionalskills" />
                </div>
              
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Creating Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
               <input type="date" name="JobCreatedDate" onChange={this.handleChangeJobcreated}  value={this.state.JobCreatedDate} className="form-control adduser mt-1" min={new Date().toISOString().split('T')[0]} />
              
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Closing Date</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
               
                  
                  <input type="date" value={this.state.JobClosingDate} className="form-control adduser mt-1" placeholder="Job Closing Date" name="JobClosingDate" onChange={this.handleChangeJobcreated}  min={new Date().toISOString().split('T')[0]}/>
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Notice Period</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
    onChange={(e)=>this.handleNotice(e)}
    placeholder={this.state.NoticePeriod}
    options={this.state.Noticeperiod}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
                <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.noticeperioderror}</span>
                </div>
                </div>
 
 
                <div className="row mt-2">
          
             
           
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Status</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
          <Select 
          placeholder={this.state.jobstatusname}
onChange={(e)=>this.handleUserstatus(e)}
options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                        styles={style}/>
          </div>
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Relevant Experience Min</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <input value={this.state.ExperienceMin} 
              name="ExperienceMin" onChange={this.handleChange} 
              className="form-control adduser mt-1" placeholder="Relevant Experience Min"/>
               <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.minexperror}</span>
          </div>
          <div className="col-xl-3">
            
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Relevant Experience Max</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <input name="ExperienceMax" onChange={this.handleChange} value={this.state.ExperienceMax}  className="form-control adduser mt-1" placeholder="Relevant Experience Max"/>
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.maxexperror}</span>
         
      
          </div>
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Mandatory Skills</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
         
            
         <input value={this.state.PrimarySkills} 
         name="PrimarySkills" onChange={this.handleChange}
         className="form-control adduser mt-1" placeholder="Mandatory Skills"/>
          <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.primaryskillserror}</span>
          </div>
          </div>

          <div className="row mt-2">
          <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Mode of Staffing</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select 
       onChange={(e)=>this.handleStaffing(e)}
       placeholder={this.state.StaffingTypename}
       options={this.state.staffingdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
              <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Account Manager</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
                <Select
                placeholder={this.state.HiringManager} 
       onChange={(e)=>this.handleReport(e)}
       options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>

              </div>
             
              <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Degree Name</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <Select 
              placeholder={this.state.Degreename}
       onChange={(e)=>this.handleDegree(e)}
       options={this.state.degreedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
             
              </div>
              <div className="col-xl-3">
              <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Languages</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
              <Select 
       onChange={(e)=>this.handleLanguage(e)}
       placeholder={this.state.LanguagesName}
       options={this.state.languagesdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
              </div>
              <div className="row mt-2">
            
             <div className="col-xl-12">
             <span style={{fontWeight:"bold",fontFamily:"Poppins Light",fontSize:13}}>Job Description</span>  <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:14}}>*</span><br/>
             <textarea value={this.state.JobDescription} className="form-control mt-2 adduser" rows="3" name="JobDescription" onChange={this.handleChangeJD}>

             </textarea>
             <span style={{fontWeight:"bold",fontFamily:"Poppins Light",color:"red",fontSize:13}}>{this.state.jderror}</span>
             </div>
             
              </div>

  
              <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}

              {/**Edit Job Modal ends */}
                    {/* Header Section Starts */}

      
                    <div className="dashboardheader">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li onClick={this.handleHome} style={{cursor:"pointer"}}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="litext">Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleApplicant}  style={{cursor:"pointer"}}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClientnew}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.userroleid==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.userroleid==9 || this.state.userroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
          </ul>
          <Header/>
        </div>

        {/* Header Section Ends */}
        
           {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}  
        <div
              style={{
                backgroundColor: "#EAF4FF",
                height: "auto",
                paddingBottom: "3%",
              }}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            {/**View Job Page Starts */}
                           <div style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
 <div className="row">
                            <div className="col-sm-10">
                                <h5 style={{fontFamily:"Poppins",fontWeight:"bold"}}>
                                    {this.state.JobCode} - {this.state.JobTitle}
                                </h5>
                            </div>
                            <div className="col-xl-1">
                            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(this.state.jobid)} 
                          > <button style={{width:"max-content"}} className="btn btn-primary updatebtn" >Edit Job</button></a>
                           
                            </div>
                              <div className="col-xl-1">
                            <button  className="btn btn-primary updatebtn" onClick={this.handleBack}>Back</button>
                            </div>
                          
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h5 style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>
                                   {this.state.CountryName==""||this.state.CountryName==null?<>
                                   Location Not Avaliable
                                   </>:<><i className="fa fa-map-marker"></i> &nbsp;{this.state.CountryName}</>}
                                </h5>
                            </div>
                           
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h5 style={{fontFamily:"Poppins",fontSize:15,fontWeight:"bold"}}>
                                   {this.state.AssingnedName==""||this.state.AssingnedName==null?<>
                                   Assigned to - &nbsp; N/A
                                   </>:<>Assigned to - &nbsp;{this.state.AssingnedName}</>}
                                </h5>
                            </div>
                           
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-xl-3" style={{borderRight:"2px solid rgba(0,0,0,0.1)",height:"4em"}}>
                                <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>
                                    Hiring Manager
                                </span><br/>
                                <span style={{fontFamily:"Poppins",fontSize:14}}>
                                    {this.state.HiringManager==""||this.state.HiringManager==null?<>
                                    N/A
                                    </>:<>{this.state.HiringManager}</>}
                                </span>
                            </div>
                           {/** <div className="col-xl-2" style={{borderRight:"2px solid rgba(0,0,0,0.1)",height:"4em"}}>
                            <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>
                                    Client Bill Rate
                                </span>
                                <br/>
                                <span style={{fontFamily:"Poppins",fontSize:14}}>
                                    {this.state.CBR_Coin==""||this.state.CBR_Coin==null?<>
                                    N/A
                                    </>:<>{this.state.CBR_Coin} {this.state.CBR_Amount} </>}
                                </span>
                            </div> */}
                            <div className="col-xl-2" style={{borderRight:"2px solid rgba(0,0,0,0.1)",height:"4em"}}>
                            <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:14}}>
                                    Maintec Offered CTC
                                </span>
                                <br/>
                                <span style={{fontFamily:"Poppins",fontSize:14}}>
                                    {this.state.PR_Coin==""||this.state.PR_Coin==null?<>
                                    N/A
                                    </>:<>{this.state.PR_Coin} {this.state.PR_MinAmount}-{this.state.PR_MaxAmount} </>}
                                </span>
                            </div>
                            <div className="col-xl-3" style={{borderRight:"2px solid rgba(0,0,0,0.1)",height:"4em"}}>
                            <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>
                                    Created By & On
                                </span>
                           <br/>
                            <span style={{fontFamily:"Poppins",fontSize:14}}>
                                    {this.state.CreatedBy==""||this.state.CreatedBy==null?<>
                                    N/A
                                    </>:<>{this.state.CreatedBy}- {this.state.created_on}</>}
                                </span>
                            </div>
                            <div className="col-xl-2" style={{borderRight:"2px solid rgba(0,0,0,0.1)",height:"4em"}}>
                            <span style={{fontFamily:"Poppins",fontSize:15,fontWeight:"bold"}}>
                                    Job Status
                                </span>
                                <br/>
                            <span style={{fontFamily:"Poppins",fontSize:14}}>
                                    {this.state.jobstatusname==""||this.state.jobstatusname==null?<>
                                    N/A
                                    </>:<>{this.state.jobstatusname}</>}
                                </span>
                            </div>
                            <div className="col-xl-2" style={{height:"4em"}}>
                            <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>
                                    Business Unit
                                </span>
                                <br/>
                            <span style={{fontFamily:"Poppins",fontSize:14}}>
                                    {this.state.BusinessUnit==""||this.state.BusinessUnit==null?<>
                                    N/A
                                    </>:<>{this.state.BusinessUnit}</>}
                                </span>
                            </div>
                        </div>
                        <br/>
                        <div className="row" style={{height:"8em",overflow:"auto"}}>
                            <div className="col-xl-12">
                            <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>
                                    Job Description
                                </span> <br/>
                                <span style={{fontFamily:"Poppins",fontSize:14}}>

                                {this.state.JobDescription}
                                </span>
                                
                            </div>

                        </div>

                           </div>
                     {/**View Job Page End */}
                        
                        {/**Submission Section Starts */}
                        <div    style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                    
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-xl-11">
                                <h5 style={{fontFamily:"Poppins",fontWeight:"bold"}}>
                                   Submissions
                                </h5>
                            </div>
                           
                           
                        </div>
                        <hr/>
                        <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
        {this.state.loadingicon==0?<>
          <img src={LoadingIcon} style={{marginLeft:"33em",width:"7%"}}/>
        </>:<></>}
        {this.state.loadingicon==1?<>
          <DataTable
               expandableIcon={{
                collapsed:
               <img   src={`${PlusincircleIcon}`} 
                style={{width:"60%"}} />
                ,
                expanded: <img src={MinusincircleIcon} style={{width:"60%"}} />, 
              }}
               expandableRows
               columns={this.columns}
               data={this.state.rows}
               pagination
               striped
               dense
               expandableRowsComponent={<SubmissionsTable/>}
               customStyles={tableCustomStyles}
               onRowExpandToggled={( row,e) => {   
                //console.log(e)
                localStorage.setItem("Applicant_ID",e.ApplicantID)
                localStorage.setItem("Job_ID",this.state.jobid)
               }}
             />
        </>:<></>}   
        {this.state.loadingicon==2?<>
          <div className="text-center" style={{marginTop:"3em",marginBottom:"2em"}}>
            <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>No Data Found !</span>
          </div>
        </>:<></>}     
            </div>
          </div>
                    </div>
                        {/**Submission Section Ends */}

                         {/**Documents Section Starts */}
                         <div   style={{
                    padding: "2%",
                    margin: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: this.state.candidateDoc.length==0?"20em":"auto",
                   
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                      <div className="row">
                            <div className="col-sm-9">
                                <h5 style={{fontFamily:"Poppins",fontWeight:"bold"}}>
                                   Documents
                                </h5>
                            </div>
                            <div className="col-xl-3" style={{textAlign:"right"}}>
                            <a   href="#ft-adddocument-modal"  onClick={()=>this.openModal3()} > <button className="btn btn-primary" style={{fontSize:13,fontFamily:"Poppins",fontWeight:"bold"}}><i className="fa fa-plus-circle" style={{fontSize:17,cursor:"pointer"}}></i>&nbsp;&nbsp;Add Document</button></a>
                             
                         
                            </div>
                        </div>
                        <hr/>
                        {this.state.candidateDoc.length==0?<>
                          <div className="text-center" style={{marginTop:"10%"}}>
                          <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:14}}>No Data Available.</span>
                          
                        </div>
                        
                        </>:<>
                        <div className="row">
                          <div className="col-md-4">
                          <div class="input-group">
                  <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                              onKeyDown={this.handleKeyDown} />
                  <div class="input-group-append">
                    <button class="btn btn-primary" style={{marginBottom:"1em"}} type="button" onChange={this.handleInputChange}
                              onClick={this.handleKeyDown}>
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>

                          </div>

                        </div>
                        <DataTable
                defaultSortField="id"
               
                columns={this.columns2}
                data={this.state.rows2}
                pagination
                striped
                dense
                responsive
                />
                       {/** <table border="1" style={{width:"-webkit-fill-available",border:"1px solid #C9C9C9",textAlign:"center"}} cellPadding="10px">
          <thead style={{backgroundColor:"#4293FE",color:"white",fontSize:14}} >
                  <tr>
                    
                    <th>Document Name</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>View Document</th>
                    <th>Action</th>

                  </tr>
            </thead>
            <tbody style={{fontFamily:"Poppins",fontSize:12,fontWeight:"bold",background:"white"}}>
              {this.state.candidateDoc.map((e)=>(
                <tr>
                  <td>{e.doctype}</td>
                  <td>{e.createdbyname}</td>
                  <td>{e.CreatedDate}</td>
                  <td><button style={{fontSize:12}} onClick={()=>this.viewDocument(e.docname)} className="btn btn-primary"><i className="fa fa-eye"></i> View</button></td>
                  <td><button onClick={()=>this.deleteDocument(e.documentid)} style={{fontSize:12}} className="btn btn-danger"><i className="fa fa-trash"></i> Delete</button></td>
                </tr>
              ))}

              </tbody>
                          </table> */}
                          </>}
                        
                       
                    </div>
                        {/**Documents Section Ends */}
                        </div>
                     <div className="col-xl-3" style={{
                    padding: "2%",
                    marginTop: "1%",
                    background: "#fff",
                    borderRadius: "10px",
                    height: "auto",
                   marginLeft:"-1%",
                    boxShadow: "0 0 5px #a2a2a2",
                    paddingBottom:"3%"
                   
                  }}>
                    <div className="row">
                        <div className="col-xl-1">
                            <img src={ClockIMG} style={{width:"20px",verticalAlign:"sub"}} />
                            
                        </div>
                        <div className="col-xl-8">
                        <h5 style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:18,display:"contents"}}>Recent Search</h5>
                        </div>

                    </div>
                    <div className="row" style={{height:this.state.errormsg=="No data found!"?null:"8em",overflowY:this.state.errormsg=="No data found!"?null:"scroll"}}>
                        {this.state.recentsearch.length==0?<>
                         
                        
                        <div className="col-xl-12 mt-1">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg}</span>
                        
                        </div>

                    
                        </>:<>
                        {this.state.recentsearch.map((e)=>(
                        <>
                      <div className="col-xl-12 mt-1">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.keywords} | {e.minexp==""?<>0</>:<>{e.minexp}</>} Years | {e.maxexp==""?<>0</>:<>{e.maxexp}</>} Years</span><br/>
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:13,color:"#4293FE",cursor:"pointer"}} onClick={()=>this.handleSearch(e.keywords,e.minexp,e.maxexp,e.location,e.noticeperiod)}>Search Profiles</span>
                        </div>

                        </>

                        ))}
                        
                        </>}
                      

                    </div>
                    
                    <div className="row mt-4">
                        <div className="col-xl-1">
                            <img src={ClockIMG} style={{width:"20px",verticalAlign:"sub"}} />
                            
                        </div>
                        <div className="col-xl-8">
                        <h5 style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:18,display:"contents"}} >Saved Search</h5>
                        </div>

                    </div>
                    <div className="row" style={{height:this.state.errormsg=="No data found!"?null:"10em",overflow:this.state.errormsg=="No data found!"?null:"auto"}}>
                        {this.state.savedsearch.length==0?<>
                         
                        
                        <div className="col-xl-12 mt-1">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{this.state.errormsg}</span>
                        
                        </div>

                        
                        </>:<>
                        {this.state.savedsearch.map((e)=>(
                        <>
                      <div className="col-xl-12 mt-1">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.keywords} | {e.minexp==""?<>0</>:<>{e.minexp}</>} Years | {e.maxexp==""?<>0</>:<>{e.maxexp}</>} Years</span><br/>
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:13,color:"#4293FE",cursor:"pointer"}} 
                        onClick={()=>this.handleSearch(e.keywords,e.minexp,e.maxexp,e.location,e.noticeperiod)}>Search Profiles</span>
                        </div>

                        </>

                        ))}
                        
                        </>}
                      

                    </div>
                  <hr className="hrnew"/>
                  <div className="row">
                        
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>Job Title</span><br/>
                        <input className="form-control adduser mt-1" value={this.state.JobTitle} disabled />

                     
                        </div>

                    </div>
                  <div className="row mt-2" style={{lineHeight:"18px"}}>
                        
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>Search Criteria</span><br/>
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:12}}>Keywords(Comma Separated words)</span>
                        <textarea placeholder="For excample: java,html,css...." className="form-control adduser mt-1" rows="3" onChange={this.handleChange} name="getkeywordtext">

                        </textarea>
                        </div>

                    </div>
                   
                   
                    <div className="row mt-2">
                        
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>Experience</span>
                      
                        </div>

                    </div>
                    <div className="row mt-2">
                        <div className="col-xl-4">
                        <input className="form-control adduser" placeholder="Min" name="getminvalue" onChange={this.handleChange} type="number" min="0" max="20"/>
                        </div>
                        <div className="col-xl-1">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:13}}>To</span>
                        </div>
                        <div className="col-xl-4">
                        <input className="form-control adduser" placeholder="Max" name="getmaxvalue" onChange={this.handleChange} type="number" min="0" max="20"/>
                        </div>
                        <div className="col-xl-2">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:13}}>Years</span>
                        </div>
                    </div>
                    <hr className="hrnew"/>
                    <div className="row mt-2">
                        
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>Location</span><br/>
                        <Select onChange={this.handleLocation} options={this.state.countrydata} components={{  IndicatorSeparator:() => null }} styles={style}  />
                        </div>

                    </div>
                    
                    <div className="row mt-2">
                        
                        <div className="col-xl-12">
                        <span style={{fontFamily:"Poppins",fontWeight:"bold",fontSize:15}}>Notice Period</span><br/>
                        <Select onChange={this.handleNoticeperiod}  options={this.state.Noticeperiod} components={{  IndicatorSeparator:() => null }} styles={style}  />
                        </div>

                    </div>
                 
            
                 <br/><br/><br/>
                  <table style={{margin:"auto"}}>
                    <tr>
                      <td>   <button className="btn btn-primary" style={{width:"100%",fontFamily:"Poppins",fontWeight:"bold",fontSize:13}} onClick={this.handlejobSearch2}>
                      Save & Search</button></td>
                      <td>
                      <button className="btn btn-primary" style={{width:"100%",marginLeft:"1em",fontFamily:"Poppins",fontWeight:"bold",fontSize:13}} onClick={this.handlejobSearch}>Search</button>
                      </td>
                      <td>
                      <button className="btn btn-primary" style={{marginLeft:"2em",fontFamily:"Poppins",fontWeight:"bold",fontSize:13}}  onClick={()=>window.location.reload()}>Reset</button>
                      </td>
                    </tr>
                  </table>
                     </div>

                    </div>
                   
                  
                  
                </div>
        </div>
            </div>
        )
    }
}