import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import UserImg from "../images/usering.png";
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Activeuserdatatable from "./Activeuserdatatable";
import Select from 'react-select'
import Inactiveuser from "./Inactiveuser";
import PendingUser from "./PendingUser";
import DeletedUser from "./DeletedUser";
import Swal from "sweetalert2";
import ActiveTeam from "./ActiveTeam";
import Resizer from "react-image-file-resizer";
import Header from "./Header";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
export const data = [
  ["Pizza", "Popularity"],
  ["Client 01", 33],
  ["Client 02", 26],
  ["Client 03", 20],
  ["Client 04", 20], // Below limit.
  
];

export const options = {
 
  sliceVisibilityThreshold: 0.2, // 20%,
  slices: {
    0: { color: '#010c4e' },
    1: { color: '#4293fe' },
    2: { color: '#c6c6c4' },
    3: { color: '#7fc200' }
  }
};

export default class UserManagement extends React.Component {

  constructor(){
   
    if (localStorage.getItem("Username") == null) {
            

      localStorage.clear()
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/`


     } else {
      var username=JSON.stringify(localStorage.getItem("Username"))
      
     }

     
    const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let decodedid=window.atob(data1)
    let decodedname=window.atob(data2)
    let decodedemail=window.atob(data3)
  
    let decodedroleid=window.atob(data5)
    //console.log(splitURL)
    super();
    this.state={
          encodedid:data1,
          encodedname:data2,
          encodedemail:data3,
         user_email:decodedemail,
          user_name:decodedname,
          user_id:decodedid,
          roleid:data5,
          encodedroleid:decodedroleid,
          display:0,
          displayform:0,
          options : [
            { value: '1', label: 'Bangalore' },
            { value: '2', label: 'Pune' },
            { value: '3', label: 'Chennai' }
          ],
          permissonmodule:[
            { value: "1", label: "Role" },
            { value: "2", label: "Job Posting" },
          { value: "3", label: "Applicants" },
          { value: "4", label: "Clients" },
          
        ],
          on:0,
          usermanagementscreen:1,
          accesscontrolscreen:0,
          rolesscreen:0,
          userfieldscreen:0,
          teamsscreen:0,
          businessunit:[
            { value: '1', label: 'Maintec Technologies' },
           
          ],
          userstausdata:[
            { value: '1', label: 'Active'},
            {value: '2', label: 'Inactive'},
            {value: '3', label: 'Pending' },
           
          ],
          rolesdata:[],
          teamdata:[],
          reportingtodata:[],
          firstname:"",
          lastname:"",
          username:"",
          email:"",
          workinglocation:"",
          password:"",
          userrole:"",
          userrolename:"",
          userstatus:"",
          mobile:"",
          reportingto:"",
          reporting_toname:"",
          BusinessUnit:"",
          adminprivileges:"No",
          dob:"",
          doj:"",
          teamids:"",
          teamname:"",
          employeeID:"",
          userstatusname:"",
          RoleAPIdata:[],
          addteam:0,
          teamname:"",
          TLroleids:"",
          TLrolename:"",
          TLids:"",
          TLname:"",
          Description:"",
          totalmembers:"",
          DataPermission:"",
          BussinessUnit:"Maintec Technologies",
          accessmenu:[],
          accessdata:[],
          menuids:1,
          showModal: false,
          showModal2: false,
          accessid:"",
          addaccess:"",
          deleteaccess:"",
          editaccess:"",
          menuname:"",
          rolename:"",
          roletypeids:"",
          editaccessid:"",
          message:"",
          profilepic:" ",
          errormsg:"",
          errormsgpwd:"",
          errorfirstname:"",
          errorlastname:"",
          timezonedata:[],
          timezoneid:"",
          timezonename:"",
          ProfileImg:"",
          showpwd:0 ,
          netcount:"" ,
          middlename:"" ,
          mobileerror:"",
          emailerror:"",
          imagename :"",
          Sourcesemail:[],
          sourceemailid:"",
          sourceemailname:""

        
        }
    this.handleDisplay=this.handleDisplay.bind(this)
    this.handleDisplayClose=this.handleDisplayClose.bind(this)
    this.handleLogout=this.handleLogout.bind(this)
    this.handleJob=this.handleJob.bind(this)
    this.handleApplicant=this.handleApplicant.bind(this)
    this.handleHome=this.handleHome.bind(this)
    this.handleAdd=this.handleAdd.bind(this)
    this.handleCancel=this.handleCancel.bind(this)
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleAccess=this.handleAccess.bind(this)
    this.handleUser=this.handleUser.bind(this)
    this.hanldelRoles=this.hanldelRoles.bind(this)
    this.handleUsefield=this.handleUsefield.bind(this)
    this.handleTeam=this.handleTeam.bind(this)
    this.hanldelClient=this.hanldelClient.bind(this)
    this.handleRegister=this.handleRegister.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleaddTeam=this.handleaddTeam.bind(this)
    this.handlebackTeam=this.handlebackTeam.bind(this)
    this.handleRegisterteam=this.handleRegisterteam.bind(this)
    this.handleAccesscontrol=this.handleAccesscontrol.bind(this)
    this.handleView=this.handleView.bind(this)
    this.handleViewclose=this.handleViewclose.bind(this)
    this.handleAddd=this.handleAddd.bind(this)
    this.handleAdddclose=this.handleAdddclose.bind(this)
    this.handleEdit=this.handleEdit.bind(this)
    this.handleEditclose=this.handleEditclose.bind(this)
    this.handleDelete=this.handleDelete.bind(this)
    this.handleDeleteclose=this.handleDeleteclose.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleTime=this.handleTime.bind(this)
    this.handleMaster=this.handleMaster.bind(this)
    this.handleUpdateImage=this.handleUpdateImage.bind(this)
    this.handleClosepwd=this.handleClosepwd.bind(this)
    this.handleShowpwd=this.handleShowpwd.bind(this)
    this.handleOnboarding=this.handleOnboarding.bind(this)
    this.handleCheckboxclose1=this.handleCheckboxclose1.bind(this)
    this.handleCheckbox1=this.handleCheckbox1.bind(this)
  }
  handleCheckboxclose1(){
     
    this.setState({sourceemailid : ""})
    this.setState({sourceemailname : ""})
  
  
}
handleCheckbox1(id,name){
  this.setState({sourceemailid : ""})
  this.setState({sourceemailname : ""})
  this.setState({sourceemailid:id})
  this.setState({sourceemailname:name},()=>{
    //console.log(this.state.sourceemailname)
  })

}

  handleOnboarding(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}`
  }
  handleClosepwd(){
    this.setState({showpwd:0})
  }
  /*Show password function call end */
  
  /* Show password function call starts */
  
  handleShowpwd(){
    this.setState({showpwd:1})
  }
  handleUpdateImage(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,
        adduserdata:"editsinglephotoinfo",
        profilepic:this.state.profilepic,
        imagename:this.state.imagename
     
    }
      //console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
         if(response.message=="Profile Pic Updated successfully"){
          const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          //console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             //console.log(response)
                 this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                 
                        
                 })
             
                    
                  });
         }
          
                
         
                
              });
  }
  handleMaster(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader);
        baseURL = reader.result;
        //console.log(baseURL);
        resolve(baseURL);
      };
      //console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    
    // let file = new FileReader();
    //console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        //console.log("File Is", file);
        this.setState({
          profilepic: result,
          ProfileImg:result,
          file,
          imagename:file["name"],
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
           // console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        //console.log(err);
      }
    }
  };
  handleTime(e){
this.setState({timezoneid:e.value,timezonename:e.label})
  }
  handleView(){

    this.setState({viewaccess:1})
  }
  handleViewclose(){
    this.setState({viewaccess:2})
  }
  handleAddd(){
    this.setState({addaccess:1})
  }
  handleAdddclose(){
    this.setState({addaccess:2})
  }
  handleEdit(){
    this.setState({editaccess:1})
  }
  handleEditclose(){
    this.setState({editaccess:2})
  }
  handleDelete(){
    this.setState({deleteaccess:1})
  }
  handleDeleteclose(){
    this.setState({deleteaccess:2})
  }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addAccessdata:"editAccessinfo",
      addaccesscode:this.state.addaccess,
      deleteaccesscode:this.state.deleteaccess,
      editaccesscode:this.state.editaccess,
      viewaccesscode:this.state.viewaccess,
      editaccessid:this.state.editaccessid
    }
      //console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
         
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
                
         
                
              });
     
  }
  openModal = (roletypeids) => {
    this.setState({editaccessid:roletypeids})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addAccessdata:"getsingleaccessinfo",editaccessid:roletypeids}
      //console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          //console.log(response)
          response.result.map((e)=>{
            this.setState({
            accessid:e.accessid,
            addaccess:e.addaccess,
            deleteaccess:e.deleteaccess,
            editaccess:e.editaccess,
            menuids:e.menuids,
            menuname:e.menuname,
            rolename:e.rolename,
            roletypeids:e.roletypeids,
            viewaccess:e.viewaccess

            })
          })
                
         
                
              });
     
    this.setState({ showModal: true });
  };
  openModal2=()=>{
    this.setState({ showModal2: true,display:0 });
  }
  closeModal = () => {
    this.setState({ showModal: false,display:0 });
  };
  closeModal2 = () => {
    this.setState({ showModal2: false });
  };
  handleAccesscontrol(smenuid){
              /* Access Data API Call Start */
       const amenudata={ userid : this.state.user_id,addAccessdata:"getAccessinfo",menuids:smenuid}
      // console.log(amenudata)
        fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
            method: "POST",
    
            body: JSON.stringify(amenudata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
            // console.log(response)  
       
            this.setState({accessdata:response.result})
                  
                });
        /* Access Data API Call End */
  }
  handlebackTeam(){
    this.setState({addteam:0,teamsscreen:1})
  }
  handleaddTeam(){
    this.setState({addteam:1,teamsscreen:0})
  }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value,TLids:e.value,TLname:e.label},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value,TLroleids:
e.value,TLrolename:e.label},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,BussinessUnit:e.label},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value,teamname:e.label},()=>{
          
        })
          }
  handleRegister(){
    var passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    if(this.state.firstname==null||this.state.firstname==""){
      Swal.fire(
        "Please Enter First Name"
      )
    }
   else  if(this.state.lastname==null||this.state.lastname==""){
      Swal.fire(
        "Please Enter Last Name"
      )
    }
    else if(this.state.mobile==null||this.state.mobile==""){
      Swal.fire(
        "Please Enter Mobile Number"
      )
    }
    else if(this.state.email==null||this.state.email==""){
      Swal.fire(
        "Please Enter Email ID"
      )
    }
    else if(this.state.email==2){
      Swal.fire(
        "This email already available."
      )
    }
    else if(this.state.workinglocation==null||this.state.workinglocation==""){
      Swal.fire(
        "Please Enter Branch"
      )
    }
    else if(this.state.dob==null||this.state.dob==""){
      Swal.fire(
        "Please Select Date of Birth"
      )
    }
    else if(this.state.doj==null||this.state.doj==""){
      Swal.fire(
        "Please Select Joining Date"
      )
    }
    else if(this.state.timezoneid==null||this.state.timezoneid==""){
      Swal.fire(
        "Please Select Time Zone"
      )
    }
    else if(this.state.employeeID==null||this.state.employeeID==""){
      Swal.fire(
        "Please Enter Employee ID"
      )
    }
    else if(this.state.employeeID==null||this.state.employeeID==""){
      Swal.fire(
        "Please Enter Employee ID"
      )
    }
    else if(this.state.adminprivileges==null||this.state.adminprivileges==""){
      Swal.fire(
        "Please Select Admin Privildges"
      )
    }
    else if(this.state.userstatus==null||this.state.userstatus==""){
      Swal.fire(
        "Please Select User Status"
      )
    }
   
    else if(this.state.userrolename==null||this.state.userrolename==""){
      Swal.fire(
        "Please Select Roles"
      )
    }
   
    else if(this.state.reporting_toname==null||this.state.reporting_toname==""){
      Swal.fire(
        "Please Select Reporting To"
      )
    }
    else if(this.state.teamname==null||this.state.teamname==""){
      Swal.fire(
        "Please Select Team Name"
      )
    }
   else if(this.state.password.match(passw)==false){
    Swal.fire(
      "Please Enter a Valid Password"
    )
      
    }
   else{
    const data={
      userid:this.state.user_id,
      adduserdata:"adduserinfo",
      middlename:this.state.middlename,
      firstname:this.state.firstname,
      profilepic:this.state.profilepic,
      lastname:this.state.lastname,
      username:this.state.username,
      email:this.state.email,
      workinglocation:this.state.workinglocation,
      password:this.state.password,
      userrole:this.state.userrole,
      userrolename:this.state.userrolename,
      userstatus:this.state.userstatus,
      mobile:this.state.mobile,
      reportingto:this.state.reportingto,
      reporting_toname:this.state.reporting_toname,
      BusinessUnit:"Maintec Technologies",
      adminprivileges:this.state.adminprivileges,
      dob:this.state.dob,
      doj:this.state.doj,
      teamids:this.state.teamids,
      teamname:this.state.teamname,
      timezoneid:this.state.timezoneid,
      timezonename:this.state.timezonename,
      employeeID:this.state.employeeID,
      imagename:this.state.imagename,
      sourceemailid:this.state.sourceemailid,
      sourceemailname:this.state.sourceemailname
    }
   // console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              if(response.message=="User Data successfully Added."){
                Swal.fire(
                  response.message,
                  
                  
                )
                setTimeout(
                  function() {
                    window.location.reload()
                  }
                  .bind(this),
                  3000
              );
                
               //  console.log(response)
              }
            else{
              Swal.fire(
                response.message,
                
                
              )
              //console.log(response)
            }
      
                  
                });
   
   
   
   }

  }
  handleRegisterteam(){
    const data={
      userid:this.state.user_id,
      adduserdata:"addteamsinfo",
      teamname:this.state.teamname,
      BussinessUnit:this.state.BussinessUnit,
      TLroleids:this.state.TLroleids,
      TLrolename:this.state.TLrolename,
      TLids:this.state.TLids,
      TLname:this.state.TLname,
      Description:this.state.Description,
      DataPermission:this.state.DataPermission,
     
    }
   // console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
    
             
              Swal.fire(
                response.message,
                
              )
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
                3000
            );
              // console.log(response)
      
                  
                });
      }
  handleChange = (e) => {
    //var regex = /[0-9!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleChangeEmpID = (e) => {
    //var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    //e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleChangeEmail = (e) => {
    const menudata={ dropdownlist_input: "checkemailinfo",email:e.target.value}

    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(menudata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
        if(response.message=="This email already available."){
          this.setState({emailerror:response.message,email:2})
        }
            
        else{
          this.setState({emailerror:response.message,email:e.target.value})
          this.setState({
            [e.target.name]: e.target.value,

          });
        }
            });
 
    
  };
  
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value.length!=10){
      this.setState({mobileerror:"Contact number should be digits only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
    }
  
    
  };
  handleChangeDate = (e) => {
    let currentDate = new Date().toJSON().slice(0, 10);
    if(e.target.value==currentDate ){
      e.target.value = e.target.value.replace(currentDate, "");
   
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
  
      }); 
    }
  };
  
  handleChangetwo = (e) => {
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByName("doj")[0].setAttribute('min', today);
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  hanldelClient(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleTeam(){
    this.setState({rolesscreen:0,accesscontrolscreen:0,usermanagementscreen:0,teamsscreen:1,userfieldscreen:0,addteam:0})
    
  }
  handleUsefield(){
    this.setState({rolesscreen:0,accesscontrolscreen:0,usermanagementscreen:0,userfieldscreen:1,teamsscreen:0,addteam:0})
  
  }
  hanldelRoles(){
    this.setState({rolesscreen:1,accesscontrolscreen:0,usermanagementscreen:0,userfieldscreen:0,teamsscreen:0,addteam:0})
  
  
const data={ dropdownlist_input: "getuserrolestabinfo"}
//console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
        this.setState({RoleAPIdata:response.result})
          
        });
  }

  handleUser(){
    window.location.reload();
    this.setState({accesscontrolscreen:0,usermanagementscreen:1,rolesscreen:0,userfieldscreen:0,teamsscreen:0,addteam:0})
  }
  handleAccess(){
    this.setState({accesscontrolscreen:1,usermanagementscreen:0,rolesscreen:0,userfieldscreen:0,teamsscreen:0,addteam:0})
  }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  handleCancel(){
    this.setState({displayform:0})
  }
  handleAdd(){
    this.setState({displayform:1})
  }
  handleApplicant(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleJob(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleDisplay(){
    this.setState({display:1})
  }
  handleDisplayClose(){
    this.setState({display:0})
  }
  handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
  handleHome(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.roleid}`
  }
  componentDidMount(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const pdata={ userid:withoutQuotes,
        adduserdata:"getsinglephotoinfo",
      
     
    }
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(pdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
            if(response.result.profilepic=="BASE64"){
              this.setState({ProfileImg:""},()=>{
               
              })
          
           }
           else{
              this.setState({ProfileImg:response.result.profilepic},()=>{
               
              })
          
           }
         
                
              });
     /* Source Email API Start */
     const semail={ dropdownlist_input: "getsourceemaillistinfo",userid:withoutQuotes}
     //console.log(menudata)
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
 
         body: JSON.stringify(semail), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         this.setState({Sourcesemail:data})
               
             });
     /* Source Email API Call End */
      /* Access Menu API Call Start */
      const menudata={ dropdownlist_input: "getaccessmenus"}
      //console.log(menudata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(menudata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.smenuid,
              "label" : d.smenuname
  
            })) 
     
          this.setState({accessmenu:options})
                
              });
      /* Access Menu API Call End */


       /* Net Count API Call Start */
       const netcount={ dropdownlist_input: "getnetcountinfo"}
      
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(netcount), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               this.setState({netcount:response.netcount})
          
                 
               });
       /* Net Count API Call End */
       /* Time Zone API Call Start */
       const timedata={ dropdownlist_input: "gettimezoneinfo"}
       //console.log(menudata)
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(timedata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({timezonedata:options})
                 
               });
       /* Time Zone API Call End */
       /* Access Data API Call Start */
       const amenudata={ userid : withoutQuotes,addAccessdata:"getAccessinfo",menuids:this.state.menuids}
       // console.log(amenudata)
         fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
             method: "POST",
     
             body: JSON.stringify(amenudata), // data can be `string` or {object}!
     
             headers: { "Content-Type": "application/json" },
           })
             .then((res) => res.json())
     
             .catch((error) => console.error("Error:", error))
     
             .then((response) => {
              // console.log(response)  
        
             this.setState({accessdata:response.result})
                   
                 });
         /* Access Data API Call End */
    /* Roles API Call Start */
    const data={ dropdownlist_input: "getuserrole"}
    //console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });
    /* Roles API Call End */
      /* Reporting To API Call Start */
      const rdata={ dropdownlist_input: "getreportinginfo"}
      //console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
      /* Reporting To  API Call End */

       /* Reporting To API Call Start */
       const tdata={ userid: withoutQuotes,
        adduserdata:"getteamsinfo"
      }
       //console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
       /* Reporting To  API Call End */
  }
  render() {
    return (
      <div>
        {/* Header Section Starts */}

        <div className="dashboardheader fixed-top">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li style={{cursor:"pointer"}} onClick={this.handleHome}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleJob}>Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleApplicant}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.hanldelClient} style={{cursor:"pointer"}}>Clients</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li onClick={this.handleOnboarding} style={{cursor:"pointer"}}>Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} className="litext">User Management</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
          </ul>
          <Header/>
          
        </div>
         {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={`https://www.mrecruit.in/sysload/profiledocuments/${this.state.ProfileImg}`}
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}
    {/**Edit access control starts */}
    {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"60%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Access Control Information
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
                      <div className="row">
                        <div className="col-md-12">
                        <table class="table" >

                    <thead style={{backgroundColor:"#4293FE",color:"white"}}>
                      <tr>
                        <th scope="col">Roles</th>
                        <th scope="col">View</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Add</th>
                        <th scope="col">Delete</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.rolename}</td>
                      <td>{this.state.viewaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleView}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>View all records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleViewclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>Can not view records</span></>}
                        
                        </td>
                      <td>{this.state.editaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleEdit}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}> Edit all records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleEditclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>Can not edit records</span></>}</td>
                      <td>{this.state.addaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleAddd}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>Allowed to add records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleAdddclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>Can not add records</span></>}</td>
                      <td>{this.state.deleteaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleDelete}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>Delete all records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleDeleteclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"text-top",fontSize:12}}>Can not delete records</span></>}</td>
                    
                    </tr> 
                    </tbody>
                    </table>
                   
                        </div>
                      </div>
                      <div className="row mt-3" style={{marginBottom:"3%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row mt-3" style={{marginBottom:"3%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Edit access control ends */}
        <div>
              {/**Side Bar starts */}



              <div class="sidebar">
                {this.state.usermanagementscreen==1?<>
                  <a class="active">User Management</a>
                </>:<>
                <a onClick={this.handleUser} className="usermgmttxt usermgmtmenu">User Management</a>
                </>}
  
  {/**{this.state.accesscontrolscreen==1?<>
    <a  class="access">Access Control</a>
  </>:<>
  <a  onClick={this.handleAccess} className="usermgmtmenu">Access Control</a>
  </>} */}
  {this.state.rolesscreen==1?<>
    <a className="access">User Count</a>
  </>:<> <a onClick={this.hanldelRoles} className="usermgmtmenu">User Count</a></>}
  
 
  {/*this.state.userfieldscreen==1?<>
    <a className="access">User Field Restrictions </a>
  </>:<> <a onClick={this.handleUsefield} className="usermgmtmenu">User Field Restrictions </a></>*/}
  
 
</div>
{/**Side bar ends */}
{/* User Management Screen Starts */}
{this.state.usermanagementscreen==1?<>
  <div class="content" >
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Poppins Bold"}}>User Information</h2></div>
     <div className="col-xl-2 text-center">
      {this.state.displayform==1?<>
        <button onClick={this.handleCancel} className="btn btn-primary useraddbtn">Back</button>
      </>:<><button onClick={this.handleAdd} className="btn btn-primary useraddbtn">Add</button></>}
       </div>
 </div>
 {this.state.displayform==0?<>
  <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
    <div className="row">
    <div className="col-xl-8">
     <span className="nettxt">Users Currently Logged In :   <i class="fa fa-globe globeicon" aria-hidden="true" style={{fontSize:16}}></i> {this.state.netcount}</span>
    </div>
   
    {/**<div className="col-xl-1">
    <i class="fa fa-mobile globeicon" aria-hidden="true"></i>
    <span className="webtxt">22</span>
    </div> */}
  </div>
  
  <div className="row" style={{marginLeft:"0%"}}>
            <div className="col-sm-12">

          
               
          
               <div>
               <Tabs
          activeTab="1"
          className=""
          ulClassName=""
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="Active Users" className="mr-4">
                <div className="mt-3">
                 <Activeuserdatatable />
                </div>
            </Tab>
            <Tab title="Inactive Users" className="mr-4">
                <div className="mt-3">
                <Inactiveuser/>
                </div>
            </Tab>
          
            <Tab title="Pending Activation Users" className="mr-4">
            <div className="mt-3">
                <PendingUser/>
                </div>
            </Tab>
        </Tabs>
                <table>
                  <tr>
                    <td> <span style={{fontFamily:"Poppins Bold",borderBottom:"2px solid blue",color:"blue",cursor:"pointer"}}></span></td> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <td> <span style={{fontFamily:"Poppins Bold"}}></span></td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <td> <span style={{fontFamily:"Poppins Bold"}}></span></td>
                  </tr>
                </table>
              
               </div> 
            </div>
          </div>
 </>:<>
 {/**Add user for starts */}

 <div>
  <hr/>
  
  <div className="row mt-1">
    <div className="col-xl-1">
    {this.state.profilepic==" "?<>
<img src={UserImg} 
 
 style={{width:"107%"}}  />
</>:<>  <img src={this.state.profilepic} 
 
 style={{width:"120%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-2">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
  <div className="row mt-2">
    <div className="col-xl-12">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>User Name</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    </div>
  </div>

  <div className="row">
    <div className="col-xl-4">
     <input className="form-control adduser mt-2" placeholder="First Name" name="firstname"    onChange={this.handleChange}/>
     <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.errorfirstname}</span>

    </div>
    <div className="col-xl-4">
     <input onChange={this.handleChange} name="middlename" className="form-control adduser mt-2" placeholder="Middle Name"/>
    </div>
    <div className="col-xl-4">
     <input name="lastname" onChange={this.handleChange} className="form-control adduser mt-2" placeholder="Last Name"/>
     <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.errorlastname}</span>
    </div>
  </div>
  <div className="row mt-4">
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Display Name</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="text" name="username"  onChange={this.handleChange} className="form-control adduser mt-2" />
     <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.errormsg}</span>
    </div>
   
    <div className="col-xl-8" style={{maxWidth:"65%"}}>
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Password</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>&nbsp;&nbsp;<span style={{color:"red",fontWeight:"bold",fontFamily:"Poppins Light",fontSize:11,verticalAlign:"middle"}} >(Password Must be 8 Characters or Longer Combination of Upper and Lower Case letters, Numbers and Special Character)</span>
    {this.state.showpwd==0?<>

<div class="input-group mt-2">

<input type="password" style={{borderRadius:"4px"}}  class="form-control  shadow-none adduser"  placeholder="Password" name="password" onChange={this.handleChangetwo} value={this.state.password} />
<button type="button"  class="toggle">
<i id="eyeIcon" class="fa fa-eye" style={{color:"black"}}  onClick={this.handleShowpwd}></i></button>
</div>
          </>:null}
{this.state.showpwd==1?<>
  <div class="input-group mt-2">

<input style={{borderRadius:"4px"}} type="text" value={this.state.password}  class="form-control  shadow-none adduser"  placeholder="Password" name="password" onChange={this.handleChangetwo} />
<button type="button"  class="toggle">
<i id="eyeIcon" class="fa fa-eye-slash" style={{color:"black"}}  onClick={this.handleClosepwd}></i></button>
</div>
 
</>:null} 
    
    </div>
  </div>
  <div className="row mt-4">
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Contact Number</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input name="mobile" maxLength="10"   onChange={this.handleChangemobile}  className="form-control adduser mt-2" />
     <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.mobileerror}</span>
    </div>
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Email ID</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="email" 
     name="email" onChange={this.handleChangeEmail}
     className="form-control adduser mt-2" />
     <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.emailerror}</span>
    </div>
    <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Branch</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
      <input type="text" 
      name="workinglocation" onChange={this.handleChange}className="form-control adduser mt-2" />
    <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.errorworkinglocation}</span>
    </div>
  </div>
  <div className="row mt-4">
 
   
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>DOB</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="date"
      name="dob" min="1965-01-01" max="2006-01-01" onChange={this.handleChangeDate} className="form-control adduser mt-2" />
   <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.errordob}</span>
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Joining Date</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
     <input type="date"
      name="doj" min={new Date().toISOString().split('T')[0]} onChange={this.handleChangetwo}
     className="form-control adduser mt-2" />
     <span style={{fontFamily:"Poppins Light",color:"red",fontSize:12,fontWeight:"bold"}}>{this.state.errordoj}</span>
    
    </div>
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Time Zone</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
   onChange={(e)=>this.handleTime(e)}
    options={this.state.timezonedata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>

    </div>
  </div>

  <div className="row mt-4">
    <div className="col-xl-4">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Employee ID</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <input type="text"
    name="employeeID"
    className="form-control adduser mt-2" onChange={this.handleChangeEmpID} />
     
    
    </div>
   
    <div className="col-xl-2">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Admin Privilleges</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span><br/>
   {this.state.on==1?<>
    <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handlecloseAdmin}
    type="checkbox" id="myToggle" checked />
    <div class="toggle__fill"></div>
</label>
   </>:<> <label class="toggle" for="myToggle">
    <input class="toggle__input" name="" 
   onClick={this.handleAdmin}
    type="checkbox" id="myToggle"  />
    <div class="toggle__fill"></div>
</label></>}
   


    </div>
    <div className="col-xl-1" style={{marginTop:"3%",marginLeft:"-6%"}}>
    <div style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>
  {this.state.on==1?<>
    <span>On</span>
  </>:<> <span>Off</span></>}
 </div>
    </div>
    <div className="col-xl-5" style={{marginLeft:"6%"}}>
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>User Status</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    onChange={(e)=>this.handleUserstatus(e)}
    options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
  </div>
  <div className="row mt-4">
    {/**<div className="col-xl-6">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Business Unit</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select 
    onChange={(e)=>this.handleBusiness(e)}
    options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
     
    
    </div> */}
   
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Roles</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select
    onChange={(e)=>this.handleRoles(e)}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Reporting To</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select onChange={(e)=>this.handleReport(e)} options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
     
    
    </div>
   
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Team</span>    <span style={{fontWeight:"bold",fontFamily: "Poppins Light",color:"red",fontSize:14}}>*</span>
    <Select onChange={this.handleTeamdata} options={this.state.teamdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    <div className="col-xl-3">
    <span style={{fontWeight:"bold",fontFamily: "Poppins Light"}}>Add Source Email ID</span>
    <div className="mt-1" style={{height:"5.5em",overflow:"auto",border:"2px solid #A0BED8",margin:"0.1%",padding:"0.1%"}}>
      {this.state.Sourcesemail.map((e)=>(
        <>
        <div className="col-xl-12">
        {this.state.sourceemailid==e.id?<>
          <i className="fa fa-check-square" onClick={()=>this.handleCheckboxclose1(e.id,e.name)} style={{color:"#4293FE",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
        {this.state.sourceemailid!=e.id?<>
          <i className="fa fa-square" onClick={()=>this.handleCheckbox1(e.id,e.name)} style={{color:"#A0BED8",cursor:"pointer"}}></i>  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:13}}>{e.name}</span>
        </>:null}
       
      </div>
        </>
      ))}

    </div>
    </div>
  </div>
 

  <div className="row mt-4">
  <div className="col-xl-8">

  </div>
  <div className="col-xl-2">
<button className="btn btn-primary regbtn" onClick={this.handleRegister}>Register</button>
</div>
<div className="col-xl-2">
<button className="btn btn-primary regbtn" onClick={this.handleCancel}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
 </>}
 
  
</div>
</>:null}
{/* User Management Screen Ends */}

{/* Access Control Screen Starts */}
{this.state.accesscontrolscreen==1?<>
  <div class="content">
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Poppins Bold"}}>
      Access Control
      </h2></div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
    <div className="row" style={{marginBottom:"2%"}}>
      <div className="col-xl-6">
 
      </div>
      <div className="col-xl-3" style={{marginTop:"1%"}}>
      <span style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>
    Assign Permissions for the Module
    </span>
      </div>
      <div className="col-xl-3">
      <Select 
      placeholder="Job Posting"
    onChange={(e)=>this.handleAccesscontrol(e.value)}
    options={this.state.accessmenu}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
      </div>
    </div>
   <div className="row">
    <div className="col-xl-12">
 
<table class="table" >

  <thead style={{backgroundColor:"#4293FE",color:"white"}}>
    <tr>
      <th scope="col">Roles</th>
      <th scope="col">View</th>
      <th scope="col">Edit</th>
      <th scope="col">Add</th>
      <th scope="col">Delete</th>
      <th scope="col">Action Permission</th>
    </tr>
  </thead>
  <tbody style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>
{this.state.accessdata.map((e,index)=>(
   <tr className={index % 2 !== 0?"accesstbl":null}>
   <td style={{fontSize:13}}> 
   <a className="editaccess" href="#ft-edituser-modal"><i className="fa fa-edit" onClick={()=>this.openModal(e.roletypeids)} style={{cursor:"pointer"}}></i></a>&nbsp;&nbsp;{e.rolename}</td>
   <td>{e.viewaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i>&nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>View all records</span>
   </>:<></>}
   {e.viewaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Can not view records</span></>:null}
   </td>
   <td>{e.editaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i>&nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Edit all records</span>
   </>:<></>}
   {e.editaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Can not edit records</span></>:null}
   </td>
   <td>{e.addaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i>
    &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Allowed to add records</span>
    
   </>:<></>}
   {e.addaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>
   &nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Can not add records</span>
   </>:null}
   </td>
   <td>{e.deleteaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Delete all records</span>
   </>:<></>}
   {e.deleteaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>
   &nbsp;
   <span style={{color:"red",fontFamily:"Poppins Light",verticalAlign:"middle",fontSize:12}}>Can not delete records</span>
   </>:null}
   </td>
   <td>Access to {e.totalaccess} Actions</td>
 </tr>
))}
    
  </tbody>
</table>

    </div>
  </div>  
 
 
  
</div>
</>:null}
{/* Access Control Screen Ends */}


  {/* Roles Control Screen Starts */}
{this.state.rolesscreen==1?<>
  <div class="content">
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Poppins Bold"}}>
    User Count
      </h2></div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
   <div className="row mt-3">
    <div className="col-xl-12">
 
<table class="table" >
  <thead style={{backgroundColor:"#4293FE",color:"white"}}>
    <tr>
      <th scope="col">Roles</th>
      <th scope="col">No. of Active Users</th>
     
    </tr>
  </thead>
  <tbody style={{fontFamily:"Poppins Light",fontSize:14,fontWeight:"bold"}}>
    {this.state.RoleAPIdata.map((e,index)=>(
      <tr className={index % 2 !== 0?"accesstbl":null}>
        <td>{e.rolename}</td>
        <td>{e.totalusers}</td>
      </tr>
    ))}

  </tbody>
</table>

    </div>
  </div>  
 
 
  
</div>
</>:null}
{/* Roles Screen Ends */}   


  {/* Team Screen Starts */}
  {this.state.teamsscreen==1?<>
  <div class="content">
  <div className="row">
    <div className="col-xl-9"><h2 style={{fontFamily:"Poppins Bold"}}>
       Teams
      </h2></div>
      <div className="col-xl-3" style={{textAlign:"right"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"3%"}}
      onClick={this.handleaddTeam}
      >Add Team</button>
      </div>
     
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
     <div className="row mt-3">
    <div className="col-xl-12">
<ActiveTeam/>

    </div>
  </div> 

 
 
 
  
</div>
</>:<>

</>}


{/* Team Screen Ends */}  
{this.state.addteam==1?<>
  <div class="container" style={{padding: "1% 16px",marginLeft:"21%"}}>
  <div className="row" >
    <div className="col-xl-9"><h2 style={{fontFamily:"Poppins Bold"}}>
       Teams
      </h2></div>
    
      <div className="col-xl-3" style={{textAlign:"right"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"3%"}}
      onClick={this.handlebackTeam}
      >Back</button>
      </div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
     <div className="row mt-3">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>Business Unit</span>
  <Select 
    onChange={(e)=>this.handleBusiness(e)}
    options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>Team Name</span>
  <input type="text" placeholder="Team Name" onChange={this.handleChange} className="form-control mt-2 adduser" name="teamname" />
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>Team Lead</span>
  <Select 
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
     </div>
     <div className="row mt-3">
      <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>Team Role</span>
      <Select 
    onChange={(e)=>this.handleRoles(e)}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
      </div>
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>Team Description</span>
  <textarea rows="1" className="form-control adduser mt-2" placeholder="Description" 
  name="Description" onChange={this.handleChange}>
   </textarea>
  </div>
 
     </div>
     <div className="row mt-3">
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>Team Data Access Permissions
  </span><br/>
  <input type="radio" value="1" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>&nbsp;&nbsp;Data is shared across the team members</span>
  <br/>
  <input type="radio" value="1" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily: "Poppins Light",fontSize:14}}>&nbsp;&nbsp;Only team lead can view the data of team members</span>
  </div>
 
     </div>
 
     <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleRegisterteam}>Register</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handlebackTeam}>Cancel</button>
</div>
  </div>
 
  
</div>
</>:<>

</>}

  {/* User Restrictions Screen Starts */}
  {this.state.userfieldscreen==1?<>
  <div class="container" style={{padding: "1% 16px",marginLeft:"22%"}}>
  <div className="row" style={{borderBottom:"2px solid"}}>
    <div className="col-xl-5"><h2 style={{fontFamily:"Poppins Bold"}}>
      User Field Restrictions
      </h2></div>
      <div className="col-xl-3"></div>
      <div className="col-xl-2" style={{marginLeft:"6%"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"3%"}}>Restore to default</button>
      </div>
      <div className="col-xl-1" style={{marginLeft:"-3%"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"8%"}}>Save</button>
      </div>
      <div className="col-xl-1" style={{marginLeft:"-3%"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"8%"}}>Cancel</button>
      </div>
     </div>
     <div className="row mt-2">
    <div className="col-xl-12">
    <span>Lorem ipsum is placeholder text commonly used in the graphic,</span>
    </div>
     </div>
     <div className="row mt-3">
    <div className="col-xl-12">
 
<table class="table" >
  <thead style={{backgroundColor:"#4293FE",color:"white"}}>
    <tr>
      <th scope="col" style={{fontSize:22}}>Modules</th>
      <th scope="col">Admin</th>
      <th scope="col">IT Admin</th>
      <th scope="col">Management</th>
      <th scope="col">Acc manager</th>
      <th scope="col">Assoc Acc manager</th>
      <th scope="col">Delivery Lead</th>
      <th scope="col">Recruiter</th>
    </tr>
  </thead>
  <tbody style={{fontFamily:"Poppins Bold",fontSize:11}}>
    <tr className="accesstbl">
      <td>Login Module</td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
    <td>User Management Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr className="accesstbl">
    <td>Applicant Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr >
    <td>Job (Requirements) Module</td>
     
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr className="accesstbl">
    <td>Client Management Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
    <td>Search Select Update and Email generation Module</td>
    <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
     <tr className="accesstbl">
     <td>Dashboard Module</td>
     <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
      <td>Profiles Search and Import Module</td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr className="accesstbl">
    <td>OnBoarding Management</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
    <td>Settings Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
  </tbody>
</table>

    </div>
  </div> 
 
 
  
</div>
</>:null}
{/* User Restrictions Screen Ends */}       


        </div>
      
      </div>
    );
  }
}










