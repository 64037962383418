import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Select from 'react-select'
import Swal from "sweetalert2";
import ActiveTeam from "./ActiveTeam";
import Resizer from "react-image-file-resizer";
import RolesTable from "./RolesTable";
import DegreeTable from "./DegreeTable";
import LanguageTable from "./LanguageTable";
import TimeZoneTable from "./TimeZoneTable";
import ClientCategory from "./ClientCategory";
import ClientCategoryDelete from "./ClientCategoryDelete";
import ApplicantsSources from "./ApplicantsSources";
import IndustryTable from "./IndustryTable";
import NationalityTable from "./NationalityTable";
import CountryTable from "./CountryTable";
import StateTable from "./StateTable";
import CityTable from "./CityTable";
import CurrencyTable from "./CurrencyTable";
import DocumentTypes from "./DocumentTypes";
import BusinessUnitTable from "./BusinessUnitTable";
import "react-data-table-component-extensions/dist/index.css";
import Header from "./Header";
import SourceTable from "./SourceTable";
import SourceEmailTable from "./SourceEmailTable";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
export const data = [
  ["Pizza", "Popularity"],
  ["Client 01", 33],
  ["Client 02", 26],
  ["Client 03", 20],
  ["Client 04", 20], // Below limit.
  
];

export const options = {
 
  sliceVisibilityThreshold: 0.2, // 20%,
  slices: {
    0: { color: '#010c4e' },
    1: { color: '#4293fe' },
    2: { color: '#c6c6c4' },
    3: { color: '#7fc200' }
  }
};

function DeleteLanguage(id){
  Swal.fire({
    title: 'Are you sure?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addLanguagedata:"deleteLanguage",editlanguageid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getlanguagesinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              console.log(response)
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

export default class MasterSettings extends React.Component {

  constructor(){
  
    if (localStorage.getItem("Username") == null) {
            

      localStorage.clear()
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/`


     } else {
      var username=JSON.stringify(localStorage.getItem("Username"))
      
     }

    const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    let decodedid=window.atob(data1)
    let decodedname=window.atob(data2)
    let decodedemail=window.atob(data3)
  
    let decodedroleid=window.atob(data5)
    console.log(decodedemail)
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes2 = username.replaceAll('"', '');
    super();
    this.state={
          encodedid:data1,
          encodedname:data2,
          encodedemail:data3,
         
          user_name:decodedname,
          user_id:withoutQuotes2,
          roleid:data5,
          encodedroleid:decodedroleid,
          display:0,
          displayform:0,
          options : [
            { value: '1', label: 'Bangalore' },
            { value: '2', label: 'Pune' },
            { value: '3', label: 'Chennai' }
          ],
          permissonmodule:[
            { value: "1", label: "Role" },
            { value: "2", label: "Job Posting" },
          { value: "3", label: "Applicants" },
          { value: "4", label: "Clients" },
          
        ],
          on:0,
          usermanagementscreen:1,
          accesscontrolscreen:0,
          rolesscreen:1,
          userfieldscreen:0,
         
          teamsscreen:0,
          businessunit:[
            { value: '1', label: 'Maintec Technologies' },
           
          ],
          userstausdata:[
            { value: '1', label: 'Active'},
            {value: '2', label: 'Inactive'},
            {value: '3', label: 'Pending' },
           
          ],
          rolesdata:[],
          teamdata:[],
          reportingtodata:[],
          firstname:"",
          lastname:"",
          username:"",
          email:"",
          workinglocation:"",
          password:"",
          userrole:"",
          userrolename:"",
          userstatus:"",
          mobile:"",
          reportingto:"",
          reporting_toname:"",
          BusinessUnit:"",
          adminprivileges:"No",
          dob:"",
          doj:"",
          teamids:"",
          teamname:"",
          employeeID:"",
          userstatusname:"",
          RoleAPIdata:[],
          addteam:0,
          teamname:"",
          TLroleids:"",
          TLrolename:"",
          TLids:"",
          TLname:"",
          Description:"",
          totalmembers:"",
          DataPermission:"",
          BussinessUnit:"Maintec Technologies",
          accessmenu:[],
          accessdata:[],
          menuids:1,
          showModal: false,
          accessid:"",
          addaccess:"",
          deleteaccess:"",
          editaccess:"",
          menuname:"",
          rolename:"",
          roletypeids:"",
          editaccessid:"",
          message:"",
          profilepic:" ",
          errormsg:"",
          errormsgpwd:"",
          errorfirstname:"",
          errorlastname:"",
          timezonedata:[],
          timezoneid:"",
          timezonename:"",
          globalset:0,
          globalsetmenu:1,
          accessmgt:0,
          accessmgtmenu:1,
          application:0,
          applicationmenu:1,
          jobposting:0,
          jobpostingmenu:1,
          client:0,
          clientmenu:1,
          rolesscreen2:1,
          usrrolename:"",
          degreescreen:0,
          degreescreen2:0,
          degreename:"",
          teamsscreen2:0,
          languagescreen:0,
          languagename:"",
          timezonescreen:0,
          tzonename:"",
          clientcategoryscreen:0,
          sourcesscreen:0,
          industryscreen:0,
          sourcename:"",
          Industryname:"",
          nationalityscreen:0,
          Nationalityname:"",
          showModal2: false,
          profilepic:"",
          ProfileImg:"",
          locationscreen:0,
          countryname:"",
          countrydata:1,
          statedata:0,
          citydata:0,
          CountryData:[],
          country_id:"",
          statename:"",
          StateData:[],
          state_id:"",
          cityname:"",
          currencyscreen:0,
          Currencyname:"",
          documenttypescreen:0,
          documenttypename:"",
          businessunitscreen:0,
          businessunitname:"",
          categoryname:"",
          original_rows1:[],
          rows1:[],
          loadingicon:0,
          sourceemail:0,
          sourcename1:"",
          sourcedisplay:"",
          sourcename2:"",
          sourcelistdata:[]
         
    }
    this.columns1 = [     
      {
        name: "Name",
        selector: "name",
        sortable: true
      },
      {
        name:"Action",
      cell: (e) => (
          <>
        <a   href="#ft-edituser-modal"
                      onClick={()=>this.openModal(e.id)} 
                      style={{width:"26%",fontSize:13,backgroundColor:"green",padding:"0% 7% 4% 6%",fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><i className="fa fa-edit" ></i></a>{'     '}
        <span   onClick={() =>DeleteLanguage(e.id)}  style={{width:"26%",fontSize:13,backgroundColor:"red",padding:"0% 7% 4% 6%",fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px",verticalAlign:"sub"}}><i className="fa fa-trash" onClick={() =>DeleteLanguage(e.id)}></i></span>{'     '}
       
        </>
      ),
    },
];
    this.handleDisplay=this.handleDisplay.bind(this)
    this.handleDisplayClose=this.handleDisplayClose.bind(this)
    this.handleLogout=this.handleLogout.bind(this)
    this.handleJob=this.handleJob.bind(this)
    this.handleApplicant=this.handleApplicant.bind(this)
    this.handleHome=this.handleHome.bind(this)
    this.handleAdd=this.handleAdd.bind(this)
    this.handleCancel=this.handleCancel.bind(this)
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleAccess=this.handleAccess.bind(this)
    this.handleUser=this.handleUser.bind(this)
    this.hanldelRoles=this.hanldelRoles.bind(this)
    this.handleUsefield=this.handleUsefield.bind(this)
    this.handleTeam=this.handleTeam.bind(this)
    this.hanldelClient=this.hanldelClient.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleaddTeam=this.handleaddTeam.bind(this)
    this.handlebackTeam=this.handlebackTeam.bind(this)
    this.handleRegisterteam=this.handleRegisterteam.bind(this)
    this.handleAccesscontrol=this.handleAccesscontrol.bind(this)
    this.handleView=this.handleView.bind(this)
    this.handleViewclose=this.handleViewclose.bind(this)
    this.handleAddd=this.handleAddd.bind(this)
    this.handleAdddclose=this.handleAdddclose.bind(this)
    this.handleEdit=this.handleEdit.bind(this)
    this.handleEditclose=this.handleEditclose.bind(this)
    this.handleDelete=this.handleDelete.bind(this)
    this.handleDeleteclose=this.handleDeleteclose.bind(this)
    this.handleUpdate=this.handleUpdate.bind(this)
    this.handleTime=this.handleTime.bind(this)
    this.handleGlobal=this.handleGlobal.bind(this)
    this.handleGlobalclose=this.handleGlobalclose.bind(this)
    this.handleAccmgt=this.handleAccmgt.bind(this)
    this.handleAccmgtclose=this.handleAccmgtclose.bind(this)
    this.handleApp=this.handleApp.bind(this)
    this.handleAppclose=this.handleAppclose.bind(this)
    this.handlejobPosting=this.handlejobPosting.bind(this)
    this.handlejobPostingclose=this.handlejobPostingclose.bind(this)
    this.handleclients=this.handleclients.bind(this)
    this.handleclientsclose=this.handleclientsclose.bind(this)
    this.handlecloserole=this.handlecloserole.bind(this)
    this.handleopenrole=this.handleopenrole.bind(this)
    this.handleChnagerole=this.handleChnagerole.bind(this)
    this.handleDegree=this.handleDegree.bind(this)
    this.handleclosedegree=this.handleclosedegree.bind(this)
    this.handleopendegree=this.handleopendegree.bind(this)
    this.handleChnagedegree=this.handleChnagedegree.bind(this)
    this.handleLanguage=this.handleLanguage.bind(this)
    this.handleChnagelanguage=this.handleChnagelanguage.bind(this)
    this.handleTimezone=this.handleTimezone.bind(this)
    this.handleTimezoneadd=this.handleTimezoneadd.bind(this)
    this.handleClientCate=this.handleClientCate.bind(this)
    this.handleSources=this.handleSources.bind(this)
    this.handleApplicantsource=this.handleApplicantsource.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleChangeIndustry=this.handleChangeIndustry.bind(this)
    this.handleNationality=this.handleNationality.bind(this)
    this.handleChangeNationality=this.handleChangeNationality.bind(this)
    this.handleUpdateImage=this.handleUpdateImage.bind(this)
    this.handleLocation=this.handleLocation.bind(this)
    this.handleChnageCountry=this.handleChnageCountry.bind(this)
    this.handleState=this.handleState.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handlechangecountry=this.handlechangecountry.bind(this)
    this.handleChnageState=this.handleChnageState.bind(this)
    this.handleChnageCity=this.handleChnageCity.bind(this)
    this.handlechangestate=this.handlechangestate.bind(this)
    this.handleCurrency=this.handleCurrency.bind(this)
    this.handleChnagecurrency=this.handleChnagecurrency.bind(this)
    this.handleDoctype=this.handleDoctype.bind(this)
    this.handleDocumentType=this.handleDocumentType.bind(this)
    this.handleBusinessunitscreen=this.handleBusinessunitscreen.bind(this)
    this.handleChangeBusinessUnit=this.handleChangeBusinessUnit.bind(this)
    this.handleSave=this.handleSave.bind(this)
    this.handleClientnew=this.handleClientnew.bind(this)
    this.handleOnboarding=this.handleOnboarding.bind(this)
    this.handleSourceEmail=this.handleSourceEmail.bind(this)
    this.handleSourceEmailadd=this.handleSourceEmailadd.bind(this)
    this.handleSourceEmailadd1=this.handleSourceEmailadd1.bind(this)
    this.handleSourcedisplay=this.handleSourcedisplay.bind(this)
    this.handlesourceemail=this.handlesourceemail.bind(this)
  }
  handleSourceEmailadd1(){
    if(this.state.sourcename2==""){
      Swal.fire(
        "Please Enter Source Email"
        
        
      )
    }
    else if(this.state.sourcedropdownid==""){
      Swal.fire(
        "Please Select Source"
      )
    }
    else if(this.state.password==""){
      Swal.fire(
        "Please Enter Password"
      )
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes11 = username.replaceAll('"', ''); 
      const data={
        userid:withoutQuotes11,
        addSourceemaildata:"addSourceinfos",
        sourcename:this.state.sourcename2,
        sourcedropdownid:this.state.sourcedropdownid,
        password:this.state.password
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getsourceemailinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
                
         
                
              });
    }
   
  }
  handleSourceEmailadd(){
    if(this.state.sourcename1==""){
      Swal.fire(
        "Please Enter Source"
        
        
      )
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes11 = username.replaceAll('"', ''); 
      const data={
        userid:withoutQuotes11,
        addSourcedata:"addSourceinfo",
        sourcename:this.state.sourcename1
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getsourceinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
                
         
                
              });
    }
   
  }
  handleSourcedisplay(){
    this.setState({clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:1})
  }
  handleSourceEmail(){
    this.setState({clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:1,sourcedisplay:0})
  }
  handleClientnew(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleOnboarding(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/onboardings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}`
  }
  handleChangeBusinessUnit(){
    if(this.state.businessunitname==""){
      Swal.fire(
        "Please Enter Businessunit Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addbusinessunitdata:"addbusinessunitinfo",
        businessunitname:this.state.businessunitname
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getbusinessunitinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
  
  }
  handleBusinessunitscreen(){
    this.setState({businessunitscreen:1,accesscontrolscreen:0,usermanagementscreen:0,rolesscreen:0,userfieldscreen:0,teamsscreen:0,addteam:0,rolesscreen2:2,degreescreen:0,languagescreen:0,teamsscreen2:0,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleDocumentType(){
    if(this.state.documenttypename==""){
      Swal.fire(
        "Please Enter Document Type Name"
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        adddocumenttypedata:"adddocumenttypeinfo",
        documenttypename:this.state.documenttypename
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdocumenttypeinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
   
  }
  handleChnagecurrency(){
    if(this.state.Currencyname==""){
      Swal.fire(
        "Please Enter Currency Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addCurrencydata:"addCurrencyinfo",
        Currencyname:this.state.Currencyname
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getCurrencyinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
   
  }
  handleAccess(){
    this.setState({accesscontrolscreen:1,usermanagementscreen:0,rolesscreen:0,userfieldscreen:0,degreescreen:0,teamsscreen:0,addteam:0,rolesscreen2:2,degreescreen2:5,languagescreen:0,teamsscreen2:0,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleDoctype(){
    this.setState({sourcesscreen:0,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:1,businessunitscreen:0,sourceemail:0,sourcedisplay:0})  
  }
  handleCurrency(){
    this.setState({sourcesscreen:0,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:1,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})  
  }
  handlechangestate(value){
    this.setState({state_id:value})
  }
  handleChnageCity(){
    if(this.state.country_id=="" || this.state.country_id==null){
      Swal.fire(
        "Please Select Country Name"
        
        
      )
    }
    else if(this.state.state_id==""){
      Swal.fire(
        "Please Enter State Name"
        
        
      )
    }
    else if(this.state.cityname==""){
      Swal.fire(
        "Please Enter City Name"
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addLocationdata:"addCityinfo",
        cityname:this.state.cityname,
        stateid:this.state.state_id
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
    
  }
  handleChnageState(){
    if(this.state.country_id=="" || this.state.country_id==null){
      Swal.fire(
        "Please Select Country Name"
        
        
      )
    }
    else if(this.state.statename==""){
      Swal.fire(
        "Please Enter State Name"
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addLocationdata:"addStatesinfo",
        statename:this.state.statename,
        countryid:this.state.country_id
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
  
  }
  handlechangecountry(value){
this.setState({country_id:value},()=>{
  const data = { dropdownlist_input: "getstateinfo", countryid: value };
  console.log(data);
  fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
      const data = response.result;
      //console.log(json)

      const options = data.map((d) => ({
        value: d.id,
        label: d.name,
      }));

      this.setState({ StateData: options });
    });
})
  }
  handleCountry(){
    this.setState({statedata:0,countrydata:1,citydata:0})
  }
  handleState(){
    this.setState({statedata:1,countrydata:0,citydata:0})
  }
  handleCity(){
    this.setState({statedata:0,countrydata:0,citydata:1})
  }
  handleUpdateImage(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,
        adduserdata:"editsinglephotoinfo",
        profilepic:this.state.profilepic
     
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
         if(response.message=="Profile Pic Updated successfully"){
          const withoutQuotes = username.replaceAll('"', '');
          const data={ userid:withoutQuotes,
            adduserdata:"getsinglephotoinfo",
          
         
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                
             //console.log(response)
                 this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                 
                        
                 })
             
                    
                  });
         }
          
                
         
                
              });
  }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          profilepic: result,
          ProfileImg:result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handlesourceemail=(id)=>{
    this.setState({sourcedropdownid:id})
  }
  openModal2=()=>{
    this.setState({ showModal2: true });
  }
  closeModal2 = () => {
    this.setState({ showModal2: false });
  };
  handleLocation(){
    this.setState({sourcesscreen:0,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:0,nationalityscreen:0,locationscreen:1,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})  
  }
  handleNationality(){
    this.setState({sourcesscreen:0,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:0,nationalityscreen:1,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})  
  }
  handleChangeIndustry(){
    if(this.state.Industryname==""){
      Swal.fire(
        "Please Enter Industry Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addIndustrydata:"addIndustryinfo",
        Industryname:this.state.Industryname
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getindustryinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
   
  }
  handleApplicantsource(){
    if(this.state.sourcename==""){
      Swal.fire(
        "Please Enter Source Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addSourcedata:"addSourceinfo",
        sourcename:this.state.sourcename
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getsourceinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
   
  }
  handleIndustry(){
    this.setState({sourcesscreen:0,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:1,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleSources(){
    this.setState({sourcesscreen:1,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleSave(){
    if(this.state.categoryname==""){
      Swal.fire(
        "Please Enter Category Name."
        
        
      )
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const c_data={userid:withoutQuotes,addclientdata:"addclientcategoryinfo",categoryname:this.state.categoryname}
      console.log(c_data)
      fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
        method: "POST",
  
        body: JSON.stringify(c_data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
  
          Swal.fire(
            response.message,
            
            'success'
          )
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
          2500
        );
          
           console.log(response)
  
              
            });
    }
  
  }
  handleClientCate(){
    this.setState({clientcategoryscreen:1,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleChangeNationality(){
    if(this.state.Nationalityname==""){
      Swal.fire(
        "Please Enter Nationality Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addNationalitydata:"addNationalityinfo",
        Nationalityname:this.state.Nationalityname
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getNationalityinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              }); 
    }
   
  }
  handleTimezoneadd(){
    if(this.state.tzonename==""){
      Swal.fire(
        "Please Enter Time Zone Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addTimezonedata:"addTimezoneinfo",
        tzonename:this.state.tzonename
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/gettimezoneinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
    
  }
  handleTimezone(){
    this.setState({languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:4,teamsscreen2:0,timezonescreen:1,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleLanguage(){
    this.setState({languagescreen:1,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:3,teamsscreen2:0,timezonescreen:0,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleopendegree(){
    this.setState({degreescreen2:2})
  }
  handleDegree(){
    this.setState({degreescreen:1,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:2,teamsscreen2:0,languagescreen:0,timezonescreen:0,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,documenttypescreen:0,businessunitscreen:0,sourceemail:0,sourcedisplay:0})
  }
  handleChnagelanguage(){
   if(this.state.languagename==""){
    Swal.fire(
      "Please Enter Language Name."
      
      
    )
   }
   else{
    const data={
      
      userid:this.state.user_id,
      addLanguagedata:"addLanguageinfo",
      languagename:this.state.languagename
  }
    
    fetch("https://www.mrecruit.in:5200/api/getlanguagesinfo", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          Swal.fire(
            response.message,
            
            
          )
          setTimeout(
            function() {
              var username=JSON.stringify(localStorage.getItem("UserId"))
              const withoutQuotes = username.replaceAll('"', '');
              console.log(withoutQuotes)
              const data={userid:withoutQuotes,addLanguagedata:"getLanguageinfo"}
              console.log(data)
              fetch("https://www.mrecruit.in:5200/api/getlanguagesinfo", {
                  method: "POST",
          
                  body: JSON.stringify(data), // data can be `string` or {object}!
          
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
          
                  .catch((error) => console.error("Error:", error))
          
                  .then((response) => {
                   
                     let data=response.result
                        data = data.map((currentValue, Index) => {
                          currentValue.SNO = Index + 1;
                          return currentValue;
                        });
                        this.setState({ original_rows: data, rows: data });
                        
                      });
            }
            .bind(this),
            1500
        );
          
              
       
              
            });
   }
 
  }
  handleChnageCountry(){

    if(this.state.countryname==""){
      Swal.fire(
       "Please Enter Country Name"
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addLocationdata:"addCountryinfo",
        countryname:this.state.countryname
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getlocationinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
          this.setState({sourcesscreen:0,clientcategoryscreen:0,languagescreen:0,degreescreen:0,teamsscreen:0,rolesscreen:0,rolesscreen2:2,accesscontrolscreen:0,degreescreen2:5,teamsscreen2:0,timezonescreen:0,industryscreen:0,nationalityscreen:0,locationscreen:1,currencyscreen:0,documenttypescreen:0,businessunitscreen:0})
                
         
                
              });
    }
 
  }
  handleChnagedegree(){
   
    if(this.state.degreename==""){
      Swal.fire(
        "Please Enter Degree Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addDegreedata:"addDegreeinfo",
        degreename:this.state.degreename
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdegreeinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
  
  }
  handleChnagerole(){
    if(this.state.usrrolename==""){
      Swal.fire(
        "Please Enter User Role Name."
        
        
      )
    }
    else{
      const data={
      
        userid:this.state.user_id,
        addRoledata:"addroleinfo",
        usrrolename:this.state.usrrolename
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getUserrolesinfoinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              response.message,
              
              
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              2500
          );
            
                
         
                
              });
    }
   
  }
  handleopenrole(){
    this.setState({rolesscreen2:0})
  }
  handlecloserole(){
    this.setState({rolesscreen2:0})
  }
  handleclosedegree(){
    this.setState({degreescreen2:1})
  }
  handleAccmgtclose(){
    this.setState({accessmgtmenu:0,accessmgt:1})
  }
  handleAccmgt(){
    this.setState({accessmgtmenu:1,accessmgt:0})
  }
  handleAppclose(){
    this.setState({applicationmenu:0,application:1})
  }
  handleApp(){
    this.setState({applicationmenu:1,application:0})
  }
  handlejobPostingclose(){
    this.setState({jobpostingmenu:0,jobposting:1})
  }
  handlejobPosting(){
    this.setState({jobpostingmenu:1,jobposting:0})
  }
  handleclientsclose(){
    this.setState({clientmenu:0,client:1})
  }
  handleclients(){
    this.setState({clientmenu:1,client:0})
  }
  handleGlobalclose(){
    this.setState({globalsetmenu:0,globalset:1})
  }
  handleGlobal(){
    this.setState({globalsetmenu:1,globalset:0})
  }
 
  handleTime(e){
this.setState({timezoneid:e.value,timezonename:e.label})
  }
  handleView(){

    this.setState({viewaccess:1})
  }
  handleViewclose(){
    this.setState({viewaccess:2})
  }
  handleAddd(){
    this.setState({addaccess:1})
  }
  handleAdddclose(){
    this.setState({addaccess:2})
  }
  handleEdit(){
    this.setState({editaccess:1})
  }
  handleEditclose(){
    this.setState({editaccess:2})
  }
  handleDelete(){
    this.setState({deleteaccess:1})
  }
  handleDeleteclose(){
    this.setState({deleteaccess:2})
  }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addAccessdata:"editAccessinfo",
      addaccesscode:this.state.addaccess,
      deleteaccesscode:this.state.deleteaccess,
      editaccesscode:this.state.editaccess,
      viewaccesscode:this.state.viewaccess,
      editaccessid:this.state.editaccessid
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
         
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
           2500
        );
                
         
                
              });
     
  }
  openModal = (roletypeids) => {
    this.setState({editaccessid:roletypeids})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addAccessdata:"getsingleaccessinfo",editaccessid:roletypeids}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          //console.log(response)
          response.result.map((e)=>{
            this.setState({
            accessid:e.accessid,
            addaccess:e.addaccess,
            deleteaccess:e.deleteaccess,
            editaccess:e.editaccess,
            menuids:e.menuids,
            menuname:e.menuname,
            rolename:e.rolename,
            roletypeids:e.roletypeids,
            viewaccess:e.viewaccess

            })
          })
                
         
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  handleAccesscontrol(smenuid){
              /* Access Data API Call Start */
       const amenudata={ userid : this.state.user_id,addAccessdata:"getAccessinfo",menuids:smenuid}
      // console.log(amenudata)
        fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
            method: "POST",
    
            body: JSON.stringify(amenudata), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
            // console.log(response)  
       
            this.setState({accessdata:response.result})
                  
                });
        /* Access Data API Call End */
  }
  handlebackTeam(){
    this.setState({addteam:0,teamsscreen:1})
  }
  handleaddTeam(){
    this.setState({addteam:1,teamsscreen:0})
  }
  handleUserstatus(e){
    this.setState({userstatus:e.value}) 
  }
  handleReport(e){
    this.setState({reporting_toname:e.label,reportingto:e.value,TLids:e.value,TLname:e.label},()=>{
      //console.log(this.state.userrole)
    })
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value,TLroleids:
e.value,TLrolename:e.label},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,BussinessUnit:e.label},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value,teamname:e.label},()=>{
          
        })
          }

  handleRegisterteam(){
    if(this.state.teamname==""||this.state.teamname==null){
      Swal.fire(
        "Please Enter Team Name"
        
      )
    }
    else if(this.state.BussinessUnit==""||this.state.BussinessUnit==null){
      Swal.fire(
        "Please Select Business Unit"
        
      )
    }
    else if(this.state.TLroleids==""||this.state.TLroleids==null){
      Swal.fire(
        "Please Select Team Role"
        
      )
    }
    else if(this.state.TLids==""||this.state.TLids==null){
      Swal.fire(
        "Please Select Team Lead"
        
      )
    }
    else if(this.state.Description==""||this.state.Description==null){
      Swal.fire(
        "Please Enter Description"
        
      )
    }
    else if(this.state.DataPermission==""||this.state.DataPermission==null){
      Swal.fire(
        "Please Choose Data Permission"
        
      )
    }
    else{
      const data={
        userid:this.state.user_id,
        adduserdata:"addteamsinfo",
        teamname:this.state.teamname,
        BussinessUnit:this.state.BussinessUnit,
        TLroleids:this.state.TLroleids,
        TLrolename:this.state.TLrolename,
        TLids:this.state.TLids,
        TLname:this.state.TLname,
        Description:this.state.Description,
        DataPermission:this.state.DataPermission,
       
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                Swal.fire(
                  response.message,
                  
                )
                setTimeout(
                  function() {
                    window.location.reload()
                  }
                  .bind(this),
                  2500
              );
                
                 console.log(response)
        
                    
                  });
    }
  
      }
  handleChange = (e) => {
   
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  handleChangetwo = (e) => {
    
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  hanldelClient(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/clientmanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleTeam(){
    this.setState({degreescreen:0,rolesscreen:0,accesscontrolscreen:0,usermanagementscreen:0,teamsscreen:1,userfieldscreen:0,addteam:0,rolesscreen2:2,degreescreen2:0,teamsscreen2:1,languagescreen:0,timezonescreen:0,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,businessunitscreen:0,documenttypescreen:0,sourceemail:0,sourcedisplay:0})
    
  }
  handleUsefield(){
    this.setState({rolesscreen:0,accesscontrolscreen:0,usermanagementscreen:0,userfieldscreen:1,teamsscreen:0,addteam:0})
  
  }
  hanldelRoles(){
    this.setState({rolesscreen:1,accesscontrolscreen:0,usermanagementscreen:0,userfieldscreen:0,teamsscreen:0,addteam:0,rolesscreen2:1,degreescreen:0,degreescreen2:0,teamsscreen2:0,languagescreen:0,timezonescreen:0,clientcategoryscreen:0,sourcesscreen:0,industryscreen:0,nationalityscreen:0,locationscreen:0,currencyscreen:0,businessunitscreen:0,documenttypescreen:0,sourceemail:0,sourcedisplay:0})
  
  
const data={ dropdownlist_input: "getuserrolestabinfo"}
console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(data), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
        this.setState({RoleAPIdata:response.result})
          
        });
  }

  handleUser(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}`
  }
 
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  handleCancel(){
    this.setState({displayform:0})
  }
  handleAdd(){
    this.setState({displayform:1})
  }
  handleApplicant(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleJob(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.roleid}` 
  }
  handleDisplay(){
    this.setState({display:1})
  }
  handleDisplayClose(){
    this.setState({display:0})
  }
  handleLogout(){
    localStorage.clear()
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/`
  }
  handleHome(){
    let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.roleid}`
  }
  componentDidMount(){
    /**Get Language API Call Starts */
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes11 = username.replaceAll('"', ''); 
    const data11={userid:withoutQuotes11,addLanguagedata:"getLanguageinfo"}
    fetch("https://www.mrecruit.in:5200/api/getlanguagesinfo", {
        method: "POST",

        body: JSON.stringify(data11), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         
           let data=response.result
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
              });
              this.setState({ original_rows1: data, rows1: data,loadingicon:1 });
              
            });
  
    /**Get Langauge API Call Ends */
    /* Source Email API Call Start */
    const condata1 = { dropdownlist_input: "getsourcelistinfo",userid:withoutQuotes11 };
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(condata1), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
        const data = response.result;
        //console.log(json)

        const options = data.map((d) => ({
          value: d.sourcedropdownid,
          label: d.name,
        }));

        this.setState({ sourcelistdata: options });
      });
    /* Source Email API Call  End */
     /* Country API Call Start */
     const condata = { dropdownlist_input: "getcountryinfo" };
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
       method: "POST",
 
       body: JSON.stringify(condata), // data can be `string` or {object}!
 
       headers: { "Content-Type": "application/json" },
     })
       .then((res) => res.json())
 
       .catch((error) => console.error("Error:", error))
 
       .then((response) => {
         const data = response.result;
         //console.log(json)
 
         const options = data.map((d) => ({
           value: d.countryid,
           label: d.conuntryname,
         }));
 
         this.setState({ CountryData: options });
       });
     /* Country API Call  End */
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const pdata={ userid:withoutQuotes,
      adduserdata:"getsinglephotoinfo",
    
   
  }
  //console.log(pdata)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(pdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
          
        if(response.result.profilepic=="BASE64"){
            this.setState({ProfileImg:""},()=>{
             
            })
        
         }
         else{
            this.setState({ProfileImg:response.result.profilepic},()=>{
             
            })
        
         }
       
              
            });
      /* Access Menu API Call Start */
      const menudata={ dropdownlist_input: "getaccessmenus"}
      console.log(menudata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(menudata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.smenuid,
              "label" : d.smenuname
  
            })) 
     
          this.setState({accessmenu:options})
                
              });
      /* Access Menu API Call End */

       /* Time Zone API Call Start */
       const timedata={ dropdownlist_input: "gettimezoneinfo"}
       //console.log(menudata)
       fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
           method: "POST",
   
           body: JSON.stringify(timedata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({timezonedata:options})
                 
               });
       /* Time Zone API Call End */
       /* Access Data API Call Start */
       const amenudata={ userid : this.state.user_id,addAccessdata:"getAccessinfo",menuids:this.state.menuids}
       // console.log(amenudata)
         fetch("https://www.mrecruit.in:5200/api/getaccescontrolinfo", {
             method: "POST",
     
             body: JSON.stringify(amenudata), // data can be `string` or {object}!
     
             headers: { "Content-Type": "application/json" },
           })
             .then((res) => res.json())
     
             .catch((error) => console.error("Error:", error))
     
             .then((response) => {
              // console.log(response)  
        
             this.setState({accessdata:response.result})
                   
                 });
         /* Access Data API Call End */
    /* Roles API Call Start */
    const data={ dropdownlist_input: "getuserrole"}
    //console.log(data)
    fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
        method: "POST",

        body: JSON.stringify(data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({rolesdata:options})
              
            });
    /* Roles API Call End */
      /* Reporting To API Call Start */
      const rdata={ dropdownlist_input: "getreportinginfo"}
      //console.log(rdata)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(rdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.reportingid,
              "label" : d.reportingname
  
            })) 
     
          this.setState({reportingtodata:options})
                
              });
      /* Reporting To  API Call End */

       /* Reporting To API Call Start */
       const tdata={ userid: this.state.user_id,
        adduserdata:"getteamsinfo"
      }
       //console.log(tdata)
       fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
           method: "POST",
   
           body: JSON.stringify(tdata), // data can be `string` or {object}!
   
           headers: { "Content-Type": "application/json" },
         })
           .then((res) => res.json())
   
           .catch((error) => console.error("Error:", error))
   
           .then((response) => {
               console.log(response)
            const data = response.result
             //console.log(json)
       
           const options = data.map(d => ({
               "value" : d.id,
               "label" : d.name
   
             })) 
      
           this.setState({teamdata:options})
                 
               });
       /* Reporting To  API Call End */
  }
  render() {
    return (
      <div>
        {/* Header Section Starts */}

        <div className="dashboardheader fixed-top">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li style={{cursor:"pointer"}} onClick={this.handleHome}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleJob}>Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={this.handleApplicant}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.encodedroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleClientnew}>Clients</li></>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.encodedroleid==6?null:<> <li style={{cursor:"pointer"}} onClick={this.handleOnboarding}>Onboardings</li></>}
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           {this.state.encodedroleid==9 || this.state.encodedroleid==6?null:<><li style={{cursor:"pointer"}} onClick={this.handleUser}>User Management</li></>}
        
           
          </ul>
          <Header/>
          
        </div>
                 {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>

                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}
    {/**Edit access control starts */}
    {this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content" style={{width:"60%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Access Control Information
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
                      <div className="row">
                        <div className="col-md-12">
                        <table class="table" >

                    <thead style={{backgroundColor:"#4293FE",color:"white"}}>
                      <tr>
                        <th scope="col">Roles</th>
                        <th scope="col">View</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Add</th>
                        <th scope="col">Delete</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td style={{fontFamily:"Inter",fontWeight:"bold"}}>{this.state.rolename}</td>
                      <td>{this.state.viewaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleView}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>View all records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleViewclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>Can not view records</span></>}
                        
                        </td>
                      <td>{this.state.editaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleEdit}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}> Edit all records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleEditclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>Can not edit records</span></>}</td>
                      <td>{this.state.addaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleAddd}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>Allowed to add records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleAdddclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>Can not add records</span></>}</td>
                      <td>{this.state.deleteaccess==2?<>
                      <i class="fa fa-check-square solidcheckbox" onClick={this.handleDelete}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>Delete all records</span>
                      </>:<><i class="fa fa-square blankcheckbox" onClick={this.handleDeleteclose}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"text-top",fontSize:12}}>Can not delete records</span></>}</td>
                    
                    </tr> 
                    </tbody>
                    </table>
                   
                        </div>
                      </div>
                      <div className="row mt-3" style={{marginBottom:"3%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row mt-3" style={{marginBottom:"3%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Edit access control ends */}
        <div>
              {/**Side Bar starts */}
              <div class="sidebar" style={{overflow:"scroll",paddingBottom:"5%"}}>
                <table style={{lineHeight:"0px"}}>
                    <tr>
                        <th style={{padding:"3%",width:"96.5%"}}> 
                        {this.state.globalset==1?<>
                            <a style={{cursor:"pointer",fontFamily:"Inter Bold"}} onClick={this.handleGlobal}>Global Settings</a>
                        </>:<></>}
                   {this.state.globalset==0?<><a onClick={this.handleGlobalclose} style={{cursor:"pointer",fontFamily:"Inter Bold"}}>Global Settings</a></>:null}
                   </th>
                   {this.state.globalset==1?<>
                    <th style={{padding:"1%"}}> <i  onClick={this.handleGlobal} style={{fontSize:18,marginLeft:"-1em",cursor:"pointer"}} className="fa fa-plus"></i></th>
                   </>:<><th style={{padding:"1%"}}> <i style={{fontSize:18,marginLeft:"-1em",cursor:"pointer"}} onClick={this.handleGlobalclose} className="fa fa-minus"></i></th></>}
                
                    
                    </tr>
                    <tr>
                        {this.state.globalsetmenu==1?<>
                            <div className="submenuglobalsetting">
                        <td>
                          {this.state.rolesscreen==1?<>
                            <div className="row mastersettingmenus">

                        <div className="col-md-12">
                        <span style={{fontSize:14,fontFamily:"Inter"}}>Roles</span>
                          </div></div> 
                          </>:<>         <div className="row" onClick={this.hanldelRoles}>
                            <div className="col-md-12" onClick={this.hanldelRoles}>
                            <span style={{fontSize:14,fontFamily:"Inter"}} onClick={this.hanldelRoles}>Roles</span>
                            </div>

                          </div></>}
                          {this.state.languagescreen==1?<>
                            <div className="row mastersettingmenus">

                        <div className="col-md-12">
                        <span style={{fontSize:14,fontFamily:"Inter"}}>Languages</span>
                          </div></div> 
                          </>:<>         <div className="row" onClick={this.handleLanguage}>
                            <div className="col-md-12" onClick={this.handleLanguage}>
                            <span style={{fontSize:14,fontFamily:"Inter"}} onClick={this.handleLanguage}>Languages</span>
                            </div>

                          </div></>}
                        

                          {this.state.timezonescreen==1?<>
                            <div className="row mastersettingmenus">

                        <div className="col-md-12">
                        <span style={{fontSize:14,fontFamily:"Inter"}}>Time Zone</span>
                          </div></div> 
                          </>:<>         <div className="row" onClick={this.handleTimezone}>
                            <div className="col-md-12" onClick={this.handleTimezone}>
                            <span style={{fontSize:14,fontFamily:"Inter"}} onClick={this.handleTimezone}>Time Zone</span>
                            </div>

                          </div></>}
                          {this.state.locationscreen==1?<>
                            <div className="row mastersettingmenus">

                        <div className="col-md-12">
                        <span style={{fontSize:14,fontFamily:"Inter"}}>Location</span>
                          </div></div> 
                          </>:<>         <div className="row" onClick={this.handleLocation}>
                            <div className="col-md-12" onClick={this.handleLocation}>
                            <span style={{fontSize:14,fontFamily:"Inter"}} onClick={this.handleLocation}>Location</span>
                            </div>

                          </div></>}
                        </td>
                        </div>
                        </>:null}
                      
                        
                    </tr>
                    <tr>
                        <th style={{padding:"3%",width:"96.5%"}}>  
                        {this.state.accessmgt==1?<>
                            <a style={{cursor:"pointer",fontFamily:"Inter Bold"}} onClick={this.handleAccmgt}>Access Mgt</a>
                        </>:<></>}
                   {this.state.accessmgt==0?<><a onClick={this.handleAccmgtclose} style={{cursor:"pointer",fontFamily:"Inter Bold"}}>Access Mgt</a></>:null}
        </th>
        {this.state.accessmgt==1?<>
            <th style={{padding:"1%"}}> <i  style={{fontSize:18,marginLeft:"-1em",cursor:"pointer"}} onClick={this.handleAccmgt} className="fa fa-plus"></i></th>
                   </>:<><th style={{padding:"1%"}}> <i style={{fontSize:18,marginLeft:"-1em",cursor:"pointer"}} onClick={this.handleAccmgtclose} className="fa fa-minus"></i></th></>}
                    
                    </tr>
                    <tr>
                        {this.state.accessmgtmenu==1?<>
                            <div className="submenuglobalsetting">
                        <td>
                       {this.state.teamsscreen2==1?<>
                        <div className="row mastersettingmenus">

                          <div className="col-md-12">
                          <span style={{fontSize:14}} >Teams</span>
                            </div></div> 
                       </>:<>   <div className="row" onClick={this.handleTeam}>

                        <div className="col-md-12">
                        <span style={{fontSize:14}} onClick={this.handleTeam}>Teams</span>
                          </div></div> </>}
                     

                         {this.state.accesscontrolscreen==1?<>
                          <div className="row mastersettingmenus">
                            <div className="col-md-12">
                            <span style={{fontSize:14}}>Access Control</span>
                              </div></div> 
                         </>:<> 
                         <div className="row" onClick={this.handleAccess}>
                            <div className="col-md-12">
                            <span style={{fontSize:14}}>Access Control</span>
                              </div></div> 
                        </>}
                      
                       
                        </td>
                        </div>
                        </>:null}
                      
                        
                    </tr>
                    <tr>
                        <th style={{padding:"3%",width:"96.5%"}}>  
                        {this.state.application==1?<>
                            <a style={{cursor:"pointer",fontFamily:"Inter Bold"}} onClick={this.handleApp}>Applicants</a>
                        </>:<></>}
                   {this.state.application==0?<><a onClick={this.handleAppclose} style={{cursor:"pointer",fontFamily:"Inter Bold"}}>Applicants</a></>:null}
        </th>
        {this.state.application==1?<>
            <th style={{padding:"1%"}}> <i  style={{fontSize:18,marginLeft:"-1em",cursor:"pointer"}} onClick={this.handleApp} className="fa fa-plus"></i></th>
                   </>:<><th style={{padding:"1%"}}> <i style={{fontSize:18,marginLeft:"-1em",cursor:"pointer"}} onClick={this.handleAppclose} className="fa fa-minus"></i></th></>}
                    
                    </tr>
                    <tr>
                        {this.state.applicationmenu==1?<>
                            <div className="submenuglobalsetting">
                            <td>
                              {this.state.degreescreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Degree</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleDegree}>

                      <div className="col-md-12">
                      <span style={{fontSize:14}} >Degree</span>
                        </div></div> 
                              </>}
                             
                              {this.state.documenttypescreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Document Types</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleDoctype}>

                      <div className="col-md-12">
                      <span style={{fontSize:14}} >Document Types</span>
                        </div></div> 
                              </>}
                        {this.state.sourcesscreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Applicant Sources</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleSources}>

                      <div className="col-md-12">
                      <span style={{fontSize:14}} >Applicant Sources</span>
                        </div></div> 
                              </>}
                              {this.state.industryscreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Industry</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleIndustry}>

                      <div className="col-md-12" onClick={this.handleIndustry}>
                      <span style={{fontSize:14}} >Industry</span>
                        </div></div> 
                              </>}
                              {this.state.nationalityscreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Nationality</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleNationality}>

                      <div className="col-md-12" onClick={this.handleNationality}>
                      <span style={{fontSize:14}} >Nationality</span>
                        </div></div> 
                              </>}
                        </td>
                        </div>
                        </>:null}
                      
                        
                    </tr>
                
                   
                
                    <tr>
                        <th style={{padding:"3%",width:"96.5%"}}>  
                        {this.state.jobposting==1?<>
                            <a style={{cursor:"pointer",fontFamily:"Inter Bold"}} onClick={this.handlejobPosting}>Job Posting</a>
                        </>:<></>}
                   {this.state.jobposting==0?<><a onClick={this.handlejobPostingclose} style={{cursor:"pointer",fontFamily:"Inter Bold"}}>Job Posting</a></>:null}
        </th>
        {this.state.jobposting==1?<>
                    <th style={{padding:"1%"}}> <i style={{fontSize:18,marginLeft:"-200%",cursor:"pointer"}} onClick={this.handlejobPosting} className="fa fa-plus"></i></th>
                   </>:<><th style={{padding:"1%"}}> <i style={{fontSize:18,marginLeft:"-200%",cursor:"pointer"}} className="fa fa-minus" onClick={this.handlejobPostingclose}></i></th></>}
                    
                    </tr>
                    <tr>
                        {this.state.jobpostingmenu==1?<>
                            <div className="submenuglobalsetting">
                            <td>           {this.state.currencyscreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Currency</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleCurrency}>

                      <div className="col-md-12">
                      <span style={{fontSize:14}} >Currency</span>
                        </div></div> 
                              </>}
                              {this.state.businessunitscreen==1?<>
                                <div className="row mastersettingmenus">

                              <div className="col-md-12">
                              <span style={{fontSize:14}} >Business Unit</span>
                                </div></div> 
                              </>:<>
                              <div className="row" onClick={this.handleBusinessunitscreen}>

                      <div className="col-md-12">
                      <span style={{fontSize:14}} >Business Unit</span>
                        </div></div> 
                              </>}
                        </td>
                        </div>
                        </>:null}
                      
                        
                    </tr>
                    <tr>
                        <th style={{padding:"3%",width:"96.5%"}}>  
                        {this.state.client==1?<>
                            <a style={{cursor:"pointer",fontFamily:"Inter Bold"}} onClick={this.handleclients}>Clients</a>
                        </>:<></>}
                   {this.state.client==0?<><a onClick={this.handleclientsclose} style={{cursor:"pointer",fontFamily:"Inter Bold"}}>Clients</a></>:null}
        </th>
        {this.state.client==1?<>
                    <th style={{padding:"1%"}}> <i style={{fontSize:18,marginLeft:"-200%",cursor:"pointer"}} onClick={this.handleclients} className="fa fa-plus"></i></th>
                   </>:<><th style={{padding:"1%"}}> <i onClick={this.handleclientsclose} style={{fontSize:18,marginLeft:"-200%",cursor:"pointer"}} className="fa fa-minus"></i></th></>}
                    
                    </tr>
                    <tr>
                      {this.state.clientmenu==1?<>
                        {this.state.clientcategoryscreen==1?<>
                                <div className="row mastersettingmenus submenuglobalsetting" style={{marginLeft:"3%"}}>

                              <div className="col-md-12">
                              <span style={{fontSize:14}}>Client Categories</span>
                                </div></div> 
                              </>:<>
                              <div className="submenuglobalsetting" onClick={this.handleClientCate}>
                            <td><span style={{fontSize:14}} onClick={this.handleClientCate}>Client Categories</span>
                      
                        </td>
                        </div>
                              </>}
                      </>:null}
                   
                       
                        
                    </tr>
                    <th style={{padding:"3%",width:"96.5%"}}>  
                        {this.state.client==1?<>
                            <a style={{cursor:"pointer",fontFamily:"Inter Bold"}} onClick={this.handleclients}>Clients</a>
                        </>:<></>}
                   {this.state.client==0?<><a onClick={this.handleclientsclose} style={{cursor:"pointer",fontFamily:"Inter Bold"}}>Source Management</a></>:null}
        </th>
                    <tr>
                      {this.state.clientmenu==1?<>
                        {this.state.sourceemail==1?<>
                                <div className="row mastersettingmenus submenuglobalsetting" style={{marginLeft:"3%"}}>

                              <div className="col-md-12">
                              <span style={{fontSize:14}}>Source</span>
                                </div></div> 
                              </>:<>
                              <div className="submenuglobalsetting" onClick={this.handleSourceEmail}>
                            <td><span style={{fontSize:14}} onClick={this.handleSourceEmail}>Source</span>
                      
                        </td>
                        </div>
                              </>}
                      </>:null}
                   
                       
                        
                    </tr>
                    <tr>
                      {this.state.clientmenu==1?<>
                        {this.state.sourcedisplay==1?<>
                                <div className="row mastersettingmenus submenuglobalsetting" style={{marginLeft:"3%"}}>

                              <div className="col-md-12">
                              <span style={{fontSize:14}}>Source Email</span>
                                </div></div> 
                              </>:<>
                              <div className="submenuglobalsetting" onClick={this.handleSourcedisplay}>
                            <td><span style={{fontSize:14}} onClick={this.handleSourcedisplay}>Source Email</span>
                      
                        </td>
                        </div>
                              </>}
                      </>:null}
                   
                       
                        
                    </tr>
                </table>
               
  

 
</div>
{/**Side bar ends */}


{/* Access Control Screen Starts */}
{this.state.accesscontrolscreen==1?<>
  <div class="content">
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Inter Bold"}}>
      Access Control
      </h2></div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
    <div className="row" style={{marginBottom:"2%"}}>
      <div className="col-xl-6">
 
      </div>
      <div className="col-xl-3" style={{marginTop:"1%"}}>
      <span style={{fontFamily:"Inter",fontSize:14,fontWeight:"bold"}}>
    Assign Permissions for the Module
    </span>
      </div>
      <div className="col-xl-3">
      <Select 
      placeholder="Job Posting"
    onChange={(e)=>this.handleAccesscontrol(e.value)}
    options={this.state.accessmenu}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
      </div>
    </div>
   <div className="row">
    <div className="col-xl-12">
 
<table class="table" >

  <thead style={{backgroundColor:"#4293FE",color:"white"}}>
    <tr>
      <th scope="col">Roles</th>
      <th scope="col">View</th>
      <th scope="col">Edit</th>
      <th scope="col">Add</th>
      <th scope="col">Delete</th>
      <th scope="col">Action Permission</th>
    </tr>
  </thead>
  <tbody style={{fontFamily:"Inter",fontSize:14,fontWeight:"bold"}}>
{this.state.accessdata.map((e,index)=>(
   <tr className={index % 2 !== 0?"accesstbl":null}>
   <td style={{fontSize:13}}> 
   <a className="editaccess" href="#ft-edituser-modal"><i className="fa fa-edit" onClick={()=>this.openModal(e.roletypeids)} style={{cursor:"pointer"}}></i></a>&nbsp;&nbsp;{e.rolename}</td>
   <td>{e.viewaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i>&nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>View all records</span>
   </>:<></>}
   {e.viewaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Can not view records</span></>:null}
   </td>
   <td>{e.editaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i>&nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Edit all records</span>
   </>:<></>}
   {e.editaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>&nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Can not edit records</span></>:null}
   </td>
   <td>{e.addaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i>
    &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Allowed to add records</span>
    
   </>:<></>}
   {e.addaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>
   &nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Can not add records</span>
   </>:null}
   </td>
   <td>{e.deleteaccess==2?<>
    <i class="fa fa-check-circle" aria-hidden="true" style={{color:"rgb(66, 147, 254)"}}></i> &nbsp;
    <span style={{color:"rgb(66, 147, 254)",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Delete all records</span>
   </>:<></>}
   {e.deleteaccess==1?<><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i>
   &nbsp;
   <span style={{color:"red",fontFamily:"Inter",verticalAlign:"middle",fontSize:12}}>Can not delete records</span>
   </>:null}
   </td>
   <td>Access to {e.totalaccess} Actions</td>
 </tr>
))}
    
  </tbody>
</table>

    </div>
  </div>  
 
 
  
</div>
</>:null}
{/* Access Control Screen Ends */}


  {/* Roles Screen Starts */}
{this.state.rolesscreen2==1?<>
 
  <div class="content">
  <div className="row">
    <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
       Roles
      </h2></div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
    <div className="row mt-3">
    <div className="col-xl-2">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Role Name</span>

    </div>
    <div className="col-xl-5">
    <input className="form-control adduser" name="usrrolename" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3"><button className="btn btn-primary updatebtn" onClick={this.handleChnagerole} style={{marginTop:"0%"}}>Save</button></div>
  </div> 

     <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
    <div className="col-xl-12">
<RolesTable/>

    </div>
  </div> 
</div>
 
 
 
  

</>:null}
{/* Roles Screen Ends */}   

  {/* Degree Screen Starts */}
  {this.state.degreescreen2==2?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Degree
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-2">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Degree Name</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="degreename" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChnagedegree} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<DegreeTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Degree Screen Ends */}  
  {/* Currency Screen Starts */}
  {this.state.currencyscreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Currency
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Currency Name</span>

    </div>
    <div className="col-xl-5">
    <input className="form-control adduser" name="Currencyname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChnagecurrency} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<CurrencyTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Currency Screen Ends */} 
 {/* Industry Screen Starts */}
 {this.state.industryscreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Industry
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-2">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Industry Name</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="Industryname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChangeIndustry} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<IndustryTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Industry Screen Ends */} 


 {/* Business Unit Screen Starts */}
 {this.state.businessunitscreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Business Unit
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Business Unit Name</span>

    </div>
    <div className="col-xl-5">
    <input className="form-control adduser" name="businessunitname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChangeBusinessUnit} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<BusinessUnitTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Business Unit Screen Ends */} 

{/* Nationality Screen Starts */}
{this.state.nationalityscreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Nationality
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Nationality Name</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="Nationalityname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChangeNationality} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<NationalityTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Nationality Screen Ends */} 

 {/* Language Screen Starts */}
 {this.state.languagescreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Languages
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter a Language Name</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="languagename" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChnagelanguage} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
   <LanguageTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Language Screen Ends */}   

 {/* Location Screen Starts */}
 {this.state.locationscreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Location
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row">
  <div className="col-xl-3">
    {this.state.countrydata==1?<>
      <span className="masterdatalocation" style={{color:"#4293FE"}}>Country</span>
    </>:<> <span className="masterdatalocation" onClick={this.handleCountry}>Country</span></>}
 
  </div>
  <div className="col-xl-3">
  {this.state.statedata==1?<>
      <span className="masterdatalocation" style={{color:"#4293FE"}}>State</span>
    </>:<> <span className="masterdatalocation" onClick={this.handleState}>State</span></>}
  </div>
  <div className="col-xl-3">
  {this.state.citydata==1?<>
      <span className="masterdatalocation" style={{color:"#4293FE"}}>City</span>
    </>:<> <span className="masterdatalocation" onClick={this.handleCity}>City</span></>}
  </div>
    
    </div>
    {this.state.countrydata==1?<>
   <div className="row" style={{marginTop:"5%"}}>
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter Country Name</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="countryname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChnageCountry} style={{marginTop:"0%"}}>Save</button>
      </div>
      </div>
      <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<CountryTable/>

   </div>
 </div> 
      </>:null}
   
      {this.state.statedata==1?<>
      <div className="row" style={{marginTop:"5%"}}>
        <div className="col-xl-4">
        <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Select Country Name</span>
        </div>
          <div className="col-xl-4">
          <Select 
      placeholder="Select Country"
    onChange={(e)=>this.handlechangecountry(e.value)}
    options={this.state.CountryData}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
          </div>
      </div>
   <div className="row" style={{marginTop:"2%"}}>
    <div className="col-xl-4">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter State Name</span>

    </div>
    <div className="col-xl-4">
    <input className="form-control adduser" name="statename" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChnageState} style={{marginTop:"0%"}}>Save</button>
      </div>
      </div>
      <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<StateTable/>

   </div>
 </div> 
      </>:null}
      {this.state.citydata==1?<>
      <div className="row" style={{marginTop:"5%"}}>
        <div className="col-xl-4">
        <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Select Country Name</span>
        </div>
          <div className="col-xl-4">
          <Select 
      placeholder="Select Country"
    onChange={(e)=>this.handlechangecountry(e.value)}
    options={this.state.CountryData}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
          </div>
      </div>
       <div className="row" style={{marginTop:"2%"}}>
        <div className="col-xl-4">
        <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Select State Name</span>
        </div>
          <div className="col-xl-4">
          <Select 
      placeholder="Select State"
    onChange={(e)=>this.handlechangestate(e.value)}
    options={this.state.StateData}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
          </div>
      </div>
   <div className="row" style={{marginTop:"2%"}}>
    <div className="col-xl-4">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter City Name</span>

    </div>
    <div className="col-xl-4">
    <input className="form-control adduser" name="cityname" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleChnageCity} style={{marginTop:"0%"}}>Save</button>
      </div>
      </div>
      <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<CityTable/>

   </div>
 </div> 
      </>:null}
  
</div>



 

</>:null}
{/* Location Screen Ends */} 
{/* Time Zone Screen Starts */}
{this.state.timezonescreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Time Zone
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter Time Zone</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="tzonename" onChange={this.handleChangetwo}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleTimezoneadd} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<TimeZoneTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Time Zone Screen Ends */} 


{/* Applicants Source Screen Starts */}
{this.state.sourcesscreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Applicant Sources
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter Applicant Sources</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="sourcename" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleApplicantsource} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<ApplicantsSources/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Applicants Source Screen Ends */} 

{/* Document Types Screen Starts */}
{this.state.documenttypescreen==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Document Types
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter Document Types</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="documenttypename" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleDocumentType} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<DocumentTypes/>

   </div>
 </div> 
</div>



 

</>:null}
{/* Document Types Screen Ends */} 

{/**Client Categories Screen Starts */}
{this.state.clientcategoryscreen==1?<>
  <div class="content" >
  <div className="row">
    <div className="col-xl-10"><h2 style={{fontFamily:"Inter Bold"}}>
      Client Categories
      </h2></div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
   <div className="row">
  <div className="col-xl-2">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Client Category</span>
  </div>
  <div className="col-xl-3">
  <input type="text" className="form-control adduser" name="categoryname" onChange={this.handleChangetwo}/>
  </div>
  </div>  
  <div className="row mt-2">
  <div className="col-xl-2"></div>
  <div className="col-xl-1">
    
<button className="btn btn-primary updatebtn" onClick={this.handleSave}>Save</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleclosecat}>Cancel</button>
</div>
  </div>  
 <div className="row">
<div className="col-xl-12">
<Tabs
          activeTab="1"
          className=""
          ulClassName=""
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="Active Client Category" className="mr-4">
                <div className="mt-3">
                <ClientCategory/>
                </div>
            </Tab>
            <Tab title="Deleted Client Category" className="mr-4">
                <div className="mt-3">
              <ClientCategoryDelete/>
                </div>
            </Tab>
          </Tabs>

</div>
 </div>
 
  
</div>
</>:null}

{/**Client Categories Screen Ends */}
{/**Source Screen Starts */}
{this.state.sourceemail==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Source
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-3">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter Source</span>

    </div>
    <div className="col-xl-6">
    <input className="form-control adduser" name="sourcename1" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleSourceEmailadd} style={{marginTop:"0%"}}>Save</button>
      </div>
  </div> 

    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<SourceTable/>

   </div>
 </div> 
</div>



 

</>:null}
{/**Source Screen Ends */}
{/**Source Email Screen Starts */}
{this.state.sourcedisplay==1?<>
 
 <div class="content">
 <div className="row">
   <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
      Source Email
     </h2></div>

    
    </div>
    <div className="row">
   <div className="col-xl-12">
     <hr/>
   </div>
   </div>
   <div className="row mt-3">
    <div className="col-xl-2">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Enter Source Email</span>

    </div>
    <div className="col-xl-4">
    <input className="form-control adduser" name="sourcename2" onChange={this.handleChange}/>
    </div>
    <div className="col-xl-2">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Select Source</span>

    </div>
    <div className="col-xl-4" style={{marginLeft:"-2%",marginTop:"-0.5%"}}>
    <Select 
      placeholder="Select Source"
    onChange={(e)=>this.handlesourceemail(e.value)}
    options={this.state.sourcelistdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
    </div>
    
   
  </div> 
<div className="row mt-3">
<div className="col-xl-2">
  <span style={{fontFamily:"Inter",fontWeight:"bold"}}>Password</span>

    </div>
    <div className="col-xl-4">
    <input className="form-control adduser" name="password" onChange={this.handleChange}/>
    </div>
<div className="col-xl-3">
    <button className="btn btn-primary updatebtn" onClick={this.handleSourceEmailadd1} style={{marginTop:"0%"}}>Save</button>
      </div>
</div>
    <div className="row mt-3" style={{marginLeft:"-2.5%"}}>
   <div className="col-xl-12">
<SourceEmailTable/>
   </div>
 </div> 
</div>



 

</>:null}
{/**Source Screen Ends */}
  {/* Team Screen Starts */}
  {this.state.teamsscreen==1?<>
  <div class="content">
  <div className="row">
    <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
       Teams
      </h2></div>
      <div className="col-xl-3" style={{textAlign:"right"}}>
      <button className="btn btn-primary updatebtn" style={{marginTop:"3%"}}
      onClick={this.handleaddTeam}
      >Add Team</button>
      </div>
     
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
     <div className="row mt-3">
    <div className="col-xl-12">
<ActiveTeam/>

    </div>
  </div> 

 
 
 
  
</div>
</>:<>

</>}


{/* Team Screen Ends */}  
{this.state.addteam==1?<>
  <div class="content">
  <div className="row" >
    <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
       Teams
      </h2></div>
    
      <div className="col-xl-3" style={{textAlign:"right"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Inter",border:"none",padding:"3%"}}
      onClick={this.handlebackTeam}
      >Back</button>
      </div>
     </div>
     <div className="row">
    <div className="col-xl-12">
      <hr/>
    </div>
    </div>
     <div className="row mt-3">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Business Unit</span>
  <Select 
    onChange={(e)=>this.handleBusiness(e)}
    options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Name</span>
  <input type="text" placeholder="Team Name" onChange={this.handleChangetwo} className="form-control mt-2 adduser" name="teamname" />
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Lead</span>
  <Select 
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
     </div>
     <div className="row mt-3">
      <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Role</span>
      <Select 
    onChange={(e)=>this.handleRoles(e)}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
      </div>
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Description</span>
  <textarea rows="1" style={{height:"37px"}} className="form-control adduser mt-2" placeholder="Description" 
  name="Description" onChange={this.handleChangetwo}>
   </textarea>
  </div>
 
     </div>
     <div className="row mt-3">
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Data Access Permissions
  </span><br/>
  <input type="radio" value="1" name="DataPermission" onChange={this.handleChangetwo} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Data is shared across the team members</span>
  <br/>
  <input type="radio" value="1" name="DataPermission" onChange={this.handleChangetwo} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Only team lead can view the data of team members</span>
  </div>
 
     </div>
 
     <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleRegisterteam}>Save</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handlebackTeam}>Cancel</button>
</div>
  </div>
 
  
</div>
</>:<>

</>}

     


        </div>
      
      </div>
    );
  }
}
