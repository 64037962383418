import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Clientimage  from "../images/clientimage.png";
import Jobpipelinedata from "./Jobpipelinedata";
import PencilIcon  from "../images/pencilicon.png";
import DeleteIcon  from "../images/deleteicon.png";
import EyeIcon  from "../images/eyeicon.png";
import PlusincircleIcon  from "../images/plusincircle.png";
import MinusincircleIcon  from "../images/minusincircle.png";
import Dropdown from 'react-bootstrap/Dropdown';
import LoadingIcon  from "../images/loadingicon.gif";
import Applicantimg from "../images/applicantimg.png";
import Pipelinedata from "./Pipelinedata";
import Pagination from "./Pagination";
import ReactPaginate from "react-paginate";

import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState,convertToRaw} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import DatePicker from "react-datepicker";


const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

const NoticePeriod_style = {
  control: (base, state) => ({
    ...base,
    borderRadius:"2px",
   marginTop:"0.2%",
   backgroundColor:"#e8e8e8",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #e8e8e8",
      borderRadius:"2px",
      backgroundColor:"#e8e8e8",
      color:"black"
    }
  }),
};


function ViewApplicant(id){
 
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[2]
  var data2=splitURL[3]
  var data3=splitURL[4] 
  var data5=splitURL[5]
  let encodedjobid=window.btoa(id)
 // window.location.href=`${data}/viewapplicant/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`

  window.open(`${data}/viewapplicant/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`, '_blank');

}
function DeleteApplicant(id){
  Swal.fire({
    title: 'Are you sure to delete this Applicant?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))


      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addAppliantdata: "deleteApplicant",editapplicantid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            Swal.fire(
              'Deleted!',
               response.message,
             
            )
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
              3000
          );
              });
     
    }
  })
}

const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) =>
  {
   return(
       <>
               <input 
                   type="checkbox"
                   className="submissions-checkbox"
                   ref={ref}
                   onClick={ onClick }
                   {...rest}
               />
       </>
   )
  })

const tableCustomStyles = {
  table: {
    style: {
     
    },
},
tableWrapper: {
  style: {
      display: "table",
     minWidth:"140%",
      fontFamily:"Poppins Light",
      
      fontWeight:"bold",
      fontSize:"14px"
      
  },
},
headCells: {
  style: {
   
  },
 
},
expanderButton:{
  tableWrapper: {
    style: {
        display: "table",
       
        fontFamily:"Poppins Light",
        
    },
},
  style:{
    '&:disabled': {
     display:"none"
    },
  }
}
}

function ViewJob(id){
  console.log(id)
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  var data5=splitURL[6]
  let encodedjobid=window.btoa(id)
  window.location.href=`${data}/viewjob/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
}
class Activeapplicant extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      clientlogo:" ",
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      updatemsg:"",
      countrydata:[],
      citydata:[],
      industrydata:[],
      catageroydata:[],
      Countryname:"",
      Countryid:"",
      statedata:[],
      reportingtodata:[],
      BusinessUnit:"",
      editclientid:"",
      reportingtodata:[],
      languagesdata:[],
      degreedata:[],
      JobTitle:"",
      JobCode:"",
      ClientName:"",
      ClientId:"",
      CBR_Coin:"",
      CBR_Amount:"",
      PR_Coin:"",
      PR_MinAmount:"",
      PR_MaxAmount:"",
      CountryID:"",
      CountryName:"",
      StateID:"",
      StateName:"",
      CityID:"",
      CityName:"",
      ClientJobID:"",
      NoofPositions:"",
      ClosedPostions:"",
      JobCreatedDate:"",
      JobClosingDate:"",
      SPOCMobile:"",
      SPOCName:"",
      HiringManagerid:"",
      HiringManager:"",
      AssingnedID:"",
      AssingnedName:"",
      BusinessUnit:"",
      NoticePeriod:"",
      JobDescription:"",
      DegreeID:"",
      Degreename:"",
      ExperienceMin:"",
      ExperienceMax:"",
      PrimarySkills:"",
      Languages:"",
      JobStatus:"",
      jobstatusname:"",
      CreatedBy:"",
      IndustryID:"",
      userstausdata:[
        { value: '1', label: 'Active'},
       
     
       
      ],
      staffingdata:[
        { value: "1", label: "Permanent Staffing" },
        { value: "2", label: "Contract To Hire" },
      ],
      LanguagesName:"",
    editjobid:"",
 prioritydropdown:0,
 original_rows: [], 
 rows: [], 
 search: "",
 clientdata:[],
 jobcodeerror:"",
 jobtitleerror:"",
 nopositionerror:"",
 clientraterrror:"",
 payraterror:"",
 spocnameerror:"",
 spocmobileerror:"",
 noticeperioderror:"",
 minexperror:"",
 maxexperror:"",
 primaryskillserror:"",
 jderror:"",
 loadingicon:0,
 resume_name:"",
 Noticeperiod : [
  {value:'0',label: "All Records"},
  { value: "1", label: "15 Days or less" },
  { value: "2", label: "1 Month " },
  { value: "3", label: "2 Months" },
  { value: "4", label: "3 Months" },
  { value: "5", label: "More than 3 Months" },
  { value: "6", label: "Currently Serving Notice Period" },
  { value: "7", label: "7 Days" },
  { value: "8", label: "45 Days" },
  { value: "9", label: "Any" },
  { value: "10", label: "Immediate Joiner" },
  { value: "11", label: "Maintenance" },
] ,
 hover:false,
          StaffingTypeid:"",
          StaffingTypename:"",
          pincode:"",
          Additionalskills:"",
          ClientJobID :"",
          IndustryName:"",
          SourceName:"",
          PrefCityName:"",
          PrefStateName:"",
          PrefCountryName:"",
          PrefCountryID:"",
          currentlocation:"",
          preferredlocation:"",
          editAppliantid:"",
          resume_name:"",
          uploadresumebase64:"",
          fileerrormsg:"",
          updatingmsg:"",
          Relocate:"",
          docdata:[
       
            { value: '2', label: 'Aadhar Card'},
            { value: '1', label: 'PAN Card'},          
            { value: '3', label: 'Other'},
           
          ],
          GovtIDType:"",
          GovtIDNumber:"",
          sourcedata:[
            { value: '1', label: 'Naukri'},
            { value: '2', label: 'Monster'},
            { value: '6', label: 'Linkedin'},
            { value: '3', label: 'Other'},
          ],
          ProfilePhoto:"",
          imagename:"",
          totalRows:"",
          firstRow:1,
          ShowingPageStart:"",
          ShowingPageEnd:"",
          sendEmailmodal:false,
          checkboxData:[],
          Emaildata:[],
          maildescription:"",
          editorState: EditorState.createEmpty(),
          successmsg:"",
          secondarybtn:1,
          sendemaildisplayscreen:false,
          noticeperiod:0,
          keyGen10:[],
          startdate:"",
          gtodate:"",
          fromdate:"",
          gnoticeperiod:"",
          glocation:"",
          nodatafoundmsg:""
    }
 
    this.columns = [
      {
      
        selector: e => <>
        {e.submissioncount==0?null:<> <span style={{fontSize:13,fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"rgb(66, 147, 254)",color:"white",fontFamily:"Inter",fontWeight:"bold",visibility:"hidden"}}> {e.submissioncount}</span></>}
                  {e.pipelinecount==0?null:<> <span style={{fontSize:13,padding:"7px",fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"blueviolet",color:"white",fontFamily:"Inter",fontWeight:"bold",position:"absolute",visibility:"hidden"}}> {e.pipelinecount}</span></>}
                  {e.submissioncount==0?null:<> <span style={{fontSize:13,padding:"7px",fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"rgb(66, 147, 254)",color:"white",fontFamily:"Inter",fontWeight:"bold"}}> {e.submissioncount}</span></>}
                  {e.pipelinecount==0?null:<> <span style={{fontSize:13,padding:"7px",fontSize:12,textAlign:"center",cursor:"pointer",backgroundColor:"blueviolet",color:"white",fontFamily:"Inter",fontWeight:"bold"}}> {e.pipelinecount}</span></>}</>,
        sortable: true,
      width:"5%",
     
      },
     
     
        
    {
      name:"Applicant Name",
selector: row =><span onClick={() =>ViewApplicant(row.id)}className="applicantname" title={row.ApplicantName}>{row.ApplicantName}</span>,
   
  
    sortable: true,
    width:"8%",
   
  },
         
        
          {
            name: "Email",
            selector: row =><span className="keyskills" title={row.EmailID}>{row.EmailID}</span>,
            sortable: true,
            width:"10%",
            style:{
              whiteSpace:"nowrap",
             
              textOverflow: "clip",
            
              fontFamily:"Poppins Light",
              fontWeight:"bold"
            }
          },
          {
            name: "Created Date",
            selector: "CreatedDate",
            sortable: true,
            width:"7%",
            style:{
            
              fontFamily:"Poppins Light",
              fontWeight:"bold",

            }
              ,
          },
         
          {
            name: "Mobile",
            selector: "MobileNo",
            sortable: true,
            width:"7%",
            style:{
            
              fontFamily:"Poppins Light",
              fontWeight:"bold",

            }
              ,
          },
          {
            name:"Skills",
           
            width:"11%",
              cell: (e) => (
               
                  <>
                   <span className="keyskills" title={e.KeySkills}>{e.KeySkills}</span> 

                </>),
        style:{
          border:"1px solid transparent"
        }
          ,
          sortable: true
        },
          {
            name: "Exp",
            selector: "experience",
            sortable: true,
            width:"3%",
            style:{
              
              fontFamily:"Poppins Light",
              fontWeight:"bold",
              
            }
              ,
          },
          {
            name:"Current CTC",
           
            width:"7%",
              cell: (e) => (
               
                  <>
                    {e.CurrentCTC==null?<>
                    <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>Not Available</span>
                    </>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.CurrentCTC}</span></>}

                </>),
        
          sortable: true,
          style:{
            border:"1px solid transparent"
          }
            ,
        },
          {
            name:"Expected CTC",
           
            width:"7%",
              cell: (e) => (
               
                  <>
                    {e.ExpectedCTC==null || e.ExpectedCTC==""?<>
                    <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>NA</span>
                    </>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.ExpectedCTC}</span></>}

                </>),
        
          sortable: true,
          style:{
            border:"1px solid transparent"
          }
            ,
        },
        {
          name:"Notice Period",
          width:"11%",
     
            cell: (e) => (
             
                <>
                  {e.NoticePeriod==null ||e.NoticePeriod==""?<>
                  <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>NA</span>
                  </>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.NoticePeriod}</span></>}

              </>),
      
        sortable: true,
        style:{
          border:"1px solid transparent"
        }
          ,
      },
         
         
      {
        name:"Current Location",
        width:"9%",
       
          cell: (e) => (
           
              <>
                {e.location==null?<>
                <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>Not Available</span>
                </>:<><span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>{e.location}</span></>}

            </>),
style:{
  border:"1px solid transparent"
}
  ,
    sortable: true
    },
    {
      name:"Preferred Location",
      width:"10%",
 
        cell: (e) => (
         
            <>
              {e.preferred_location==null?<>
              <span className="keyskills">Not Available</span>
              </>:<><span className="keyskills" title={e.preferred_location}>{e.preferred_location}</span></>}

          </>),
  sortable: true,
  width:"10%",
style:{
  border:"1px solid transparent"
}
   
  },
          {
            name:"Action",
          
          cell: (e) => (
           
              <>
              
            <a   href="#ft-edituser-modal"
                          onClick={()=>this.openModal(e.id)} 
                          style={{fontSize:13,fontSize:12,margin:"3%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img src={PencilIcon} style={{width:"50%",marginTop:"5%"}} /></a>{'     '}
            <span   onClick={() =>DeleteApplicant(e.id)}  style={{fontSize:13,fontSize:12,marginLeft:"-6%",color:"white",textAlign:"center",cursor:"pointer",borderRadius:"3px"}}><img style={{width:"52%",marginTop:"5%",cursor:"pointer"}}  onClick={() =>DeleteApplicant(e.id)}  src={DeleteIcon} /></span>{'     '}
        
            </>
          ),
          style:{
            border:"1px solid transparent"
          },
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
    ];

    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
this.handleUpdate=this.handleUpdate.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.hanldelState=this.handleState.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleRequired=this.handleRequired.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleCategory=this.handleCategory.bind(this)
    this.handleDisable=this.handleDisable.bind(this)
    this.Removepriority1=this.Removepriority1.bind(this)
    this.handlePriority=this.handlePriority.bind(this)
    this.Removepriority2=this.Removepriority2.bind(this)
    this.Removepriority3=this.Removepriority3.bind(this)
    this.Removepriority4=this.Removepriority4.bind(this)
    this.handleDegree=this.handleDegree.bind(this)
    this.handleLanguage=this.handleLanguage.bind(this)
    this.handleNotice=this.handleNotice.bind(this)
    this.handleStaffing=this.handleStaffing.bind(this)
    this.handleSave=this.handleSave.bind(this)
    this.handleNoticePeriod=this.handleNoticePeriod.bind(this)
    this.handleDoc=this.handleDoc.bind(this)
    this.handleSavenew=this.handleSavenew.bind(this)
    this.handleSearch=this.handleSearch.bind(this)
    this.handleNoticeperiod=this.handleNoticeperiod.bind(this)
    this.handleNoticeperiodclose=this.handleNoticeperiodclose.bind(this)
    this.updateStateList10=this.updateStateList10.bind(this)
    this.handleSubmissionsearch=this.handleSubmissionsearch.bind(this)
    this.handleReset=this.handleReset.bind(this)
    this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
    this.handleEmail=this.handleEmail.bind(this)


  }

  handleEmail(){
    this.setState({secondarybtn:1,dummytext:"Please Wait..."})
    if(this.state.maildescription=="" || this.state.maildescription=="<p></p>\n"){
        this.setState({successmsg:"Email Body Can't be empty."})
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const data={ userid: withoutQuotes,addJobpipelinedata:"sendemailtoapplicant",editjobid:this.state.editjobid,editapplicantid:this.state.checkboxData.toString(),maildescription:this.state.maildescription,
      attachmentfilename:this.state.attachmentfilename,
      attachmentbase64stringt:this.state.attachmentbase64stringt
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
          method: "POST",
    
          body: JSON.stringify(data), // data can be `string` or {object}!
    
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
    
          .catch((error) => console.error("Error:", error))
    
          .then((response) => {
              this.setState({successmsg:response.message,dummytext:""})
              setTimeout(
                function() {
                  this.setState({showModal5:false,successmsg:"",secondarybtn:0,})
                  this.plusButtonData()
                }
                .bind(this),
              3000
            );
                
              });
    }
  
  }
  handleAttachment = (e) => {
  
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;
  
    file = e.target.files[0];
  
    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          attachmentbase64stringt:result,
          attachmentfilename:file["name"],
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  
    this.setState({
      //  file: e.target.files[0]
    });
  
    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  handleReset = () => {
    this.setState({gtodate:"",fromdate:"",glocation:"",gnoticeperiod:"",search:"",loadingicon:0,sendemaildisplayscreen:false},()=>{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      //console.log(withoutQuotes)
      //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
      const repdata={
        userid:withoutQuotes,
        addAppliantdata:"getAppliantinfo",
        page:"1",
        searchTerm:this.state.search,
        records:"25",
        statuscode:"1"
      }
      //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
        fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
          method: "POST",
  
          body: JSON.stringify(repdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
           console.log(response)
           
             let data=response.data
                data = data.map((currentValue, Index) => {
                  currentValue.SNO = Index + 1;
                  return currentValue;
                  
                });
                this.setState({ original_rows: data, 
                  rows: data,loadingicon:1,
                  totalRecords:response.paging.totalRecords,
                  ShowingPageStart:response.paging.ShowingPageStart,
                  ShowingPageEnd:response.paging.ShowingPageEnd
                });      
              });
    
    })
  }
  handleDateChange(date){
    //console.log(date)
    this.setState({fromdate:date})
  }
  handleTODateChange(date){
    //console.log(date)
    this.setState({gtodate:date})
  }
  handleNoticePeriodChange(e){
    this.setState({gnoticeperiod:e})
  }
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2,   
   '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;   
  }


  // handleSubmissionsearch(){
  //   this.setState({loadingicon:0,noticeperiod:0})
  //   var username=JSON.stringify(localStorage.getItem("UserId"))
  //   const withoutQuotes = username.replaceAll('"', '');
  //   //console.log(withoutQuotes)
  //   //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
  //   const repdata={
  //     userid:withoutQuotes,
  //     addAppliantdata:"getAppliantinfo",
  //     page:this.state.firstRow,
  //     searchTerm:this.state.search,
  //     records:"25",
  //     statuscode:"1",
  //     gnoticeperiod:this.state.keyGen10.toString()
  //   }
  //   console.log(repdata)
  //   //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
  //     fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
  //       method: "POST",

  //       body: JSON.stringify(repdata), // data can be `string` or {object}!

  //       headers: { "Content-Type": "application/json" },
  //     })
  //       .then((res) => res.json())

  //       .catch((error) => console.error("Error:", error))

  //       .then((response) => {
  //        console.log(response)
         
  //          let data=response.data
  //             data = data.map((currentValue, Index) => {
  //               currentValue.SNO = Index + 1;
  //               return currentValue;
                
  //             });
  //             this.setState({ original_rows: data, 
  //               rows: data,loadingicon:1,
  //               totalRecords:response.paging.totalRecords,
  //               ShowingPageStart:response.paging.ShowingPageStart,
  //               ShowingPageEnd:response.paging.ShowingPageEnd 
  //             }); 
  //           });
  // }



  handleSubmissionsearch(){
    this.setState({loadingicon:0,sendemaildisplayscreen:false})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
    const repdata={
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:this.state.firstRow,
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1",
      gnoticeperiod:this.state.gnoticeperiod.label,
      glocation:this.state.glocation,
      fromdate:this.state.fromdate==""?"":this.formatDate(this.state.fromdate),
      gtodate:this.state.gtodate==""?"":this.formatDate(this.state.gtodate),
    }
    console.log(repdata)
    //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
         
           let data=response.data
           if(response.data==null){
            this.setState({nodatafoundmsg:"No Data Found!",loadingicon:2})
           }else{
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
                
              });
              this.setState({ original_rows: data, 
                rows: data,loadingicon:1,
                totalRecords:response.paging.totalRecords,
                ShowingPageStart:response.paging.ShowingPageStart,
                ShowingPageEnd:response.paging.ShowingPageEnd 
              }); 
            }
            });
  }


  updateStateList10(e,value){
         
    if (e.target.checked){
      //console.log(e.target.value)
        this.setState({keyGen10: this.state.keyGen10.concat([e.target.value])},()=>{
        
        })
    }
    else{
      this.setState({keyGen10 : this.state.keyGen10.filter(function(val) {return val!==e.target.value})},()=>{
        //console.log(this.state.keyGen)
      })
    }
  }
  handleNoticeperiod(){
    this.setState({noticeperiod:1,keyGen10:[]})
  }
  handleNoticeperiodclose(){
    this.setState({noticeperiod:0})
  }
  handleOpenSendEmail=()=>{
    this.setState({ sendEmailmodal: true },()=>{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const data={userid:withoutQuotes,addJobpipelinedata:"getpipesendemailids",editapplicantid:this.state.checkboxData.toString(),editjobid:1,}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobpipelineinfo", {
          method: "POST",
    
          body: JSON.stringify(data), // data can be `string` or {object}!
    
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
    
          .catch((error) => console.error("Error:", error))
    
          .then((response) => {
            console.log(response)
            response.result.map((e)=>{
              this.setState({Emaildata:this.state.Emaildata.concat(e.EmailID)},()=>{
                console.log(this.state.Emaildata)
              })
            })
          })
    });
  }
  handleCheckbox(e,row){
    //console.log(row)
    const applicantIDs = e.selectedRows.map(applicant => applicant.ApplicantID.toString());
    if(e.selectedCount>=1){
      this.setState({checkboxData: applicantIDs,sendemaildisplayscreen:true},()=>{
        //console.log(this.state.checkboxData)
      })
    }
    else if(e.selectedRows.length==0){
      this.setState({sendemaildisplayscreen:false})
    }
  }
  //onEditorStateChange: Function = (editorState) => {
    onEditorStateChange = (editorState) => {  
  //console.log(editorState)
   this.setState({
     editorState,
   });
   const contentState = editorState.getCurrentContent();
   const data=convertToRaw(contentState)
   const data1=draftToHtml(data)
   this.setState({maildescription:data1,successmsg:"",secondarybtn:0})
   //console.log(data1);
 };
  
  handleSavenew() {
    if(this.state.IndustryID==""||this.state.IndustryID==null){
      this.setState({IndustryID:14,IndustryName:"Computer Software"})
    }
    else if(this.state.NationalityID==""||this.state.NationalityID==null){
      this.setState({NationalityID:"1",NationalityName:"Indian"})
    }
   
    else{
      this.setState({secondarybtn:1})
      var username = JSON.stringify(localStorage.getItem("UserId"));
      const withoutQuotes = username.replaceAll('"', "");
      const data = {
        userid: withoutQuotes,
        addAppliantdata: "editApplicantinfo",
        ApplicantName:this.state.ApplicantName,
        ProfilePhoto:this.state.ProfilePhoto,
        imagename:this.state.imagename,
        Gender:this.state.Gender,
        AlternateEmail:this.state.AlternateEmail,
        MobileNo:this.state.MobileNo,
        AlternateMobile:this.state.AlternateMobile,
        DateofBirth:this.state.DateofBirth,
        PreviousOrg:this.state.PreviousOrg,
        PrevDesignation:this.state.PrevDesignation,
        TotalExp:this.state.TotalExp,
        RelevantExp:this.state.RelevantExp,
        HighestDegree:this.state.HighestDegree,
        CurrentCTC:this.state.CurrentCTC,
        ExpectedCTC:this.state.ExpectedCTC,
        VariableAmount:this.state.VariableAmount,
        FixedAmount:this.state.FixedAmount,
        Address:this.state.Address,
        SourceID:this.state.SourceID,
        SourceName:this.state.SourceName,
        Availablity:this.state.Availablity,
        NationalityID:this.state.NationalityID,
        NationalityName:this.state.NationalityName,
        GovtIDType:this.state.GovtIDType,
        GovtIDNumber:this.state.GovtIDNumber,
        IndustryID:this.state.IndustryID,
        IndustryName:this.state.IndustryName,
        linkedInurl:this.state.linkedInurl,
        KeySkills:this.state.KeySkills,
        NoticePeriod:this.state.NoticePeriod,
        Remarks:this.state.Remarks,
        Relocate:this.state.Relocate,
        currentlocation:this.state.currentlocation,
        preferredlocation:this.state.preferredlocation,
        editapplicantid:this.state.editAppliantid,
        resume_name:"",
        uploadresumebase64:"",
  
      };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
         
          console.log(response);
          var username=JSON.stringify(localStorage.getItem("UserId"))
          const withoutQuotes = username.replaceAll('"', '');
            const data={ userid:withoutQuotes,addAppliantdata:"getsingleApplicant",editAppliantid:this.state.editAppliantid}
            console.log(data)
            fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
                method: "POST",
        
                body: JSON.stringify(data), // data can be `string` or {object}!
        
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
        
                .catch((error) => console.error("Error:", error))
        
                .then((response) => {
                    
                console.log(response)
                response.result.map((e)=>{
                  this.setState({
                  
                    Honorific:e.Honorific,
                    fullname:e.fullname,
                    Gender:e.Gender,
                    EmailID:e.EmailID,
                    NoticePeriod:e.NoticePeriod,
                    AlternateEmail:e.AlternateEmail,
                    MobileNo:e.MobileNo,
                    AlternateMobile:e.AlternateMobile,
                    DateofBirth:e.DateofBirth,
                    PreviousOrg:e.PreviousOrg,
                    PrevDesignation:e.PrevDesignation,
                    TotalExp:e.TotalExp,
                    RelevantExp:e.RelevantExp,
                    HighestDegree:e.HighestDegree,
                    CurrentCTC:e.CurrentCTC,
                    ExpectedCTC:e.ExpectedCTC,
                    VariableAmount:e.VariableAmount,
                    FixedAmount:e.FixedAmount,
                    Address:e.Address,
                    CountryID:e.CountryID,
                    CountryName:e.CountryName,
                    StateID:e.StateID,
                    StateName:e.StateName,
                    CityID:e.CityID,
                    CityName:e.CityName,
                    SourceID:e.SourceID,
                    SourceName:e.SourceName,
                    JobTitle:e.JobTitle,
                    Availablity:e.Availablity,
                    NationalityID:e.NationalityID,
                    NationalityName:e.NationalityName,
                    Relocate:e.Relocate,
                    GovtIDType:e.GovtIDType,
                    GovtIDNumber:e.GovtIDNumber,
                    IndustryID:e.IndustryID,
                    IndustryName:e.IndustryName,
                    linkedInurl:e.linkedInurl,
                    KeySkills:e.KeySkills,
                    Remarks:e.Remarks,
                    AppStatus:e.AppStatus,
                    AppStatusnam:e.AppStatusname,
                    resume_name:e.resume_name,
                    ProfilePhoto:e.ProfilePhoto,
                    ApplicantName:e.fullname,
                    SourceName:e.SourceName,
                    preferredlocation:e.preferred_location,
                    currentlocation:e.location
      
                  })
                })
              })       
        });
    }
     
    
   
  }
  handleDoc(e){
    this.setState({GovtIDType:e.label})
  }
  handleNoticePeriod(e){
    this.setState({NoticePeriod:e.label})
      }
  handleSave() {
    if(this.state.IndustryID==""||this.state.IndustryID==null){
      this.setState({IndustryID:14,IndustryName:"Computer Software"})
    }
    else if(this.state.NationalityID==""||this.state.NationalityID==null){
      this.setState({NationalityID:"1",NationalityName:"Indian"})
    }
   
    else{
      this.setState({secondarybtn:1})
      var username = JSON.stringify(localStorage.getItem("UserId"));
      const withoutQuotes = username.replaceAll('"', "");
      const data = {
        userid: withoutQuotes,
        addAppliantdata: "editApplicantinfo",
        ApplicantName:this.state.ApplicantName,
        ProfilePhoto:"",
        imagename:"",
        Gender:this.state.Gender,
        AlternateEmail:this.state.AlternateEmail,
        MobileNo:this.state.MobileNo,
        AlternateMobile:this.state.AlternateMobile,
        DateofBirth:this.state.DateofBirth,
        PreviousOrg:this.state.PreviousOrg,
        PrevDesignation:this.state.PrevDesignation,
        TotalExp:this.state.TotalExp,
        RelevantExp:this.state.RelevantExp,
        HighestDegree:this.state.HighestDegree,
        CurrentCTC:this.state.CurrentCTC,
        ExpectedCTC:this.state.ExpectedCTC,
        VariableAmount:this.state.VariableAmount,
        FixedAmount:this.state.FixedAmount,
        Address:this.state.Address,
        SourceID:this.state.SourceID,
        SourceName:this.state.SourceName,
        Availablity:this.state.Availablity,
        NationalityID:this.state.NationalityID,
        NationalityName:this.state.NationalityName,
        GovtIDType:this.state.GovtIDType,
        GovtIDNumber:this.state.GovtIDNumber,
        IndustryID:this.state.IndustryID,
        IndustryName:this.state.IndustryName,
        linkedInurl:this.state.linkedInurl,
        KeySkills:this.state.KeySkills,
        NoticePeriod:this.state.NoticePeriod,
        Remarks:this.state.Remarks,
        Relocate:this.state.Relocate,
        currentlocation:this.state.currentlocation,
        preferredlocation:this.state.preferredlocation,
        editapplicantid:this.state.editAppliantid,
        resume_name:this.state.resume_name,
        uploadresumebase64:this.state.uploadresumebase64,
  
      };
      console.log(data);
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
        method: "POST",
  
        body: JSON.stringify(data), // data can be `string` or {object}!
  
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
  
        .catch((error) => console.error("Error:", error))
  
        .then((response) => {
          Swal.fire(
            response.message
          );
          setTimeout(
            function () {
              window.location.reload();
            }.bind(this),
            2500
          );
         this.setState({updatemsg:response.message})
  
          console.log(response);
        });
    }
     
    
   
  }
  handleStaffing(e){
    this.setState({StaffingTypeid:e.value,errormsg3:"",StaffingTypename:e.label})
      }
  handleMouseIn() {
    this.setState({ hover: true })
  }
  
  handleMouseOut() {
    this.setState({ hover: false })
  }
  handleNotice(e){
    this.setState({NoticePeriod:e.label})
  }
  handleLanguage(e){
    this.setState({Languages:e.value})
  }
  handleDegree(e){
    this.setState({Degreename:e.label,DegreeID:e.value})
  }
  handleChangeNopos = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value>20){
      this.setState({nopositionerror:"No of Open Positions between 20 only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        nopositionerror:"",
       
      });
    }
  
    
  };

  closeSendEmailModal = () => {
    this.setState({ sendEmailmodal: false,Emaildata:[]})
  };

  handleChangeBillrate = (e) => {
    var regex = /[a-zA-Z!@#\$%\^\&*\)\(+=._-`;,:<>-]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      clientraterrror:""
    });

    
  };
  handleChangePincode = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
 
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
   
  
    
  };
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          ProfilePhoto: result,
          imagename:file["name"],
          file,
        },()=>{
          this.handleSavenew()
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handleImageone = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];
    if(file["size"]>500000){
      this.setState({fileerrormsg:"File Size should not be more than 500kb"})
      
    }
    else{
      this.getBase64(file)
   
      .then((result) => {
       
          file["base64"] = result;
      
          console.log("File Is", file);
          this.setState({
            uploadresumebase64: result,
            resume_name:file["name"],
            fileerrormsg:"",
            file,
          },()=>{
            //console.log(this.state.resume_name)
          });
        
        
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }

    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
    }
    
  };
  handleChangeJobTitle = (e) => {
    var regex = /[0-9!@#\$%\^\&*\)\(+=._-`;,:<>]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
jobtitleerror:""
    });

    
  };
  handlePriority(){
    this.setState({prioritydropdown:1})
  }
  Removepriority1(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:1,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  Removepriority2(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:2,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  Removepriority3(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:3,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  Removepriority4(jobid){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"jobprioritystatus",
      setprioritystatus:4,
      editjobid:jobid

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              Swal.fire(response.message)
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
  }
  handleDisable(row){
    if(row.pipelinecount==0 && row.submissioncount==0){
     return true;
    
    }
    else{
     return false;
   
    }
   }
  handleCategory(e){
    this.setState({CategoryName:e.label,categoryid:e.value},()=>{
     
    })
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleUpdate(){
    if(this.state.JobCode==""){
      this.setState({jobcodeerror:"Please Enter Job Code"})
    }
    else if(this.state.JobTitle==""){
      this.setState({jobtitleerror:"Please Enter Job Title"})
     
    }
  
    else if(this.state.NoofPositions==""){
      this.setState({nopositionerror:"Please Enter No of Position"})
    }
  
    else if(this.state.PR_MinAmount==""){
      this.setState({payraterror:"Please Enter Pay Rate Minimum Amount"})
      
    }
   
 
   
  
    else if(this.state.SPOCName==""){
      this.setState({spocnameerror:"Please Enter SPOC Name"})
    }
    else if(this.state.SPOCMobile==""){
      this.setState({spocmobileerror:"Please Enter SPOC Mobile"})
    }
    else if(this.state.NoticePeriod==""){
      this.setState({noticeperioderror:"Please Enter Notice Period"})
    }
   
    else if(this.state.ExperienceMin==""){
   
      this.setState({minexperror:"Please Enter Minimum Experience"})
    }
    else if(this.state.ExperienceMax==""){
      this.setState({maxexperror:"Please Enter Maximum Experience"})
   
    }
    else if(this.state.PrimarySkills==""){
      this.setState({primaryskillserror:"Please Enter Primary Skills"})
    }

    else if(this.state.JobDescription==""){

      this.setState({jderror:"Please Enter Job Description"})
    }
    else{
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const data={
        userid:withoutQuotes,
        addjobpostingdata:"editjobpostinginfo",
        editjobid:this.state.editjobid,
        JobCode:this.state.JobCode,
        JobTitle:this.state.JobTitle,
        ClientId:this.state.ClientId,
        AssingnedID:this.state.AssingnedID,
        AssingnedName:this.state.AssingnedName,
        ClientName:this.state.ClientName,
        CBR_Coin:this.state.CBR_Coin,
        CBR_Amount:this.state.CBR_Amount,
        PR_Coin:this.state.PR_Coin,
        PR_MinAmount:this.state.PR_MinAmount,
        PR_MaxAmount:this.state.PR_MaxAmount,
        CountryID:this.state.CountryID,
        CountryName:this.state.CountryName,
        StateID:this.state.StateID,
        StateName:this.state.StateName,
        CityID:this.state.CityID,
        CityName:this.state.CityName,
        ClientJobID:this.state.ClientJobID,
        NoofPositions:this.state.NoofPositions,
        ClosedPostions:this.state.NoofPositions,
        JobCreatedDate:this.state.JobCreatedDate,
        JobClosingDate:this.state.JobClosingDate,
        SPOCName:this.state.SPOCName,
        SPOCMobile:this.state.SPOCMobile,
        HiringManagerid:this.state.HiringManagerid,
        HiringManager:this.state.HiringManager,
        NoticePeriod:this.state.NoticePeriod,
        BusinessUnit:this.state.BusinessUnit,
        JobDescription:this.state.JobDescription,
        DegreeID:this.state.DegreeID,
        Degreename:this.state.Degreename,
        ExperienceMin:this.state.ExperienceMin,
        ExperienceMax:this.state.ExperienceMax,
        PrimarySkills:this.state.PrimarySkills,
        Languages:this.state.Languages,
        JobStatus:this.state.JobStatus,
        StaffingTypename:this.state.StaffingTypename,
        StaffingTypeid:this.state.StaffingTypeid,
        pincode:this.state.pincode,
        Additionalskills:"",
        
  
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getJobposting", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                this.setState({updatemsg:response.message})
              
             
              console.log(response.message)
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
            

                    
                  });
    }
   
      }
  handleUserstatus(e){
    this.setState({cstatus:e.value}) 
  }
  handleIndustry(e){
    this.setState({IndustryName:e.label,IndustryID:e.value})
  }
  handleRequired(e){
    this.setState({ReqDocument:e.value})
  }
  handleReport(e){
    this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
  }
  handleCity(e){
    this.setState({Cityname:e.label,Cityid:e.value})
  }
  handleState(e){
    this.setState({Statename:e.label,Stateid:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      })
  }
  handleCountry(e){
    this.setState({Countryname:e.label,Countryid:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }
  handleRowClicked(row){
    //console.log(row.id)
    //console.log(row)
    if(row.pipelinecount>=1 && row.submissioncount>=1){
      console.log(row)
      localStorage.setItem("PipCont",row.id)
      localStorage.setItem("SubCont",row.id)
     }
    else if(row.pipelinecount>=1){
      localStorage.setItem("PipCont",row.id)
      localStorage.removeItem("SubCont")
    }
   else if(row.submissioncount>=1){
    localStorage.setItem("SubCont",row.id)
    localStorage.removeItem("PipCont")
   }
  
   else{
    localStorage.removeItem("PipCont")
    localStorage.removeItem("SubCont")
   }
   
  }
  handleChangemobile = (e) => {
    var regex = /[a-z,A-Z,e,!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
    e.target.value = e.target.value.replace(regex, "");
    if(e.target.value.length!=10){
      this.setState({mobileerror:"Contact number should be digits only."})
    }
    else{
      this.setState({
        [e.target.name]: e.target.value,
        mobileerror:""
      });
    }
  
    
  };
  handleCancel(){
    this.setState({displayform:0})
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (ApplicantID) => {
   
    this.setState({editAppliantid:ApplicantID})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addAppliantdata:"getsingleApplicant",editAppliantid:ApplicantID}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
            
              Honorific:e.Honorific,
              fullname:e.fullname,
              Gender:e.Gender,
              EmailID:e.EmailID,
              NoticePeriod:e.NoticePeriod,
              AlternateEmail:e.AlternateEmail,
              MobileNo:e.MobileNo,
              AlternateMobile:e.AlternateMobile,
              DateofBirth:e.DateofBirth,
              PreviousOrg:e.PreviousOrg,
              PrevDesignation:e.PrevDesignation,
              TotalExp:e.TotalExp,
              RelevantExp:e.RelevantExp,
              HighestDegree:e.HighestDegree,
              CurrentCTC:e.CurrentCTC,
              ExpectedCTC:e.ExpectedCTC,
              VariableAmount:e.VariableAmount,
              FixedAmount:e.FixedAmount,
              Address:e.Address,
              CountryID:e.CountryID,
              CountryName:e.CountryName,
              StateID:e.StateID,
              StateName:e.StateName,
              CityID:e.CityID,
              CityName:e.CityName,
              SourceID:e.SourceID,
              SourceName:e.SourceName,
              JobTitle:e.JobTitle,
              Availablity:e.Availablity,
              NationalityID:e.NationalityID,
              NationalityName:e.NationalityName,
              Relocate:e.Relocate,
              GovtIDType:e.GovtIDType,
              GovtIDNumber:e.GovtIDNumber,
              IndustryID:e.IndustryID,
              IndustryName:e.IndustryName,
              linkedInurl:e.linkedInurl,
              KeySkills:e.KeySkills,
              Remarks:e.Remarks,
              AppStatus:e.AppStatus,
              AppStatusnam:e.AppStatusname,
              resume_name:e.resume_name,
              ProfilePhoto:e.ProfilePhoto,
              ApplicantName:e.fullname,
              SourceName:e.SourceName,
              preferredlocation:e.preferred_location,
              currentlocation:e.location

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };

  handlePageChange=(pageNumber)=>{
    this.setState({loadingicon:0,firstRow:pageNumber})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
    const repdata={
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:pageNumber,
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1"
    }
    //console.log(repdata)
    //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
         
           let data=response.data
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
                
              });
              this.setState({ original_rows: data, 
                rows: data,loadingicon:1,
                totalRecords:response.paging.totalRecords,
                ShowingPageStart:response.paging.ShowingPageStart,
                ShowingPageEnd:response.paging.ShowingPageEnd
              });
            
                
            });
   

   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }
  renderCustomPagination = ({ rowsPerPage, onRowsPerPageChange, page, onPageChange }) => {
    const totalRows = this.state.totalRecords;
    const firstRow = this.state.ShowingPageStart;
    //const lastRow = Math.min(firstRow + rowsPerPage - 1, totalRows);
    const lastRow=this.state.ShowingPageEnd;
    return (
      <div className="custom-pagination text-center">
        Showing {firstRow} - {lastRow} of {totalRows}
        <Pagination
                      offset={0}
                      pageCount={
                        totalRows /
                        25
                      }
                      onPageChangeApplicant={this.handlePageChange}
                    />
         {/* <ul className="pagination">
          {pageNumbers.map((pageNumber) => (
            <li key={pageNumber} className={pageNumber === page ? 'active' : ''}>
              {pageNumber === '...' ? (
                pageNumber
              ) : (
                <button onClick={() => this.handlePageChange(pageNumber)}>{pageNumber}</button>
              )}
            </li>
          ))}
        </ul> */}
      </div>
    );
  };


  componentDidMount() {
    
         /* Client API Call Start */
         const cdata={ dropdownlist_input: "getclientDropinfo"
        }
         console.log(cdata)
         fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
             method: "POST",
     
             body: JSON.stringify(cdata), // data can be `string` or {object}!
     
             headers: { "Content-Type": "application/json" },
           })
             .then((res) => res.json())
     
             .catch((error) => console.error("Error:", error))
     
             .then((response) => {
                 
              const data = response.result
               //console.log(json)
         
             const options = data.map(d => ({
                 "value" : d.clientid,
                 "label" : d.clientname
     
               })) 
        
             this.setState({clientdata:options})
                   
                 });
         /* Client API Call  End */
     /* Language API Call Start */
     const ledata={ dropdownlist_input: "getlanguagesinfoinfo"}
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
 
         body: JSON.stringify(ledata), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.id,
             "label" : d.name
 
           })) 
    
         this.setState({languagesdata:options})
               
             });
            
     /*Language  API Call End */

      /* Degree API Call Start */
      const degdata={ dropdownlist_input: "getdegreesinfo"}
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(degdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({degreedata:options})
                
              });
             
      /*Language  API Call End */
/* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
/*Category API Call Start */
const data={ dropdownlist_input: "getclientcategoryinfo"}
console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(data), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.categoryid,
       "label" : d.categoryname

     })) 

   this.setState({catageroydata:options})
         
       });
/* Category API Call End */
 /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */

  /* Country API Call Start */
  const tdata={ dropdownlist_input: "getcountryinfo"
 }
  console.log(tdata)
  fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          console.log(response)
       const data = response.result
        //console.log(json)
  
      const options = data.map(d => ({
          "value" : d.countryid,
          "label" : d.conuntryname

        })) 
 
      this.setState({countrydata:options})
            
          });
  /* Country API Call  End */

   /* Reporting To API Call Start */
   var username=JSON.stringify(localStorage.getItem("UserId"))
   const withoutQuotes1 = username.replaceAll('"', '');
   const cdata1={ userid: withoutQuotes1,
     adduserdata:"getteamsinfo"
   }
  
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(cdata1), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({teamdata:options})
              
            });
    /* Reporting To  API Call End */



     
    // var username=JSON.stringify(localStorage.getItem("UserId"))
    // const withoutQuotes = username.replaceAll('"', '');
    // console.log(withoutQuotes)
    // const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
    // console.log(repdata)
    // fetch("https://www.mrecruit.in/api/getApplicantdetails", {
    //     method: "POST",

    //     body: JSON.stringify(repdata), // data can be `string` or {object}!

    //     headers: { "Content-Type": "application/json" },
    //   })
    //     .then((res) => res.json())

    //     .catch((error) => console.error("Error:", error))

    //     .then((response) => {
    //      console.log(response.result)
        
    //        let data=response.result
    //           data = data.map((currentValue, Index) => {
    //             currentValue.SNO = Index + 1;
    //             return currentValue;
                
    //           });
    //           this.setState({ original_rows: data, rows: data,loadingicon:1 });
            
                
    //         });
   

    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
    const repdata={
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:"1",
      searchTerm:this.state.search,
      records:"25",
      statuscode:"1"
    }
    //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
         
           let data=response.data
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
                
              });
              this.setState({ original_rows: data, 
                rows: data,loadingicon:1,
                totalRecords:response.paging.totalRecords,
                ShowingPageStart:response.paging.ShowingPageStart,
                ShowingPageEnd:response.paging.ShowingPageEnd
              });
            
                
            });


    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }


  handleLocationInputChange(event) {
    this.setState({ glocation:event.target.value });
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }


  handleSearch(){
    const { search,firstRow }=this.state;
    this.setState({loadingicon:0})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    //console.log(withoutQuotes)
    //const repdata={userid:withoutQuotes,addAppliantdata:"getAppliantinfo",statuscode:1}
    const repdata={
      userid:withoutQuotes,
      addAppliantdata:"getAppliantinfo",
      page:firstRow,
      searchTerm:search,
      records:"25",
      statuscode:"1"
    }
    //console.log(repdata)
    //fetch("https://www.mrecruit.in:5200/api/getApplicantdetails", {
      fetch("https://mrecruit.in/api/getApplicantdetails_v2", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         console.log(response)
         
           let data=response.data
              data = data.map((currentValue, Index) => {
                currentValue.SNO = Index + 1;
                return currentValue;
                
              });
              this.setState({ original_rows: data, 
                rows: data,loadingicon:1,
                totalRecords:response.paging.totalRecords,
                ShowingPageStart:response.paging.ShowingPageStart,
                ShowingPageEnd:response.paging.ShowingPageEnd
              });
            
                
            });
   

  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const columns=this.state.original_rows[0] && Object.keys(this.state.original_rows[0])
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).toString().toLowerCase().includes(this.state.search.toLowerCase()) 
    //(e)=>JSON.stringify(e).toLowerCase().indexOf(this.state.search.toLowerCase())>-1
    
    
    );

   
    this.setState({ rows: temp_rows });
  }
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    var regex = /[!@#\$%\^\&*\)\(+=._-`;,:<>-]/g;
    e.target.value = e.target.value.replace(regex, "");
    this.setState({
      [e.target.name]: e.target.value,
      jderror:"",
      jobcodeerror:"",
      payraterror:"",
      minexperror:"",
      maxexperror:"",
      primaryskillserror:""
    });
    
  };
  render() {
   
    const tooltipStyle = {
      display: this.state.hover ? 'inline' : 'none',
      fontSize:"10px",
      marginLeft:"-1%",
      backgroundColor:"black",
      color:"white",
      fontFamily:"Poppins Light",
      fontWeight:"bold",
      padding:"0.5%"
    }
    const arrowleft = {
      width: "0px",
      height: "0px",
      borderTop: "7px solid transparent",
      borderBottom: "6px solid transparent",
      borderRight: "15px solid black",
      display: this.state.hover ? 'inline-block' : 'none',
  }
    return (
      <>
         {/**Send Email Modal starts */}
         {this.state.sendEmailmodal && (
        <div class="ft-modal" id="ft-sendemail-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"80%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                     
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                   Send Email
                  </h5>
          
           <hr/>
            </div>
           <div className="ft-modal-body">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                            <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>From</span>
                            </div>
                          <div className="col-md-11">
                          <input type="text" value="System (jobs@maintec.in)" disabled className="form-control" />
                          </div>
                       {/**   <div className="col-md-2">
                          <button style={{color:"white",marginTop:"1%",width:"100%"}} className="btn updatebtn">Send</button>
                          </div> */}
                          </div>
                          <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>TO</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                            <textarea className="form-control" disabled rows="3" style={{fontSize:12}} value={this.state.Emaildata.toString()}>

                            </textarea>
                         
                          
                         <span  style={{color:"#007bff",fontSize:14}}>(Separate emails will be sent to each applicant)</span>
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13,textDecoration:"underline",color:"#4293FE",cursor:"pointer"}} onClick={this.handleBCC}>CC</span>
                            </div>

                         
                          </div>
{this.state.bccscreen==1?<>
  <div className="row" style={{background:"#e9ecef",padding:"1.5%"}}>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>BCC</span>
                            </div>
                          <div className="col-md-10">
                          <div class="input-group mb-3">
                            <textarea className="form-control"  rows="1" style={{fontSize:12,backgroundColor:"#e9ecef"}} onChange={this.handleChange}>

                            </textarea>
                         
                          
                        </div>
                           
                       
                          </div>
                          <div className="col-md-1">
                            <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:20,textDecoration:"underline",color:"red",cursor:"pointer"}} onClick={this.handleBCCclose}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                            </div>
                         
                          </div>
</>:null}

                        </div>
                       

                      </div>

                      <div className="row mt-5">
                        
                        <div className="col-xl-12">
                        <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}    
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
       
        }}
      />
                        </div>

                      </div>
                      <div className="row mt-3">
                        <div className="col-xl-2">
                        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Attachment</span>
                        </div>
              <div className="col-xl-6" style={{border:"1px solid"}}>
                <input type="file" accept=".pdf" onChange={this.handleAttachment} name="profilepic" />

              </div>
                      </div>

                      {this.state.dummytext==""?<></>:<>
                      <div className="text-center mt-3">
<span style={{color:"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.dummytext}</span>

</div>
        
                      </>}
                <div className="mt-5 row">
                      <div className="col-xl-8"></div>
                      <div className="col-xl-2">
                        {this.state.secondarybtn==0?<><button className="btn updatebtn"  style={{color:"white",marginTop:"5%",width:"100%"}} onClick={this.handleEmail}>Send</button></>:<><button className="btn updatebtn"  style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}} disabled >Send</button></>}
                         
</div><div className="col-xl-2"> <button className="btn updatebtn" onClick={this.closeSendEmailModal} style={{color:"black",marginTop:"5%",backgroundColor:"#F3F0F0",border:"1px solid",width:"100%"}}>Cancel</button>
</div>
                     
                     </div>
<div className="text-center mt-3">
<span style={{color:this.state.successmsg=="Email Body Can't be empty."?"red":"#00539C",fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.successmsg}</span>

</div>
           </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeSendEmailModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Send Email Modal ends */}

      {this.state.showModal && (
         <div class="ft-modal" id="ft-edituser-modal" style={{top:"0%"}}>
         <div class="ft-modal-content  bigmodalcontent" >
           <div class="ft-modal-header">
             
             <h5
                   class="modal-title"
                   id="exampleModalLabel"
                   style={{
                     textAlign: "center",
                     fontSize: 25,
                     fontWeight: "bold",
                   }}
                 >
                   Edit Applicant Information
                 </h5>
               <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
          
           </div>
           <div class="ft-modal-body">
          
           <div className="row">
            

               <div
                 className="col-xl-12"
               
               >
                 {/** Personal Details Starts */}
                <div className="row">
                <div className="col-xl-1">
                
                {this.state.ProfilePhoto == " " ? (
                        <>
                          <img src={Applicantimg} style={{ width: "100%" }} />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            src={`https://www.mrecruit.in/sysload/pdf/${this.state.ProfilePhoto}`}
                            style={{
                              width: "135%",
                              border: "2px solid #4293fe",
                            }}
                          />
                        </>
                      )}
                      </div>
                     <div className="col-xl-2 text-center">
                      <input
                        id="upload"
                        type="file"
                        name="ProfilePhoto"
                        onChange={this.handleImage}
                        hidden

                      />
                      <label id="actual-btn" for="upload">
                        Upload
                      </label>
                    </div>
                    <div className="col-xl-9">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Candidate Full Name</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input name="ApplicantName" 
                   value={this.state.ApplicantName}
                   onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" placeholder="Enter Full Name"/>    
                   </div>
                   
                </div>
                 <div className="row mt-2">
                  
                   
               
                   <div className="col-xl-4">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Key Skills</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input  value={this.state.KeySkills}  name="KeySkills" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Key Skills" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-4">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}} >Gender</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span> <br/>
                   <Select 
                   placeholder={this.state.Gender}
      onChange={(e)=>this.handleGender(e)}
      options={this.state.Genderdata}  components={{  IndicatorSeparator:() => null }}
                                styles={style}/>   
                   </div>
                   
                   <div className="col-xl-4">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Email ID</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input style={{height:"38px"}} 
                   value={this.state.EmailID} disabled
                   name="EmailID" onChange={this.handleChange} className="form-control adduser mt-1" type="email" placeholder="Email ID"/> 
                   </div>
                 </div>
                 <div className="row mt-2">
                
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Email ID</span>
                   <input name="AlternateEmail"
                   value={this.state.AlternateEmail} onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="email" placeholder="Alternate Email ID"/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Mobile Number</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input name="MobileNo" onChange={this.handleChange} style={{height:"38px"}} className="form-control 
                   
                   adduser mt-1"value={this.state.MobileNo} type="email" placeholder="Mobile Number"/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Alternate Mobile Number</span>
                   <input name="AlternateMobile" 
                   value={this.state.AlternateMobile}
                   onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" placeholder="Alternate Mobile Number"/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Date Of Birth</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input   value={this.state.DateofBirth} name="DateofBirth" onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="date"/>  
                   </div>
                 </div>
                 <div className="row mt-2">
                 
                 
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>LinkedIn Profile URL</span>
                   <input name="linkedInurl" value={this.state.linkedInurl}  onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter LinkedIn URL"/>  
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Nationality</span>
                   <Select 
                   onChange={(e)=>this.handleNationality(e)}
                   placeholder={this.state.NationalityName}
                   options={this.state.nationdata}  components={{  IndicatorSeparator:() => null }}
                                             styles={style}/>    
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Source</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <Select 
                   placeholder={this.state.SourceName}
      onChange={(e)=>this.handleSource(e)}
      options={this.state.sourcedata}  components={{  IndicatorSeparator:() => null }}
                                styles={style}/>    
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Industry</span>
                   <Select
                   placeholder={this.state.IndustryName} 
      onChange={(e)=>this.handleIndustry(e)}
      options={this.state.industrydata}  components={{  IndicatorSeparator:() => null }}
                                styles={style}/>
                   </div>
                 </div>
                 <div className="row mt-2">
               
                  
                 
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Open To Reclocate</span><br/>
                   {this.state.Relocate=="Yes"?<> <input type="radio"  name="Relocate" checked onChange={this.handleChange}  value="Yes"/>&nbsp;&nbsp;Yes&nbsp;&nbsp;</>:<><input type="radio"  name="Relocate"  onChange={this.handleChange}  value="Yes"/>&nbsp;&nbsp;Yes&nbsp;&nbsp;</>}
                  {this.state.Relocate=="No"?<>  <input type="radio" className="mt-2" name="Relocate" onChange={this.handleChange} checked value="No" />&nbsp;&nbsp;No</>:<>  <input type="radio" className="mt-2" name="Relocate" onChange={this.handleChange} value="No" />&nbsp;&nbsp;No</>}
                 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current / Last Organization</span>
                   <input className="form-control adduser mt-1"
                   value={this.state.PreviousOrg} 
                   name="PreviousOrg" onChange={this.handleChange}
                   placeholder="Previous Organization" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Preferred Location</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                    <input value={this.state.preferredlocation} name="preferredlocation"  type="text" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Enter Preferred Location" style={{height:"38px"}}/>
                    </div>
                   
                   <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current Location</span>     <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span><br/>
                    <input name="currentlocation" value={this.state.currentlocation} onChange={this.handleChange} style={{height:"38px"}} className="form-control adduser mt-1" type="text" placeholder="Enter Current Location"/> 
                    </div>
                 </div>
                 <div className="row mt-2">
             
                
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Notice Period</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <Select 
       onChange={(e)=>this.handleNoticePeriod(e)}
       options={this.state.Noticeperiod}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                   </div>
                           <div className="col-xl-3">
                           <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Availablity</span>
                   <input value={this.state.Availablity}  name="Availablity" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Availablity" style={{height:"38px"}} />
                           </div>
                 
                           <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Previous Designation</span>
                   <input  value={this.state.PrevDesignation}  className="form-control adduser mt-1"
                   name="PrevDesignation" onChange={this.handleChange} 
                   placeholder="Previous Designation" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Total Experience</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input value={this.state.TotalExp}   name="TotalExp" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Total Experience" style={{height:"38px"}} />  
                   </div>
                 </div>
                 <div className="row mt-2">
                
                  
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Relevant Experience</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input value={this.state.RelevantExp}  name="RelevantExp" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Relevant Experience" style={{height:"38px"}}/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Highest Degree</span>
                   <input value={this.state.HighestDegree} name="HighestDegree" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Highest Degree" style={{height:"38px"}}/>
                   </div>
                 
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Current CTC</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                   <input value={this.state.CurrentCTC}  name="CurrentCTC" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Current CTC" style={{height:"38px"}} />  
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Expected CTC</span>
                   <input  value={this.state.ExpectedCTC} name="ExpectedCTC" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Expected CTC" style={{height:"38px"}} />  
                   </div>
                 </div>
                 <div className="row mt-2">
               
                  
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Variable Amount</span>
                   <input value={this.state.VariableAmount} name="VariableAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="VariableAmount" style={{height:"38px"}}/> 
                   </div>
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Fixed Amount</span>
                   <input value={this.state.FixedAmount} name="FixedAmount" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Fixed Amount" style={{height:"38px"}}/>
                   </div>
                   <div className="col-xl-3">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Type</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>
                            <Select 
                            placeholder={this.state.GovtIDType}
              onChange={(e)=>this.handleDoc(e)}
              options={this.state.docdata}  components={{  IndicatorSeparator:() => null }}
                                        styles={style}/>
                    </div>
                
                   <div className="col-xl-3">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>GovtID Number</span>
                   <input value={this.state.GovtIDNumber} name="GovtIDNumber" onChange={this.handleChange}  className="form-control adduser mt-1" placeholder="GovtID Number" style={{height:"38px"}}/> 
                   </div>
                
                 </div>
                 <div className="row mt-2">
                  <div className="col-xl-12">
                    <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Upload Candidate Resume</span>    <span style={{fontWeight:"bold",fontFamily:"Montserrat",color:"red",fontSize:14}}>*</span>&nbsp;&nbsp;<span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13,color:"red"}}>(You can Upload only PDF File)</span>
                      <br/>
                     <div className="adduser text-center" style={{padding:"0.5%"}}>
                     <input
                        id="upload"
                        type="file"
                        
                       style={{padding:"0.5%",margin:"1%",background:"#4293fe",color:"white"}}
                        onChange={this.handleImageone}
                        accept=".pdf"
                      />
                     <br/>
                     <span style={{fontFamily:"Inter",fontWeight:"bold",color:"red",fontSize:14}}>{this.state.fileerrormsg}</span>
                     </div>
                    </div>
                  </div>
                 <div className="row mt-2">
                   <div className="col-xl-12">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Address</span>
                    <textarea  placeholder={this.state.Address} name="Address" onChange={this.handleChange} className="adduser form-control mt-1" rows="2">

                    </textarea>
                   </div>
                 </div>
                 <div className="row mt-2">
                   <div className="col-xl-12">
                   <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Remarks</span>
                    <textarea placeholder={this.state.Remarks} name="Remarks" onChange={this.handleChange} className="adduser form-control mt-1" rows="2">

                    </textarea>
                   </div>
                 </div>
                 <div className="row mt-3">
                   <div className="col-xl-10" >
                           
                   </div>
                           <div className="col-xl-1" style={{textAlign:"right"}}>
                            {this.state.fileerrormsg=="File Size should not be more than 500kb"?<>  <button className="btn btn-secondary updatebtn" style={{marginTop:"2%"}} disabled>Update</button></>:<>
                            <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={this.handleSave}>Update</button>
                            </>}
                         
                           </div>
                           <div className="col-xl-1" style={{textAlign:"right"}}>
                           <button className="btn btn-primary updatebtn" style={{marginTop:"2%"}} onClick={this.closeModal}>Close</button>
                           </div>
                 </div>
                 <div className="row">
     <div className="col-xl-4"></div>
     <div className="col-xl-4 mt-3">
       <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
     </div>
     <div className="col-xl-4"></div>
  </div>
                 {/**Personal Details Ends*/}
               </div>
             </div>
       {/*New Applicant End*/}
                   </div>
           <div class="ft-modal-footer">
             <a class="ft-modal-close"  data-dismiss="modal"
                      onClick={this.closeModal}>
               <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
             </a>
           </div>
         </div>
       </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>

        <Row className={styles.marginBottom1}>
            <Col md={3}>
              
              <div class="input-group">
    <input type="text" value={this.state.search} class="form-control applicants-filter" placeholder="Search"  onChange={this.handleInputChange}
                />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button"
                onClick={this.handleSearch}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
        <Col md={3} className="margin-left-css">
            <Select 
           // onChange={(e)=>this.handleSubmissionsearch(e)}
            options={this.state.Noticeperiod} 
            onChange={(e)=>this.handleNoticePeriodChange(e)}
            components={{  IndicatorSeparator:() => null }}
            styles={NoticePeriod_style}
            value={this.state.gnoticeperiod}
            placeholder="Select Notice Period"
            />
      </Col>
     
      <Col md={2} className="margin-left-css">
      <div class="input-group">
      <input type="text" class="form-control applicants-filter" 
             placeholder="Location" 
             onChange={this.handleLocationInputChange}
             value={this.state.glocation}
                />
                {/* <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button"
                onClick={this.handleSearch}>
        <i class="fa fa-search"></i>
      </button>
    </div> */}
      </div>      
        </Col>
        <Col md={1} className="margin-left-css">
        <DatePicker 
        placeholderText="Form Date"
        className="formdate"
        selected={this.state.fromdate}
        onChange={(date)=>this.handleDateChange(date)}
        dateFormat="dd/MM/yyyy"
        />
      </Col>
      <Col md={1} style={{marginLeft:"2%"}}>
        <DatePicker 
        placeholderText="To Date"
        className="formdate"
        selected={this.state.gtodate}
        onChange={(date)=>this.handleTODateChange(date)}
        dateFormat="dd/MM/yyyy"
        />
      </Col>
      <Col md={1} style={{marginLeft:"2%"}}>
      <button className="btn applicant-search-btn" onClick={this.handleSubmissionsearch}>
        Search
      </button>
      </Col>
      <Col md={1} className="margin-left-css">
      <button className="applicant-reset-btn" onClick={this.handleReset}>
        Clear All
      </button>
      </Col>
          </Row>
      <Row className="applicant-send-email-row">
      <Col md={4}>
          {this.state.sendemaildisplayscreen?<>
              <a href="#ft-sendemail-modal" onClick={()=>this.handleOpenSendEmail()}>
             <span 
             className="send-emailtext-applicants">Send Email</span>
             </a>
            </>:<>
          
            </>}         
      </Col>
      </Row>


      <div className={styles.clientContainer_old}>
            <div className={styles.tableContainer}>
           {this.state.loadingicon==0?<>
            <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
           </>:null
          }
           {this.state.loadingicon==1?<>
            <DataTable
                paginationComponent={this.renderCustomPagination}
                paginationPerPage={25}
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
                expandableRows
                selectableRows={true}
                selectableRowsHighlight
                onSelectedRowsChange={(e,row)=>this.handleCheckbox(e,row)}
                selectableRowsComponent={Checkbox}
                expandableRowsComponent={<Pipelinedata/>}
                onRowClicked={this.handleRowClicked}
                expandableRowDisabled={this.handleDisable }
                customStyles={tableCustomStyles}
                expandOnRowClicked
                responsive
                onRowExpandToggled={( row,e) => {   
                if(e.pipelinecount>=1 && e.submissioncount>=1){
                localStorage.setItem("PipCont",e.id)
                localStorage.setItem("SubCont",e.id)
               }
              else if(e.pipelinecount>=1){
                localStorage.setItem("PipCont",e.id)
                localStorage.removeItem("SubCont")
              }
             else if(e.submissioncount>=1){
              localStorage.setItem("SubCont",e.id)
              localStorage.removeItem("PipCont")
             }
            
             else{
              localStorage.removeItem("PipCont")
              localStorage.removeItem("SubCont")
             } }}
                expandableIcon={{
                  collapsed:
                 
                 <img   src={`${PlusincircleIcon}`} 
                  style={{width:"60%"}} />
                 
            
                  ,
                  expanded: <img src={MinusincircleIcon} style={{width:"60%"}} />,
                  
                }}
               
              />
           </>:null
          }   
             {this.state.loadingicon==2?<>
             <br/><br/><br/>
            <span className="nodatafound-msg">{this.state.nodatafoundmsg}</span>
            <br/><br/><br/><br/>
           </>:null
          }
            </div>
          </div>
          
        </div>
      </div>
      </>
    );
  }
}

export default Activeapplicant;
