import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "./style.css";
import { loadCSS } from "fg-loadcss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styles from "./App.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select'
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import LoadingIcon  from "../images/loadingicon.gif";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};

function Deleteclient(id){
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      console.log(withoutQuotes)
      const data={userid:withoutQuotes,addjobpostingdata: "deletejobposting",editjobid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
                  Swal.fire(
                    'Deleted!',
                     response.message,
                    'success'
                  )
                  setTimeout(
                    function() {
                      window.location.reload()
                    }
                    .bind(this),
                    3000
                );
              });
     
    }
  })
}

function EditUser(e,id){
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[3]
  var data2=splitURL[4]
  var data3=splitURL[5]
  let data=window.location.pathname.slice(0,12);
  let encodedid=window.btoa(id)
  //window.location.href=`${data}/edituser/${encodedid}/${data1}/${data2}/${data3}`
}


function ViewJob(id){
  console.log(id)
  let data=window.location.pathname.slice(0,0);
  var newURL=window.location.pathname;
  var splitURL=newURL.toString().split("/");
  var data1=splitURL[2]
  var data2=splitURL[3]
  var data3=splitURL[4]
  var data5=splitURL[5]
  let encodedjobid=window.btoa(id)
  window.location.href=`${data}/viewjob/${data1}/${data2}/${data3}/${data5}/${encodedjobid}`
}
class DeletedJobposting extends React.Component {
  constructor() {
   
    super();
    this.state={
      showModal: false,
      on:0,
      clientlogo:" ",
      rolesdata:[],
      teamdata:[],
      reportingtodata:[],
      updatemsg:"",
      countrydata:[],
      citydata:[],
      industrydata:[],
      catageroydata:[],
      Countryname:"",
      Countryid:"",
      statedata:[],
      reportingtodata:[],
      BusinessUnit:"",
      editclientid:"",
      reportingtodata:[],
      languagesdata:[],
      degreedata:[],
      JobTitle:"",
      JobCode:"",
      ClientName:"",
      ClientId:"",
      CBR_Coin:"",
      CBR_Amount:"",
      PR_Coin:"",
      PR_MinAmount:"",
      PR_MaxAmount:"",
      CountryID:"",
      CountryName:"",
      StateID:"",
      StateName:"",
      CityID:"",
      CityName:"",
      ClientJobID:"",
      NoofPositions:"",
      ClosedPostions:"",
      JobCreatedDate:"",
      JobClosingDate:"",
      SPOCMobile:"",
      SPOCName:"",
      HiringManagerid:"",
      HiringManager:"",
      AssingnedID:"",
      AssingnedName:"",
      BusinessUnit:"",
      NoticePeriod:"",
      JobDescription:"",
      DegreeID:"",
      Degreename:"",
      ExperienceMin:"",
      ExperienceMax:"",
      PrimarySkills:"",
      Languages:"",
      JobStatus:"",
      jobstatusname:"",
      CreatedBy:"",
      userstausdata:[
        { value: '1', label: 'Active'},
       
     
       
      ],
    editjobid:"",
    loadingicon:0,
    dataerrormsg:""
    }
   
    this.columns = [
        {
            name: "Job ID",
            selector: "JobCode",
            sortable: true
          },
          {
            name:"Job Title",
          cell: (e) => (
           
              <>
              
           <span onClick={() =>ViewJob(e.jobid)} style={{fontFamily:"Inter",fontWeight:"bold",color:"#4293FE",textDecoration:"underline",verticalAlign:"sub",cursor:"pointer",fontSize:12}}>{e.JobTitle}</span>
        
            </>
          ),
          
          
        },
          {
            name: "Client",
            selector: "ClientName",
            sortable: true
          },
         
          {
            name: "No of Positions",
            selector: "NoofPositions",
            sortable: true
          },
          {
            name: "Closed Postions",
            selector: "ClosedPostions",
            sortable: true
          },
          {
            name: "Location",
            selector: "location",
            sortable: true
          },
         
         
         
    ];
    this.state = { original_rows: [], rows: [], search: "" };
    this.columns_data_for_export = this.columns
      .slice(0, this.columns.length)
      .map((d) => d.name);
    this.rows_data_for_export = this.state.rows.map((d1) =>
    
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
        
    );
    const temp_rows = this.state.rows.map((d1) =>
    this.columns
      .slice(0, this.columns.length)
      .map((d2) => d2.selector)
      .map((d3) => d1[d3])
  );
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.do_search = this.do_search.bind(this);
    this.download_pdf = this.download_pdf.bind(this);
    this.handleAdmin=this.handleAdmin.bind(this)
    this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleRoles=this.handleRoles.bind(this)
    this.handleTeamdata=this.handleTeamdata.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
this.handleUpdate=this.handleUpdate.bind(this)
    this.handleCountry=this.handleCountry.bind(this)
    this.hanldelState=this.handleState.bind(this)
    this.handleCity=this.handleCity.bind(this)
    this.handleReport=this.handleReport.bind(this)
    this.handleRequired=this.handleRequired.bind(this)
    this.handleIndustry=this.handleIndustry.bind(this)
    this.handleUserstatus=this.handleUserstatus.bind(this)
    this.handleBusiness=this.handleBusiness.bind(this)
    this.handleCategory=this.handleCategory.bind(this)
  }

  handleCategory(e){
    this.setState({CategoryName:e.label,categoryid:e.value},()=>{
     
    })
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label},()=>{
     
    })
  }
  handleUpdate(){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    const data={
      userid:withoutQuotes,
      addjobpostingdata:"editjobpostinginfo",
      editjobid:this.state.editjobid,
      JobCode:this.state.JobCode,
      JobTitle:this.state.JobTitle,
      ClientId:this.state.ClientId,
      AssingnedID:this.state.AssingnedID,
      AssingnedName:this.state.AssingnedName,
      ClientName:this.state.ClientName,
      CBR_Coin:this.state.CBR_Coin,
      CBR_Amount:this.state.CBR_Amount,
      PR_Coin:this.state.PR_Coin,
      PR_MinAmount:this.state.PR_MinAmount,
      PR_MaxAmount:this.state.PR_MaxAmount,
      CountryID:this.state.CountryID,
      CountryName:this.state.CountryName,
      StateID:this.state.StateID,
      StateName:this.state.StateName,
      CityID:this.state.CityID,
      CityName:this.state.CityName,
      ClientJobID:this.state.ClientJobID,
      NoofPositions:this.state.NoofPositions,
      ClosedPostions:this.state.NoofPositions,
      JobCreatedDate:this.state.JobCreatedDate,
      JobClosingDate:this.state.JobClosingDate,
      SPOCName:this.state.SPOCName,
      SPOCMobile:this.state.SPOCMobile,
      HiringManagerid:this.state.HiringManagerid,
      HiringManager:this.state.HiringManager,
      NoticePeriod:this.state.NoticePeriod,
      BusinessUnit:this.state.BusinessUnit,
      JobDescription:this.state.JobDescription,
      DegreeID:this.state.DegreeID,
      Degreename:this.state.Degreename,
      ExperienceMin:this.state.ExperienceMin,
      ExperienceMax:this.state.ExperienceMax,
      PrimarySkills:this.state.PrimarySkills,
      Languages:this.state.Languages,
      JobStatus:this.state.JobStatus

    }
    console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getJobposting", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
              this.setState({updatemsg:response.message})
            
           
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
               
      
                  
                });
      }
  handleUserstatus(e){
    this.setState({cstatus:e.value}) 
  }
  handleIndustry(e){
    this.setState({Industryname:e.label,Industryid:e.value})
  }
  handleRequired(e){
    this.setState({ReqDocument:e.value})
  }
  handleReport(e){
    this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
  }
  handleCity(e){
    this.setState({Cityname:e.label,Cityid:e.value})
  }
  handleState(e){
    this.setState({Statename:e.label,Stateid:e.value,},()=>{
      const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({citydata:options})
                
              });
      })
  }
  handleCountry(e){
    this.setState({Countryname:e.label,Countryid:e.value,},()=>{
      const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({statedata:options})
                
              });
      })
  }
  handleRowClicked(row){
    //console.log(row.id)
   localStorage.setItem("JobId",row.id)
  }
  handleCancel(){
    this.setState({displayform:0})
  }
  handleRoles(e){
this.setState({userrolename:e.label,userrole:e.value},()=>{
  //console.log(this.state.userrole)
})
  }
  handleBusiness(e){
    this.setState({BusinessUnit:e.label,},()=>{
     
    })
      }
      handleTeamdata(e){
        this.setState({teamname:e.label,teamids:e.value},()=>{
          
        })
          }
  handlecloseAdmin(){
    this.setState({on:0,adminprivileges:"No"})
  }
  handleAdmin(){
this.setState({on:1,adminprivileges:"Yes"})
  }
  openModal = (id) => {
    this.setState({editjobid:id})
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
      const data={ userid:withoutQuotes,addjobpostingdata:"getsinglejobinfo",editjobid:id}
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getJobposting", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
          console.log(response)
          response.result.map((e)=>{
            this.setState({
              JobTitle:e.JobTitle,
              JobCode:e.JobCode,
              ClientId:e.ClientId,
              ClientName:e.ClientName,
              CBR_Coin:e.CBR_Coin,
              CBR_Amount:e.CBR_Amount,
              PR_Coin:e.PR_Coin,
              PR_MinAmount:e.PR_MinAmount,
              PR_MaxAmount:e.PR_MaxAmount,
              CountryID:e.CountryID,
              CountryName:e.CountryName,
              StateID:e.StateID,
              StateName:e.StateName,
              CityID:e.CityID,
              CityName:e.CityName,
              ClientJobID:e.ClientJobID,
              NoofPositions:e.NoofPositions,
              ClosedPostions:e.ClosedPostions,
              JobCreatedDate:e.JobCreatedDate,
              JobClosingDate:e.JobClosingDate,
              SPOCMobile:e.SPOCMobile,
              SPOCName:e.SPOCName,
              HiringManagerid:e.HiringManagerid,
              HiringManager:e.HiringManager,
              AssingnedID:e.AssingnedID,
              AssingnedName:e.AssingnedName,
              BusinessUnit:e.BusinessUnit,
              NoticePeriod:e.NoticePeriod,
              JobDescription:e.JobDescription,
              DegreeID:e.DegreeID,
              Degreename:e.Degreename,
              ExperienceMin:e.ExperienceMin,
              ExperienceMax:e.ExperienceMax,
              PrimarySkills:e.PrimarySkills,
              Languages:e.Languages,
              JobStatus:e.JobStatus,
              jobstatusname:e.jobstatusname,
              CreatedBy:e.CreatedBy

            })
          })
                
              });
     
    this.setState({ showModal: true });
  };
  
  closeModal = () => {
    this.setState({ showModal: false });
  };
  componentDidMount() {
     /* Language API Call Start */
     const ledata={ dropdownlist_input: "getlanguagesinfoinfo"}
     fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
         method: "POST",
 
         body: JSON.stringify(ledata), // data can be `string` or {object}!
 
         headers: { "Content-Type": "application/json" },
       })
         .then((res) => res.json())
 
         .catch((error) => console.error("Error:", error))
 
         .then((response) => {
             
          const data = response.result
           //console.log(json)
     
         const options = data.map(d => ({
             "value" : d.id,
             "label" : d.name
 
           })) 
    
         this.setState({languagesdata:options})
               
             });
            
     /*Language  API Call End */

      /* Degree API Call Start */
      const degdata={ dropdownlist_input: "getdegreesinfo"}
      fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
          method: "POST",
  
          body: JSON.stringify(degdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
              
           const data = response.result
            //console.log(json)
      
          const options = data.map(d => ({
              "value" : d.id,
              "label" : d.name
  
            })) 
     
          this.setState({degreedata:options})
                
              });
             
      /*Language  API Call End */
/* Reporting To API Call Start */
const redata={ dropdownlist_input: "getreportinginfo"}
console.log(redata)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
    method: "POST",

    body: JSON.stringify(redata), // data can be `string` or {object}!

    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())

    .catch((error) => console.error("Error:", error))

    .then((response) => {
        
     const data = response.result
      //console.log(json)

    const options = data.map(d => ({
        "value" : d.reportingid,
        "label" : d.reportingname

      })) 

    this.setState({reportingtodata:options})
          
        });
       
/* Reporting To  API Call End */
/*Category API Call Start */
const data={ dropdownlist_input: "getclientcategoryinfo"}
console.log(data)
fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
   method: "POST",

   body: JSON.stringify(data), // data can be `string` or {object}!

   headers: { "Content-Type": "application/json" },
 })
   .then((res) => res.json())

   .catch((error) => console.error("Error:", error))

   .then((response) => {
       
    const data = response.result
     //console.log(json)

   const options = data.map(d => ({
       "value" : d.categoryid,
       "label" : d.categoryname

     })) 

   this.setState({catageroydata:options})
         
       });
/* Category API Call End */
 /* Industry API Call Start */
 const rdata={ dropdownlist_input: "getindustryinfo"}
 console.log(rdata)
 fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
     method: "POST",

     body: JSON.stringify(rdata), // data can be `string` or {object}!

     headers: { "Content-Type": "application/json" },
   })
     .then((res) => res.json())

     .catch((error) => console.error("Error:", error))

     .then((response) => {
         
      const data = response.result
       //console.log(json)
 
     const options = data.map(d => ({
         "value" : d.id,
         "label" : d.name

       })) 

     this.setState({industrydata:options})
           
         });
 /* Industry  API Call End */

  /* Country API Call Start */
  const tdata={ dropdownlist_input: "getcountryinfo"
 }
  console.log(tdata)
  fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
      method: "POST",

      body: JSON.stringify(tdata), // data can be `string` or {object}!

      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .catch((error) => console.error("Error:", error))

      .then((response) => {
          console.log(response)
       const data = response.result
        //console.log(json)
  
      const options = data.map(d => ({
          "value" : d.countryid,
          "label" : d.conuntryname

        })) 
 
      this.setState({countrydata:options})
            
          });
  /* Country API Call  End */

   /* Reporting To API Call Start */
   var username=JSON.stringify(localStorage.getItem("UserId"))
   const withoutQuotes1 = username.replaceAll('"', '');
   const cdata={ userid: withoutQuotes1,
     adduserdata:"getteamsinfo"
   }
    console.log(tdata)
    fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
        method: "POST",

        body: JSON.stringify(cdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
            
         const data = response.result
          //console.log(json)
    
        const options = data.map(d => ({
            "value" : d.id,
            "label" : d.name

          })) 
   
        this.setState({teamdata:options})
              
            });
    /* Reporting To  API Call End */
     
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    console.log(withoutQuotes)
    const repdata={userid:withoutQuotes,addjobpostingdata:"getjobpostinginfo",statuscode:4}
    console.log(repdata)
    fetch("https://www.mrecruit.in:5200/api/getJobposting", {
        method: "POST",

        body: JSON.stringify(repdata), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {
         if(response.message=="No Data Found."){
          this.setState({dataerrormsg:response.message,loadingicon:2}) 
         }else{
          let data=response.result
          data = data.map((currentValue, Index) => {
            currentValue.SNO = Index + 1;
            return currentValue;
          });
          this.setState({ original_rows: data, rows: data,loadingicon:1 });
          
         }
           
            });
   

    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
      
     
  }

  handleInputChange(event) {
    this.setState({ search: event.target.value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.do_search();
    }
    else{
      this.do_search();
    }
  }

  do_search() {
    const temp_rows = this.state.original_rows.filter(
      (e) => JSON.stringify(e).indexOf(this.state.search) >= 0
    );
    this.setState({ rows: temp_rows });
  }
 
  download_pdf() {
    /**const doc = new jsPDF();

    const temp_rows = this.state.rows.map((d1) =>
      this.columns
        .slice(0, this.columns.length)
        .map((d2) => d2.selector)
        .map((d3) => d1[d3])
    );
    doc.autoTable({
      head: [this.columns_data_for_export],
      body: temp_rows
    });
    console.log(this.columns_data_for_export, temp_rows);
    doc.save("Active_Userslists.pdf"); */

    
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

    });
    
  };
  render() {
   

    return (
      <>{this.state.showModal && (
        <div class="ft-modal" id="ft-edituser-modal">
          <div class="ft-modal-content  bigmodalcontent">
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Edit Job Information
                  </h5>
                <hr style={{marginRight:"-6%",marginLeft:"-4.2%",height:2}} />
           
            </div>
            <div class="ft-modal-body">
           
            {/**Add user for starts */}
 <div>


 <div className="row">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Code</span><br/>
                <input className="form-control adduser mt-1" placeholder="Job Code" name="JobCode" value={this.state.JobCode} onChange={this.handleChange}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Title</span><br/>
                <input className="form-control adduser mt-1" placeholder="Job Title" 
                value={this.state.JobTitle}
                name="JobTitle" onChange={this.handleChange}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Client</span><br/>
                <Select 
                placeholder={this.state.ClientName}
       onChange={(e)=>this.handleClient(e)}
       options={this.state.clientdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>No of Open Positions</span><br/>
                <input type="number" className="form-control adduser
                 mt-1" value={this.state.NoofPositions} placeholder="No of Open Positions" name="NoofPositions" onChange={this.handleChange}/>
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Client Bill Rate</span><br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" value={this.state.CBR_Amount} placeholder="Client Bill Rate" 
                  name="CBR_Amount" onChange={this.handleChange}/>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Pay Rate / Salary </span><br/>
                <div className="row">
                  <div className="col-xl-4">
                  <input className="form-control adduser mt-1" placeholder="INR" disabled/>
                  </div>
                  <div className="col-xl-8">
                  <input className="form-control adduser mt-1" value={this.state.PR_MinAmount} placeholder="Pay Rate / Salary" name="PR_MinAmount" onChange={this.handleChange}/>
                  </div>
                </div>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Country</span><br/>
                <Select 
                placeholder={this.state.CountryName}
       onChange={(e)=>this.handleCountry(e)}
       options={this.state.countrydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>State</span><br/>
                <Select 
                placeholder={this.state.StateName}
       onChange={(e)=>this.handleState(e)}
       options={this.state.statedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
               
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Location</span><br/>
                <Select 
                 placeholder={this.state.CityName}
       onChange={(e)=>this.handleCity(e)}
       options={this.state.citydata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>SPOC Name</span><br/>
               
                  
                  <input className="form-control adduser mt-1" value={this.state.SPOCName} name="SPOCName" onChange={this.handleChange} placeholder="SPOC Name"/>
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>SPOC Mobile</span><br/>
                <input value={this.state.SPOCMobile} className="form-control adduser mt-1" name="SPOCMobile" onChange={this.handleChange} placeholder="SPOC Mobile"/>
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Hiring Manager</span><br/>
                <Select
                placeholder={this.state.HiringManager} 
       onChange={(e)=>this.handleReport(e)}
       options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
                </div>
                </div>
                <div className="row mt-2">
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Business Unit</span><br/>
                <Select 
                placeholder={this.state.BusinessUnit}
    onChange={(e)=>this.handleBusiness(e)}
    options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
                </div>
              
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Creating Date</span><br/>
               
                  
               <input type="date" value={this.state.JobCreatedDate} className="form-control adduser mt-1" />
              
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Closing Date</span><br/>
               
                  
                  <input type="date" value={this.state.JobClosingDate} className="form-control adduser mt-1" placeholder="Job Closing Date"/>
          
               
            
                </div>
                <div className="col-xl-3">
                <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Noticed Period</span><br/>
                <input value={this.state.NoticePeriod} name="NoticePeriod" onChange={this.handleChange} className="form-control adduser mt-1" placeholder="Noticed Period"/>
                </div>
                </div>
 
 
                <div className="row mt-2">
          
             
           
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Status</span><br/>
          <Select 
          placeholder={this.state.jobstatusname}
onChange={(e)=>this.handleUserstatus(e)}
options={this.state.userstausdata}  components={{  IndicatorSeparator:() => null }}
                        styles={style}/>
          </div>
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Experience Min</span><br/>
              <input value={this.state.ExperienceMin} 
              name="ExperienceMin" onChange={this.handleChange}
              className="form-control adduser mt-1" placeholder="Experience Min"/>
          </div>
          <div className="col-xl-3">
            
          <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Experience Max</span><br/>
              <input name="ExperienceMax" onChange={this.handleChange} value={this.state.ExperienceMax}  className="form-control adduser mt-1" placeholder="Experience Max"/>
    
         
      
          </div>
          <div className="col-xl-3">
          <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Primary Skills</span><br/>
         
            
         <input value={this.state.PrimarySkills} 
         name="PrimarySkills" onChange={this.handleChange}
         className="form-control adduser mt-1" placeholder="Primary Skills"/>
          </div>
          </div>

          <div className="row mt-2">
              
              
             
              <div className="col-xl-6">
              <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Degree Name</span><br/>
              <Select 
              placeholder={this.state.Degreename}
       onChange={(e)=>this.handleDegree(e)}
       options={this.state.degreedata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
             
              </div>
              <div className="col-xl-6">
              <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Languages</span><br/>
              <Select 
       onChange={(e)=>this.handleDegree(e)}
       options={this.state.languagesdata}  components={{  IndicatorSeparator:() => null }}
                                 styles={style}/>
              </div>
              </div>
              <div className="row mt-2">
             
             <div className="col-xl-12">
             <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:13}}>Job Description</span><br/>
             <textarea value={this.state.JobDescription} className="form-control mt-2 adduser" rows="3" name="JobDescription" onChange={this.handleChange}>

             </textarea>
             </div>
             
              </div>

  
              <div className="row">
      <div className="col-xl-4"></div>
      <div className="col-xl-4 mt-3">
        <h5 style={{color:"rgb(0,147,69)",fontFamily:"Inter Bold"}}>{this.state.updatemsg}</h5>
      </div>
      <div className="col-xl-4"></div>
   </div>
  <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleUpdate}>Update</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.closeModal}>Cancel</button>
</div>
  </div>
 </div>
 
 {/**Add user form ends */}
          
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
      <div className={styles.pageContainer}>
       
        <div className={styles.pageBox}>
          <Row className={styles.marginBottom1}>
            <Col md={4}>
              
              <div class="input-group">
    <input type="text" class="form-control xxx" placeholder="Search"  onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown} />
    <div class="input-group-append">
      <button class="btn btn-primary" style={{marginBottom:"4em"}} type="button" onChange={this.handleInputChange}
                onClick={this.handleKeyDown}>
        <i class="fa fa-search"></i>
      </button>
    </div>
  </div>
            </Col>
           
  
            <Col md={8}>
              <div className={styles.displayFlex}>
                <a to="/add-document-category" exact className={styles.addLink}>
                 
                </a>
                
                
                 
                
                
               { /*<Button
                  variant="primary"
                  className={`${styles.marginLeft}`}
                  onClick={this.download_pdf}
                  style={{backgroundColor:"#4293FE",fontFamily:"Montserrat",width:"14%",fontWeight:"bold"}}
                >
                 
                 Export
                  </Button>*/}
               
              </div>
            </Col>
          </Row>
          <div className={styles.clientContainer_old} >
            <div className={styles.tableContainer}>
            {this.state.loadingicon==0?<>
            <img src={LoadingIcon} style={{marginLeft:"36em",width:"6%"}}/>
           </>:<></>}
           {this.state.loadingicon==1?<>
            <DataTable
                defaultSortField="id"
               
                columns={this.columns}
                data={this.state.rows}
                pagination
                striped
                dense
               
              />
           </>:<></>}
           {this.state.loadingicon==2?<>
            <div className="text-center" style={{paddingBottom:"3%"}}>
            <span style={{fontFamily:"Poppins Light",fontWeight:"bold"}}>{this.state.dataerrormsg}</span>
            </div>
            
            </>:null} 
           
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default DeletedJobposting;
