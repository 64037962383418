import React from "react";
import "./style.css";
import Mainteclogo  from "../images/mrecruit-19.png";
export class Register extends React.Component{
    constructor(){
        super();
        this.state={
            email:"",
            password:"",
            showpwd:0,
            forgotpwd:0
        }
       this.handleRegister=this.handleRegister.bind(this)
      }
      handleRegister(){
        window.location.href=`./`
      }
    render(){
        return(
            <>
              <div>
          {/* Background Image Starts */}
        <div className="backgroundimg">
           <img src={Mainteclogo} className="mainteclogo" />

            <div className="loginbox">
           
            </div>
            {/* First name Field Starts*/}
          
            <div className="row loginboxuser">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-2 userinputbox">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="text" placeholder="First Name" name="text" onChange={this.handleChange} />
                    </div>
                    <div className="col-sm-2 userinputbox">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="text" placeholder="Last Name" name="text" onChange={this.handleChange} />
                    </div>
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* First name Field End*/}

             {/* Contact Number Field Starts*/}
          
             <div className="row mt-4">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="text" placeholder="Contact Number" name="text" onChange={this.handleChange} />
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* Contact Number Field End*/}

             {/* Employee ID Field Starts*/}
          
             <div className="row mt-4">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="text" placeholder="Employee ID" name="text" onChange={this.handleChange} />
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* Employee ID Field End*/}

             {/* Email ID Field Starts*/}
          
             <div className="row mt-4">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="email" placeholder="Email ID" name="text" onChange={this.handleChange} />
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* Email ID Field End*/}

            {/* Password Field Starts*/}
                            
                    <div className="row mt-4">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <input autoComplete="off" className="form-control usernamebox shadow-none" type="password" placeholder="Password" name="text" onChange={this.handleChange} />
                    </div>
                    
                    <div className="col-sm-3"></div>
             </div>
          
            
              {/* Password Field End*/}
              {/* Password Validation Text Start */}
              <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-7 passwordvalidtxt">
                <table className="mt-2">
                    <tr>
                        <td className="passwordvalidationtxt">Password Must be 8 Characters or Longer</td>
                    </tr>
                    <tr>
                        <td className="passwordvalidationtxt">Combination of Upper and Lower Case letters, Numbers and Special Character</td>
                    </tr>
                </table>
                </div>
                </div>
              {/* Password Validtion Text End */}
             {/* Register button  Starts*/}
          
             <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4 mt-4">
                      <button className="btn loginbtn" onClick={this.handleRegister}>Register</button>
                    </div>
                    <div className="col-sm-3"></div>
             </div>
            
             
           
             <span className="mrecruitheadinguser">mRecruit</span>
             
             <span className="userdetailsheadingdetails">
           
              User Details
             
              </span>
            
        </div>
      </div>
            </>
        )
    }
}