import React from "react";
import Mainteclogo from "../images/mrecruit-19.png";
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Clientactiveuser from "./Clientactiveuser";
import Clientinactive from "./Clientinactive";
import Clientdelete from "./Clientdelete";
import Select from 'react-select'
import Clientimage  from "../images/clientimage.png";
import ActiveTeam from "./ActiveTeam";
import Clientpending from "./Clientpending";
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import ClientCategory from "./ClientCategory";
import ClientCategoryDelete from "./ClientCategoryDelete";
import PlusIcon from "../images/plus.png";
import SearchIcon from "../images/search.png";
import Menues from "../images/menus.png";
import User from "../images/user.png";
import UserImg from "../images/usering.png";
import FileIcon from "../images/fileicon.png";
import DeleteIcon from "../images/deleteicon.png";
import ClientFormat from "./ClientFormat";
import LoadingIcon  from "../images/loadingicon.gif";
const style = {
  control: (base, state) => ({
    ...base,
   border:"1px solid #A0BED8",
    borderRadius:"1px",
   marginTop:"1.5%",
   backgroundColor:"#EAF4FF",
   color:"black",
   borderRadius:"2px",
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: "1px solid #A0BED8",
      borderRadius:"1px",
      backgroundColor:"#EAF4FF",
      color:"black"
    }
  }),
 
};
export default class ClientSubmissionformat extends React.Component{
    constructor(){
        var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data1=splitURL[2]
    var data2=splitURL[3]
    var data3=splitURL[4]
    var data5=splitURL[5]
    var data6=splitURL[6]
    let decodedid=window.atob(data1)
    let decodedname=window.atob(data2)
    let decodedemail=window.atob(data3)
    let roleid=window.atob(data1)
          super();
        this.state={
            user_name:decodedname,
            clientlogo:" ",
            display:0,
            user_email:decodedemail,
            user_id:decodedid,
          
           encodedid:data1,
           encodedname:data2,
           encodedemail:data3,
          
           displayform:0,
           userroleids:roleid,
           encodedroleid:data5,
           clientmanagementscreen:1,
           clientcategoryscreen:0,
           businessunit:[
            { value: '1', label: 'Maintec Technologies' },
           
          ],
          userstausdata:[
            { value: '1', label: 'Active'},
            {value: '2', label: 'Inactive'},
            {value: '3', label: 'Pending' },
           
          ],
          documentdata:[
            { value: '1', label: 'CV' },
            { value: '2', label: 'Resume' },
            { value: '3', label: 'Others' },
          ],
          ReqDocument:"",
         countrydata:[],
         citydata:[],
         industrydata:[],
         catageroydata:[],
         Countryname:"",
         Countryid:"",
         statedata:[],
         reportingtodata:[],
         ClientLeadname:"",
         ClientLeadids:"",
         Industryname:"",
         Industryid:"",
         cname:"",
         POC1_conactname:"",
         POC1_email:"",
         POC1_contactno:"",
         POC2_conactname:"",
         POC2_email:"",
         POC2_contactno:"",
         POC3_conactname:"",
         POC3_email:"",
         POC3_contactno:"",
         cphone:"",
         Address:"",
         websiteurl:"",
         Stateid:"",
         Cityid:"",
         Countryname:"",
         Statename:"",
         Cityname:"",
         Postalcode:"",
         categoryid:"",
         CategoryName:"",
         cstatus:"",
         BusinessUnit:"",
         categoryname:"",
         clientsubmissionscreen:0,
         profilepic:" ",
         data:0,
         elements:[{fullname:"",salary:"",age:""}],
         clientdocname:"",
         uploaddocscreen:0,
         downloadscreen:1,
         docfile:"",
         doctypename:"",
         clientid:data6,
         submissiondata:[],
         uploadbtn:0,
         docfileerror:"",
         doctypenameerror:"",
         base64files:[],
         doctynamedata:[]

      
        }
        this.handleDisplay=this.handleDisplay.bind(this)
        this.handleDisplayClose=this.handleDisplayClose.bind(this)
        this.handleLogout=this.handleLogout.bind(this)
        this.handleJob=this.handleJob.bind(this)
        this.handleUser=this.handleUser.bind(this)
        this.handleHome=this.handleHome.bind(this)
        this.handlecloseAdmin=this.handlecloseAdmin.bind(this)
        this.handleAdmin=this.handleAdmin.bind(this)
        this.handleAdd=this.handleAdd.bind(this)
        this.handleClient=this.handleClient.bind(this)
        this.handleClientcat=this.handleClientcat.bind(this)
        this.handleCancel=this.handleCancel.bind(this)
        this.handleCountry=this.handleCountry.bind(this)
        this.hanldelState=this.handleState.bind(this)
        this.handleCity=this.handleCity.bind(this)
        this.handleReport=this.handleReport.bind(this)
        this.handleRequired=this.handleRequired.bind(this)
        this.handleIndustry=this.handleIndustry.bind(this)
        this.handleUserstatus=this.handleUserstatus.bind(this)
        this.handleRegister=this.handleRegister.bind(this)
        this.handleBusiness=this.handleBusiness.bind(this)
        this.handleCategory=this.handleCategory.bind(this)
        this.handleclosecat=this.handleclosecat.bind(this)
        this.handleSave=this.handleSave.bind(this)
        this.handleApplicant=this.handleApplicant.bind(this)
        this.handleClientSubmission=this.handleClientSubmission.bind(this)
        this.handleMaster=this.handleMaster.bind(this)
       
        this.handlePlus=this.handlePlus.bind(this)
        this.handleminus=this.handleminus.bind(this)
        this.handleupload=this.handleupload.bind(this)
        this.handledownload=this.handledownload.bind(this)
       this.handleclientformat=this.handleclientformat.bind(this)
       this.deleteFormat=this.deleteFormat.bind(this)
       this.handleUpdateImage=this.handleUpdateImage.bind(this)
    }
    handleUpdateImage(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
        const data={ userid:withoutQuotes,
          adduserdata:"editsinglephotoinfo",
          profilepic:this.state.profilepic
       
      }
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
               this.setState({message:response.message}) 
            //console.log(response)
            setTimeout(
              function() {
                window.location.reload()
              }
              .bind(this),
             2500
          );
           if(response.message=="Profile Pic Updated successfully"){
            const withoutQuotes = username.replaceAll('"', '');
            const data={ userid:withoutQuotes,
              adduserdata:"getsinglephotoinfo",
            
           
          }
            console.log(data)
            fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
                method: "POST",
        
                body: JSON.stringify(data), // data can be `string` or {object}!
        
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => res.json())
        
                .catch((error) => console.error("Error:", error))
        
                .then((response) => {
                  
               //console.log(response)
                   this.setState({ProfileImg:response.result.profilepic,showModal2:false},()=>{
                   
                          
                   })
               
                      
                    });
           }
            
                  
           
                  
                });
    }
    handleUser(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
    }
    handleupload(){
      this.setState({uploaddocscreen:0,downloadscreen:1})
    }
    handledownload(){
      this.setState({uploaddocscreen:1,downloadscreen:0})
    }
    handleminus(index){
      const rows = [...this.state.elements];
      rows.splice(index, 1);
      this.setState({elements:rows})
    }  
    handlePlus(){
      this.setState({elements:[...this.state.elements, {
        fullName:'',
        emailAddress:'',
        salary:''  
    }]})
    }  

  
    getBase64 = (file) => {
      return new Promise((resolve) => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          console.log("Called", reader);
          baseURL = reader.result;
          console.log(baseURL);
          resolve(baseURL);
        };
        console.log(fileInfo);
      });
    };
    handleImage = (e) => {
      
      // let file = new FileReader();
      console.log(e.target.files[0]);
      let { file } = this.state;
  
      file = e.target.files[0];
  
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;
          console.log("File Is", file);
          this.setState({
            profilepic: result,
            ProfileImg:result,
            file,
          });
        })
        .catch((err) => {
          console.log(err);
        });
  
      this.setState({
        //  file: e.target.files[0]
      });
  
      this.setState({
        //companylogo: e.target.files[0]
      });
      var fileInput = false;
      if (e.target.files[0]) {
        fileInput = true;
      }
      
      if(fileInput) {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              console.log(uri);
              this.setState({ newImage: uri });
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          console.log(err);
        }
      }
    };
    openModal2=()=>{
      this.setState({ showModal2: true });
    }
    closeModal2 = () => {
      this.setState({ showModal2: false });
    };
    openModal3=()=>{
      this.setState({ showModal3: true });
    }
    closeModal3 = () => {
      this.setState({ showModal3: false,docfile:"",doctypename:"" });
    };
   deleteFormat(id){
    var username=JSON.stringify(localStorage.getItem("UserId"))
    const withoutQuotes = username.replaceAll('"', '');
    var newURL=window.location.pathname;
    var splitURL=newURL.toString().split("/");
    var data6=splitURL[6]
      const data={ userid:withoutQuotes,
        addctsubmissiondata:"deletects",
        clientid:data6,
        editctsubmissionid:id
    }
      console.log(data)
      fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
          method: "POST",
  
          body: JSON.stringify(data), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
             this.setState({message:response.message}) 
          //console.log(response)
       
          Swal.fire(
            response.message,
            
            'success'
          )
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
          2500
        );
         
                
              });
   }
    handleclientformat(){
      if(this.state.doctypename==""){
        this.setState({doctypenameerror:"Please Enter Doc Type name."})
      }
      else if(this.state.docfile==""){
        this.setState({docfileerror:"Please Choose a Document."})
      }
      else{
        this.setState({uploadbtn:1,message:"Uploading...."})
        var username=JSON.stringify(localStorage.getItem("UserId"))
        const withoutQuotes = username.replaceAll('"', '');
        var newURL=window.location.pathname;
        var splitURL=newURL.toString().split("/");
        var data6=splitURL[6]
        console.log(data6)
          const data={ userid:withoutQuotes,
            addctsubmissiondata:"addctsinfo",
            clientid:data6,
            doctypename:this.state.doctypename,
            docfile:this.state.docfile
        }
          console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
                 this.setState({message:response.message}) 
              //console.log(response)
         
              setTimeout(
                function() {
                  window.location.reload()
                }
                .bind(this),
              2500
            );
             
                    
                  });
      }
     
    }
    handleMaster(){
      let data=window.location.pathname.slice(0,0);
      window.location.href=`${data}/mastersettings/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
    }
    handleSave(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const c_data={userid:withoutQuotes,addclientdata:"addclientcategoryinfo",categoryname:this.state.categoryname}
      console.log(c_data)
      fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
        method: "POST",

        body: JSON.stringify(c_data), // data can be `string` or {object}!

        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())

        .catch((error) => console.error("Error:", error))

        .then((response) => {

          Swal.fire(
            response.message,
            
            'success'
          )
          setTimeout(
            function() {
              window.location.reload()
            }
            .bind(this),
          2500
        );
          
           console.log(response)
  
              
            });
    }
    handleChange = (e) => {
      //console.log(e.target.value)
      if(this.state.doctynamedata.includes(e.target.value)){
        this.setState({doctypenameerror:"The Document Name is already exist.",uploadbtn:1})
      }
      else if(this.state.docfileerror=="This Document already exist."){
        this.setState({uploadbtn:1,doctypenameerror:""})
      }
      else{
        var regex = /[!@#\$%\^\&*\)\(+=._-``"",:;<>?|{}/-]/g;
        e.target.value = e.target.value.replace(regex, "");
        this.setState({
          [e.target.name]: e.target.value,
          doctypenameerror:"",
          uploadbtn:0
    
        });
      }
     
      
    };
    handleChangetwo = (e) => {
    	document.getElementById("file-name").innerHTML = e.target.files[0].name;
      
    };
    handleCategory(e){
      this.setState({CategoryName:e.label,categoryid:e.value},()=>{
       
      })
    }
    handleBusiness(e){
      this.setState({BusinessUnit:e.label},()=>{
       
      })
    }
  
    handleRegister(){
      const data={
        userid:this.state.user_id,
        addclientdata:"addclientinfo",
        clientlogo:this.state.clientlogo,
        cname:this.state.cname,
        BusinessUnit:this.state.BusinessUnit,
        POC1_conactname:this.state.POC1_conactname,
        POC1_email:this.state.POC1_email,
        POC1_contactno:this.state.POC1_contactno,
        POC2_conactname:this.state.POC2_conactname,
        POC2_email:this.state.POC2_email,
        POC2_contactno:this.state.POC1_contactno,
        POC3_conactname:this.state.POC3_conactname,
        POC3_contactno:this.state.POC3_contactno,
        POC3_email:this.state.POC3_email,
        cphone:this.state.cphone,
        Address:this.state.Address,
        BusinessUnit:this.state.BusinessUnit,
        websiteurl:this.state.websiteurl,
        Countryid:this.state.Countryid,
        Stateid:this.state.Stateid,
        Cityid:this.state.Cityid,
        Countryname:this.state.Countryname,
        Statename:this.state.Statename,
        Cityname:this.state.Cityname,
        Postalcode:this.state.Postalcode,
        Industryid:this.state.Industryid,
        Industryname:this.state.Industryname,
        categoryid:this.state.categoryid,
        CategoryName:this.state.CategoryName,
        AboutCompany:this.state.AboutCompany,
        cstatus:this.state.cstatus,
        ClientLeadids:this.state.ClientLeadids,
        ClientLeadname:this.state.ClientLeadname,
        ReqDocument:this.state.ReqDocument
      }
      console.log(data)
          fetch("https://www.mrecruit.in:5200/api/getClientdetails", {
              method: "POST",
      
              body: JSON.stringify(data), // data can be `string` or {object}!
      
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
      
              .catch((error) => console.error("Error:", error))
      
              .then((response) => {
      
                Swal.fire(
                  response.message,
                  
                  'success'
                )
                setTimeout(
                  function() {
                    window.location.reload()
                  }
                  .bind(this),
                  3000
              );
                
                 console.log(response)
        
                    
                  });
        }
    handleUserstatus(e){
      this.setState({cstatus:e.value}) 
    }
    handleIndustry(e){
      this.setState({Industryname:e.label,Industryid:e.value})
    }
    handleRequired(e){
      this.setState({ReqDocument:e.value})
    }
    handleReport(e){
      this.setState({ClientLeadname:e.label,ClientLeadids:e.value})
    }
    handleCity(e){
      this.setState({Cityname:e.label,Cityid:e.value})
    }
    handleState(e){
      this.setState({Statename:e.label,Stateid:e.value,},()=>{
        const data={ dropdownlist_input: "getcityinfo",stateid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({citydata:options})
                  
                });
        })
    }
    handleCountry(e){
      this.setState({Countryname:e.label,Countryid:e.value,},()=>{
        const data={ dropdownlist_input: "getstateinfo",countryid:e.value}
        console.log(data)
        fetch("https://www.mrecruit.in:5200/api/getdropdownlist", {
            method: "POST",
    
            body: JSON.stringify(data), // data can be `string` or {object}!
    
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
    
            .catch((error) => console.error("Error:", error))
    
            .then((response) => {
                
             const data = response.result
              //console.log(json)
        
            const options = data.map(d => ({
                "value" : d.id,
                "label" : d.name
    
              })) 
       
            this.setState({statedata:options})
                  
                });
        })
    }
    componentDidMount(){
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes1 = username.replaceAll('"', '');
      var newURL=window.location.pathname;
      var splitURL=newURL.toString().split("/");
      var data6=splitURL[6]
      const pdata1={ userid:withoutQuotes1,
        addctsubmissiondata:"getctsubmissioninfo",
        clientid:data6
      
     
    }
    //console.log(pdata1)
      fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
          method: "POST",
  
          body: JSON.stringify(pdata1), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
           // console.log(response)
           if(response.message=="Data Retrived successfully"){
            this.setState({submissiondata:response.result})
           }
           else{
            this.setState({submissiondata:[]})
           }
            
              });
      var username=JSON.stringify(localStorage.getItem("UserId"))
      const withoutQuotes = username.replaceAll('"', '');
      const pdata={ userid:withoutQuotes,
        adduserdata:"getsinglephotoinfo",
      
     
    }
      fetch("https://www.mrecruit.in:5200/api/getUserdetails", {
          method: "POST",
  
          body: JSON.stringify(pdata), // data can be `string` or {object}!
  
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
  
          .catch((error) => console.error("Error:", error))
  
          .then((response) => {
            
            if(response.result.profilepic=="BASE64"){
              this.setState({ProfileImg:""},()=>{
               
              })
          
           }
           else{
              this.setState({ProfileImg:response.result.profilepic},()=>{
               
              })
          
           }
         
                
              });
          

                      var username=JSON.stringify(localStorage.getItem("UserId"))
                      const withoutQuotes11 = username.replaceAll('"', '');
                      var newURL=window.location.pathname;
                      var splitURL=newURL.toString().split("/");
                      var data6=splitURL[6]
                      const data={userid:withoutQuotes11,addctsubmissiondata:"getctsubmissioninfo",clientid:data6}
                     
                      fetch("https://www.mrecruit.in:5200/api/getclientsubmissioninfo", {
                          method: "POST",
                  
                          body: JSON.stringify(data), // data can be `string` or {object}!
                  
                          headers: { "Content-Type": "application/json" },
                        })
                          .then((res) => res.json())
                  
                          .catch((error) => console.error("Error:", error))
                  
                          .then((response) => {
                           response.result.map((e)=>{
                            this.setState({base64files:this.state.base64files.concat([e.docfile])})
                           })
                           response.result.map((e)=>{
                            this.setState({doctynamedata:this.state.doctynamedata.concat([e.doctypename])})
                           })
                            //console.log(this.state.base64files)
                             
                              });
                             
      
    }
    handleCancel(){
      this.setState({displayform:0})
    }
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  handleImage = (e) => {
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          clientlogo: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  clientDoc = (e) => {
   
    document.getElementById("file-name").innerHTML = e.target.files[0].name;
    // let file = new FileReader();
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
       // console.log("File Is", file);
       if(this.state.base64files.includes(result)){
        this.setState({docfileerror:"This Document already exist.",uploadbtn:1})
      }
      else if(this.state.doctypenameerror=="The Document Name is already exist."){
        this.setState({uploadbtn:1,docfileerror:""})
      }
      else{
        this.setState({
          docfile: result,
          file,
          docfileerror:"",
          uploadbtn:0
        });
      }
        
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      //  file: e.target.files[0]
    });

    this.setState({
      //companylogo: e.target.files[0]
    });
    var fileInput = false;
    if (e.target.files[0]) {
      fileInput = true;
    }
    
    if(fileInput) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          120,
          120,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
          },
          "base64",
          120,
          120
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  handleClientSubmission(){
    this.setState({clientcategoryscreen:0,clientmanagementscreen:0,clientsubmissionscreen:1})
        }
    handleClientcat(){
      this.setState({clientcategoryscreen:1,clientmanagementscreen:0,clientsubmissionscreen:0})
    }
    handleClient(){
      this.setState({clientmanagementscreen:1,clientcategoryscreen:0,clientsubmissionscreen:0})
    }
    handleclosecat(){
      this.setState({clientcategoryscreen:0,clientmanagementscreen:1,clientsubmissionscreen:0})
          }
    handlecloseAdmin(){
        this.setState({on:0})
      }
      handleAdmin(){
    this.setState({on:1})
      }
      handleAdd(){
        this.setState({displayform:1})
      }
      handleApplicant(){
        let data=window.location.pathname.slice(0,0);
  
     window.location.href=`${data}/applicants/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      handleJob(){
        let data=window.location.pathname.slice(0,0);
    window.location.href=`${data}/jobposting/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}` 
      }
      handleDisplay(){
        this.setState({display:1})
      }
      handleDisplayClose(){
        this.setState({display:0})
      }
      handleLogout(){
        localStorage.clear()
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/`
      
      }
     handleHome(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/dashboard/${this.state.encodedemail}/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedroleid}`
     }
      handleUser(){
        let data=window.location.pathname.slice(0,0);
        window.location.href=`${data}/usermanagement/${this.state.encodedid}/${this.state.encodedname}/${this.state.encodedemail}/${this.state.encodedroleid}`
      }
    render(){
        return(
            <>
              {/* Header Section Starts */}

        <div className="dashboardheader fixed-top">
          <img src={Mainteclogo} className="dashboardlogo" />
          <ul className="dashboardmenu">
            <li style={{cursor:"pointer"}} onClick={()=>window.history.back()}>Home</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} >Job Posting</li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li  style={{cursor:"pointer"}} onClick={()=>window.history.back()}>Applicants</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li style={{cursor:"pointer"}} onClick={()=>window.history.back()} className="litext">Clients</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li>Onboardings</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

           <li style={{cursor:"pointer"}} onClick={()=>window.history.back()}>User Management</li>
          
          </ul>
          <div class="socialWrap">
          <table style={{margin:"auto"}}>
            <tr>
            {/**  <th> <img src={PlusIcon} className="icondashboard"/></th>
              <th> <img src={SearchIcon} className="icondashboard"/></th>
              <th> <img src={Menues} className="icondashboard"/></th> */}
              <th >          {this.state.display==1?<>
                <img src={this.state.ProfileImg==""?`${User}`:`${this.state.ProfileImg}`} className={this.state.ProfileImg==""||this.state.ProfileImg==null?"icondashboard":"changeimg"} onClick={this.handleDisplayClose} />
          </>:<> <img src={this.state.ProfileImg==""?`${User}`:`${this.state.ProfileImg}`} className={this.state.ProfileImg==""||this.state.ProfileImg==null?"icondashboard":"changeimg"} onClick={this.handleDisplay}/></>} </th>&nbsp;&nbsp;
          <th>
          <span className="username" style={{marginLeft:"auto"}}>Hi,{this.state.user_name}</span>
            {this.state.display==1?<>
                <div class="dropdown-content" style={{marginLeft:this.state.ProfileImg==""?null:"-6em"}}>
                <i class="fa fa-user-circle" aria-hidden="true"></i>&nbsp;&nbsp;{this.state.user_name}<br/>
                <i class="fa fa-picture-o" aria-hidden="true"></i><a href="#ft-changephoto-modal" style={{cursor:"pointer",lineHeight:"30px"}} onClick={()=>this.openModal2()} className="changeprofilepic">&nbsp;&nbsp;Change Profile Photo</a><br/>
              <i class="fa fa-cog" aria-hidden="true"></i><span span  onClick={this.handleMaster} style={{cursor:"pointer",lineHeight:"30px"}}>&nbsp;&nbsp;Master Settings</span><br/>
              <span style={{lineHeight:"22px"}} onClick={this.handleLogout}><i class="fa fa-reply" aria-hidden="true"></i>&nbsp;&nbsp;Logout</span>
    
            </div>
            </>:<div style={{display:"none"}}>
              <span><i style={{lineHeight:"30px"}} class="fa fa-reply" aria-hidden="true" ></i> Logout</span>
            </div>}
          </th>
            </tr>
          </table>
          </div>
        </div>

        {/* Header Section Ends */}
                 {/**Change Profile Image Modal starts */}
    {this.state.showModal2 && (
        <div class="ft-modal" id="ft-changephoto-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Change Profile Photo
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
            <div className="row mt-1">
              <div className="col-xl-3"></div>
    <div className="col-xl-3">
    {this.state.ProfileImg=="" || this.state.ProfileImg==null?<>
<img src={UserImg} 
 
 style={{width:"90%",border:"2px solid #4293fe"}}  />
</>:<>  <img src={this.state.ProfileImg} 
 
 style={{width:"80%",border:"2px solid #4293fe"}}  /></>}
    </div>
    <div className="col-xl-3">
    <input id="upload" type="file" 
     name="clientlogo" onChange={this.handleImage} 
     hidden
     />
                                  <label id="actual-btn" for="upload">
                                    Upload 
                                  </label>
   
    </div>
  </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
<button className="btn btn-primary" onClick={this.handleUpdateImage}>Update</button>
</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={this.closeModal2}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Change Profile Image Modal ends */}



              {/**Client Submission Format Modal starts */}
              {this.state.showModal3 && (
        <div class="ft-modal" id="ft-clientsubmission-modal" style={{top:"0%"}}>
          <div class="ft-modal-content" style={{width:"43%"}}>
            <div class="ft-modal-header">
              
              <h5
                    class="modal-title"
                    id="exampleModalLabel"
                    style={{
                      textAlign: "center",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                  >
                    Add Document
                  </h5>
          
           <hr/>
            </div>
            <div class="ft-modal-body">
      <div className="row">
        <div className="col-xl-12">
        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Type Document Name</span>
        <p><input type="text" className="form-control adduser mt-2" onChange={this.handleChange} name="doctypename" />
        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"red",fontSize:13}}>{this.state.doctypenameerror}</span>
        </p>
        </div>

      </div>

      <div className="row">
        <div className="col-xl-12">
        <span style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:14}}>Choose Document</span>
       <p>
       <div class="inputfile-box">
  <input type="file" style={{height:"2.7em"}} id="file" name="docfile" class="inputfile"  onChange={this.clientDoc} />
  <label for="file">
    <span id="file-name" class="file-box"></span>
    <span class="file-button">
      <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;
      Choose a File
    </span>
  </label>
</div>
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"red",fontSize:13}}>{this.state.docfileerror}</span>
       </p>

        </div>

      </div>
                      <div className="row mt-3" style={{marginBottom:"3%",marginTop:"4%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-4" style={{textAlign:"center"}}>
{this.state.uploadbtn==0?<>
  <button className="btn btn-success updatebtn" style={{width:"inherit",backgroundColor:"#26CE55",marginTop:"5%"}} onClick={this.handleclientformat}>Upload</button>
</>:<>
<button className="btn btn-secondary updatebtn" disabled style={{width:"inherit",marginTop:"5%"}} onClick={this.handleclientformat}>Upload</button>
</>}

</div>
<div className="col-md-4">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom:"2%",marginTop:"2%"}}>
                        <div className="col-md-4">

                        </div>
                        <div className="col-md-8">
<span style={{fontFamily:"Poppins Light",fontWeight:"bold",color:"#4293FE"}}>{this.state.message}</span>
</div>

                      </div>     
            </div>
            <div class="ft-modal-footer">
              <a class="ft-modal-close"  data-dismiss="modal"
                       onClick={()=>this.closeModal3()}>
                <i class="fa fa-times" style={{color:"black",cursor:"pointer"}}></i>{" "}
              </a>
            </div>
          </div>
        </div>
       )}
    {/**Client Submission Format Modal ends */}
        <div>
              {/**Side Bar starts */}
              <div class="sidebar">
               
  {this.state.clientsubmissionscreen==0?<>
    <a className="access" style={{fontSize:16}}>Client Submission Format</a>
  </>:<> <a onClick={this.handleClientSubmission} className="usermgmtmenu">Client Submission Format</a></>}
  
  
  
 
  
 
</div>
{/**Side bar ends */}


  {/* Client Submission Screen Starts */}
{this.state.clientsubmissionscreen==0?<>
  <div class="content">
  <div class="row">
    <div class="col-xl-11">
    <h2 style={{fontFamily:"Inter Bold"}}>Client Name</h2>
    </div>
    <div class="col-xl-1">
      <button className="btn btn-primary updatebtn" onClick={()=>window.history.back()}>Back</button>
    </div>
    
</div>
<hr/>
{this.state.submissiondata.length!=0?<>

<ClientFormat/>

 {/** {this.state.submissiondata.map((e)=>(
    <>
<div className="row">
<div className="col-xl-10">
<h5>{e.doctypename}</h5>
<p style={{fontFamily:"Poppins Light",fontWeight:"bold",fontSize:13}}>Uploaded by {e.uploadedbyname} <br/>
{e.createddatetext}
</p>

</div>
<div className="col-xl-2">
<a href={e.docfile} download={e.doctypename} className="btn btn-primary updatebtn">Download</a><img onClick={()=>this.deleteFormat(e.id)} className="subdeleteicon" src={DeleteIcon}/>
</div>
</div>
<hr/>
    </>
  ))} */}
  <div className="text-center">
  <p><a href="#ft-clientsubmission-modal" onClick={()=>this.openModal3()} className="btn btn-success updatebtn" style={{fontSize:14,backgroundColor:"#26CE55",marginTop:"0%",border:"1px #26CE55"}}>Add Documents</a></p>
  </div>
</>:<>

  <div className="text-center" style={{marginTop:"14%"}}>
<img src={FileIcon} style={{width:"7%"}}/>
<p style={{marginTop:"1%",fontFamily:"Poppins Light",fontWeight:"bold"}}>Lets Add Some Documents</p>
<p><a href="#ft-clientsubmission-modal" onClick={()=>this.openModal3()} className="btn btn-success updatebtn" style={{fontSize:14,backgroundColor:"#26CE55",marginTop:"0%",border:"1px #26CE55"}}>Add Documents</a></p>
</div>
</>}



</div>
</>:null}
{/* Client Submission  Screen Ends */}   


  {/* Team Screen Starts */}
  {this.state.teamsscreen==1?<>
  <div class="container" style={{padding: "3% 16px",marginLeft:"22%"}}>
  <div className="row" style={{borderBottom:"2px solid"}}>
    <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
       Teams
      </h2></div>
      <div className="col-xl-3" style={{textAlign:"right"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"3%"}}
      onClick={this.handleaddTeam}
      >Add Team</button>
      </div>
     
     </div>
     <div className="row mt-3">
    <div className="col-xl-12">
<ActiveTeam/>

    </div>
  </div> 

 
 
 
  
</div>
</>:<>

</>}


{/* Team Screen Ends */}  
{this.state.addteam==1?<>
  <div class="container" style={{padding: "3% 16px",marginLeft:"22%"}}>
  <div className="row" style={{borderBottom:"2px solid"}}>
    <div className="col-xl-9"><h2 style={{fontFamily:"Inter Bold"}}>
       Teams
      </h2></div>
    
      <div className="col-xl-3" style={{textAlign:"right"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"3%"}}
      onClick={this.handlebackTeam}
      >Back</button>
      </div>
     </div>
     <div className="row mt-3">
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Business Unit</span>
  <Select 
    onChange={(e)=>this.handleBusiness(e)}
    options={this.state.businessunit}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Name</span>
  <input type="text" placeholder="Team Name" onChange={this.handleChange} className="form-control mt-2 adduser" name="teamname" />
  </div>
  <div className="col-xl-4">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Lead</span>
  <Select 
    onChange={(e)=>this.handleReport(e)}
    options={this.state.reportingtodata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
  </div>
     </div>
     <div className="row mt-3">
      <div className="col-xl-4">
      <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Role</span>
      <Select 
    onChange={(e)=>this.handleRoles(e)}
    options={this.state.rolesdata}  components={{  IndicatorSeparator:() => null }}
                              styles={style}/>
      </div>
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Description</span>
  <textarea rows="1" className="form-control adduser mt-2" placeholder="Description" 
  name="Description" onChange={this.handleChange}>
   </textarea>
  </div>
 
     </div>
     <div className="row mt-3">
  <div className="col-xl-8">
  <span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>Team Data Access Permissions
  </span><br/>
  <input type="radio" value="1" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Data is shared across the team members</span>
  <br/>
  <input type="radio" value="1" name="DataPermission" onChange={this.handleChange} /><span style={{fontWeight:"bold",fontFamily:"Montserrat",fontSize:14}}>&nbsp;&nbsp;Only team lead can view the data of team members</span>
  </div>
 
     </div>
 
     <div className="row">
  <div className="col-xl-10">

  </div>
  <div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handleRegisterteam}>Register</button>
</div>
<div className="col-xl-1">
<button className="btn btn-primary updatebtn" onClick={this.handlebackTeam}>Cancel</button>
</div>
  </div>
 
  
</div>
</>:<>

</>}

  {/* User Restrictions Screen Starts */}
  {this.state.userfieldscreen==1?<>
  <div class="container" style={{padding: "3% 16px",marginLeft:"22%"}}>
  <div className="row" style={{borderBottom:"2px solid"}}>
    <div className="col-xl-5"><h2 style={{fontFamily:"Inter Bold"}}>
      User Field Restrictions
      </h2></div>
      <div className="col-xl-3"></div>
      <div className="col-xl-2" style={{marginLeft:"6%"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"3%"}}>Restore to default</button>
      </div>
      <div className="col-xl-1" style={{marginLeft:"-3%"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"8%"}}>Save</button>
      </div>
      <div className="col-xl-1" style={{marginLeft:"-3%"}}>
      <button style={{backgroundColor:"#4293FE",color:"white",fontFamily:"Poppins Light",border:"none",padding:"8%"}}>Cancel</button>
      </div>
     </div>
     <div className="row mt-2">
    <div className="col-xl-12">
    <span>Lorem ipsum is placeholder text commonly used in the graphic,</span>
    </div>
     </div>
     <div className="row mt-3">
    <div className="col-xl-12">
 
<table class="table" >
  <thead style={{backgroundColor:"#4293FE",color:"white"}}>
    <tr>
      <th scope="col" style={{fontSize:22}}>Modules</th>
      <th scope="col">Admin</th>
      <th scope="col">IT Admin</th>
      <th scope="col">Management</th>
      <th scope="col">Acc manager</th>
      <th scope="col">Assoc Acc manager</th>
      <th scope="col">Delivery Lead</th>
      <th scope="col">Recruiter</th>
    </tr>
  </thead>
  <tbody style={{fontFamily:"Inter Bold",fontSize:11}}>
    <tr className="accesstbl">
      <td>Login Module</td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
    <td>User Management Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr className="accesstbl">
    <td>Applicant Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr >
    <td>Job (Requirements) Module</td>
     
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr className="accesstbl">
    <td>Client Management Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
    <td>Search Select Update and Email generation Module</td>
    <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
     <tr className="accesstbl">
     <td>Dashboard Module</td>
     <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
      <td>Profiles Search and Import Module</td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr className="accesstbl">
    <td>OnBoarding Management</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
    <tr>
    <td>Settings Module</td>
    <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-times-circle" aria-hidden="true" style={{color:"red"}}></i></td>
      <td><i class="fa fa-check-circle" aria-hidden="true" style={{color:"#4293FE"}}></i></td>
    </tr>
  </tbody>
</table>

    </div>
  </div> 
 
 
  
</div>
</>:null}
{/* User Restrictions Screen Ends */}       


        </div>











           
      
            </>
        )
    }
}